import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { findEntitiesInState } from '../../draft/find-entities-in-editor-state.util';
import { convertFromRaw, EditorState } from 'draft-js';
import { toJS } from 'mobx';
import { draftRequiredValidator } from '../../validators/draft-required-validator';

export interface IPlainTextEditComponentProps {
  placeholder?: string;
  multiLine?: boolean;
}

interface IReturnType {
  placeholder: (placeholder: string) => IEditModeReturnValue<IPlainTextEditComponentProps, any, IReturnType>;
  isMultiLine: (multiline?: boolean) => IEditModeReturnValue<IPlainTextEditComponentProps, any, IReturnType>;
  required: (messageKey?: string, messageProps?: string[]) => IEditModeReturnValue<IPlainTextEditComponentProps, any, IReturnType>;
}

export const plainTextEdit = (componentProps: IPlainTextEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IPlainTextEditComponentProps, any, IReturnType> = returnEditMode<IPlainTextEditComponentProps, any, IReturnType>('plainText', componentProps) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.isMultiLine = (multiline: boolean = true) => {
    toReturn._decoratorProperties.componentProps.multiLine = multiline;
    return toReturn;
  };
  toReturn.setUsedMentionsReducer((data: any) => {
    if (data) {
      const state = EditorState.createWithContent(convertFromRaw(toJS(data)));
      const entities = findEntitiesInState(state, 'MENTION');
      return entities.map((foundEntity) => foundEntity.entity.getData());
    }
    return [];
  });
  toReturn.required = (messageKey: string = 'validation.requiredField', messageProps: string[] = []) => {
    return toReturn.withValidator(draftRequiredValidator(messageKey, messageProps));
  };
  return toReturn;
};
