import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { IHttpUpdateTagManagerSettingsPayload } from '../../../action/http.action';

export const httpUpdateTagManagerSettings = (tagManagerId: string, trackCustomEvents: boolean, countryCode: string, language: string, bookingEngine: string, businessArea: string) => returnAction<IHttpUpdateTagManagerSettingsPayload>(ActionEnum.HTTP_UPDATE_TAG_MANAGER_SETTINGS, {
  tagManagerId,
  trackCustomEvents,
  countryCode,
  language,
  bookingEngine,
  businessArea
});
