import {observable} from 'mobx';
import {BlockTypeEnum} from '../../enum/block/block-type.enum';
import {editable} from './decorators/editable.decorator';
import {editGroup} from './decorators/edit-group.decorator';
import {blockType} from './decorators/block-type.decorator';
import {blockBackgroundColor} from './decorators/block-background-color.decorator';
import {authenticatedBlock} from './decorators/authenticated-block.decorator';
import {webDocumentBlock} from './decorators/web-document-block.decorator';
import {pageBlock} from './decorators/page-block.decorator';
import {jobadBlock} from './decorators/jobad-block.decorator';
import {BaseBlockDataModel} from './base-block-data.model';
import {customListBlock} from './decorators/custom-list-block.decorator';
import {enumPickerEdit} from '../../util/block/edit-types/enum-picker-edit.util';
import {VideoBlockTypes} from '../../enum/block/video-block-types';
import {linkEdit} from '../../util/block/edit-types/link-edit.util';
import {LinkOriginEnum} from '../../enum/link-origin.enum';
import {ILink} from '../../interface/page/link.interface';
import {getLinkFinalURL} from '../../util/url/get-link-final-url';
import {youtubeUrlValidator} from '../../util/validators/youtube-url-validator';
import {vimeoUrlValidator} from '../../util/validators/vimeo-url-validator';

@pageBlock
@customListBlock
@jobadBlock
@webDocumentBlock
@authenticatedBlock
@blockType(BlockTypeEnum.VideoBlock)
@blockBackgroundColor('PrimaryBackgroundColor')
export class VideoBlockModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(enumPickerEdit()
      .label('video-block.type')
      .addOption('video-block.youtube', VideoBlockTypes.Youtube)
      .addOption('video-block.vimeo', VideoBlockTypes.Vimeo))
  @observable type: VideoBlockTypes = VideoBlockTypes.Youtube;

  @editGroup.CONTENT
  @editable(linkEdit()
      .label('video-block.youtube-url')
      .availableOrigin([LinkOriginEnum.External, LinkOriginEnum.Mention])
      .withValidator((value: ILink, ov, d, c, state) => value && value.origin === LinkOriginEnum.External ?
          youtubeUrlValidator('video-block.invalid-youtube-video')(getLinkFinalURL(state, value, undefined, true), ov, d, c, state) :
          ([{isValid: true, messageKey: ''}]))
      .visibleWhen((data) => data.type === VideoBlockTypes.Youtube))
  @observable youtubeUrl: ILink;

  @editGroup.CONTENT
  @editable(linkEdit()
      .label('video-block.vimeo-url')
      .availableOrigin([LinkOriginEnum.External, LinkOriginEnum.Mention])
      .withValidator((value: ILink, ov, d, c, state) => value && value.origin === LinkOriginEnum.External ?
          vimeoUrlValidator('video-block.invalid-vimeo-video')(getLinkFinalURL(state, value, undefined, true), ov, d, c, state) :
          ([{isValid: true, messageKey: ''}]))
      .visibleWhen((data) => data.type === VideoBlockTypes.Vimeo))
  @observable vimeoUrl: ILink;
}
