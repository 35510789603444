import { observable } from 'mobx';
import { ColorModel } from './color.model';
import { FontModel } from './font.model';
import { persist } from 'mobx-persist';
import { LogoModel } from './logo.model';
import { CookiePolicyModel } from './cookie-policy.model';
import { SpinnerModel } from './spinner.model';
import { MainMenuModel } from './main-menu.model';

export class SettingsModel {
  @persist @observable isConfigured: boolean;
  @persist @observable siteName: string;
  @persist @observable publishedFrom: string;
  @persist @observable publishedTo: string;
  @persist @observable buttonThemeId: number = 0;
  @persist('object', SpinnerModel) @observable spinnerConfig: SpinnerModel = new SpinnerModel();
  @persist('object', MainMenuModel) @observable mainMenu: MainMenuModel = new MainMenuModel();
  @persist('list', ColorModel) @observable colors: ColorModel[];
  @persist('list', FontModel) @observable fonts: FontModel[];
  @persist('list', LogoModel) @observable logos: LogoModel[];
  @persist('object', CookiePolicyModel) @observable cookiePolicy: CookiePolicyModel = new CookiePolicyModel();
}
