import { FieldDefinitionModel } from '../field-definition.model';
import { customListFieldType } from '../decorators/custom-list-field-type';
import { CustomListFieldDefinitionTypeEnum } from '../../../enum/custom-lists/custom-list-field-definition-type.enum';
import { BooleanFieldOptionsModel } from './options/boolean-field-options.model';
import { IBooleanFieldOptions } from '../../../interface/custom-lists/boolean-field-options.interface';

@customListFieldType(CustomListFieldDefinitionTypeEnum.Boolean)
export class BooleanFieldDefinitionModel extends FieldDefinitionModel<BooleanFieldOptionsModel> {
  setFieldOptions = (data: IBooleanFieldOptions) => {
    this.fieldOptions = new BooleanFieldOptionsModel();
    Object.assign(this.fieldOptions, data);
  }
}
