import { observable } from 'mobx';
import { BaseBlockDataModel } from './base-block-data.model';
import { persist } from 'mobx-persist';
import { AuthenticationEnum } from '../../enum/authentication.enum';
import { BlockModel } from './block.model';
import { IAuthenticatedBlock } from '../../interface/block/authenticated-block.interface';

export class AuthenticatedBlockModel<T extends BaseBlockDataModel = BaseBlockDataModel> extends BlockModel implements IAuthenticatedBlock {
  @persist @observable authentication: AuthenticationEnum = AuthenticationEnum.Public;
  @persist('list') @observable roles: string[] = [];
}
