import { jwtStorage } from '../storage/jwt-storage';
import { isTokenExpired } from './is-token-expired.util';
import { refreshTokenSilent } from './refresh-token-silent.util';
import { ConfigurationModel } from '../../model/configuration.model';

export function refreshIfTokenExpired(configuration: ConfigurationModel, client): Promise<void> {
  if (isTokenExpired(jwtStorage.getAccessToken())) {
    return refreshTokenSilent(configuration, client);
  }

  return Promise.resolve();
}
