import { ConfigurationModel } from '../../model/configuration.model';
import { joinUrl } from './join-url.util';

export function getCDNUrl(configuration: ConfigurationModel, uri: string) {
  if (!uri) {
    return undefined;
  }

  if (uri.indexOf('http') === 0) {
    return uri;
  }

  return joinUrl(configuration.CDNUrl, uri);
}
