import { toJS } from 'mobx';
import { uniq } from 'lodash';
import { DesignObjectModel } from '../design-object.model';
import { getEditableDecorators } from '../../util/object/get-editable-decorators.util';
import { EDIT_CONFIGS_KEY } from './decorators/editable.decorator';
import { findLinkedData } from '../../util/page/find-linked-data.util';

export class BaseBlockDataModel {
  getUsedMentions(design: DesignObjectModel): string[] {
    const decorators = getEditableDecorators(this);
    const allDecoratedKeys = Object.keys(decorators);
    return uniq(allDecoratedKeys.reduce((accumulator, currentKey) => {
      if (decorators[ currentKey ][ EDIT_CONFIGS_KEY ]) {
        for (const editConfig of decorators[ currentKey ][ EDIT_CONFIGS_KEY ]) {
          if (!this[ currentKey ]) {
            continue;
          }
          const currentUsedMentions = editConfig.usedMentionsReducer(this[ currentKey ], design, (mention) => findLinkedData(mention, design)).map(m => m.id);
          if (currentUsedMentions && currentUsedMentions.length > 0) {
            accumulator = accumulator.concat(currentUsedMentions);
          }
        }
      }
      return accumulator;
    }, []));
  }

  toString() {
    return JSON.stringify(toJS(this));
  }
}
