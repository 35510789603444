import { observable } from 'mobx';
import { persist } from 'mobx-persist';
import { ISettingsColor } from '../../interface/settings.interface';
import { getUniqueId } from '../../util/string/get-unique-id.util';

export class ColorModel implements ISettingsColor {
  static buildNewColorModel(): ColorModel {
    const newColorModel = new ColorModel();
    newColorModel.id = getUniqueId();
    newColorModel.predefined = false;
    newColorModel.hue = 0;
    newColorModel.light = 0;
    newColorModel.saturation = 0;
    newColorModel.label = '';
    newColorModel.isSaved = false;
    return newColorModel;
  }

  @persist id: string;
  @persist predefined: boolean;
  @persist isAdmin: boolean;
  @persist @observable label: string;
  @persist @observable hue: number;
  @persist @observable saturation: number;
  @persist @observable light: number;
  @persist @observable isSaved: boolean = true;
}
