import { BaseService } from './base-service';

export class UploadService extends BaseService {
  baseURL = 'streamingupload';

  uploadChunk = (fileId: string, fileName, totalFileSize: number, chunkStart: number, chunkEnd: number, chunk: Blob) => {
    const formData = new FormData();
    formData.append('files[]', chunk, fileName);
    return this.post('', undefined, formData, 'multipart/form-data;', {
      ['X-File-Identifier']: fileId,
      ['Content-Range']: `bytes ${chunkStart}-${chunkEnd}/${totalFileSize}`
    });
  }
}
