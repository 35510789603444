import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { dataSetEdit } from '../../util/block/edit-types/dataset-edit.util';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import {BaseContainerBlockModel} from './base-container-block.model';
import {blockAuthorization} from './decorators/block-authorization.decorator';

@blockAuthorization((state) => state.configuration.authorization.cmsAdvancedContentUse)
@authenticatedBlock
@webDocumentBlock
@blockType(BlockTypeEnum.RepeaterBlock)
@blockBackgroundColor('PrimaryBackgroundColor')
export class RepeaterBlockModel extends BaseContainerBlockModel {
  @editGroup.CONTENT
  @editable(dataSetEdit()
    .label('repeater-block.edit.data')
    .placeholder('repeater-block.edit.select-data')
    .disabledWhen((value, data: RepeaterBlockModel) => {
      return data.blocks.length > 0;
    })
  )
  @observable selectedMention: IUsedDesignObjectMentionMetadata;
  @observable parentId?: string;
}
