import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { htmlEdit } from '../../util/block/edit-types/html-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { emailBlock } from './decorators/email-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { quickEditBlock } from './decorators/quick-edit-block.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';

@emailBlock
@quickEditBlock([ 'text' ])
@blockType(BlockTypeEnum.EmailTextBlock)
@blockBackgroundColor('PrimaryBackgroundColor')
export class EmailTextBlockModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('text-block.textTitle'))
  @observable text: any = getDraftStateFromString('');

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('text-block.text-background-color'))
  @observable textBackgroundColor: BlockColorType = 'Transparent';
}
