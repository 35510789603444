export enum BlockTypeEnum {
    CustomList = 'customList',
    Button = 'button',
    Form = 'form',
    TextImage = 'textImage',
    ContactList = 'contactList',
    DocumentList = 'documentList',
    TwoColumnText = 'twoColumnText',
    TextBlock = 'textBlock',
    EmailTextBlock = 'emailTextBlock',
    EmailImage = 'emailImage',
    EmailButton = 'emailButton',
    JobAdList = 'jobadList',
    Header = 'header',
    SocialShareButtons = 'socialShareButtons',
    LargeHeader = 'largeHeader',
    Hero = 'hero',
    Image = 'image',
    Space = 'space',
    EmailSpace = 'emailSpace',
    RepeatingTextBlock = 'repeatingTextBlock',
    VideoBlock = 'videoBlock',
    RepeaterBlock = 'repeaterBlock',
    TableRowBlock = 'tableRowBlock'
}
