export interface ITableRow {
  rowBorder: RowBorderOptions;
  cells: ITableCell[];
}
export interface ITableCell {
  id: string;
  value: any;
  isSelected: boolean;
  cellBorder: CellBorderOptions;
}

export enum CellBorderOptions {
  LEFT,
  RIGHT,
  BOTH,
  NONE
}

export enum RowBorderOptions {
  TOP,
  BOTTOM,
  NONE
}