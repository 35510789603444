import { limitValueToRange } from '../number/limit-value-to-range.util';
import { padString } from '../string/pad-string.util';
import { ColorEnum } from '../../enum/color.enum';

function getHexValue(n: number) {
  return padString(n.toString(16));
}

export function lightenColor(hexColor: string, percentage: number): string {
  if (!hexColor) {
    return ColorEnum.Transparent();
  }
  const amount = limitValueToRange(percentage * 255, -255, 255);
  const r = limitValueToRange(Math.round(parseInt(hexColor.slice(1, 3), 16) + amount), 0, 255);
  const g = limitValueToRange(Math.round(parseInt(hexColor.slice(3, 5), 16) + amount), 0, 255);
  const b = limitValueToRange(Math.round(parseInt(hexColor.slice(5, 7), 16) + amount), 0, 255);
  return `#${getHexValue(r)}${getHexValue(g)}${getHexValue(b)}`;
}
