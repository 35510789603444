import { returnEditMode } from './return-edit-mode.util';
import { convertFromRaw, EditorState } from 'draft-js';
import { toJS } from 'mobx';
import { findEntitiesInState } from '../../draft/find-entities-in-editor-state.util';

export interface ITableRowEditComponentProps {
  placeholder?: string;
}

export const tableRowEdit = () => {
  const toReturn = returnEditMode('tableRow');
  toReturn.setUsedMentionsReducer((data: any) => {
    if (data && data.cells.length > 0) {
      let tableRowEntities = [];
      for (const cell of data.cells) {
        const state = EditorState.createWithContent(convertFromRaw(toJS(cell.value)));
        const entities = findEntitiesInState(state, 'MENTION');
        tableRowEntities = tableRowEntities.concat(entities.map((foundEntity) => foundEntity.entity.getData()));
      }
      return tableRowEntities;
    }
    return [];
  });
  return toReturn;
};
