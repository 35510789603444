import { IEditModeReturnValue } from './return-edit-mode.util';
import { enumPickerEdit } from './enum-picker-edit.util';
import { ImageSizeStyleEnum } from '../../../enum/image-size-style.enum';

export interface IImageEditComponentProps {
}

interface IReturnType {
}

export const imageSizeStylePickerEdit = (componentProps: IImageEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IImageEditComponentProps, ImageSizeStyleEnum, IReturnType> = enumPickerEdit(componentProps as any)
    .addOption('image-style.edit.stretch', ImageSizeStyleEnum.Stretch)
    .addOption('image-style.edit.fit', ImageSizeStyleEnum.Fit)
  ;
  return toReturn;
};
