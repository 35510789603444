import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { HeroModel } from '../../../model/block';

const HeroView = asyncComponent(() => import('./hero.view').then(c => c.HeroView));
const HeroIcon = asyncComponent(() => import('../../icons/block/hero.icon').then(c => c.HeroIcon), 'svg');

export const Hero = getBlock(HeroModel, BlockGroupEnum.MenuAndStartPage, 'block.hero-title', <HeroIcon/>, HeroView);
