import { Observable } from 'rxjs/Observable';
import { IAction } from '../interface/action/action.interface';
import { ActionEnum } from '../enum/action.enum';

export function typeMethod<T extends IAction>(this: Observable<T>, actionType: ActionEnum): Observable<T['payload']> {
  return this.filter((action) => action.type === actionType)
    .map((action) => action.payload);
}

Observable.prototype.type = typeMethod;
