import { ICustomListDefinition } from '../../interface/custom-lists/custom-list-definition.interface';
import { FieldDefinitionModel } from './field-definition.model';
import { action, observable, toJS } from 'mobx';
import { persist } from 'mobx-persist';
import { createCustomListFieldDefinition } from '../../util/custom-lists/create-custom-list-field-definition.util';
import { custom, list, serializable } from 'serializr';
import { editGroup } from '../block/decorators/edit-group.decorator';
import { editable } from '../block/decorators/editable.decorator';
import { plainStringEdit } from '../../util/block/edit-types/plain-string-edit.util';

const FieldDefinitionSerializationFunction = (fieldDefinition: FieldDefinitionModel) => {
  return toJS(fieldDefinition);
};

const FieldDefinitionDeseralizationFunction = (fieldDefinitionData: any) => {
  return createCustomListFieldDefinition(fieldDefinitionData);
};

export class CustomListDefinitionModel implements ICustomListDefinition {
  @observable @serializable(list(custom(FieldDefinitionSerializationFunction, FieldDefinitionDeseralizationFunction)))
  fieldsDefinition: FieldDefinitionModel[] = [];
  @observable @persist id: string;

  @editGroup.CONTENT
  @editable(plainStringEdit()
    .label('custom-list.name')
    .required('custom-list.name-required')
    .placeholder('custom-list.name-placeholder')
  )
  @observable @persist name: string;

  @observable @persist isSaved: boolean = false;

  @action
  setCustomListDefinitionData = (listDefinition: ICustomListDefinition) => {
    Object.assign(this, listDefinition);
    this.isSaved = true;
    this.fieldsDefinition = (listDefinition.fieldsDefinition || []).map((def) => createCustomListFieldDefinition(def));
  }
}
