import { asyncComponent } from '../async-component/async-component';

export const AdminSettingsIcon = asyncComponent(() => import('./admin-settings.icon'), 'svg');
export const ArrowDownIcon = asyncComponent(() => import('./arrow-down.icon'), 'svg');
export const AvatarIcon = asyncComponent(() => import('./avatar.icon'), 'svg');
export const BoldIcon = asyncComponent(() => import('./bold.icon'), 'svg');
export const ColorPickerIcon = asyncComponent(() => import('./color-picker.icon'), 'svg');
export const ContactAvatarIcon = asyncComponent(() => import('./contact-avatar.icon'), 'svg');
export const CopyIcon = asyncComponent(() => import('./copy.icon'), 'svg');
export const CrossIcon = asyncComponent(() => import('./cross.icon'), 'svg');
export const DeleteIcon = asyncComponent(() => import('./delete.icon'), 'svg');
export const DownArrowIcon = asyncComponent(() => import('./down-arrow.icon'), 'svg');
export const EditIcon = asyncComponent(() => import('./edit.icon'), 'svg');
export const EyeIcon = asyncComponent(() => import('./eye.icon'), 'svg');
export const FacebookIcon = asyncComponent(() => import('./facebook.icon'), 'svg');
export const ImagePlaceholderIcon = asyncComponent(() => import('./image-placeholder.icon'), 'svg');
export const InvoiceIcon = asyncComponent(() => import('./invoice.icon'), 'svg');
export const ItalicIcon = asyncComponent(() => import('./italic.icon'), 'svg');
export const JobadPinIcon = asyncComponent(() => import('./jobad-pin.icon'), 'svg');
export const LinkedInIcon = asyncComponent(() => import('./linked-in.icon'), 'svg');
export const LogoutIcon = asyncComponent(() => import('./logout.icon'), 'svg');
export const MailIcon = asyncComponent(() => import('./mail.icon'), 'svg');
export const MaximizeIcon = asyncComponent(() => import('./maximize.icon'), 'svg');
export const MediaLibraryIcon = asyncComponent(() => import('./media-library.icon'), 'svg');
export const MediaLibraryCloseIcon = asyncComponent(() => import('./media-library-close-icon'), 'svg');
export const MenuIcon = asyncComponent(() => import('./menu.icon'), 'svg');
export const MinusIcon = asyncComponent(() => import('./minus.icon'), 'svg');
export const PagesIcon = asyncComponent(() => import('./pages.icon'), 'svg');
export const PenIcon = asyncComponent(() => import('./pen.icon'), 'svg');
export const PhoneIcon = asyncComponent(() => import('./phone.icon'), 'svg');
export const PinIcon = asyncComponent(() => import('./pin.icon'), 'svg');
export const PlusIcon = asyncComponent(() => import('./plus.icon'), 'svg');
export const RubbishIcon = asyncComponent(() => import('./rubbish.icon'), 'svg');
export const SaveIcon = asyncComponent(() => import('./save.icon'), 'svg');
export const SearchIcon = asyncComponent(() => import('./search.icon'), 'svg');
export const SettingsIcon = asyncComponent(() => import('./settings.icon'), 'svg');
export const SystemSettingsIcon = asyncComponent(() => import('./system-settings.icon'), 'svg');
export const TagIcon = asyncComponent(() => import('./tag.icon'), 'svg');
export const TimeIcon = asyncComponent(() => import('./time.icon'), 'svg');
export const TwitterIcon = asyncComponent(() => import('./twitter.icon'), 'svg');
export const UnderlineIcon = asyncComponent(() => import('./underline.icon'), 'svg');
export const UpArrowIcon = asyncComponent(() => import('./up-arrow.icon'), 'svg');
export const UploadImageIcon = asyncComponent(() => import('./upload-image.icon'), 'svg');
export const ErrorIcon = asyncComponent(() => import('./error.icon'), 'svg');
export const CloseIcon = asyncComponent(() => import('./close.icon'), 'svg');
export const CheckMarkIcon = asyncComponent(() => import('./check-mark.icon'), 'svg');
export const ClearCheckMarkIcon = asyncComponent(() => import('./clear-check-mark.icon'), 'svg');
export const DocumentIcon = asyncComponent(() => import('./document.icon'), 'svg');
export const LeftArrowIcon = asyncComponent(() => import('./left-arrow.icon'), 'svg');
export const RightArrowIcon = asyncComponent(() => import('./right-arrow.icon'), 'svg');
export const ZoomInIcon = asyncComponent(() => import('./zoom-in.icon'), 'svg');
export const ZoomOutIcon = asyncComponent(() => import('./zoom-out.icon'), 'svg');
export const ToggleDownArrowIcon = asyncComponent(() => import('./toggle-down-arrow.icon'), 'svg');
export const BackArrowIcon = asyncComponent(() => import('./back-arrow.icon'), 'svg');
export const FolderIcon = asyncComponent(() => import('./folder.icon'), 'svg');
export const ArrowFoldersIcon = asyncComponent(() => import('./arrow-folders.icon'), 'svg');
export const CustomListsIcon = asyncComponent(() => import('./custom-lists.icon'), 'svg');
export const MenuArrowDownIcon = asyncComponent(() => import('./menu-arrow-down.icon'), 'svg');
export const MenuArrowLeftIcon = asyncComponent(() => import('./menu-arrow-left.icon'), 'svg');
export const MenuArrowRightIcon = asyncComponent(() => import('./menu-arrow-right.icon'), 'svg');
export const MenuCloseIcon = asyncComponent(() => import('./menu-close.icon'), 'svg');
export const FormErrorIcon = asyncComponent(() => import('./form-error.icon'), 'svg');
export const LinkIcon = asyncComponent(() => import('./link.icon'), 'svg');
export const PrivateIcon = asyncComponent(() => import('./private.icon'), 'svg');
export const ReloadIcon = asyncComponent(() => import('./reload-icon'), 'svg');
export const VideoIcon = asyncComponent(() => import('./video.icon'), 'svg');
export const GlobeIcon = asyncComponent(() => import('./globe.icon'), 'svg');
