import { FieldDefinitionModel } from '../field-definition.model';
import { customListFieldType } from '../decorators/custom-list-field-type';
import { CustomListFieldDefinitionTypeEnum } from '../../../enum/custom-lists/custom-list-field-definition-type.enum';
import { StyledTextFieldOptionsModel } from './options/styled-text-field-options.model';
import { IStyledTextFieldOptions } from '../../../interface/custom-lists/styled-text-field-options.interface';

@customListFieldType(CustomListFieldDefinitionTypeEnum.StyledText)
export class StyledTextFieldDefinitionModel extends FieldDefinitionModel<StyledTextFieldOptionsModel> {
  setFieldOptions = (data: IStyledTextFieldOptions) => {
    this.fieldOptions = new StyledTextFieldOptionsModel();
    Object.assign(this.fieldOptions, data);
  }
}
