import { FieldDefinitionModel } from '../field-definition.model';
import { customListFieldType } from '../decorators/custom-list-field-type';
import { CustomListFieldDefinitionTypeEnum } from '../../../enum/custom-lists/custom-list-field-definition-type.enum';
import { TextFieldOptionsModel } from './options/text-field-options.model';
import { ITextFieldOptions } from '../../../interface/custom-lists/text-field-options.interface';

@customListFieldType(CustomListFieldDefinitionTypeEnum.Text)
export class TextFieldDefinitionModel extends FieldDefinitionModel<TextFieldOptionsModel> {
  setFieldOptions = (data: ITextFieldOptions) => {
    this.fieldOptions = new TextFieldOptionsModel();
    Object.assign(this.fieldOptions, data);
  }
}
