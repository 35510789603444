import {AppStateModel} from './model/app-state.model';
import {FormLinkedDataModel} from './model/linked-data';
import {draftToString} from './util/draft/draft-to-string.util';

export class GoogleTagManager {
    static push(data: {event: string, [key: string]: (string | number)}) {
        if ((window as any).dataLayer) {
            (window as any).dataLayer.push(data);
        } else {
            console.warn('Tag Manager Data Layer missing!');
        }
    }

    static pushCustomPageViewEvent(state: AppStateModel, statusCode: string) {
        const shouldTrackCustom = state.adminSettings.tagManagerTrackCustomEvents && !state.configuration.isPreview && !state.configuration.inAdmin;
        if (shouldTrackCustom && window && state.activeDesign.page) {
          const pageTitle = draftToString(state.activeDesign.page.title, state);
          GoogleTagManager.push({
            event: 'vpv',
            bookingEngine: state.adminSettings.tagManagerBookingEngine,
            businessArea: state.adminSettings.tagManagerBusinessArea,
            pageId: state.activeDesign.page.id,
            countryCode: state.adminSettings.tagManagerCountryCode,
            languageCode: state.adminSettings.tagManagerLanguage,
            pagePath: window.location.pathname,
            pageTitle: pageTitle,
            statusCode: statusCode
          });
        }
    }

    static pushCustomFormSubmitEvent(state: AppStateModel, formLinkedData: FormLinkedDataModel) {
      const shouldTrackCustom = state.adminSettings.tagManagerTrackCustomEvents && !state.configuration.isPreview && !state.configuration.inAdmin;
      if (shouldTrackCustom && formLinkedData && state.adminSettings) {
        GoogleTagManager.push({
          event: 'formSubmit',
          formName: formLinkedData.displayName,
          formID: formLinkedData.mentionId,
          countryCode: state.adminSettings.tagManagerCountryCode,
          languageCode: state.adminSettings.tagManagerLanguage
        });
      }
    }
}