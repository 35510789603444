import { isStickyBlockType } from './is-sticky-block-type.util';
import { BlockModel } from '../../model/block/block.model';

export const isStickyBlock = (block: BlockModel, activePageBlocks: BlockModel[]) => {
  if (!isStickyBlockType(block.blockType)) {
    return false;
  }
  let blockIndex = -1;
  let otherStickyBlockIndex = -1;
  let index = 0;
  while (index < activePageBlocks.length) {
    const activePageBlock = activePageBlocks[ index ];
    if (activePageBlock.id === block.id) {
      blockIndex = index;
    }
    if (activePageBlock.id !== block.id && isStickyBlockType(activePageBlock.blockType)) {
      otherStickyBlockIndex = index;
    }
    index++;
  }

  return blockIndex > otherStickyBlockIndex;
};
