import { IEditModeReturnValue } from './return-edit-mode.util';
import { enumPickerEdit } from './enum-picker-edit.util';
import { ImageStyleEnum } from '../../../enum/image-style.enum';

export interface IImageEditComponentProps {
}

interface IReturnType {
}

export const imageStylePickerEdit = (componentProps: IImageEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IImageEditComponentProps, ImageStyleEnum, IReturnType> = enumPickerEdit(componentProps as any)
    .addOption('image-style.edit.square', ImageStyleEnum.Square)
    .addOption('image-style.edit.rounded', ImageStyleEnum.Rounded)
    .addOption('image-style.edit.circle', ImageStyleEnum.Circle)
  ;
  return toReturn;
};
