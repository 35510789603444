import { ColorEnum } from './color.enum';

const DefaultBlockColorEnum = {
  PrimaryBackgroundColor: '#f5f5f5',
  SecondaryBackgroundColor: '#3b8b8c',
  TertiaryBackgroundColor: '#d5d5d5',
  PrimaryButtonColor: '#3b8b8c',
  PrimaryTextColor: '#2b2b2b',
  SecondaryTextColor: '#FFFFFF',
  ErrorColor: '#E35555',
  ConfirmColor: '#7CC77A',
  InactiveColor: '#C7C7C7'
};

export type BlockColorType = keyof typeof DefaultBlockColorEnum | 'Transparent';

export type BlockColorEnumReturn = {
  [key in BlockColorType]: string;
};

type BlockColorEnumType = ((props: any) => BlockColorEnumReturn) & {
  [key in BlockColorType]: (props: any) => string;
};

export const BlockColorEnum: BlockColorEnumType = ((props: any) => {
  const colorEnum = ColorEnum(props);
  return {
    PrimaryBackgroundColor: colorEnum.getColor('PrimaryBackgroundColor') || DefaultBlockColorEnum.PrimaryBackgroundColor,
    SecondaryBackgroundColor: colorEnum.getColor('SecondaryBackgroundColor') || DefaultBlockColorEnum.SecondaryBackgroundColor,
    TertiaryBackgroundColor: colorEnum.getColor('TertiaryBackgroundColor') || DefaultBlockColorEnum.TertiaryBackgroundColor,
    PrimaryButtonColor: colorEnum.getColor('PrimaryButtonColor') || DefaultBlockColorEnum.PrimaryButtonColor,
    PrimaryTextColor: colorEnum.getColor('PrimaryTextColor') || DefaultBlockColorEnum.PrimaryTextColor,
    SecondaryTextColor: colorEnum.getColor('SecondaryTextColor') || DefaultBlockColorEnum.SecondaryTextColor,
    ErrorColor: colorEnum.getColor('ErrorColor') || DefaultBlockColorEnum.SecondaryTextColor,
    ConfirmColor: colorEnum.getColor('ConfirmColor') || DefaultBlockColorEnum.PrimaryButtonColor,
    InactiveColor: colorEnum.getColor('InactiveColor') || DefaultBlockColorEnum.InactiveColor
  };
}) as any;

BlockColorEnum.PrimaryBackgroundColor = (props) => {
  return ColorEnum.getColor('PrimaryBackgroundColor')(props) || DefaultBlockColorEnum.PrimaryBackgroundColor;
};

BlockColorEnum.SecondaryBackgroundColor = (props) => {
  return ColorEnum.getColor('SecondaryBackgroundColor')(props) || DefaultBlockColorEnum.SecondaryBackgroundColor;
};

BlockColorEnum.TertiaryBackgroundColor = (props) => {
  return ColorEnum.getColor('TertiaryBackgroundColor')(props) || DefaultBlockColorEnum.TertiaryBackgroundColor;
};

BlockColorEnum.PrimaryButtonColor = (props) => {
  return ColorEnum.getColor('PrimaryButtonColor')(props) || DefaultBlockColorEnum.PrimaryButtonColor;
};

BlockColorEnum.PrimaryTextColor = (props) => {
  return ColorEnum.getColor('PrimaryTextColor')(props) || DefaultBlockColorEnum.PrimaryTextColor;
};

BlockColorEnum.SecondaryTextColor = (props) => {
  return ColorEnum.getColor('SecondaryTextColor')(props) || DefaultBlockColorEnum.SecondaryTextColor;
};

BlockColorEnum.ErrorColor = (props) => {
  return ColorEnum.getColor('ErrorColor')(props) || DefaultBlockColorEnum.ErrorColor;
};

BlockColorEnum.ConfirmColor = (props) => {
  return ColorEnum.getColor('ConfirmColor')(props) || DefaultBlockColorEnum.ConfirmColor;
};

BlockColorEnum.InactiveColor = (props) => {
  return ColorEnum.getColor('InactiveColor')(props) || DefaultBlockColorEnum.InactiveColor;
};

export const allBlockColors = Object.keys(DefaultBlockColorEnum);
