import { BaseService } from './base-service';

export class MediaLibraryService extends BaseService {
  baseURL = 'MediaLibrary';

  getImage = (imageId: string) => {
    return this.get(`file`, { fileId: imageId });
  }

  uploadImage = (folderId: string, image: any) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('folderId', folderId);
    return this.post(`image`, undefined, formData, 'multipart/form-data;');
  }

  uploadFile = (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(`file`, undefined, formData, 'multipart/form-data;');
  }

  createFolder = (name: string) => {
    return this.post(`folder`, undefined, { name });
  }

  deleteFolder = (id: string) => {
    return this.delete(`folder`, undefined, { id });
  }

  deleteImage = (id: string) => {
    return this.delete(`file`, undefined, { id });
  }

  moveImage = (imageId: string, newFolderId: string) => {
    return this.put(`move`, undefined, { imageId, newFolderId });
  }

  deleteFile = (id: string) => {
    return this.delete(`file`, undefined, { id });
  }

  rename = (id: string, newName: string) => {
    return this.put('rename', undefined, { id, newName });
  }

  getMediaLibraryImages = (folderId: string) => {
    return this.get(`files`, { folderId });
  }

  getMediaLibraryFolders = () => {
    return this.get(`/`, { dontIncludeFiles: true });
  }
}
