// Polyfill
import 'core-js/fn/object/assign';
import 'core-js/fn/string/starts-with';
import 'core-js/es6/promise';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/find';
import 'core-js/fn/array/fill';
import 'core-js/fn/array/from';
import 'core-js/fn/array/map';
import 'core-js/fn/array/includes';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/symbol';
import 'raf/polyfill';