import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { listMentionPickerEdit } from '../../util/block/edit-types/list-mention-picker-edit.util';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { PossibleListTypesEnum } from '../../enum/possible-list-types.enum';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';

const blockMentionEdit = (placeholder: string) => plainTextEdit()
  .placeholder(placeholder)
  .visibleWhen((data) => Boolean(data.selectedMention))
  .addDataMentionsReducer(() => ([
    {
      mentionId: 'name',
      displayName: 'name',
      type: DesignObjectMentionMetadataType.Text,
      pickerResolver: '',
      resolverType: '',
      dataType: [],
      dataSetFilters: [],
      dataSetGroups: []
    },
    {
      mentionId: 'email',
      displayName: 'email',
      type: DesignObjectMentionMetadataType.Text,
      pickerResolver: '',
      resolverType: '',
      dataType: [],
      dataSetFilters: [],
      dataSetGroups: []
    },
    {
      mentionId: 'phone',
      displayName: 'phone',
      type: DesignObjectMentionMetadataType.Text,
      pickerResolver: '',
      resolverType: '',
      dataType: [],
      dataSetFilters: [],
      dataSetGroups: []
    },
    {
      mentionId: 'title',
      displayName: 'title',
      type: DesignObjectMentionMetadataType.Text,
      pickerResolver: '',
      resolverType: '',
      dataType: [],
      dataSetFilters: [],
      dataSetGroups: []
    }
  ]));

@jobadBlock
@blockType(BlockTypeEnum.ContactList)
@blockBackgroundColor('SecondaryBackgroundColor')
@authenticatedBlock
export class ContactListModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(listMentionPickerEdit()
    .label('contact-list.edit.listData')
    .placeholder('contact-list.edit.select-listData')
    .listType(PossibleListTypesEnum.Contact)
  )
  @observable selectedMention: IUsedDesignObjectMentionMetadata;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('contact-list.edit.social-buttons-background-color')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable socialButtonsBackgroundColor: BlockColorType = 'TertiaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('contact-list.edit.social-buttons-color')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable socialButtonsTextColor: BlockColorType = 'PrimaryTextColor';

  @editGroup.CONTENT
  @editable(blockMentionEdit('contact-list.edit.title-placeholder')
    .label('contact-list.edit.title'))
  @observable title: any = getDraftStateFromString('Contact');

  @editGroup.CONTENT
  @editable(blockMentionEdit('contact-list.edit.first-row-placeholder')
    .label('contact-list.edit.first-row'))
  @observable firstRow: any = getDraftStateFromString('name', [ {
    usedMention: {
      id: 'name',
      displayName: 'name',
      isBlockData: true
    }, position: {startIndex: 0, endIndex: 4}
  } ]);

  @editGroup.CONTENT
  @editable(blockMentionEdit('contact-list.edit.second-row-placeholder')
    .label('contact-list.edit.second-row'))
  @observable secondRow: any = getDraftStateFromString('title', [ {
    usedMention: {
      id: 'title',
      displayName: 'title',
      isBlockData: true
    }, position: {startIndex: 0, endIndex: 5}
  } ]);

  @editGroup.CONTENT
  @editable(numberEdit()
    .label('contact-list.edit.top-bottom-padding')
    .min(1)
    .placeholder('contact-list.edit.top-bottom-padding')
  )
  @observable topBottomPadding: number = 40;
}
