import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { ButtonBlockModel, EmailButtonBlockModel } from '../../../model/block';
import { getLinkFinalURL } from '../../../util/url/get-link-final-url';
import { ButtonView } from './button.view';

const EmailButtonView = asyncComponent(() => import('./email-button.view').then(c => c.EmailButtonView));
const ButtonIcon = asyncComponent(() => import('../../icons/block/button.icon').then(c => c.ButtonIcon), 'svg');

export const Button = getBlock(ButtonBlockModel, BlockGroupEnum.Interactive, 'block.button-title',
  <ButtonIcon/>, ButtonView);
export const EmailButton = getBlock(EmailButtonBlockModel, BlockGroupEnum.Interactive, 'block.button-title',
  <ButtonIcon/>, EmailButtonView, {
    text: (model, state) => getLinkFinalURL(state, model.link, undefined, true)
  });
