import { Logger } from '../debug/logger.util';
import { IFieldDefinition } from '../../interface/custom-lists/field-definition.interface';
import { FieldDefinitionModel } from '../../model/custom-lists/field-definition.model';
import { getCustomListFieldDefinitionByType } from './field-definition-by-type.enum';

export function createCustomListFieldDefinition(fieldDefinition: IFieldDefinition): FieldDefinitionModel {
  let CurrentFieldDefinition = getCustomListFieldDefinitionByType(fieldDefinition.type as any);
  if (!CurrentFieldDefinition) {
    Logger.warn(`Field definition type ${fieldDefinition.type} does not exist, creating base block.`);
    CurrentFieldDefinition = FieldDefinitionModel as any;
  }

  const newFieldDefinition = new CurrentFieldDefinition();
  newFieldDefinition.setData(fieldDefinition);
  return newFieldDefinition;
}
