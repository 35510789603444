import { BaseService } from './base-service';
import { ILinkedDataDefinition } from '../interface/page/design-object-mention-metadata.interface';
import { IParams } from '../model/page-parameters.model';

export class LinkedDataResolverService extends BaseService {
  baseURL = 'linkedDataResolver';

  resolveData = (mentions: ILinkedDataDefinition[], pageId: string, filterParams: IParams = {}) => {
    return this.post('/async', undefined, {
      pageId,
      linkedData: mentions,
      resolvingParameters: Object.keys(filterParams).map((key) => ({id: key, label: filterParams[ key ]}))
    });
  }
}
