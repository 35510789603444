const a = 'å';
const a1 = 'ä';
const o = 'ö';
const u = 'ü';
const s = 'ß';

const transliterationTable = {
  [ a ]: 'a',
  [ a1 ]: 'a',
  [ o ]: 'o',
  [ u ]: 'u',
  [ s ]: 's',
};

export const getUrlSafeString = (str: string) => {
  if (!str) {
    return '';
  }
  let returnString = str;
  const transliterationKeys = Object.keys(transliterationTable);
  for (const key of transliterationKeys) {
    returnString = returnString.replace(new RegExp(key, 'g'), transliterationTable[ key ]);
  }
  return returnString.trim().replace(/[–\/]/g, '-').replace(/[\-\?\!]/gi, '').replace(/[^a-z0-9\s]/gi, '_').replace(/\s+/gi, '-').toLowerCase();
};
