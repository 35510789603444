import { isObject } from 'lodash';
import { invariant } from '../debug/invariant.util';

export function filterObjectKeys<T extends object>(object: T, predicate: (key: string, value: any) => boolean): Partial<T> {
  invariant(isObject(object), 'You must provide a object for filtering keys on', object);
  return Object.keys(object).reduce((newObject, key) => {
    if (predicate(key, object[ key ])) {
      newObject[ key ] = object[ key ];
    }
    return newObject;
  }, {});
}
