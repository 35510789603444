import { BaseService } from './base-service';
import { IParams } from '../model/page-parameters.model';
import { IFilterGroupsHolder } from '../interface/page/design-object-mention-metadata.interface';

export class PickerService extends BaseService {
  baseURL = 'picker';

  fetchProperty = (resolver: string, property: string) => {
    return this.get(`${resolver}/${property}`);
  }

  fetchProperties = (resolver: string, properties: string[], filters?: IFilterGroupsHolder) => {
    return this.post(`${resolver}/properties`, undefined, {properties, filters});
  }

  fetchPropertiesWithFilters = (resolver: string, properties: string[], filters: IFilterGroupsHolder, pageId?: string, filterParameters?: IParams) => {
    return this.post(`${resolver}/with-page-filters`, undefined, {properties, filters, pageId, ...filterParameters});
  }
}
