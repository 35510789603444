import {asyncComponent} from "../../async-component/async-component";

export const CountryFlagAFIcon = asyncComponent(() => import('./country-flag-af-icon'), 'svg');
export const CountryFlagALIcon = asyncComponent(() => import('./country-flag-al-icon'), 'svg');
export const CountryFlagATIcon = asyncComponent(() => import('./country-flag-at-icon'), 'svg');
export const CountryFlagAUIcon = asyncComponent(() => import('./country-flag-au-icon'), 'svg');
export const CountryFlagBAIcon = asyncComponent(() => import('./country-flag-ba-icon'), 'svg');
export const CountryFlagBBIcon = asyncComponent(() => import('./country-flag-bb-icon'), 'svg');
export const CountryFlagBEIcon = asyncComponent(() => import('./country-flag-be-icon'), 'svg');
export const CountryFlagBGIcon = asyncComponent(() => import('./country-flag-bg-icon'), 'svg');
export const CountryFlagBRIcon = asyncComponent(() => import('./country-flag-br-icon'), 'svg');
export const CountryFlagBYIcon = asyncComponent(() => import('./country-flag-by-icon'), 'svg');
export const CountryFlagCAIcon = asyncComponent(() => import('./country-flag-ca-icon'), 'svg');
export const CountryFlagCHIcon = asyncComponent(() => import('./country-flag-ch-icon'), 'svg');
export const CountryFlagCNIcon = asyncComponent(() => import('./country-flag-cn-icon'), 'svg');
export const CountryFlagCRIcon = asyncComponent(() => import('./country-flag-cr-icon'), 'svg');
export const CountryFlagCZIcon = asyncComponent(() => import('./country-flag-cz-icon'), 'svg');
export const CountryFlagDEIcon = asyncComponent(() => import('./country-flag-de-icon'), 'svg');
export const CountryFlagDKIcon = asyncComponent(() => import('./country-flag-dk-icon'), 'svg');
export const CountryFlagEEIcon = asyncComponent(() => import('./country-flag-ee-icon'), 'svg');
export const CountryFlagEGIcon = asyncComponent(() => import('./country-flag-eg-icon'), 'svg');
export const CountryFlagESIcon = asyncComponent(() => import('./country-flag-es-icon'), 'svg');
export const CountryFlagFIIcon = asyncComponent(() => import('./country-flag-fi-icon'), 'svg');
export const CountryFlagFRIcon = asyncComponent(() => import('./country-flag-fr-icon'), 'svg');
export const CountryFlagGBIcon = asyncComponent(() => import('./country-flag-gb-icon'), 'svg');
export const CountryFlagGRIcon = asyncComponent(() => import('./country-flag-gr-icon'), 'svg');
export const CountryFlagHRIcon = asyncComponent(() => import('./country-flag-hr-icon'), 'svg');
export const CountryFlagHUIcon = asyncComponent(() => import('./country-flag-hu-icon'), 'svg');
export const CountryFlagIEIcon = asyncComponent(() => import('./country-flag-ie-icon'), 'svg');
export const CountryFlagINIcon = asyncComponent(() => import('./country-flag-in-icon'), 'svg');
export const CountryFlagISIcon = asyncComponent(() => import('./country-flag-is-icon'), 'svg');
export const CountryFlagITIcon = asyncComponent(() => import('./country-flag-it-icon'), 'svg');
export const CountryFlagLTIcon = asyncComponent(() => import('./country-flag-lt-icon'), 'svg');
export const CountryFlagLVIcon = asyncComponent(() => import('./country-flag-lv-icon'), 'svg');
export const CountryFlagMKIcon = asyncComponent(() => import('./country-flag-mk-icon'), 'svg');
export const CountryFlagNLIcon = asyncComponent(() => import('./country-flag-nl-icon'), 'svg');
export const CountryFlagNOIcon = asyncComponent(() => import('./country-flag-no-icon'), 'svg');
export const CountryFlagPKIcon = asyncComponent(() => import('./country-flag-pk-icon'), 'svg');
export const CountryFlagPLIcon = asyncComponent(() => import('./country-flag-pl-icon'), 'svg');
export const CountryFlagRUIcon = asyncComponent(() => import('./country-flag-ru-icon'), 'svg');
export const CountryFlagSEIcon = asyncComponent(() => import('./country-flag-se-icon'), 'svg');
export const CountryFlagTRIcon = asyncComponent(() => import('./country-flag-tr-icon'), 'svg');
export const CountryFlagUSIcon = asyncComponent(() => import('./country-flag-us-icon'), 'svg');