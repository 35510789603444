import { isBoolean } from 'lodash';

function convertValueToURI(value: any): string {
  if (isBoolean(value)) {
    return value ? 'true' : 'false';
  }
  return encodeURIComponent(value);
}

export function encodeQuery(queryParameters: any) {
  const keys = Object.keys(queryParameters).filter((key) => queryParameters[ key ]);
  if (keys.length === 0) {
    return '';
  }
  return keys.map(key => key + '=' + convertValueToURI(queryParameters[ key ])).join('&');
}
