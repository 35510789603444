import { AppStateModel } from '../../model/app-state.model';
import { isServerSide } from '../server-side-rendering/is-server-side';

const defaultLogCheck = (logfunction: (...args: any[]) => void) => (...args: any[]) => {
  if (isServerSide() || ((window as any).appState && ((window as any).appState as AppStateModel).configuration.debug)) {
    // TODO2024 - remove @ts-ignore
    // @ts-ignore
    return logfunction(...args);
  }
};

const LoggerObject = {
  log: defaultLogCheck(console.log) as Console['log'],
  dir: defaultLogCheck(console.dir) as Console['dir'],
  assert: defaultLogCheck(console.assert) as Console['assert'],
  debug: defaultLogCheck(console.debug) as Console['debug'],
  info: defaultLogCheck(console.info) as Console['info'],
  action: defaultLogCheck(console.info) as Console['info'],
  warn: defaultLogCheck(console.warn) as Console['warn'],
  error: defaultLogCheck(console.error) as Console['error'],
};

export type LoggerType = typeof LoggerObject;

export const Logger = LoggerObject;

export function createLogger(state: AppStateModel, registeredConsole = window.console): LoggerType {
  const logMessage = (logName: string, args: any[]) => {
    typeof registeredConsole[ logName ] === 'function' ?
      registeredConsole[ logName ].apply(registeredConsole, args) :
      registeredConsole.info.apply(registeredConsole, args);
  };

  const newLogger = Object.assign({}, LoggerObject);
  if (state.configuration.debug && window.console) {
    for (const logName in LoggerObject) {
      if (LoggerObject.hasOwnProperty(logName)) {
        newLogger[ logName ] = (...args: any[]) => {
          logMessage(logName, args);
        };
      }
    }
  }

  return newLogger;
}
