import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../interface/component/injected-props.interface';
import { DesignTypeEnum } from '../../enum/design-type.enum';
import { PageBlocksView } from '../design-object-blocks-views/page-blocks.view';
import {GoogleTagManager} from '../../google-tag-manager';

@inject('state', 'dispatch')
@observer
export class NotFoundPage extends React.Component<IInjectedProps> {
  componentWillMount() {
    if (this.props.state.activeDesign.page && this.props.state.activeDesign.page.slug !== '404') {
      this.props.dispatch.getPageBySlug('404', true, DesignTypeEnum.Page);
    }
    const state = this.props.state;
    GoogleTagManager.pushCustomPageViewEvent(state, '404');
  }

  render() {
    return this.props.state.activeDesign.page && this.props.state.activeDesign.page.slug === '404'
      ? <PageBlocksView page={this.props.state.activeDesign.page}/>
      : null;
  }
}
