import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editGroup } from './decorators/edit-group.decorator';
import { observable } from 'mobx';
import { editable } from './decorators/editable.decorator';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { emailBlock } from './decorators/email-block.decorator';
import { BaseBlockDataModel } from './base-block-data.model';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { BlockColorType } from '../../enum/block-color.enum';

@emailBlock
@blockType(BlockTypeEnum.EmailSpace)
@blockBackgroundColor('PrimaryBackgroundColor')
export class EmailSpaceModel extends BaseBlockDataModel {
  @editGroup.STYLE
  @editable(numberEdit()
    .label('space.edit.height')
    .placeholder(`space.edit.heightPlaceholder`)
  )
  @observable height: number = 50;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('space.background-color'))
  @observable spaceBackgroundColor: BlockColorType = 'Transparent';
}
