import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { ColorType } from '../../enum/color.enum';
import { ISelectedImage } from '../../interface/component/selected-image.interface';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { htmlEdit } from '../../util/block/edit-types/html-edit.util';
import { linkEdit } from '../../util/block/edit-types/link-edit.util';
import { ILink } from '../../interface/page/link.interface';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { toggleEdit } from '../../util/block/edit-types/toggle-edit.util';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { imagesEdit } from '../../util/block/edit-types/images-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockLimit } from './decorators/block-limit.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';
import { ISelectedVideo } from '../../interface/component/selected-video.interface';
import { videoEdit } from '../../util/block/edit-types/video-edit.util';

export interface IHeroButton {
  text?: any;
  link?: ILink;
}

@pageBlock
@customListBlock
@jobadBlock
@webDocumentBlock
@authenticatedBlock
@blockLimit(1)
@blockType(BlockTypeEnum.Hero)
export class HeroModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(toggleEdit()
    .label('hero.edit.isvideo'))
  @observable isVideo: boolean = false;

  @editGroup.CONTENT
  @editable(videoEdit()
    .label('hero.edit.video'))
  @observable video: ISelectedVideo;

  @editGroup.CONTENT
  @editable(imagesEdit()
    .label('hero.edit.images'))
  @observable images: ISelectedImage[] = [];

  @editGroup.STYLE
  @editable(numberEdit()
    .label(`hero.edit.presentationTimePlaceholder`)
    .min(1.5)
    .max(30)
    .visibleWhen(data => data.images && data.images.length > 1)
  )
  @observable transitionTime: number = 3;

  @editGroup.STYLE
  @editable(numberEdit()
    .label(`hero.edit.transitionDurationLabel`)
    .min(0.1)
    .max(1.2)
    .visibleWhen(data => data.images && data.images.length > 1)
  )
  @observable transitionDuration: number = 1;

  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('hero.edit.mainHeader'))
  @observable mainHeader: any = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('hero.edit.secondaryHeader'))
  @observable secondaryHeader: any = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('hero.edit.mobileMainHeader'))
  @observable mobileMainHeader: any = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('hero.edit.mobileSecondaryHeader'))
  @observable mobileSecondaryHeader: any = getDraftStateFromString('');

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('hero.edit.use-parallax'))
  @observable useParallax: boolean = true;

  @editGroup.STYLE
  @editable(numberEdit()
    .max(1)
    .min(0)
    .label('hero.edit.parallax-amount')
    .visibleWhen((data) => data.useParallax))
  @observable parallaxAmount: number = .8;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .placeholder(`hero.edit.hasBottomStyle-placeholder`)
    .addOption('hero.edit.hasBottomStyleYes', 'on')
    .addOption('hero.edit.hasBottomStyleNo', 'off')
    .label('hero.edit.hasBottomStyle')
  )
  @observable colorStyling: 'on' | 'off' = 'off';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.bottomColorLeft')
    .visibleWhen((data: HeroModel) => data.colorStyling === 'on'))
  @observable bottomColorLeft?: BlockColorType = 'PrimaryButtonColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.bottomColorRight')
    .visibleWhen((data: HeroModel) => data.colorStyling === 'on'))
  @observable bottomColorRight?: BlockColorType = 'PrimaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.leftButtonColor'))
  @observable leftButtonColor?: BlockColorType = 'PrimaryButtonColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.rightButtonColor'))
  @observable rightButtonColor?: BlockColorType = 'PrimaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.leftButtonTextColor'))
  @observable leftButtonTextColor?: BlockColorType = 'PrimaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.rightButtonTextColor'))
  @observable rightButtonTextColor?: BlockColorType = 'PrimaryButtonColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.arrowColor'))
  @observable arrowColor?: ColorType = 'White';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('hero.edit.arrowBackgroundColor'))
  @observable arrowBackgroundColor?: BlockColorType = 'PrimaryButtonColor';

  @editGroup.CONTENT
  @editable(
    plainTextEdit()
      .label(`hero.edit.buttonLeft_text`)
      .onParameter('text'),
    linkEdit()
      .label(`hero.edit.buttonLeft_link`)
      .onParameter('link')
  )
  @observable buttonLeft?: IHeroButton = { text: getDraftStateFromString(''), link: {} };

  @editGroup.CONTENT
  @editable(
    plainTextEdit()
      .label(`hero.edit.buttonRight_text`)
      .onParameter('text'),
    linkEdit()
      .label(`hero.edit.buttonRight_link`)
      .onParameter('link')
  )
  @observable buttonRight?: IHeroButton = { text: getDraftStateFromString(''), link: {} };

}
