import { AppStateModel } from './app-state.model';
import {Service} from '../service';
import {getOIDCClient} from '../util/auth/get-oidc-client.util';
import {LoggerType} from '../util/debug/logger.util';

export interface IStores {
  appState: AppStateModel;
  services: (logger?: LoggerType) => Service;
}

class StoreProvider {
  private static singleton: IStores = null;

  static get stores(): IStores {
    if (this.singleton == null) {
      console.info('creating singleton store');
      const appState = new AppStateModel();
      let services: Service;
      this.singleton = {
        appState: appState,
        services: (logger?: LoggerType) => {
          if (services === undefined) {
            const oidcClient = getOIDCClient(appState.configuration);
            services = new Service(logger, oidcClient, appState, true);
          }
          return services;
        }
      };
    }
    return this.singleton;
  }
}

export default StoreProvider;