import * as React from 'react';
import { Router } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { RoutesConfig } from './routes';
import { action, configure, observable } from 'mobx';
import { LoggerType } from './util/debug/logger.util';
import { renderRoutes } from './util/route/render-routes';
import { IInjectedProps } from './interface/component/injected-props.interface';
import { HistoryActionEnum, ILocation } from './interface/history.interface';
import { SnippetsProvider } from './component/snippet/snippets-provider';
import { SnippetsCollectorContext } from './component/snippet/snippets-collector';
import { COOKIE_POLICY_ACCEPTED } from './constants';
import * as Cookies from 'js-cookie';
import { ErrorImagesDownloader } from './error-images-downloader';

@inject('state', 'history', 'dispatch')
@observer
export class App extends React.Component<IInjectedProps & {inAdmin?: boolean}> {
  @observable logger: LoggerType;
  snippetCollector: SnippetsCollectorContext = new SnippetsCollectorContext();
  connectivityCheckInterval = null;

  componentWillUnmount() {
    if (this.connectivityCheckInterval !== undefined) {
      clearInterval(this.connectivityCheckInterval);
    }
  }

  @action componentDidMount() {
    this.connectivityCheckInterval = setInterval(() => {
      if (!this.props.state.configuration.debug) {
        this.props.dispatch.ping();
      }

    }, 5000);

    this.props.state.configuration.cookiePolicyAccepted = Cookies.get(COOKIE_POLICY_ACCEPTED) === '1';

    try {
      if (window.parent && (window.parent as any).onLoadApp) {
        (window.parent as any).onLoadApp(this.props.state, configure);
      }
    } catch (e) {
      console.warn('Not Same Origin Frame');
    }
    this.props.history.listen(action((location: ILocation, historyAction: HistoryActionEnum) => {
      this.props.state.isBackwards = historyAction === HistoryActionEnum.POP && !this.props.state.configuration.inAdmin;
    }));
  }

  render() {
    return (
      <SnippetsProvider snippetsCollector={this.snippetCollector}>
        <React.Fragment>
          <Router history={this.props.history as any}>
            {renderRoutes(RoutesConfig(this.props.state.configuration.silentCallbackUrl, this.props.inAdmin) as any)}
          </Router>
          <ErrorImagesDownloader />
        </React.Fragment>
      </SnippetsProvider>
    );
  }
}