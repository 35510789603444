import { ConfigurationModel } from '../../model/configuration.model';
import { joinUrl } from '../url/join-url.util';

export function generateUrl(configuration: ConfigurationModel, path: string) {
  let query = path.split('?')[ 1 ];
  const p = path.split('?')[ 0 ];
  query = (query ? `?${query}&` : '?');
  if (query.indexOf('api_key') === -1) {
    query += `api_key=${configuration.apiKey}`;
  }
  return joinUrl(configuration.apiURL, p, query);
}
