import { BaseService } from './base-service';

export class JobAdsService extends BaseService {
  baseURL = 'jobAds';

  getAllJobads = () => {
    return this.get('/');
  }

  getMetadata = () => {
    return this.get('/metadata');
  }
}
