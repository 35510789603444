import { LinkedDataModel } from './linked-data.model';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';

export class StringLinkedDataModel extends LinkedDataModel<string> {
  static types = [
    DesignObjectMentionMetadataType.Unknown,
    DesignObjectMentionMetadataType.Text,
    DesignObjectMentionMetadataType.Date,
    DesignObjectMentionMetadataType.Html,
    DesignObjectMentionMetadataType.Image,
    DesignObjectMentionMetadataType.Link,
    DesignObjectMentionMetadataType.Enum,
  ];
}
