import { CrossIcon, ErrorIcon } from './component/icons';
import * as React from 'react';

// this component will download the bundles needed to show an error.
// they need to be pre-downloaded to show errors in situation when there is no internet connection
export class ErrorImagesDownloader extends React.Component {
 render() {
  return (
   <div style={{ display: 'none' }}>
    <CrossIcon/>
    <ErrorIcon/>
   </div>
  );
 }
}
