import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { CustomListModel } from '../../../model/block';

const CustomListView = asyncComponent(() => import('./custom-list.view').then(c => c.CustomListView));
const CustomListIcon = asyncComponent(() => import('../../icons/block/contact-list.icon').then(c => c.ContactListIcon), 'svg');

export const CustomList = getBlock(CustomListModel, BlockGroupEnum.Lists, 'block.custom-list-title',
  <CustomListIcon/>, CustomListView);
