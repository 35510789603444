import { SchemaORG } from '../../interface/schema.org';

export class SnippetsCollectorContext {
  private _snippetsHashMap: { [ id: string ]: SchemaORG.Thing } = {};

  addSnippet = (id: string, snippetPart: SchemaORG.Thing) => {
    this._snippetsHashMap[ id ] = snippetPart;
  }

  get snippets(): SchemaORG.Thing[] {
    return Object.keys(this._snippetsHashMap).map(key => Object.assign({[ '@context' ]: 'http://schema.org/'}, this._snippetsHashMap[ key ]));
  }
}
