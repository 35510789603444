import { BaseService } from './base-service';

export class TemplateService extends BaseService {
  baseURL = 'Template';

  getTemplateDesignById = (id: string) => {
    return this.get('template-design', {templateId: id});
  }

  getWebDocByHash = (id: string, hash: string) => {
    return this.get('webdoc-content', {templateId: id, cipher: hash});
  }

  updateTemplateById = (id: string, design: string) => {
    return this.put('template-design', undefined, {templateId: id, newTemplateDesign: design});
  }

  getTemplateMetadata = (id: string) => {
    return this.get('possible-mentions', {templateId: id});
  }

  getTemplateMetadataForGroup = (dataSetId: string, groupId: string) => {
    return this.get('mentions-for-group', {dataSetId, groupId});
  }

  getEmailContent = (emailId: string, authHeaders: any = {}) => {
    return this.get('email-content', {emailId}, authHeaders);
  }
}
