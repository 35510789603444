import { asyncComponent } from '../component/async-component/async-component';
import { loadDynamicPage } from '../util/action/type/load-dynamic-page-action.util';
import { newDynamicPage } from '../util/action/type/new-dynamic-page-action.util';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { removeActiveDesignObject } from '../util/action/type/remove-active-page-action.util';
import { httpGetLinkedInSettings } from '../util/action/type/http-get-linked-in-settings-action.util';
import { CUSTOM_LIST_SLUG, JOBAD_SLUG } from '../constants';
import { newCustomListDefinition } from '../util/action/type/new-custom-list-definition-action.util';
import { loadDynamicCustomListDefinition } from '../util/action/type/load-dynamic-custom-list-definition-action.util';
import { newCustomListEntry } from '../util/action/type/new-custom-list-entry-action.util';
import { loadDynamicCustomListEntry } from '../util/action/type/load-dynamic-custom-list-entry-action.util';
import { loadRoles } from '../util/action/type/load-roles-action.util';

const AdminHomePage = asyncComponent(() => import('../component/page/admin/home.page').then((_) => _.AdminHomePage));
const SettingsPage = asyncComponent(() => import('../component/page/admin/settings.page').then((_) => _.SettingsPage));
const AdminSettingsPage = asyncComponent(() => import('../component/page/admin/admin-settings-page/admin-settings.page').then((_) => _.AdminSettingsPage));
const FontsEditorPage = asyncComponent(() => import('../component/page/admin/fonts-editor.page').then((_) => _.FontsEditorPage));
const CustomFontsEditorPage = asyncComponent(() => import('../component/page/admin/fonts-list-editor.page/custom-fonts-editor.page').then((_) => _.CustomFontsEditorPage));
const GoogleFontsEditorPage = asyncComponent(() => import('../component/page/admin/fonts-list-editor.page/google-fonts-editor.page').then((_) => _.GoogleFontsEditorPage));
const MainMenuEditorPage = asyncComponent(() => import('../component/page/admin/main-menu-editor.page/main-menu-editor.page').then((_) => _.MainMenuEditorPage));
const LogosEditorPage = asyncComponent(() => import('../component/page/admin/logos-editor.page').then((_) => _.LogosEditorPage));
const MediaLibraryPage = asyncComponent(() => import('../component/page/admin/media-library.page').then((_) => _.MediaLibraryPage));
const EditPagePage = asyncComponent(() => import('../component/page/admin/edit-page.page').then((_) => _.EditPagePage));
const NewPagePage = asyncComponent(() => import('../component/page/admin/new-page.page').then((_) => _.NewPagePage));
const ColorsPage = asyncComponent(() => import('../component/page/admin/color-settings.page').then((_) => _.ColorSettingsPage));
const CookiePolicySettingsPage = asyncComponent(() => import('../component/page/admin/cookie-policy-settings.page').then((_) => _.CookiePolicySettingsPage));
const SpinnerSettingsPage = asyncComponent(() => import('../component/page/admin/spinner-settings.page').then((_) => _.SpinnerSettingsPage));
const ButtonSettingsPage = asyncComponent(() => import('../component/page/admin/button-settings.page').then((_) => _.ButtonSettingsPage));
const CustomListDefinitionEditorPage = asyncComponent(() => import('../component/page/admin/custom-list-definition-editor.page/custom-list-definition-editor.page').then((_) => _.CustomListDefinitionEditorPage));
const CustomListEntriesTablePage = asyncComponent(() => import('../component/page/admin/custom-list-entries-table.page/custom-list-entries-table.page').then((_) => _.CustomListEntriesTablePage));
const CustomListEntryEditorPage = asyncComponent(() => import('../component/page/admin/custom-list-entry-editor.page/custom-list-entry-editor.page').then((_) => _.CustomListEntryEditorPage));

export const AdminRoutes = () => ([
  {
    path: `/admin/settings`,
    exact: true,
    action: removeActiveDesignObject(),
    component: SettingsPage
  },
  {
    path: `/admin/settings`,
    exact: false,
    action: removeActiveDesignObject(),
    component: SettingsPage,
    routes: [
      {
        path: `/admin/settings/mainmenu`,
        exact: true,
        actions: [loadRoles()],
        component: MainMenuEditorPage
      },
      {
        path: `/admin/settings/fonts`,
        exact: true,
        component: FontsEditorPage
      },
      {
        path: `/admin/settings/custom-fonts`,
        exact: true,
        component: CustomFontsEditorPage
      },
      {
        path: `/admin/settings/google-fonts`,
        exact: true,
        component: GoogleFontsEditorPage
      },
      {
        path: `/admin/settings/logos`,
        exact: true,
        component: LogosEditorPage
      },
      {
        path: `/admin/settings/colors`,
        exact: true,
        action: removeActiveDesignObject(),
        component: ColorsPage
      },
      {
        path: `/admin/settings/cookie-policy`,
        exact: true,
        actions: [ removeActiveDesignObject(), httpGetLinkedInSettings() ],
        component: CookiePolicySettingsPage
      },
      {
        path: `/admin/settings/spinner`,
        exact: true,
        actions: [ removeActiveDesignObject() ],
        component: SpinnerSettingsPage
      },
      {
        path: `/admin/settings/button`,
        exact: true,
        actions: [ removeActiveDesignObject() ],
        component: ButtonSettingsPage
      }
    ]
  },
  {
    path: `/admin/adminSettings`,
    exact: false,
    actions: [ removeActiveDesignObject(), httpGetLinkedInSettings() ],
    component: AdminSettingsPage
  },
  {
    path: `/admin`,
    exact: true,
    action: removeActiveDesignObject(),
    component: AdminHomePage
  },
  {
    path: '/admin/media-library',
    exact: true,
    action: removeActiveDesignObject(),
    component: MediaLibraryPage
  },
  {
    path: '/admin/pages/new-page',
    exact: true,
    action: newDynamicPage(),
    component: NewPagePage
  },
  {
    path: `/admin/${CUSTOM_LIST_SLUG}/definition/*`,
    exact: true,
    action: loadDynamicCustomListDefinition(true),
    component: CustomListEntriesTablePage
  },
  {
    path: `/admin/${CUSTOM_LIST_SLUG}/new-entry/*`,
    exact: true,
    actions: [loadDynamicCustomListDefinition(true), newCustomListEntry()],
    component: CustomListEntryEditorPage
  },
  {
    path: `/admin/${CUSTOM_LIST_SLUG}/edit-entry/*`,
    exact: true,
    actions: [loadDynamicCustomListEntry()],
    component: CustomListEntryEditorPage
  },
  {
    path: `/admin/${CUSTOM_LIST_SLUG}/edit-definition/*`,
    exact: true,
    action: loadDynamicCustomListDefinition(),
    component: CustomListDefinitionEditorPage
  },
  {
    path: `/admin/${CUSTOM_LIST_SLUG}/new-list`,
    exact: true,
    action: newCustomListDefinition(),
    component: CustomListDefinitionEditorPage
  },
  {
    path: `/admin/pages/${JOBAD_SLUG}/*`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage),
    component: EditPagePage
  },
  {
    path: `/admin/pages/${CUSTOM_LIST_SLUG}/*`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.CustomList),
    component: EditPagePage
  },
  {
    path: `/admin/pages/*`,
    exact: true,
    action: loadDynamicPage(),
    component: EditPagePage
  }
]);
