import { DesignTypeEnum } from '../../enum/design-type.enum';
import { IBasePageData } from '../../interface/page/base-page-data.interface';
import { IPageSlug } from './is-valid-page-slug.util';

export const duplicateSlug = (allPages: IBasePageData[],
                              pageSlug: IPageSlug) => {
  return allPages.filter((page) => {
    const selectedType = pageSlug.type || DesignTypeEnum.Page;
    return page.type === selectedType;
  }).some((page) => page.slug === pageSlug.slug && page.id !== pageSlug.id);
};
