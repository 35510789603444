import * as cloudinary from 'cloudinary-core';
import {joinUrl} from './join-url.util';
import {isStringEmpty} from '../string/is-string-empty.util';

const cl = new cloudinary.Cloudinary({cloud_name: 'intelliplan-test', secure: true});

const cloudinaryBaseURL = 'https://ipglobalshare01.azureedge.net/';

const defaultOptions = {
    crop: 'lfill',
    gravity: 'faces:center',
    quality: 'auto:best'
};

export const getImageCloudinaryURL = (url: string, width?: number, height?: number, crop: string = 'lfill', gravity?: string) => {
    if (isStringEmpty(url)) {
        return url;
    }
    let options;
    if (width || height) {
        options = Object.assign({width: width && Math.round(width), height: height && Math.round(height)}, defaultOptions, {crop});
        if (!gravity) {
            if (crop.indexOf('fill') !== -1) {
                options.gravity = 'faces:center';
            } else {
                options.gravity = undefined;
            }
        }
    }
    if (url.indexOf(cloudinaryBaseURL) === 0 && url.indexOf('.svg') === -1) {
        return cl.url(joinUrl('storage', url.slice(cloudinaryBaseURL.length - 1)), options);
    } else  if (url.indexOf('https://res.cloudinary.com/intelliplan-test/') === 0 && url.indexOf('storage') !== -1) {
        const uri = url.split('/storage/')[1];
        const retUrl = cl.url(joinUrl('storage', uri), options);
        return retUrl;
    } else {
        return url;
    }
};
