import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { ContactListModel } from '../../../model/block';

const ContactListView = asyncComponent(() => import('./contact-list.view').then(c => c.ContactListView));
const ContactListIcon = asyncComponent(() => import('../../icons/block/contact-list.icon').then(c => c.ContactListIcon), 'svg');

export const ContactList = getBlock(ContactListModel, BlockGroupEnum.Lists, 'block.contact-list-title',
  <ContactListIcon/>, ContactListView);
