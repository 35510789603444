import {BlockModel} from '../../model/block/block.model';
import {isContainerBlock} from './is-container-block.util';

export const findBlockByIdInContainer = (container: {blocks: BlockModel[]}, blockId: string) => {
  for (const block of container.blocks) {
    if (block.id === blockId) {
      return block.data;
    } else {
      if (isContainerBlock(block)) {
        const foundInnerBlock = findBlockByIdInContainer(block.data, blockId);
        if (foundInnerBlock) {
          return foundInnerBlock;
        }
      }
    }
  }
};

export const findBlockObjectByIdInContainer = (container: {blocks: BlockModel[]}, blockId: string): BlockModel => {
  for (const block of container.blocks) {
    if (block.id === blockId) {
      return block;
    } else {
      if (isContainerBlock(block)) {
        const foundInnerBlock = findBlockByIdInContainer(block.data, blockId);
        if (foundInnerBlock) {
          return foundInnerBlock;
        }
      }
    }
  }
};