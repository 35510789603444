import { observable } from 'mobx';
import { persist } from 'mobx-persist';
import { ILogo } from '../../interface/block/logo.interface';
import { ImageOriginEnum } from '../../enum/image-origin.enum';
import { LogoTypeEnum } from '../../enum/logo-type.enum';

export class LogoModel implements ILogo {
  @persist @observable id?: string;
  @persist @observable title: string;
  @persist @observable location: string;
  @persist @observable type: LogoTypeEnum;
  @persist @observable largeLocation: string;
  @persist @observable mediumLocation: string;
  @persist @observable smallLocation: string;
  @persist @observable thumbnailLocation: string;
  @persist @observable iconLocation: string;
  @persist @observable origin: ImageOriginEnum;
  @persist @observable imageUrl?: string;
}
