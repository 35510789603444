import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { TableRowBlockModel } from '../../../model/block';

const TableRowBlockView = asyncComponent(() => import('./table-row-block.view').then(c => c.TableRowBlockView));
const TableRowBlockIcon = asyncComponent(() => import('../../icons/block/repeating-text-block.icon').then(c => c.RepeatingTextBlockIcon), 'svg');

export const TableRowBlock = getBlock(TableRowBlockModel, BlockGroupEnum.Lists, 'block.table-row-block-title',
  <TableRowBlockIcon/>, TableRowBlockView);
