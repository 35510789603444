import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import {RepeaterBlockModel} from '../../../model/block';

const RepeaterBlockView = asyncComponent(() => import('./repeater-block.view').then(c => c.RepeaterBlockView));
const RepeaterBlockIcon = asyncComponent(() => import('../../icons/block/repeating-text-block.icon').then(c => c.RepeatingTextBlockIcon), 'svg');

export const RepeaterBlock = getBlock(RepeaterBlockModel, BlockGroupEnum.Lists, 'block.repeater-block-title',
  <RepeaterBlockIcon/>, RepeaterBlockView);
