import { LinkedDataModel } from './linked-data.model';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';

class DataSetConfig {
  groupId: string;
  filterId: string;
  report: LinkedDataModel[];
}

export class DataSetLinkedDataModel extends LinkedDataModel<any[]> {
  static types = [
    DesignObjectMentionMetadataType.DataSet
  ];

  dataSetConfig: DataSetConfig = new DataSetConfig();

  setServerData = (data) => {
    Object.assign(this, data);
    this.dataSetConfig = new DataSetConfig();
    if (data.dataSetConfig) {
      Object.assign(this.dataSetConfig, data.dataSetConfig);
    }
  }
}
