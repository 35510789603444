import { uniq } from 'lodash';
import { action, computed, observable, toJS } from 'mobx';
import { IPageData } from '../interface/page/page-data.interface';
import { persist } from 'mobx-persist';
import { getPageUniqueID } from '../util/page/get-page-unique-id.util';
import { AuthenticatedDesignObjectModel } from './authenticated-design-object.model';
import { flatten } from '../util/array/flatten.util';
import { ISelectedImage } from '../interface/component/selected-image.interface';
import * as Draft from 'draft-js';
import { convertFromRaw, EditorState } from 'draft-js';
import { findEntitiesInState } from '../util/draft/find-entities-in-editor-state.util';
import { ImageOriginEnum } from '../enum/image-origin.enum';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { plainTextEdit } from '../util/block/edit-types/plain-text-edit.util';
import { editable } from './block/decorators/editable.decorator';
import { STATIC_PAGE_SLUGS } from '../constants';
import { getSlugFromTitle } from '../util/string/get-slug-from-title.util';
import { getDesignObjectMetadataOnChange } from '../util/action/type/get-design-object-metadata-on-change-action.util';
import { editGroup } from './block/decorators/edit-group.decorator';
import { isValidPageSlug } from '../util/page/is-valid-page-slug.util';
import { toggleEdit } from '../util/block/edit-types/toggle-edit.util';
import { plainStringEdit } from '../util/block/edit-types/plain-string-edit.util';
import { imageEdit } from '../util/block/edit-types/image-edit.util';
import { tagsEdit } from '../util/block/edit-types/tags-edit.util';
import { assignDesignObjectValue } from './design-object.model';
import { AppStateModel } from './app-state.model';
import { enumPickerEdit, ILabelValue } from '../util/block/edit-types/enum-picker-edit.util';
import { getDraftStateFromString } from '../util/block/get-draft-state-from-string.util';
import { isStaticPage } from '../util/page/is-static-page.util';
import { isPageModel } from '../util/design-object/is-page-model.util';

export class PageModel extends AuthenticatedDesignObjectModel implements IPageData {
  @computed
  get usedMentions(): string[] {
    let titleMentions = [];
    if (this.title) {
      const state = EditorState.createWithContent(convertFromRaw(toJS(this.title)));
      const entities = findEntitiesInState(state, 'MENTION');
      titleMentions = entities.map((foundEntity) => foundEntity.entity.getData().id);
    }
    let ogTitleMentions = [];
    if (this.ogTitle) {
      const state = EditorState.createWithContent(convertFromRaw(toJS(this.ogTitle)));
      const entities = findEntitiesInState(state, 'MENTION');
      ogTitleMentions = entities.map((foundEntity) => foundEntity.entity.getData().id);
    }
    let descriptionMentions = [];
    if (this.description) {
      const state = EditorState.createWithContent(convertFromRaw(toJS(this.description)));
      const entities = findEntitiesInState(state, 'MENTION');
      descriptionMentions = entities.map((foundEntity) => foundEntity.entity.getData().id);
    }
    const imageMentions = [];
    if (this.ogImage && this.ogImage.origin === ImageOriginEnum.Mention && this.ogImage.mention) {
      imageMentions.push(this.ogImage.mention.id);
    }
    return uniq(flatten(this.blocks.map((block) => block.getUsedMentions(this).slice())).concat(titleMentions).concat(ogTitleMentions).concat(descriptionMentions).concat(imageMentions));
  }

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('page-editor-settings.page-name')
    .placeholder('page-editor-settings.title_placeholder')
    .onChange(action((newValue, oldValue, data) => {
      const title = newValue;
      const oldTitleValue = oldValue;
      const isSlugStaticPage = STATIC_PAGE_SLUGS.indexOf(data.slug) !== -1;
      if (!isSlugStaticPage && getSlugFromTitle(oldTitleValue) === data.slug) {
        data.slug = getSlugFromTitle(title);
      }
      return newValue;
    }))
  )
  @persist('object') @(observable.shallow) title: Draft.RawDraftContentState = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(enumPickerEdit()
    .label('page-editor-settings.page_type')
    .placeholder('page-type-selector.select-type')
    .setOptionsGetter((value, data, state) => {
      const toReturn: ILabelValue[] = [
        {
          label: 'page.type.normal',
          value: DesignTypeEnum.Page
        },
        {
          label: 'page.type.jobad',
          value: DesignTypeEnum.JobAdPage
        }
      ];
      if (state.configuration.authorization.cmsAdvancedContentUse) {
        toReturn.push({
          label: 'page.type.custom-list',
          value: DesignTypeEnum.CustomList
        });
      }
      return toReturn;
    })
    .disabledWhen((value, data: PageModel) => STATIC_PAGE_SLUGS.indexOf(data.slug) !== -1 || (data.linkedData && data.linkedData.length > 0))
    .actionsOnChange([ getDesignObjectMetadataOnChange() ])
  )
  @persist @observable type: DesignTypeEnum;

  @editGroup.CONTENT
  @editable(toggleEdit()
    .label('page-editor-settings.is_home_page')
    .disabledWhen((value, data: PageModel, state: AppStateModel) => data.type === DesignTypeEnum.CustomList || state.allPages.some((page) => (!data.type || page.type === data.type) && page.isHomePage && page.id !== data.id))
  )
  @persist @observable isHomePage: boolean = false;

  @editGroup.CONTENT
  @editable(plainStringEdit()
    .label('page-editor-settings.URL')
    .disabledWhen((value, data: PageModel, state) => (data.isHomePage || (isPageModel(state.activeDesign.data) && isStaticPage(state.activeDesign.data))))
    .withValidator((value, oldValue, data, configuration, state) => {
      if (data.isHomePage) {
        return [ {
          isValid: true,
          messageKey: 'page-editor-settings.homePage'
        } ];
      }
      if (isPageModel(state.activeDesign.data) && isStaticPage(state.activeDesign.data)) {
        return [ {
          isValid: true,
          messageKey: 'page-editor-settings.static-page'
        } ];
      }
      const validity = isValidPageSlug(
        state.allPages, {
          id: data.id,
          slug: data.slug.trim(),
          type: data.type
        }, state.adminSettings.rootFiles);
      return [ {
        isValid: validity.isValid,
        messageKey: validity.message,
        messageProps: validity.props
      } ];
    })
  )
  @persist @observable slug: string = '';

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('page-editor-settings.page-og-title')
    .placeholder('page-editor-settings.ogTitle_placeholder')
  )
  @persist('object') @(observable.shallow) ogTitle?: Draft.RawDraftContentState = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(imageEdit()
    .label('page-editor-settings.page-og-image'))
  @persist('object') @observable ogImage?: ISelectedImage;

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('page-editor-settings.page-description')
    .placeholder('page-editor-settings.description_placeholder')
  )
  @persist('object') @(observable.shallow) description?: Draft.RawDraftContentState = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(tagsEdit()
    .label(`page-editor-settings.SEO-tags`)
    .tagPrefix('#')
  )
  @persist('list') @observable metaDataTags: string[] = [];

  // Local only properties
  @persist @observable isNew: boolean = false;
  @observable isCopy: boolean = false;

  get uniqueId() {
    return getPageUniqueID(this);
  }

  constructor(pageData?: IPageData, inAdmin?: boolean, isDraft: boolean = false) {
    super(pageData, inAdmin, isDraft);
    if (pageData) {
      assignDesignObjectValue(this, pageData, 'title');
      assignDesignObjectValue(this, pageData, 'description');
      assignDesignObjectValue(this, pageData, 'metaDataTags');
      assignDesignObjectValue(this, pageData, 'isHomePage');
      assignDesignObjectValue(this, pageData, 'slug');
      assignDesignObjectValue(this, pageData, 'ogTitle');
      assignDesignObjectValue(this, pageData, 'ogImage');
      assignDesignObjectValue(this, pageData, 'isNew');
    } else {
      this.type = DesignTypeEnum.Page;
    }
  }
}
