import { asyncComponent } from '../component/async-component/async-component';
import { initializeAction } from '../util/action/type/initialize-action.util';
import { loadAdminRoute } from '../util/action/type/load-admin-route-action.util';
import { AdminRoutes } from './admin-routes';
import { FrontendRoutes } from './frontend-routes';
import { getAllPages } from '../util/action/type/get-all-pages-action.util';
import { resetActiveDesignObject } from '../util/action/type/reset-active-design-object-action.util';
import { loadPublicRoute } from '../util/action/type/load-public-route-action.util';
import { loadRoles } from '../util/action/type/load-roles-action.util';
import { loadDynamicTemplate } from '../util/action/type/load-dynamic-template-action.util';
import { loadWebDocRoute } from '../util/action/type/load-web-doc-route-action.util';

const RoutesWrapper = asyncComponent(() => import('../routes-wrapper').then((_) => _.RoutesWrapper));
const IndexPage = asyncComponent(() => import('../component/page/frontend').then((_) => _.IndexPage));
const AdminIndexPage = asyncComponent(() => import('../component/page/admin').then((_) => _.PageAdminIndex));
const TemplateEditorPage = asyncComponent(() => import('../component/page/admin/templates/template-editor.page').then((_) => _.TemplateEditorPage));
const QuickTemplateEditorPage = asyncComponent(() => import('../component/page/admin/templates/quick-template-editor.page').then((_) => _.QuickTemplateEditorPage));
const WebDocPage = asyncComponent(() => import('../component/page/frontend/web-doc.page').then((_) => _.WebDocPage));

export const RoutesConfig = (silentLoginCallbackURL: string, inAdmin?: boolean) => {
  const routes = [];
  if (inAdmin === true || inAdmin === undefined) {
    routes.push(
      {
        path: `/admin/template/:templateId`,
        actions: [ loadAdminRoute(), loadRoles(), loadDynamicTemplate() ],
        exact: true,
        component: TemplateEditorPage
      }
    );
    routes.push(
      {
        path: `/admin`,
        actions: [ loadAdminRoute(), loadRoles() ],
        component: AdminIndexPage,
        routes: AdminRoutes()
      }
    );
    routes.push(
      {
        path: `/quick-edit/template/:templateId`,
        actions: [ loadAdminRoute(), loadRoles(), loadDynamicTemplate() ],
        exact: true,
        component: QuickTemplateEditorPage
      }
    );
    routes.push(
      {
        path: `/login`,
        action: loadAdminRoute(),
        component: AdminIndexPage,
        routes: AdminRoutes()
      }
    );
  }
  routes.push({
    path: `/web-doc/:templateId`,
    actions: [ loadPublicRoute(), loadWebDocRoute() ],
    exact: true,
    component: WebDocPage
  });
  routes.push({
    path: `/`, // always keep this route as last due to exact === false
    action: loadPublicRoute(),
    component: IndexPage,
    routes: FrontendRoutes(silentLoginCallbackURL)
  });
  return [ {
    component: RoutesWrapper,
    actions: [ initializeAction(), resetActiveDesignObject(), getAllPages() ],
    routes
  } ];
};
