import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { toggleEdit } from '../../util/block/edit-types/toggle-edit.util';
import { blockLimit } from './decorators/block-limit.decorator';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';

@authenticatedBlock
@pageBlock
@customListBlock
@jobadBlock
@blockLimit(1)
@blockType(BlockTypeEnum.Header)
@blockBackgroundColor('SecondaryBackgroundColor')
export class HeaderModel extends BaseBlockDataModel {
  @editGroup.STYLE
  @editable(toggleEdit()
    .label('header.edit.overlay-content')
  )
  @observable overlayContent: boolean = true;

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('header.edit.logo'))
  @observable showLogo: boolean;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .placeholder(`header.edit.pickLogoAlignment`)
    .addOption('header.edit.logo-alignment-right', 'right')
    .addOption('header.edit.logo-alignment-left', 'left')
    .label('header.edit.logoAlignment')
    .visibleWhen((data) => data.menuStyle === 'normal')
  )
  @observable normalLogoAlignment: 'right' | 'left' = 'right';

  @editGroup.STYLE
  @editable(enumPickerEdit()
      .placeholder(`header.edit.pickFallbackLogoAlignment`)
      .addOption('header.edit.logo-alignment-center', 'center')
      .addOption('header.edit.logo-alignment-right', 'right')
      .addOption('header.edit.logo-alignment-left', 'left')
      .label('header.edit.fallbackLogoAlignment')
      .visibleWhen((data) => data.menuStyle === 'normal')
  )
  @observable backupLogoAlignment: 'center' | 'right' | 'left' = 'center';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .placeholder(`header.edit.pickLogoAlignment`)
    .addOption('header.edit.logo-alignment-center', 'center')
    .addOption('header.edit.logo-alignment-right', 'right')
    .addOption('header.edit.logo-alignment-left', 'left')
    .label('header.edit.logoAlignment')
    .visibleWhen((data) => data.menuStyle === 'dropdown')
  )
  @observable dropdownLogoAlignment: 'center' | 'right' | 'left' = 'center';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .placeholder(`header.edit.pickMenuStyle`)
    .addOption('header.edit.menu-style-normal', 'normal')
    .addOption('header.edit.menu-style-dropdown', 'dropdown')
    .label('header.edit.menuStyle')
  )
  @observable menuStyle: 'normal' | 'dropdown' = 'dropdown';

  @editGroup.COLOR
  @editable(numberEdit()
    .min(0)
    .max(1)
    .maxLength(4)
    .label('header.edit.opacity')
  )
  @observable backgroundOpacity: number = 0.7;
}
