import { ConfigurationModel } from '../../model/configuration.model';
import { getOauthBearerValue } from '../string/get-oauth-bearer-value.util';
import { ADMIN_HEADER } from '../../constants';
import { isStringEmpty } from '../string/is-string-empty.util';

export function generateAuthHeader(configuration: ConfigurationModel, contentType: string = 'application/json') {
  const headers: any = {
    'Content-Type': contentType
  };

  if (configuration.accessToken) {
    const authorization = getOauthBearerValue(configuration.accessToken);
    if (!isStringEmpty(authorization)) {
      headers.Authorization = authorization;
    }
    headers[ ADMIN_HEADER ] = configuration.inAdmin ? 'true' : 'false';
  }

  return headers;
}
