import { PageModel } from './page.model';
import { ICustomListData } from '../interface/page/custom-list-data.interface';
import { observable } from 'mobx';
import { persist } from 'mobx-persist';
import { editable } from './block/decorators/editable.decorator';
import { editGroup } from './block/decorators/edit-group.decorator';
import { enumPickerEdit } from '../util/block/edit-types/enum-picker-edit.util';
import { assignDesignObjectValue } from './design-object.model';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { getDesignObjectMetadataOnChange } from '../util/action/type/get-design-object-metadata-on-change-action.util';
import {STATIC_PAGE_SLUGS} from '../constants';

export class CustomListModel extends PageModel implements ICustomListData {
  @editGroup.CONTENT
  @editable(
    enumPickerEdit()
      .required('custom-list-page.definition-required')
      .setOptionsGetter((v, d, state) => state.editor.customListDefinitions.map((data) => (
        {
          value: data.id,
          label: data.name
        }
      )))
      .label('custom-list-page.definition')
      .placeAfter('type')
      .disabledWhen((value, data: PageModel) => STATIC_PAGE_SLUGS.indexOf(data.slug) !== -1 || (data.linkedData && data.linkedData.length > 0))
      .actionsOnChange([ getDesignObjectMetadataOnChange() ])
  )
  @persist @observable customListDefinitionId: string;

  constructor(pageData?: ICustomListData, inAdmin?: boolean, isDraft: boolean = false) {
    super(pageData, inAdmin, isDraft);
    if (pageData) {
      assignDesignObjectValue(this, pageData, 'customListDefinitionId');
    } else {
      this.type = DesignTypeEnum.CustomList;
    }
  }
}
