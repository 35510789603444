import * as moment from 'moment';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { LinkedDataModel } from '../../model/linked-data/linked-data.model';
import { isArray, isObject } from 'lodash';
import { toJS } from 'mobx';
import { createLinkedDataFromData } from '../linked-data/create-linked-data-from-data';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';
import { DesignObjectModel } from '../../model/design-object.model';
import { ROOTED_LINKED_DATA_ID_PREFIX } from '../../constants';
import { findLinkedDataById } from './find-linked-data-by-id.util';
import { getLinkedDataBlockKey } from './get-linked-data-block-key.util';
import {isNumberString} from '../number/is-number-string.util';

export function findLinkedData(usedMention: IUsedDesignObjectMentionMetadata, design?: DesignObjectModel, blockMentionData: { [ key: string ]: any } = {}): LinkedDataModel {
  if (!usedMention) {
    return null;
  }
  if (blockMentionData && (!design || usedMention.isBlockData)) {
    const id = (usedMention as any).mentionId || usedMention.id;
    if (usedMention.id && usedMention.id.indexOf(ROOTED_LINKED_DATA_ID_PREFIX) === 0) {
      const linkedData = findLinkedDataById(usedMention.id, design);
      if (!linkedData) {
        return undefined;
      }
      if (linkedData.data) {
        return linkedData;
      }
      const dataId = getLinkedDataBlockKey(linkedData.mentionId);
      return createLinkedDataFromData(Object.assign({}, linkedData, {
        data: toJS(blockMentionData[ dataId ])
      }));
    } else {
      const dataId = getLinkedDataBlockKey(id);
      const data = toJS(blockMentionData[ dataId ]);
      let dateCheck = false;
      let htmlCheck = false;
      if (data !== undefined) {
        if (isObject(data)) {
          htmlCheck = !isArray(data);
        } else {
          dateCheck = !isNumberString(data) && (moment(data, 'YYYY-MM-DDTHH:mm:ss', true).isValid() || moment(data, 'YYYY-MM-DD', true).isValid());
        }
      }
      return createLinkedDataFromData(Object.assign({}, usedMention, {
        displayName: usedMention.id,
        mentionId: usedMention.id,
        data: data,
        type: htmlCheck ? DesignObjectMentionMetadataType.Html : (dateCheck ? DesignObjectMentionMetadataType.Date : DesignObjectMentionMetadataType.Unknown),
        isList: isArray(data)
      }));
    }
  }
  let returnData = findLinkedDataById(usedMention.id, design);
  if (returnData && returnData.parentId && blockMentionData) {
    const lookupId = getLinkedDataBlockKey(returnData.mentionId);
    returnData = Object.assign({}, returnData, {
      data: toJS(blockMentionData[ lookupId ])
    });
  }
  return returnData;
}
