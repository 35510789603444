import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { BaseBlockDataModel } from './base-block-data.model';
import { tableRowEdit } from '../../util/block/edit-types/table-row-edit.util';
import { RowBorderOptions, ITableRow } from '../../interface/component/table-cell.interface';
import { blockAuthorization } from './decorators/block-authorization.decorator';

@blockAuthorization((state) => state.configuration.authorization.cmsAdvancedContentUse)
@authenticatedBlock
@webDocumentBlock
@blockType(BlockTypeEnum.TableRowBlock)
@blockBackgroundColor('PrimaryBackgroundColor')
export class TableRowBlockModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(tableRowEdit()
    .label('table-row.edit.data')
  )
  @observable row: ITableRow = { cells: [], rowBorder: RowBorderOptions.NONE };
}
