import { computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { SettingsModel } from './settings/settings.model';
import { transformURLTemplate } from '../util/url/transform-url-template.util';
import { AuthorizationSettingsModel } from './settings/authorization-settings.model';
import {CustomFontModel} from './settings/custom-font.model';
import {GoogleFontsModel} from './settings/google-fonts-model';

export class ConfigurationModel {
  @observable currentVersion: string;
  @observable buildDate: string;
  @persist @observable siteVersion: string;
  @persist @observable inAdmin: boolean = false;
  @persist('object', AuthorizationSettingsModel) @observable authorization: AuthorizationSettingsModel = new AuthorizationSettingsModel();
  @persist('object', SettingsModel) @observable settings: SettingsModel = new SettingsModel();
  @persist @observable idToken: string;
  @persist @observable tenantId: string;
  @persist @observable accessToken: string;
  @persist @observable debugTenantId: string;
  @persist @observable debug: boolean;
  @persist @observable verboseLogging: boolean;
  @persist @observable isPreview: boolean;
  @persist @observable hasCache: boolean;
  @persist @observable isMobile: boolean;
  @persist @observable isChrome: boolean;
  @persist @observable isExplorer: boolean;
  @persist @observable cookiePolicyAccepted: boolean;
  @persist @observable locale: string;
  @persist @observable role: string;
  @persist @observable authorityUrl: string;
  @persist @observable clientId: string;
  @persist @observable CMSUrl: string;
  @persist @observable debugCMSUrl: string;
  @persist @observable CDNUrl: string;
  @persist @observable staticCDNNUrlTemplate: string;
  @persist @observable currentPod: string;
  @persist @observable apiURLTemplate: string;
  @persist @observable apiKey: string;
  @persist @observable loginCallbackUrl: string;
  @persist @observable logoutCallbackUrl: string;
  @persist @observable silentCallbackUrl: string;
  @persist @observable deviceId: string;
  @persist @observable partnerId: string;
  @persist('object') @observable translations: {
    [ key: string ]: string;
  } = {};

  @persist('list', CustomFontModel) @observable customFonts: CustomFontModel[] = [];
  @persist('object', GoogleFontsModel) @observable googleFonts: GoogleFontsModel = new GoogleFontsModel();

  @computed
  get apiURL() {
    return transformURLTemplate(this.apiURLTemplate, this as any);
  }

  @computed
  get staticCDNNUrl() {
    return transformURLTemplate(this.staticCDNNUrlTemplate, this as any);
  }
}
