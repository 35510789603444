import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editGroup } from './decorators/edit-group.decorator';
import { editable } from './decorators/editable.decorator';
import { htmlEdit } from '../../util/block/edit-types/html-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';

@pageBlock
@customListBlock
@jobadBlock
@webDocumentBlock
@authenticatedBlock
@blockType(BlockTypeEnum.TwoColumnText)
@blockBackgroundColor('PrimaryBackgroundColor')
export class TwoColumnTextModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('two-column-text.textBlock1'))
  @observable text1: any = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('two-column-text.textBlock2'))
  @observable text2: any = getDraftStateFromString('');
}
