import { computed, observable, autorun, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import { ConfigurationModel } from './configuration.model';
import { IMessage } from '../interface/message.interface';
import { IWindowSize } from '../interface/dom/window-size.interface';
import { IBasePageData } from '../interface/page/base-page-data.interface';
import { MediaLibraryModel } from './media-library/media-library.model';
import { EditorModel } from './editor.model';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { allColors, ColorEnum, ColorEnumReturn } from '../enum/color.enum';
import { isStaticPage } from '../util/page/is-static-page.util';
import { allFonts, FontEnum, FontEnumReturn } from '../enum/font.enum';
import { ISettingsColor, ISettingsFont } from '../interface/settings.interface';
import { LogoTypeEnum } from '../enum/logo-type.enum';
import { ILogo } from '../interface/block/logo.interface';
import { ImageOriginEnum } from '../enum/image-origin.enum';
import { joinUrl } from '../util/url/join-url.util';
import { allBlockColors, BlockColorEnum, BlockColorEnumReturn } from '../enum/block-color.enum';
import { AdminSettingsModel } from './admin-settings.model';
import { DesignObjectEnum } from '../enum/design-object.enum';
import { DesignObjectGroupModel } from './design-object-group.model';
import { findIndex } from 'lodash';
import { IEnumItem } from '../component/enum-picker/enum-picker';
import { isStringEmpty } from '../util/string/is-string-empty.util';
import { RepeaterBlocksStore } from './repeater-blocks.model';
import { BlockTypeEnum } from '../enum/block/block-type.enum';

interface ILogosReturnType {
  [LogoTypeEnum.WHITE_LOGO]: ILogo;
  [LogoTypeEnum.DARK_LOGO]: ILogo;
}

const whiteLogoImage: string = '/white_logo.svg';
const darkLogoImage: string = '/dark_logo.svg';

export class AppStateModel {
  @persist('list') @observable allPages: IBasePageData[] = [];
  @observable messages: IMessage[] = [];
  @observable windowSize: IWindowSize = {
    width: window.innerWidth,
    height: window.innerHeight
  };
  @observable loadingScriptCount: number = 0;
  @observable isLoading: boolean = false;
  @observable previewPage: boolean;
  @observable previewMode: string;
  @observable loadingPath: boolean = false;
  @persist @observable currentPath: string;
  @persist('object', MediaLibraryModel) @observable mediaLibrary: MediaLibraryModel = new MediaLibraryModel();
  @persist @observable designObjectType: DesignObjectEnum;
  @persist('object', DesignObjectGroupModel) @observable activeDesign: DesignObjectGroupModel = new DesignObjectGroupModel();
  @persist('object', ConfigurationModel) @observable configuration: ConfigurationModel = new ConfigurationModel();
  @persist('object', EditorModel) @observable editor: EditorModel = new EditorModel();
  @persist('list') @observable systemRoles: string[] = [];
  @persist('object', AdminSettingsModel) @observable adminSettings = new AdminSettingsModel();
  pagesScrollLocation: { [key: string]: number } = {};
  isBackwards: boolean = false;

  @observable hasInternetConnection: boolean = true;
  @observable pinging: boolean = false;

  @observable mouseOverBlockId: string = '';
  @observable currentDraggingBlockType: BlockTypeEnum = undefined;
  @observable currentDraggingBlockId: string = undefined;
  @observable repeaterBlocksStore: RepeaterBlocksStore;

  @computed
  get currentFullPath() {
    return joinUrl(this.configuration.CMSUrl, this.currentPath);
  }

  @computed
  get dynamicPages() {
    return this.allPages.filter((page) => !isStaticPage(page));
  }

  @computed
  get staticPages() {
    return this.allPages.filter(isStaticPage);
  }

  @computed
  get normalDynamicPages() {
    return this.dynamicPages.filter((page) => page.type === DesignTypeEnum.Page);
  }

  @computed
  get homePage() {
    return this.allPages.filter((page) => page.isHomePage)[0];
  }

  @computed
  get colors(): ColorEnumReturn {
    const colorEnum = ColorEnum(this.configuration.settings.colors.map((color) => Object.assign({}, color)));
    const colorEnumKeys = Object.keys(colorEnum).concat(Object.getOwnPropertyNames(colorEnum));
    const toReturn: ColorEnumReturn = {} as any;
    colorEnumKeys.forEach(enumKey => {
      if (enumKey === 'Transparent' || allColors.indexOf(enumKey) !== -1) {
        Object.defineProperty(toReturn, enumKey, { get: () => colorEnum[enumKey], configurable: true });
      } else {
        Object.defineProperty(toReturn, enumKey, {
          get: () => (parameter) => colorEnum[enumKey](parameter),
          configurable: true
        });
      }
    });
    return toReturn;
  }

  @computed
  get googleFontsUrl(): string {
    if (this.configuration.googleFonts && !isStringEmpty(this.configuration.googleFonts.fontsFamiliesString)) {
      return `https://fonts.googleapis.com/css2?family=${this.configuration.googleFonts.fontsFamiliesString}&display=swap`;
    }
  }

  predefinedFonts = [{
    label: 'Helvetica',
    value: '\'Helvetica\', \'Arial\', sans-serif'
  }, {
    label: 'Georgia',
    value: '\'Georgia\', \'Garamond\', serif'
  }, {
    label: 'Impact',
    value: '\'Impact\', \'Charcoal\', sans-serif'
  }, {
    label: 'Tahoma',
    value: '\'Tahoma\', \'Geneva\', sans-serif'
  }, {
    label: 'Times',
    value: '\'Times\', \'Times New Roman\', serif'
  }, {
    label: 'Verdana',
    value: '\'Verdana\', \'Geneva\', sans-serif'
  }];

  @computed
  get allFontsEnumItems(): IEnumItem[] {
    const googleFonts = this.configuration.googleFonts.fonts.map(x => {
      return { label: x.fontName, value: x.fallbackFont ? `${x.fontName}, ${x.fallbackFont}` : x.fontName };
    });
    const customFonts = this.configuration.customFonts.map(x => {
      return { label: x.fontName, value: x.fontFallback ? `${x.fontName}, ${x.fontFallback}` : x.fontName };
    });
    return this.predefinedFonts.concat(googleFonts).concat(customFonts);
  }

  @computed
  get blockColors(): BlockColorEnumReturn {
    const colorEnum = BlockColorEnum(this.configuration.settings.colors.map((color) => Object.assign({}, color)));
    const colorEnumKeys = Object.keys(colorEnum).concat(Object.getOwnPropertyNames(colorEnum));
    const toReturn: BlockColorEnumReturn = {} as any;
    colorEnumKeys.forEach(enumKey => {
      if (allBlockColors.indexOf(enumKey) !== -1) {
        Object.defineProperty(toReturn, enumKey, { get: () => colorEnum[enumKey], configurable: true });
      } else {
        Object.defineProperty(toReturn, enumKey, {
          get: () => (parameter) => colorEnum[enumKey](parameter),
          configurable: true
        });
      }
    });
    return toReturn;
  }

  @computed
  get fonts(): FontEnumReturn {
    const fontEnum = FontEnum(this.configuration.settings.fonts.map((font) => Object.assign({}, font)) as ISettingsFont[], this.configuration.settings.colors.map((color) => Object.assign({}, color)) as ISettingsColor[]);
    const colorEnumKeys = Object.keys(fontEnum).concat(Object.getOwnPropertyNames(fontEnum));
    const toReturn: FontEnumReturn = {} as any;
    colorEnumKeys.forEach(enumKey => {
      if (allFonts.indexOf(enumKey) !== -1) {
        Object.defineProperty(toReturn, enumKey, { get: () => fontEnum[enumKey], configurable: true });
      } else {
        Object.defineProperty(toReturn, enumKey, {
          get: () => (parameter) => fontEnum[enumKey](parameter),
          configurable: true
        });
      }
    });
    return toReturn;
  }

  @computed
  get logos(): ILogosReturnType {
    const toReturn: ILogosReturnType = this.configuration.settings.logos.reduce((acc: ILogosReturnType, current) => {
      switch (current.type) {
        case LogoTypeEnum.WHITE_LOGO:
          acc[LogoTypeEnum.WHITE_LOGO] = current;
          break;
        case LogoTypeEnum.DARK_LOGO:
          acc.dark = current;
          break;
      }
      return acc;
    }, {} as any);

    if (!toReturn.dark) {
      const darkIntelliplanLogoPath = joinUrl(this.configuration.staticCDNNUrl, darkLogoImage);
      const darkLogoObject = {
        title: 'dark logo',
        type: LogoTypeEnum.DARK_LOGO,
        origin: ImageOriginEnum.Url,
        location: darkIntelliplanLogoPath,
        smallLocation: darkIntelliplanLogoPath,
        mediumLocation: darkIntelliplanLogoPath,
        largeLocation: darkIntelliplanLogoPath,
        iconLocation: darkIntelliplanLogoPath,
        thumbnailLocation: darkIntelliplanLogoPath,
        imageUrl: darkIntelliplanLogoPath
      };
      toReturn.dark = darkLogoObject;
    }

    if (!toReturn.white) {
      const whiteIntelliplanLogoPath = joinUrl(this.configuration.staticCDNNUrl, whiteLogoImage);
      const whiteLogoObject = {
        title: 'white logo',
        type: LogoTypeEnum.DARK_LOGO,
        origin: ImageOriginEnum.Url,
        location: whiteIntelliplanLogoPath,
        smallLocation: whiteIntelliplanLogoPath,
        mediumLocation: whiteIntelliplanLogoPath,
        largeLocation: whiteIntelliplanLogoPath,
        iconLocation: whiteIntelliplanLogoPath,
        thumbnailLocation: whiteIntelliplanLogoPath,
        imageUrl: whiteIntelliplanLogoPath
      };
      toReturn.white = whiteLogoObject;
    }

    return toReturn;
  }

  constructor() {
    this.repeaterBlocksStore = new RepeaterBlocksStore();
    autorun(() => {
      if (!this.hasInternetConnection) {
        runInAction(() => {
          this.messages.push({
            id: 'internet_con_down',
            title: this.adminSettings.noInternetConnectionTitleText,
            message: '',
            appearance: 'error'
          });
        });
      } else {
        runInAction(() => {
          const index = findIndex(this.messages, val => val.id === 'internet_con_down');
          this.messages.splice(index, 1);
        });
      }
    });
  }
}
