import { FieldDefinitionModel } from '../field-definition.model';
import { customListFieldType } from '../decorators/custom-list-field-type';
import { CustomListFieldDefinitionTypeEnum } from '../../../enum/custom-lists/custom-list-field-definition-type.enum';
import { EnumFieldOptionsModel } from './options/enum-field-options.model';
import { IEnumFieldOptions } from '../../../interface/custom-lists/enum-field-options.interface';

@customListFieldType(CustomListFieldDefinitionTypeEnum.Enum)
export class EnumFieldDefinitionModel extends FieldDefinitionModel<EnumFieldOptionsModel> {
  setFieldOptions = (data: IEnumFieldOptions) => {
    this.fieldOptions = new EnumFieldOptionsModel();
    Object.assign(this.fieldOptions, data);
  }
}
