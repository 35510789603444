import {DesignObjectModel} from '../../model/design-object.model';
import {findBlockByIdInContainer, findBlockObjectByIdInContainer} from './find-block-by-id-in-container.util';
import {BlockModel} from '../../model/block/block.model';

export const findBlockById = (design: DesignObjectModel, blockId: string) => {
  return findBlockByIdInContainer(design, blockId);
};

export const findBlockObjectById = (design: DesignObjectModel, blockId: string): BlockModel => {
  return findBlockObjectByIdInContainer(design, blockId);
};