import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { DesignTypeEnum } from '../../../enum/design-type.enum';
import { LinkOriginEnum } from '../../../enum/link-origin.enum';
import { ILink } from '../../../interface/page/link.interface';
import {AppStateModel} from '../../../model/app-state.model';
import {IBasePageData} from '../../../interface/page/base-page-data.interface';
import {IUsedDesignObjectMentionMetadata} from '../../../interface/page/design-object-mention-metadata.interface';
import {LinkedDataModel} from '../../../model/linked-data/linked-data.model';

export interface ILinkEditComponentProps {
  placeholder?: string;
  isTemplate?: boolean;
  availableTypes?: DesignTypeEnum[];
  availableOrigin?: LinkOriginEnum[];
  pageFilterFunction?: (page: IBasePageData, data: any, state: AppStateModel, findLinkData: (mention: IUsedDesignObjectMentionMetadata) => LinkedDataModel) => boolean;
}

interface IReturnType {
  placeholder: (placeholder: string) => IEditModeReturnValue<ILinkEditComponentProps, string, IReturnType>;
  availableTypes: (availableTypes: DesignTypeEnum[]) => IEditModeReturnValue<ILinkEditComponentProps, string, IReturnType>;
  availableOrigin: (availableOrigin: LinkOriginEnum[]) => IEditModeReturnValue<ILinkEditComponentProps, string, IReturnType>;
  isTemplate: (isTemplate: boolean) => IEditModeReturnValue<ILinkEditComponentProps, string, IReturnType>;
  setPageFilterFunction: (pageFilterFunction: (page: IBasePageData, data: any, state: AppStateModel, findLinkData: (mention: IUsedDesignObjectMentionMetadata) => LinkedDataModel) => boolean) => IEditModeReturnValue<ILinkEditComponentProps, string, IReturnType>;
}

export const linkEdit = (componentProps: ILinkEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<ILinkEditComponentProps, string, IReturnType> = returnEditMode<ILinkEditComponentProps, string, IReturnType>('link', componentProps) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.isTemplate = (isTemplate: boolean) => {
    toReturn._decoratorProperties.componentProps.isTemplate = isTemplate;
    return toReturn;
  };
  toReturn.availableTypes = (availableTypes: DesignTypeEnum[]) => {
    toReturn._decoratorProperties.componentProps.availableTypes = availableTypes;
    return toReturn;
  };
  toReturn.availableOrigin = (availableOrigin: LinkOriginEnum[]) => {
    toReturn._decoratorProperties.componentProps.availableOrigin = availableOrigin;
    return toReturn;
  };
  toReturn.setUsedMentionsReducer((link: ILink) => {
    if (link && link.origin === LinkOriginEnum.Mention && link.mention) {
      return [ link.mention ];
    }
    return [];
  });
  toReturn.setPageFilterFunction = (pageFilterFunction: (page: IBasePageData, data: any, state: AppStateModel, findLinkData: (mention: IUsedDesignObjectMentionMetadata) => LinkedDataModel) => boolean) => {
    toReturn._decoratorProperties.componentProps.pageFilterFunction = pageFilterFunction;
    return toReturn;
  };
  return toReturn;
};
