export enum DesignObjectMentionMetadataType {
  Unknown = 'unknown',
  Text = 'text',
  Html = 'html',
  Link = 'link',
  Image = 'image',
  Date = 'date',
  Form = 'form',
  Folder = 'folder',
  DataSet = 'dataset',
  JobAdList = 'jobads(list)',
  ContactsList = 'contacts(list)',
  Enum = 'enum',
  CustomList = 'customlist',
  Number = 'number'
}
