import { PossibleListTypesEnum } from '../../../enum/possible-list-types.enum';
import { mentionPickerEdit, MentionPickerReturnType } from './mention-picker-edit.util';

interface IListMentionPickerProps {
  placeholder?: string;
  listType?: PossibleListTypesEnum;
}

interface IReturnType {
  placeholder: (placeholder: string) => MentionPickerReturnType<IReturnType>;
  listType: (listType: PossibleListTypesEnum) => MentionPickerReturnType<IReturnType>;
  showFilters: (shouldShow: boolean) => MentionPickerReturnType<IReturnType>;
}

export const listMentionPickerEdit = (componentProps: IListMentionPickerProps = {} as any) => {
  const toReturn: MentionPickerReturnType<IReturnType> = mentionPickerEdit(componentProps)
    .filterMentions((mention) => mention.type.indexOf('list') !== -1 && (!componentProps.listType || mention.type.indexOf(componentProps.listType) !== -1)) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.listType = (listType: PossibleListTypesEnum) => {
    componentProps.listType = listType;
    return toReturn;
  };
  return toReturn;
};
