import { IStyledTextFieldOptions } from '../../../../interface/custom-lists/styled-text-field-options.interface';
import { persist } from 'mobx-persist';
import { observable } from 'mobx';
import { editGroup } from '../../../block/decorators/edit-group.decorator';
import { editable } from '../../../block/decorators/editable.decorator';
import { numberEdit } from '../../../../util/block/edit-types/number-edit.util';
import { BaseRequiredFieldOptionsModel } from '../base-required-field-options.model';

export class StyledTextFieldOptionsModel extends BaseRequiredFieldOptionsModel implements IStyledTextFieldOptions {
  @editGroup.CONTENT
  @editable(
    numberEdit()
      .min(0)
      .label('custom-list.field-options.styled-text.max-length')
  )
  @persist @observable maxLength: number;
  @editGroup.CONTENT
  @editable(
    numberEdit()
      .min(0)
      .label('custom-list.field-options.styled-text.max-lines')
  )
  @persist @observable maxLines: number;
}