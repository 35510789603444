import { ITextFieldOptions } from '../../../../interface/custom-lists/text-field-options.interface';
import { persist } from 'mobx-persist';
import { observable } from 'mobx';
import { editGroup } from '../../../block/decorators/edit-group.decorator';
import { editable } from '../../../block/decorators/editable.decorator';
import { numberEdit } from '../../../../util/block/edit-types/number-edit.util';
import { BaseRequiredFieldOptionsModel } from '../base-required-field-options.model';

export class TextFieldOptionsModel extends BaseRequiredFieldOptionsModel implements ITextFieldOptions {
  @editGroup.CONTENT
  @editable(
    numberEdit()
      .min(0)
      .label('custom-list.field-options.text.max-length')
  )
  @persist @observable maxLength: number;
}