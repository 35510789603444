import { EDIT_CONFIGS_KEY, EDITABLE_METADATA_KEY } from '../../model/block/decorators/editable.decorator';
import { EDIT_LABEL_KEY } from '../../model/block/decorators/edit-group.decorator';
import { IEditConfig } from '../../interface/block/edit-config.interface';
import { forEachPrototype } from './for-each-prototype.util';

interface ISingleDecoratedKey {
  [ EDIT_CONFIGS_KEY ]: IEditConfig<any, any>[];
  [ EDIT_LABEL_KEY ]: string;
}

export interface IDecorators {
  [ key: string ]: ISingleDecoratedKey;
}

export function getEditableDecorators(value: any): IDecorators {
  const metadataArray: IDecorators[] = [];
  forEachPrototype(value, (baseConstructor) => {
    if (baseConstructor) {
      metadataArray.unshift(baseConstructor[ EDITABLE_METADATA_KEY ]);
    }
  });

  return metadataArray.filter(metadata => Boolean(metadata))
    .reduce((decoratorsMetadataAcc, metadata) => ({
      ...decoratorsMetadataAcc,
      ...metadata
    }), {});
}
