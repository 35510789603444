export enum HistoryActionEnum {
  POP = 'POP',
  PUSH = 'PUSH'
}

export interface ILocation {
  hash: string;
  pathname: string;
  search: string;
  state: any;
}

export interface IHistory {
  length: number;
  location: ILocation;

  push(path: string, state?: any);

  replace(path: string, state?: any);

  listen(callback: (location: ILocation, action: any) => void): any;

  goBack();

  goForward();
}
