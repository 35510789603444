import { persist } from 'mobx-persist';
import { action, observable } from 'mobx';
import { ISettingsFont } from '../../interface/settings.interface';
import { ColorType } from '../../enum/color.enum';
import { getUniqueId } from '../../util/string/get-unique-id.util';

export class FontModel implements ISettingsFont {
  @action
  static createFont() {
    return Object.assign(new FontModel(), {
      isSaved: false,
      bold: false,
      fontFamily: '\'Helvetica\', \'Arial\', sans-serif',
      fontSize: 20,
      italic: false,
      underline: false,
      predefined: false,
      highlightColor: 'Transparent',
      textColor: 'ContentColor',
      label: '',
      id: getUniqueId()
    });
  }

  @persist id: string;
  @persist predefined: boolean;
  @persist @observable label: string;
  @observable isSaved: boolean = true;
  @persist @observable fontFamily: string;
  @persist @observable fontSize: number;
  @persist @observable bold?: boolean;
  @persist @observable italic?: boolean;
  @persist @observable underline?: boolean;
  @persist @observable textColor?: ColorType; // id of the text color
  @persist @observable highlightColor?: ColorType; // id of the highlight color
}
