import { PossibleListTypesEnum } from '../../../enum/possible-list-types.enum';
import { IDesignObjectMentionMetadata } from '../../../interface/page/design-object-mention-metadata.interface';
import { mentionPickerEdit, MentionPickerReturnType } from './mention-picker-edit.util';
import { DesignObjectMentionMetadataType } from '../../../enum/page-mention-metadata-type.enum';

type MentionFilterType = (mention: IDesignObjectMentionMetadata) => boolean;

interface IListMentionPickerProps {
  placeholder?: string;
  listType?: PossibleListTypesEnum;
  mentionFilter?: MentionFilterType;
}

interface IReturnType {
  placeholder: (placeholder: string) => MentionPickerReturnType<IReturnType>;
}

export const formMentionPickerEdit = (componentProps: IListMentionPickerProps = {} as any) => {
  return mentionPickerEdit<IReturnType>(componentProps)
    .filterMentions((mention) => mention.type === DesignObjectMentionMetadataType.Form) as MentionPickerReturnType<IReturnType>;
};
