import { decodeToken } from './decode-token.util';

export function isTokenExpired(token: string) {
  if (!token) {
    return false;
  }
  let decoded;
  try {
    decoded = decodeToken(token);
  } catch (error) {
    return true;
  }
  return !decoded || decoded.exp < ((Date.now() / 1000) + 60 * 5);
}
