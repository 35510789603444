import { Observable } from 'rxjs/Observable';
import * as Cookies from 'js-cookie';
import { refreshIfTokenExpired } from '../auth/refresh-if-token-expired.util';
import { ConfigurationModel } from '../../model/configuration.model';
import { generateAuthHeader } from './generate-auth-header';
import { generateUrl } from './generate-url';
import { AjaxRequestMethodEnum } from '../../enum/ajax-request-method.enum';
import { isomorphicAjax } from './isomorphix-ajax.util';
import { isServerSide } from '../server-side-rendering/is-server-side';
import { AJAX_REQUEST_DEBOUNCE_MS, REDIRECT_AFTER_LOGIN_COOKIE } from '../../constants';
import { AjaxError } from 'rxjs/Rx';
import { requestNewToken } from '../auth/request-new-token.util';
import { writeSessionCookie } from '../auth/write-session-cookie.util';
import { readSessionCookie } from '../auth/read-session-cookie.util';
import { AppStateModel } from '../../model/app-state.model';
import { action, runInAction } from 'mobx';
import {LoggerType} from '../debug/logger.util';

const makeAjaxCall = (logger: LoggerType, oidcClient, configuration: ConfigurationModel, method, path, body, contentType?: string, extraHeaders?: { [key: string]: string }) => {
  let headers = generateAuthHeader(configuration, contentType);
  headers = Object.assign({}, headers, extraHeaders);
  if (contentType && contentType.indexOf('multipart/form-data') !== -1) {
    delete headers['Content-Type'];
  }
  headers = Object.keys(headers).reduce((newHeaders, key) => {
    if (headers[key]) {
      newHeaders[key] = headers[key];
    }
    return newHeaders;
  }, {});
  const url = generateUrl(configuration, path);
  logger.debug({body, headers: extraHeaders, contentType}, `Making ${method} request on ${url}`);
  return isomorphicAjax({
    url,
    method,
    body,
    headers,
    crossDomain: true
  });
};

export function createAjaxRequest(logger: LoggerType, appStateModel: AppStateModel, oidcClient, method: AjaxRequestMethodEnum, path: string, body?: any, contentType?: string, extraHeaders?: { [key: string]: string }) {
  if (!appStateModel.hasInternetConnection && !appStateModel.pinging) {
    runInAction(() => {
      appStateModel.isLoading = false;
    });
    return Observable.empty();
  }

  let finalPath = path;
  if (appStateModel.configuration.partnerId) {
    finalPath += (finalPath.indexOf('?') === -1 ? '?' : '&') + `partnerId=${appStateModel.configuration.partnerId}`;
  }
  if (isServerSide()) {
    return makeAjaxCall(logger, oidcClient, appStateModel.configuration, method, finalPath, body, contentType, extraHeaders);
  } else {
    /* tslint:disable:no-empty */
    return Observable.fromPromise(refreshIfTokenExpired(appStateModel.configuration, oidcClient))
      .delay(AJAX_REQUEST_DEBOUNCE_MS)
      .end(() => { })
      .mergeMap(() => makeAjaxCall(logger, oidcClient, appStateModel.configuration, method, finalPath, body, contentType, extraHeaders)
        .catch(action((error: AjaxError) => {
          appStateModel.isLoading = false;
          if (error.status === 401) {
            const sessionCookie = readSessionCookie(Cookies.get, REDIRECT_AFTER_LOGIN_COOKIE);
            return Observable.fromPromise(requestNewToken(oidcClient).then((login) => {
              if (login && login.session) {
                sessionCookie[login.state] = window.location.pathname;
                writeSessionCookie(Cookies.set, REDIRECT_AFTER_LOGIN_COOKIE, sessionCookie);
              }
            }))
              .switchMap(() => Observable.throw(error));
          } else if (error.status === 0) {
            appStateModel.hasInternetConnection = false;
            return Observable.empty();
          } else {
            return Observable.throw(error);
          }
        })));
  }
}