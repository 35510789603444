import { BaseFieldOptionsModel } from '../base-field-options.model';
import { ILinkFieldOptions } from '../../../../interface/custom-lists/link-field-options.interface';
import { LinkOriginEnum } from '../../../../enum/link-origin.enum';
import { persist } from 'mobx-persist';
import { observable } from 'mobx';
import { editGroup } from '../../../block/decorators/edit-group.decorator';
import { editable } from '../../../block/decorators/editable.decorator';
import { enumPickerEdit } from '../../../../util/block/edit-types/enum-picker-edit.util';

export class LinkFieldOptionsModel extends BaseFieldOptionsModel implements ILinkFieldOptions {
  @editGroup.CONTENT
  @editable(
    enumPickerEdit()
      .label('custom-list.field-options.link.possible-origins')
      .addOption('custom-list.field-options.link.internal-origin', LinkOriginEnum.Internal)
      .addOption('custom-list.field-options.link.external-origin', LinkOriginEnum.External)
      .isMulti()
  )
  @persist @observable possibleOrigin: LinkOriginEnum[] = [LinkOriginEnum.Internal, LinkOriginEnum.External];
}