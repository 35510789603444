import { draftToString } from '../draft/draft-to-string.util';
import { getUrlSafeString } from './get-url-safe-string.util';
import * as Draft from 'draft-js';

export const getSlugFromTitle = (title: Draft.RawDraftContentState) => {
  return getUrlSafeString(
    draftToString(
      title,
      undefined,
      undefined,
      (usedMentionData => usedMentionData.id.slice(4, 23))
    )
  );
};
