import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import { isServerSide } from '../../util/server-side-rendering/is-server-side';
import { Logger } from '../../util/debug/logger.util';
import { IInjectedProps } from '../../interface/component/injected-props.interface';
import { AppStateModel } from '../../model/app-state.model';

function isPromise(obj: (Promise<any>) | (() => Promise<any>)): obj is Promise<any> {
  return 'then' in obj;
}

export function asyncComponent<P = any>(getComponent: () => Promise<any>, elementTag: string = 'div', showLoader: boolean = false) {
  const ComponentHolder = observable({
    Component: null
  });

  @inject('state')
  @observer
  class AsyncComponent extends React.Component<P & IInjectedProps & { onAsyncComponentLoaded: () => void }, any> {
    static load(state: AppStateModel = null) {
      if (ComponentHolder.Component) {
        return;
      }
      if (state) {
        runInAction(() => {
          state.loadingScriptCount++;
        });
      }
      const componentPromise = isPromise(getComponent) ? getComponent : getComponent();
      return componentPromise
        .then(comp => comp && comp.default || comp)
        .then(
          action(action(C => {
            ComponentHolder.Component = C;
            if (state) {
              state.loadingScriptCount--;
              if (state.loadingScriptCount < 0) {
                state.loadingScriptCount = 0;
              }
            }
          })),
          action(error => {
            Logger.error('Could not load async component', error);
            if (state) {
              state.loadingScriptCount--;
              if (state.loadingScriptCount < 0) {
                state.loadingScriptCount = 0;
              }
            }
          })
        );
    }

    asynCalled = false;

    componentDidMount() {
      if (!ComponentHolder.Component) {
        // noinspection JSIgnoredPromiseFromCall
        AsyncComponent.load(this.props.state);
      }
    }

    componentDidUpdate() {
      if (ComponentHolder.Component && this.props.onAsyncComponentLoaded && !this.asynCalled) {
        this.props.onAsyncComponentLoaded();
        this.asynCalled = true;
      }
    }

    render() {
      const Component: any = ComponentHolder.Component;
      if (Component) {
        return <Component {...this.props} />;
      }
      if (showLoader) {
        return <div>Loading...</div>;
      } else {
        return React.createElement(elementTag, {
          className: (this.props as any).className,
          style: (this.props as any).style
        });
      }
    }
  }

  if (isServerSide()) {
    AsyncComponent.load();
  }
  return AsyncComponent;
}
