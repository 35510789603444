import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { FormModel } from '../../../model/block';

const FormView = asyncComponent(() => import('./form.view').then(c => c.FormView));
const FormIcon = asyncComponent(() => import('../../icons/block/form.icon').then(c => c.FormIcon), 'svg');

export const Form = getBlock(FormModel, BlockGroupEnum.Interactive, 'block.form-title', <FormIcon/>, FormView);
