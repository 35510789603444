import { EditorState, EntityInstance } from 'draft-js';

export function findEntitiesInState(editorState: EditorState, entityType: string = null): {
  entityKey: string,
  blockKey: string,
  start: number,
  end: number,
  entity: EntityInstance
}[] {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({...selectedEntity, start, end});
      });
  });
  return entities;
}
