import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { EmailImageModel, ImageModel } from '../../../model/block';

const ImageView = asyncComponent(() => import('./image.view').then(c => c.ImageView));
const EmailImageView = asyncComponent(() => import('./email-image.view').then(c => c.EmailImageView));
const ImageIcon = asyncComponent(() => import('../../icons/block/image.icon').then(c => c.ImageIcon), 'svg');

export const Image = getBlock(ImageModel, BlockGroupEnum.ImageAndVideo, 'block.image-title', <ImageIcon/>, ImageView);
export const EmailImage = getBlock(EmailImageModel, BlockGroupEnum.ImageAndVideo, 'block.email-image-title',
  <ImageIcon/>, EmailImageView, {
    text: () => ''
  });
