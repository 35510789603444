import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { stringRequiredValidator } from '../../validators/string-required-validator';

export interface IPlainStringEditComponentProps {
  placeholder?: string;
}

interface IReturnType {
  placeholder: (placeholder: string) => IEditModeReturnValue<IPlainStringEditComponentProps, any, IReturnType>;
  required: (errorMessage?: string) => IEditModeReturnValue<IPlainStringEditComponentProps, any, IReturnType>;
}

export const plainStringEdit = (componentProps: IPlainStringEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IPlainStringEditComponentProps, any, IReturnType> = returnEditMode<IPlainStringEditComponentProps, any, IReturnType>('plainString', componentProps) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.required = (errorMessage) => {
    toReturn.withValidator(stringRequiredValidator(errorMessage));
    return toReturn;
  };
  return toReturn;
};
