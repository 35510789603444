import { FieldDefinitionModel } from '../field-definition.model';
import { customListFieldType } from '../decorators/custom-list-field-type';
import { CustomListFieldDefinitionTypeEnum } from '../../../enum/custom-lists/custom-list-field-definition-type.enum';
import { ImageFieldOptionsModel } from './options/image-field-options.model';
import { IImageFieldOptions } from '../../../interface/custom-lists/image-field-options.interface';

@customListFieldType(CustomListFieldDefinitionTypeEnum.Image)
export class ImageFieldDefinitionModel extends FieldDefinitionModel<ImageFieldOptionsModel> {
  setFieldOptions = (data: IImageFieldOptions) => {
    this.fieldOptions = new ImageFieldOptionsModel();
    Object.assign(this.fieldOptions, data);
  }
}
