import { BaseService } from './base-service';
import { ILogo } from '../interface/block/logo.interface';
import { ISettingsColor, ISettingsFont } from '../interface/settings.interface';
import { ICookiePolicySettings } from '../interface/cookie-policy-settings.interface';
import { BlockColorType } from '../enum/block-color.enum';
import { LinkedinLoginTypeEnum } from '../enum/linkedin-login-type.enum';
import { IMainMenuSettings } from '../interface/main-menu-settings.interface';
import { CustomFontModel } from '../model/settings/custom-font.model';
import { GoogleFontsModel } from '../model/settings/google-fonts-model';

export class SiteService extends BaseService {
  baseURL = 'site';

  getSiteCopy = () => {
    return this.get('/copy');
  }

  updateFonts = (fonts: ISettingsFont[]) => {
    return this.put(`/update-fonts`, undefined, {
      fonts
    });
  }

  updateLogos = (logos: ILogo[]) => {
    return this.put(`/update-logos`, undefined, {
      logos
    });
  }

  updateColors = (colors: ISettingsColor[]) => {
    return this.put(`/update-colors`, undefined, {
      colors
    });
  }

  getLinkedInSettings = (apiKey?: string) => {
    console.log('linkedin: getting creds with api key', apiKey);
    return this.get(`/get-linkedin-connect`, {
      api_key: apiKey
    });
  }

  updateLinkedInSettings = (type: LinkedinLoginTypeEnum, clientId: string, clientSecret: string) => {
    return this.put(`/update-linkedin-connect`, undefined, {
      linkedInLoginType: type,
      clientId: clientId,
      clientSecret: clientSecret
    });
  }

  updateTagManagerSettings = (tagManagerId: string, trackCustomEvents: boolean, countryCode: string, language: string, bookingEngine: string, businessArea: string) => {
    return this.put(`/update-google-tag`, undefined, {
      googleTagManagerId: tagManagerId,
      trackCustomEvents: trackCustomEvents,
      countryCode: countryCode,
      language: language,
      bookingEngine: bookingEngine,
      businessArea: businessArea
    });
  }

  uploadFavIcon = (image: any) => {
    const formData = new FormData();
    formData.append('file', image);
    return this.put(`update-favicon`, undefined, formData, 'multipart/form-data;');
  }

  updateCookiePolicySettings = (cookieSettings: ICookiePolicySettings) => {
    return this.put(`/update-cookie-policy`, undefined, cookieSettings);
  }

  updateSpinnerSettings = (spinner: number, color: BlockColorType) => {
    return this.put(`/update-spinner`, undefined, {
      spinner,
      color
    });
  }

  updateMainMenuSettings = (mainMenu: IMainMenuSettings) => {
    return this.put(`/update-mainmenu`, undefined, {
      mainMenu
    });
  }

  updateGlobalErrorSettings = (errorMessageTitleText: string, noInternetConnectionTitleText: string, errorMessageJobAdExpiredText: string, errorMessageJobAdNotPublishedText: string, errorMessageIeWarning: string) => {
    return this.put(`/update-global-error-settings`, undefined, {
      errorMessageTitleText,
      errorMessageShowMoreText: '',
      noInternetConnectionTitleText,
      errorMessageJobAdExpiredText,
      errorMessageJobAdNotPublishedText,
      errorMessageIeWarning
    });
  }

  updateCookiePolicies = (enableIntelliplanCookiePolicy: boolean, enableOneTrustCookiePolicy: boolean, oneTrustAccountId: string, enableCookieInformationPolicy: boolean, cookieInformationLanguage: string) => {
    return this.put('/update-cookie-policies', undefined, {
      enableIntelliplanCookiePolicy,
      enableOneTrustCookiePolicy,
      oneTrustAccountId,
      enableCookieInformationPolicy,
      cookieInformationLanguage
    });
  }

  updateButtonThemeSettings = (buttonThemeId: number) => {
    return this.put(`/update-buttonthemeid`, undefined, {
      newId: buttonThemeId
    });
  }

  updateGoogleFonts = (googleFonts: GoogleFontsModel) => {
    return this.put(`/update-google-fonts`, undefined, {
      fontsFamiliesString: googleFonts.fontsFamiliesString,
      fonts: googleFonts.fonts
    });
  }

  updateCustomFonts(customFonts: CustomFontModel[]) {
    return this.put(`/update-custom-fonts`, undefined, {
      customFonts: customFonts
    });
  }
}
