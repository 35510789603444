import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { ColorType } from '../../enum/color.enum';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { dataSetEdit } from '../../util/block/edit-types/dataset-edit.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { linkEdit } from '../../util/block/edit-types/link-edit.util';
import { ILink } from '../../interface/page/link.interface';

export interface IDocumentItem {
  name: string;
  location: string;
  pageText: string;
  fileName: string;
}

const blockMentionEdit = (placeholder: string) => plainTextEdit()
  .placeholder(placeholder)
  .visibleWhen((data) => Boolean(data.selectedMention));

@authenticatedBlock
@webDocumentBlock
@blockType(BlockTypeEnum.DocumentList)
@blockBackgroundColor('SecondaryBackgroundColor')
export class DocumentListModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(dataSetEdit()
    .label('document-list.edit.data')
    .placeholder('document-list.edit.select-data')
  )
  @observable selectedMention: IUsedDesignObjectMentionMetadata;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('document-list.edit.document-item-background-color'))
  @observable documentItemBackgroundColor: ColorType = 'ContentBackground';

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('document-list.edit.page-text')
    .placeholder('document-list.edit.page-text-placeholder'))
  @observable pageText: any = getDraftStateFromString('Page');

  @editGroup.TRANSLATION
  @editable(blockMentionEdit('document-list.edit.document-name-placeholder')
    .label('document-list.edit.document-name'))
  @observable documentName: any = getDraftStateFromString();

  @editGroup.TRANSLATION
  @editable(linkEdit()
    .label('document-list.edit.document-location'))
  @observable documentLocation: ILink;
}
