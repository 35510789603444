import { DesignTypeEnum } from '../../enum/design-type.enum';

interface IPageUniqueID {
  id: string;
  slug: string;
  type: DesignTypeEnum;
}

export function getPageUniqueID(page: IPageUniqueID) {
  if (!page.id) {
    return 'new-page';
  }
  return `${page.type}/${page.slug}`;
}
