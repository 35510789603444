import { IEditModeReturnValue } from './return-edit-mode.util';
import { enumPickerEdit } from './enum-picker-edit.util';

export interface IFontEditComponentProps {
}

interface IReturnType {
}

export const fontAlignmentPickerEdit = (componentProps: IFontEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IFontEditComponentProps, string, IReturnType> = enumPickerEdit(componentProps as any)
    .addOption('font-alignment.left', 'left')
    .addOption('font-alignment.center', 'center')
    .addOption('font-alignment.right', 'right');
  return toReturn;
};
