import * as allBlocks from '../../component/blocks';
import { IBuildingBlock } from '../../interface/block/building-block.interface';

export function getAllBuildingBlocks(): IBuildingBlock[] {
  const blockKeys = Object.keys(allBlocks);
  const array = [];
  for (const blockKey of blockKeys) {
    const currentBlock = allBlocks[ blockKey ];
    const block: IBuildingBlock = {
      blockType: currentBlock.model.blockType,
      group: currentBlock.group,
      title: currentBlock.title,
      isDragDisabled: false
    };
    array.push(block);
  }

  return array;
}
