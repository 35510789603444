import { uniq } from 'lodash';
import { observable, toJS } from 'mobx';
import { persist } from 'mobx-persist';
import { AuthenticatedDesignObjectModel } from './authenticated-design-object.model';
import * as Draft from 'draft-js';
import { convertFromRaw, EditorState } from 'draft-js';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { IWebDocumentData } from '../interface/page/web-document-data.interface';
import { assignDesignObjectValue } from './design-object.model';
import { editGroup } from './block/decorators/edit-group.decorator';
import { editable } from './block/decorators/editable.decorator';
import { plainTextEdit } from '../util/block/edit-types/plain-text-edit.util';
import { findEntitiesInState } from '../util/draft/find-entities-in-editor-state.util';
import { flatten } from '../util/array/flatten.util';
import { getDraftStateFromString } from '../util/block/get-draft-state-from-string.util';

export class WebDocumentModel extends AuthenticatedDesignObjectModel implements IWebDocumentData {
  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('web-document-editor-settings.title')
    .placeholder('web-document-editor-settings.title_placeholder')
  )
  @persist('object') @(observable.shallow) title: Draft.RawDraftContentState = getDraftStateFromString('');

  get usedMentions(): string[] {
    let titleMentions = [];
    if (this.title) {
      const state = EditorState.createWithContent(convertFromRaw(toJS(this.title)));
      const entities = findEntitiesInState(state, 'MENTION');
      titleMentions = entities.map((foundEntity) => foundEntity.entity.getData().id);
    }
    return uniq(flatten(this.blocks.map((block) => block.getUsedMentions(this).slice())).concat(titleMentions));
  }

  constructor(webDocumentData?: IWebDocumentData, inAdmin?: boolean, isDraft: boolean = false) {
    super(webDocumentData, inAdmin, isDraft);
    if (webDocumentData) {
      assignDesignObjectValue(this, webDocumentData, 'title');
    }
    this.type = DesignTypeEnum.WebDocument;
  }
}
