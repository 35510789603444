import { css } from 'styled-components';
import { centerOfScreenSizes } from './get-center-of-page-style';

export const tabletMedia = (style: string) => {
  return css`
      @media (max-width: ${centerOfScreenSizes.find((size) => size.label === 'Tablet').maxWidth}px) {
        ${style}
      }
    `;
};
