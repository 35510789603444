import jwtDecode from 'jwt-decode';
import { jwtStorage } from '../storage/jwt-storage';
import { ConfigurationModel } from '../../model/configuration.model';
import { runInAction } from 'mobx';

export const processSignInResponseCallback = (configuration: ConfigurationModel, callback) => (response) => {
  const {access_token, id_token, state} = response;
  jwtStorage.setAccessToken(access_token);
  jwtStorage.setIdToken(id_token);
  const decoded = jwtDecode(access_token);
  jwtStorage.setLanguage(decoded.locale);
  runInAction(() => {
    configuration.accessToken = access_token;
    configuration.idToken = id_token;
  });
  callback(state);
  return undefined;
};
