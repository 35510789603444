export function transformURLTemplate(template: string, variables: { [ key: string ]: string }) {
  let apiURL = template;
  const configurationPropRegex = /{([^}]*)}/gi;
  let match = configurationPropRegex.exec(template);
  while (match !== null) {
    let propValue = variables[ match[ 1 ] ];
    if (propValue === undefined) {
      propValue = `UNKNOWN PROP IN API URL: (${match[ 1 ]})`;
    }
    apiURL = apiURL.replace(match[ 0 ], propValue);
    match = configurationPropRegex.exec(template);
  }
  return apiURL;
}
