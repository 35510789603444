import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { htmlEdit } from '../../util/block/edit-types/html-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { customListBlock } from './decorators/custom-list-block.decorator';
import {toggleEdit} from '../../util/block/edit-types/toggle-edit.util';

@pageBlock
@customListBlock
@jobadBlock
@webDocumentBlock
@authenticatedBlock
@blockType(BlockTypeEnum.TextBlock)
@blockBackgroundColor('PrimaryBackgroundColor')
export class TextBlockModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('text-block.textTitle'))
  @observable text: any = getDraftStateFromString('');

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('text-block.has-spacing'))
  @observable hasPadding: boolean = true;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('text-block.text-background-color'))
  @observable textBackgroundColor: BlockColorType = 'Transparent';
}
