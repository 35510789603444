import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { IResolveLinkedDataPayload } from '../../../action/http.action';
import {IFilterGroupsHolder, IUsedDesignObjectMentionMetadata} from '../../../interface/page/design-object-mention-metadata.interface';
import { ILinkedDataPagedProperties } from '../../../interface/linked-data-paged-properties.interface';
import {JobadViewSortEnum} from '../../../enum/block/jobad-view-sort.enum';

export const resolveLinkedData = (usedMetadata: IUsedDesignObjectMentionMetadata[], replaceData: boolean, options?: {
  sortBy?: JobadViewSortEnum;
  filters?: IFilterGroupsHolder, pagedProperties?: Partial<ILinkedDataPagedProperties>}) => returnAction<IResolveLinkedDataPayload>(ActionEnum.RESOLVE_LINKED_DATA, {
  usedMetadata,
  replaceData,
  filters: options ? options.filters : undefined,
  pagedProperties: options ? options.pagedProperties : undefined,
  sortBy: options ? options.sortBy : undefined
});
