import { LinkedDataModel } from './linked-data.model';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';
import { IFieldConfig } from '../../interface/field-config.interface';
import { FormFileUploadModeEnum } from '../../enum/block/form-file-upload-mode.enum';
import { FormCommentModeEnum } from '../../enum/block/form-comment-mode.enum';
import { FormLinkedInModeEnum } from '../../enum/block/form-linkedin-mode.enum';

interface IConsent {
  id: string;
  limitedId: string;
  text: string;
  limitedText: string;
}

export interface IFormData {
  submitEndpoint: string;
  submitButtonText: string;
  jobAdIsExpired: boolean;
  jobAdIsPublished: boolean;
  fields: IFieldConfig[];
  consent: IConsent;
}

export class FormLinkedDataModel extends LinkedDataModel<IFormData> {
  static types = [DesignObjectMentionMetadataType.Form];
  linkedInMode: FormLinkedInModeEnum = FormLinkedInModeEnum.OPTIONAL;
  commentMode: FormCommentModeEnum = FormCommentModeEnum.OPTIONAL;
  fileUploadMode: FormFileUploadModeEnum = FormFileUploadModeEnum.MANDATORY;
  forceOpenConsent: boolean = false;
  otherFilesCountLimit: number;
  defaultData = {};
}
