import CookieStorage from './cookies-storage';
import { AllowedLanguagesType } from '../../enum/allowed-language-types';

const accessTokenKey = 'jwt_accesstoken';
const idTokenKey = 'jwt_idtoken';
const locale = 'locale';
let cachedLanguage;

const STORAGE = new CookieStorage();

export const jwtStorage = {
  getAccessToken() {
    return STORAGE.getItem(accessTokenKey);
  },
  setAccessToken(token: string) {
    STORAGE.setItem(accessTokenKey, token);
  },
  getIdToken() {
    return STORAGE.getItem(idTokenKey);
  },
  setIdToken(token: string) {
    STORAGE.setItem(idTokenKey, token);
  },
  setLanguage(language: AllowedLanguagesType) {
    cachedLanguage = language;
    STORAGE.setItem(locale, language);
  },
  getLanguage(): AllowedLanguagesType {
    cachedLanguage = STORAGE.getItem(locale) as AllowedLanguagesType;
    return cachedLanguage || 'en';
  },
  clear() {
    STORAGE.removeItem(accessTokenKey);
    STORAGE.removeItem(idTokenKey);
    STORAGE.removeItem(locale);
  }
};
