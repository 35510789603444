import { BaseService } from './base-service';
import { CustomListEntryModel } from '../model/custom-lists/custom-list-entry.model';
import { toJS } from 'mobx';

export class CustomListEntryService extends BaseService {
  baseURL = 'customListEntry';

  getCustomListEntriesForDefinition = (definitionId: string) => {
    return this.get(`by-definition-id/${definitionId}`);
  }

  saveEntry = (entry: CustomListEntryModel) => {
    const saveData = toJS(entry);
    delete saveData.isSaved;
    if (entry.isSaved) {
      return this.put('/', undefined, JSON.stringify(saveData));
    }
    return this.post('/', undefined, JSON.stringify(saveData));
  }

  getListEntryById = (id: string) => {
    return this.get(`by-id/${id}`);
  }

  deleteEntry = (customListEntryId: string) => {
    return this.delete('/', {id: customListEntryId});
  }
}
