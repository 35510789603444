import { returnEditMode } from './return-edit-mode.util';
import { ISelectedImage } from '../../../interface/component/selected-image.interface';
import { ImageOriginEnum } from '../../../enum/image-origin.enum';

export const imagesEdit = () => {
  const toReturn = returnEditMode('images');
  toReturn.setUsedMentionsReducer((data: ISelectedImage[]) => {
    return data.reduce((accumulator, image) => {
      if (image && image.origin === ImageOriginEnum.Mention && image.mention) {
        accumulator.push(image.mention);
      }
      return accumulator;
    }, []);
  });
  return toReturn;
};
