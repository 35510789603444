import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { ISelectedImage } from '../../interface/component/selected-image.interface';
import { editable } from './decorators/editable.decorator';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { editGroup } from './decorators/edit-group.decorator';
import { blockType } from './decorators/block-type.decorator';
import { emailBlock } from './decorators/email-block.decorator';
import { imageEdit } from '../../util/block/edit-types/image-edit.util';
import { BaseBlockDataModel } from './base-block-data.model';

@emailBlock
@blockType(BlockTypeEnum.EmailImage)
export class EmailImageModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(imageEdit()
    .label('emailImage.edit.image'))
  @observable image: ISelectedImage = undefined;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label(`emailImage.edit.imageStyle`)
    .addOption('image.edit.carouselStyleWide', 'wide')
    .addOption('image.edit.carouselStyleNormal', 'normal')
  )
  @observable style: 'wide' | 'normal' = 'normal';

  @observable embedded: boolean = false;
}
