import { DesignTypeEnum } from '../../enum/design-type.enum';
import { duplicateSlug } from './duplicate-slug.util';
import { IBasePageData } from '../../interface/page/base-page-data.interface';
import { STATIC_PAGE_SLUGS } from '../../constants';
import { ISettingsRootFile } from '../../interface/settings.interface';

const reservedWords = [ 'jobad', 'page' ];

interface IErrorMessage {
  message?: string;
  props?: string[];
  isValid: boolean;
}

export interface IPageSlug {
  slug?: string;
  type?: DesignTypeEnum;
  id: string;
}

export const isValidPageSlug = (allPages: IBasePageData[], pageSlug: IPageSlug, rootFiles: ISettingsRootFile[]): IErrorMessage => {
  const slug = pageSlug.slug;
  if (slug.trim() === '') {
    return {
      message: 'page-editor-settings.slug_required',
      isValid: false
    };
  }

  if (slug.indexOf('/') !== -1) {
    return {
      message: 'page-editor-settings.slug_cant_be_slash',
      isValid: false
    };
  }

  if (slug.indexOf(' ') !== -1) {
    return {
      // Slug cannot contain empty space
      message: 'page-editor-settings.slug_no_empty_spaces',
      isValid: false
    };
  }

  if (reservedWords.indexOf(slug) !== -1) {
    return {
      // Slug cannot contain any of the reserved words: {0}
      message: `page-editor-settings.slug_no_reserved_words`,
      props: [ reservedWords.join(', ') ],
      isValid: false
    };
  }

  if (!/^[A-Za-z0-9_-]+$/.test(slug)) {
    return {
      // Slug can only contain characters allowed for a URL
      message: `page-editor-settings.slug_no_unsafe_URL_chars`,
      isValid: false
    };
  }

  if (STATIC_PAGE_SLUGS.indexOf(slug) !== -1) {
    return {
      message: `page-editor-settings.static`,
      isValid: false
    };
  }

  if (duplicateSlug(allPages, pageSlug)) {
    return {
      message: `page-editor-settings.unique_slug`,
      isValid: false
    };
  }

  if (rootFiles.some((rootFile: ISettingsRootFile) => {
    return rootFile.name === slug;
  })) {
    return {
      message: `page-editor-settings.root-file-name-conflict`,
      isValid: false
    };
  }

  return {
    isValid: true
  };
};
