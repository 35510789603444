import {BlockModel} from '../../model/block/block.model';
import {isContainerBlock} from './is-container-block.util';
import {isBlockInContainer} from './is-block-in-container.util';

export const findParentBlock = (container: {blocks: BlockModel[]}, blockChild: BlockModel): BlockModel => {
  for (const block of container.blocks) {
    if (isContainerBlock(block)) {
      if (isBlockInContainer(block.data, blockChild)) {
        return block;
      } else {
        const parentBlock = findParentBlock(block.data, blockChild);
        if (parentBlock) {
          return parentBlock;
        }
      }
    }
  }
};