import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { IActionsWithContext } from '../../interface/action/actions-with-context.interface';
import { IAction } from '../../interface/action/action.interface';
import { ActionEnum } from '../../enum/action.enum';
import * as Actions from '../../util/action/type/all-actions';
import { Dispatcher } from '../../interface/action/dispatcher.type';
import { flatten } from '../array/flatten.util';
import { isValidAction } from './is-valid-action.util';
import { isArray, isFunction } from 'lodash';
import { addGlobalError } from './type/add-global-error.util';
import { noop } from './type/noop-action.util';
import { IBaseContext } from '../../interface/action/base-context.interface';
import { isServerSide } from '../server-side-rendering/is-server-side';
import { getActionName } from './get-action-name.util';
import {AjaxError} from 'rxjs';
import {getTranslation} from '../translation/get-translation';
import {replacePage} from '../../util/action/type/all-actions';
import {getPageBySlug} from '../../util/action/type/all-actions';
import {DesignTypeEnum} from '../../enum/design-type.enum';

function generateNewActionObservable(baseContext: IBaseContext, actionsWithContextArray: IActionsWithContext[]) {
  const action$ = new Subject<IAction>();

  const actions: Observable<IAction | IAction[]>[] = flatten(actionsWithContextArray.map((actionsWithContext: IActionsWithContext) => {
    const context = Object.assign({}, baseContext, actionsWithContext.context);
    return actionsWithContext.actions.map(currentAction => {
      return currentAction(action$, context);
    });
  }));

  const actionObserver = Observable.merge(...actions);
  return {
    action$,
    actionObserver
  };
}

interface IActionStack {
  result?: any;
  action: IAction<any>;
  parent: number;
}

export function createDispatcher(originalBaseContext: IBaseContext, actionsWithContextArray: IActionsWithContext[]): Dispatcher {
  const baseContext = Object.assign({}, originalBaseContext);

  function dispatch(actionToDispatch: IAction) {
    let resolve;
    let reject;
    const dispatchPromise = new Promise((rs, rj) => {
      resolve = rs;
      reject = rj;
    });
    const actionStack: IActionStack[] = [];
    const {action$, actionObserver} = generateNewActionObservable(Object.assign({}, baseContext, {actionStack}), actionsWithContextArray);
    const finalObserver = actionObserver
      .catch((error: Error | IAction) => {
        if (isValidAction(error)) {
          dispatch(error);
        } else {
          const isAjaxError = (error instanceof AjaxError);
          if (isAjaxError && (error as AjaxError).status === 412) {
            dispatch(addGlobalError(getTranslation(baseContext.state.configuration, 'error.collision_detected_title'), getTranslation(baseContext.state.configuration, 'error.collision_detected_body')));
          } else if (isAjaxError && (error as AjaxError).status === 403) {
            baseContext.history.goBack();
            dispatch(addGlobalError(getTranslation(baseContext.state.configuration, 'error.not_enough_access_title'), getTranslation(baseContext.state.configuration, 'error.not_enough_access_body')));
          } else if (isAjaxError && (error as AjaxError).status === 404) {
            if (baseContext.state.configuration.inAdmin) {
              dispatch(addGlobalError(getTranslation(baseContext.state.configuration, 'error.not_found_resource_title'), getTranslation(baseContext.state.configuration, 'error.not_found_resource_body')));
            } else {
              dispatch(replacePage('404'));
            }
          } else if (isAjaxError && (error as AjaxError).status === 500) {
            dispatch(getPageBySlug('error', true, DesignTypeEnum.Page));
          } else if (!isAjaxError || (error as AjaxError).status !== 401) {
            dispatch(addGlobalError('', `${error.name}\n${error.message}`))
                .catch((e) => {
                  console.error('Error while printing global error');
                });
          }
          baseContext.logger.error(error);
          reject(error);
        }
        return Observable.of(noop());
      });

    const dispatchAction: IAction = Object.assign({}, actionToDispatch);
    if (ActionEnum[ dispatchAction.type ] === undefined) {
      baseContext.logger.warn('Dispatching a unknown action:', dispatchAction);
      dispatchAction.type = ActionEnum.UNKNOWN;
    }

    const doDispatch = (theAction: IAction, parent: number) => {
      if (theAction) {
        if (originalBaseContext.state.configuration.debug && originalBaseContext.state.configuration.verboseLogging) {
          let actionName = getActionName(theAction);
          let currentParent = parent;
          while (currentParent !== undefined && currentParent !== null) {
            const parentAction = actionStack[ currentParent ].action;
            currentParent = actionStack[ currentParent ].parent;
            actionName = (parentAction.isImmediate ? '[immediate]' : '') + `[${ActionEnum[ parentAction.type ]}] -> ` + actionName;
          }
          if (!theAction.payload || isServerSide()) {
            console.log(actionName);
          } else {
            console.log(actionName, 'payload:', theAction.payload);
          }
        }
        action$.next(theAction);
      }
    };
    let actionCounter = 0;
    let timeoutId;
    const actionPayload: any[] = [];

    // Make the action async so it don't lock a component render, unless the action is marked as immediate
    const determineDispatchTime = (theAction: IAction, parent?: number) => {
      actionStack.push({
        action: theAction,
        parent: parent
      });
      actionCounter++;
      if (theAction.isImmediate) {
        doDispatch(theAction, parent);
      } else {
        setTimeout(doDispatch.bind(undefined, theAction, parent), 0);
      }
    };

    const subscription = finalObserver.subscribe((payload: any) => {
      actionCounter--;
      let actions = payload;
      if (!isArray(actions)) {
        actions = [ payload ];
      }
      const parent = actionStack.length - 1;
      actionStack[ parent ].result = payload;
      for (const currentPayload of actions) {
        if (isValidAction(currentPayload) && currentPayload.type !== ActionEnum.NOOP) {
          determineDispatchTime(currentPayload, parent);
        } else {
          actionPayload.push(currentPayload && currentPayload.hasOwnProperty('payload') ? currentPayload.payload : currentPayload);
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            if (actionCounter === 0) {
              subscription.unsubscribe();
              const returnPayload = actionPayload.filter((load) => Boolean(load));
              resolve(returnPayload.length > 1 ? returnPayload : returnPayload[ 0 ]);
            }
            clearTimeout(timeoutId);
            timeoutId = undefined;
          });
        }
      }
    });
    determineDispatchTime(dispatchAction);
    return dispatchPromise;
  }

  Object.keys(Actions)
    .forEach(key => {
      const value = Actions[ key ];
      if (isFunction(value)) {
        dispatch[ key ] = (...args) => dispatch(value(...args));
      } else {
        dispatch[ key ] = value;
      }
    });

  (dispatch as any)._updateContext = (context: Partial<IBaseContext>) => {
    const contextKeys = Object.keys(context);
    for (const key of contextKeys) {
      if (context[ key ]) {
        baseContext[ key ] = context[ key ];
      }
    }
  };

  return dispatch as any;
}
