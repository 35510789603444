import { isStringEmpty } from './is-string-empty.util';

const isValidIpBlock = (block) => !isNaN(block) && parseInt(block, 10) >= 0 && parseInt(block, 10) <= 255;

export const isValidIpAddress: (entry: string) => boolean = (entry: string) => {
  if (isStringEmpty(entry)) {
    return false;
  }
  const blocks = entry.split('.');
  return blocks.filter(isValidIpBlock).length === 4;
};
