import { LinkedDataModel } from './linked-data.model';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';

export interface ISingleContactItemData {
  name: string;
  title: string;
  linkedInUrl: string;
  location: string;
  email: string;
  phone: string;
  image: string;
}

export class ContactListLinkedDataModel extends LinkedDataModel<ISingleContactItemData[]> {
  static types = [ DesignObjectMentionMetadataType.ContactsList ];

  defaultData = [];
}
