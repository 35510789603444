import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { JobadListModel } from '../../../model/block';

const JobadListView = asyncComponent(() => import('./jobad-list.view').then(c => c.JobadListView));
const JobadListIcon = asyncComponent(() => import('../../icons/block/jobad-list.icon').then(c => c.JobadListIcon), 'svg');

export const JobAdList = getBlock(JobadListModel, BlockGroupEnum.Lists, 'block.job-ads-title',
  <JobadListIcon/>, JobadListView);
