import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { convertFromRaw, EditorState } from 'draft-js';
import { toJS } from 'mobx';
import { findEntitiesInState } from '../../draft/find-entities-in-editor-state.util';
import { IPlainTextEditComponentProps } from './plain-text-edit.util';
import { draftRequiredValidator } from '../../validators/draft-required-validator';

export interface IHtmlEditComponentProps {
  placeholder?: string;
}

interface IReturnType {
  placeholder: (placeholder: string) => IEditModeReturnValue<IHtmlEditComponentProps, any, IReturnType>;
  required: (messageKey?: string, messageProps?: string[]) => IEditModeReturnValue<IPlainTextEditComponentProps, any, IReturnType>;
}

export const htmlEdit = (componentProps: IHtmlEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IHtmlEditComponentProps, any, IReturnType> = returnEditMode<IHtmlEditComponentProps, string, IReturnType>('html', componentProps) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.setUsedMentionsReducer((data: any) => {
    if (data) {
      const state = EditorState.createWithContent(convertFromRaw(toJS(data)));
      const entities = findEntitiesInState(state, 'MENTION');
      return entities.map((foundEntity) => foundEntity.entity.getData());
    }
    return [];
  });
  toReturn.required = (messageKey: string = 'validation.requiredField', messageProps: string[] = []) => {
    return toReturn.withValidator(draftRequiredValidator(messageKey, messageProps));
  };
  return toReturn;
};
