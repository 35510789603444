import {observable} from 'mobx';
import {persist} from 'mobx-persist';
import {IGoogleFont, IGoogleFonts} from '../../interface/google-fonts.interface';

export class GoogleFontModel implements  IGoogleFont {
  @persist @observable fontName: string;
  @persist @observable fallbackFont: string;
}

export class GoogleFontsModel implements IGoogleFonts {
  @persist @observable fontsFamiliesString: string;
  @persist('list', GoogleFontModel) @observable fonts: GoogleFontModel[];
}