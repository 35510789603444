import * as React from 'react';
import { PageModel } from '../../model/page.model';
import Helmet from 'react-helmet-async';
import { createBlockView, ICreateBlockViewParams } from '../../util/block/create-block-view.util';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../interface/component/injected-props.interface';
import { getImageURL } from '../../util/url/get-image-url.util';
import { getCDNUrl } from '../../util/url/get-cdn-url.util';
import { draftToString } from '../../util/draft/draft-to-string.util';
import { isDraftEmpty } from '../../util/draft/is-draft-empty.util';
import { Snippet } from '../snippet/snippet';
import { DesignTypeEnum } from '../../enum/design-type.enum';
import styled from 'styled-components';
import {ColorEnum} from '../../enum/color.enum';
import {getImageCloudinaryURL} from '../../util/url/get-image-cloudinary-url.util';
import { BlockModel } from 'src/model/block/block.model';
import { BaseBlockDataModel } from 'src/model/block/base-block-data.model';

const LinkedDataErrorHolder = styled.div`
  display: ${(props: {isDebug: boolean}) => props.isDebug ? 'block' : 'none'};
  background: ${ColorEnum.Red};
  color: ${ColorEnum.White};
`;

@inject('state')
@observer
export class PageBlocksView extends React.Component<{ page: PageModel } & IInjectedProps> {
  render() {
    const imageObject = this.props.page.ogImage ? getImageURL(this.props.state, this.props.page.ogImage, 250) : null;
    const imageLocation = imageObject ? imageObject.location : undefined;
    const imageURL = imageLocation ? getImageCloudinaryURL(getCDNUrl(this.props.state.configuration, imageLocation), 1200, 630, 'fit') : undefined;
    const description = !isDraftEmpty(this.props.page.description) ? draftToString(this.props.page.description, this.props.state) : undefined;
    const ogTitle = !isDraftEmpty(this.props.page.ogTitle) ? draftToString(this.props.page.ogTitle, this.props.state) : undefined;
    const title = !isDraftEmpty(this.props.page.title) ? draftToString(this.props.page.title, this.props.state) : '';
    let snippet;
    if (this.props.page.type === DesignTypeEnum.Page) {
      if (this.props.page.slug === '/' || this.props.page.slug === '') {
        snippet = <Snippet.WebSite
          id={`${this.props.state.configuration.CMSUrl}#website`}
          url={this.props.state.configuration.CMSUrl}
          image={imageURL}
        />;
      } else {
        snippet = <Snippet.WebPage
          id={`${this.props.state.currentFullPath}#webpage`}
          url={this.props.state.configuration.CMSUrl}
          image={imageURL}
        />;
      }
    } else if (this.props.page.type === DesignTypeEnum.JobAdPage) {
      snippet = <Snippet.JobPosting
        id={`${this.props.state.currentFullPath}#jobPosting`}
        url={this.props.state.configuration.CMSUrl}
        image={imageURL}
      />;
    }
    const errorLinkedData = this.props.page.linkedData.filter((ld) => Boolean(ld.error));
    return <div style={{width: '100%'}}>
      <Helmet>
        {description ? <meta
          name="description"
          content={description}
        /> : undefined}
        <meta
          name="keywords"
          content={this.props.page.metaDataTags ? this.props.page.metaDataTags.join(',') : ''}
        />
        {ogTitle ? <meta
          property="og:title"
          content={ogTitle}
        /> : null}
        {imageURL && <meta
          property="og:image"
          content={imageURL}
        />}
        {imageURL && <meta
          property="og:image:url"
          content={imageURL}
        />}
        {imageURL && <meta
          property="og:image:secure"
          content={imageURL}
        />}
        {this.props.page.ogImage && this.props.page.ogImage.title &&
        <meta
          property="og:image:alt"
          content={this.props.page.ogImage.title}
        />}
        <title>{title}</title>
        <body
          style={this.props.page.backgroundColor ? `background-color: ${this.props.state.colors.getColor(this.props.page.backgroundColor)};` as any : undefined}
        />
      </Helmet>
      {snippet}
      {errorLinkedData.map((ld) => (
        <LinkedDataErrorHolder data-type="error" data-id={ld.mentionId} key={ld.id} isDebug={this.props.state.configuration.debug}>
          <p>{ld.error.developerMessage}</p>
          <p>{ld.error.stackTrace}</p>
        </LinkedDataErrorHolder>
      ))}
      {this.props.page.blocks ? this.props.page.blocks.map((block) => {
         const createBlockViewParams: ICreateBlockViewParams<BlockModel<BaseBlockDataModel>> = {
          blockModel: block,
          design: this.props.state.activeDesign.data,
          viewType: this.props.state.activeDesign.data.type,
        };
         return createBlockView(createBlockViewParams);
      }) : undefined}
    </div>;
  }
}
