import * as React from 'react';
import { Switch } from 'react-router-dom';
import { renderRoutes as routerRenderRoutes } from 'react-router-config';
import { NotFoundPage } from '../../component/page/404';
import { ErrorBoundary } from '../../component/error-boundary';

export function renderRoutes(routes: any[]) {
  return <Switch>
    <ErrorBoundary>
      {routerRenderRoutes([ ...routes,
        {
          component: NotFoundPage
        } ])}
    </ErrorBoundary>
  </Switch>;
}
