import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { invariant } from '../../debug/invariant.util';
import { IUsedDesignObjectMentionMetadata } from '../../../interface/page/design-object-mention-metadata.interface';
import { LinkedDataModel } from '../../../model/linked-data/linked-data.model';
import { AppStateModel } from '../../../model/app-state.model';

export interface IHashMapEditComponentProps {
  getHashMapKeys?: (data: any, state: AppStateModel, findLinkedData: (mention: IUsedDesignObjectMentionMetadata) => LinkedDataModel) => string[];
  label: string;
}

interface IReturnType {
  setGetHashMapKeys: (getHashMapKeys: (data: any, state: AppStateModel, findLinkedData: (mention: IUsedDesignObjectMentionMetadata) => LinkedDataModel) => string[]) => IEditModeReturnValue<IHashMapEditComponentProps, any, IReturnType>;
  setLabel: (label: string) => IEditModeReturnValue<IHashMapEditComponentProps, any, IReturnType>;
}

export const hashMapEdit = (componentProps: IHashMapEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IHashMapEditComponentProps, any, IReturnType> = returnEditMode<IHashMapEditComponentProps, any, IReturnType>('hashMap', componentProps) as any;
  toReturn.setGetHashMapKeys = (getHashMapKeys) => {
    (toReturn._decoratorProperties.componentProps as any)._getHashMapKeys = getHashMapKeys;
    return toReturn;
  };
  toReturn._decoratorProperties.componentProps.getHashMapKeys = (data: any, state: AppStateModel, findLinkedData: (mention: IUsedDesignObjectMentionMetadata) => LinkedDataModel) => {
    let finalData = data;
    if (toReturn._decoratorProperties.parameter) {
      finalData = finalData[ toReturn._decoratorProperties.parameter ];
    }
    invariant(Boolean((toReturn._decoratorProperties.componentProps as any)._getHashMapKeys), 'You must define hash map keys for a hash map edit!');
    return (toReturn._decoratorProperties.componentProps as any)._getHashMapKeys(finalData, state, findLinkedData);
  };
  toReturn.setLabel = (label) => {
    toReturn._decoratorProperties.componentProps.label = label;
    return toReturn;
  };
  return toReturn;
};
