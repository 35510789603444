import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { IReorderBlocks } from '../../../action/block.action';
import { BaseContainerBlockModel } from 'src/model/block/base-container-block.model';

export const reorderBlocks = (startIndex: number, endIndex: number, containerBlock?: BaseContainerBlockModel) => returnAction<IReorderBlocks>(ActionEnum.REORDER_BLOCKS, {
  containerBlock,
  startIndex,
  endIndex
});
