import {observable} from 'mobx';
import {BlockTypeEnum} from '../../enum/block/block-type.enum';
import {editable} from './decorators/editable.decorator';
import {editGroup} from './decorators/edit-group.decorator';
import {IUsedDesignObjectMentionMetadata} from '../../interface/page/design-object-mention-metadata.interface';
import {blockType} from './decorators/block-type.decorator';
import {authenticatedBlock} from './decorators/authenticated-block.decorator';
import {jobadBlock} from './decorators/jobad-block.decorator';
import {customListMentionPickerEdit} from '../../util/block/edit-types/custom-list-mention-picker-edit.util';
import {pageBlock} from './decorators/page-block.decorator';
import {customListBlock} from './decorators/custom-list-block.decorator';
import {BaseBlockDataModel} from './base-block-data.model';
import {linkEdit} from '../../util/block/edit-types/link-edit.util';
import {LinkOriginEnum} from '../../enum/link-origin.enum';
import {ILink} from '../../interface/page/link.interface';
import {getDraftStateFromString} from '../../util/block/get-draft-state-from-string.util';
import {plainTextEdit} from '../../util/block/edit-types/plain-text-edit.util';
import {DesignTypeEnum} from '../../enum/design-type.enum';
import {colorPickerEdit} from '../../util/block/edit-types/color-picker-edit.util';
import {BlockColorType} from '../../enum/block-color.enum';
import {EditorModel} from '../editor.model';
import {findMention} from '../../util/page/find-mention.util';
import {fontStylePickerEdit} from '../../util/block/edit-types/font-style-picker-edit.util';
import {imageEdit} from '../../util/block/edit-types/image-edit.util';
import {ISelectedImage} from '../../interface/component/selected-image.interface';
import {imageSizeStylePickerEdit} from '../../util/block/edit-types/image-size-style-picker-edit.util';
import {ImageSizeStyleEnum} from '../../enum/image-size-style.enum';
import {enumPickerEdit} from '../../util/block/edit-types/enum-picker-edit.util';
import {ImageStyleEnum} from '../../enum/image-style.enum';
import {imageStylePickerEdit} from '../../util/block/edit-types/image-style-picker-edit.util';
import {numberEdit} from '../../util/block/edit-types/number-edit.util';
import {fontAlignmentPickerEdit} from '../../util/block/edit-types/font-alignment-picker-edit.util';
import {blockAuthorization} from './decorators/block-authorization.decorator';
import {setPageSizeForBlockLinkedData} from '../../util/action/type/set-page-size-for-block-linked-data.util';
import {IJobAdListMoreButtonData} from './jobad-list.model';
import {IBasePageData} from '../../interface/page/base-page-data.interface';
import {AppStateModel} from '../app-state.model';
import {ICustomListData} from '../../interface/page/custom-list-data.interface';
import {LinkedDataModel} from '../linked-data/linked-data.model';

const mentionsFN = (value, data, editor: EditorModel, possibleBlockMentions, findLinkedData) => {
  if (!data.selectedMention) {
    return [];
  }
  const linkedData = findLinkedData(data.selectedMention);
  const mention = findMention(linkedData ? linkedData.mentionId : null, editor.possibleMentions);
  if (!mention) {
    return [];
  }
  return mention.dataType;
};

@blockAuthorization((state) => state.configuration.authorization.cmsAdvancedContentUse)
@pageBlock
@customListBlock
@jobadBlock
@blockType(BlockTypeEnum.CustomList)
@authenticatedBlock
export class CustomListModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(customListMentionPickerEdit()
    .label('custom-list.edit.listData')
    .placeholder('custom-list.edit.select-listData')
    .showRemainingControlsWhen((data) => {
      return data.selectedMention !== undefined;
    })
  )
  @observable selectedMention: IUsedDesignObjectMentionMetadata;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label(`custom-list.edit.blockStyle`)
    .addOption('custom-list.edit.blockStyleWide', 'wide')
    .addOption('custom-list.edit.blockStyleNormal', 'normal')
  )
  @observable style: 'wide' | 'normal' = 'normal';

  @editGroup.CONTENT
  @editable(imageEdit()
    .addDataMentionsReducer(mentionsFN)
    .label('custom-list.edit.image'))
  @observable image: ISelectedImage = {} as any;

  @editGroup.STYLE
  @editable(imageSizeStylePickerEdit()
    .label('custom-list.edit.image-size-style'))
  @observable imageSizeStyle: ImageSizeStyleEnum = ImageSizeStyleEnum.Stretch;

  @editGroup.STYLE
  @editable(imageStylePickerEdit()
    .label('custom-list.edit.image-style'))
  @observable imageStyle: ImageStyleEnum = ImageStyleEnum.Square;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('custom-list.edit.image-spacing')
    .addOption('custom-list.edit.small-spacing', 5)
    .addOption('custom-list.edit.medium-spacing', 15)
    .addOption('custom-list.edit.large-spacing', 23)
  )
  @observable imageSpacing: number = 23;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('custom-list.edit.image-height')
    .addOption('custom-list.edit.small-height', 150)
    .addOption('custom-list.edit.medium-height', 200)
    .addOption('custom-list.edit.large-height', 355)
  )
  @observable imageHeight: number = 150;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('custom-list.edit.image-background-color'))
  @observable imageBackgroundColor: BlockColorType = 'Transparent';

  @editGroup.CONTENT
  @editable(fontStylePickerEdit()
    .label('custom-list.edit.headerTextFontStyle')
  )
  @observable headerFontStyleId: string;

  @editGroup.CONTENT
  @editable(fontAlignmentPickerEdit()
    .label('custom-list.edit.headerTextAlignment')
  )
  @observable headerTextAlignment: 'left' | 'center' | 'right' = 'left';

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('custom-list.edit.headerText')
    .addDataMentionsReducer(mentionsFN)
  )
  @observable headerText: any = getDraftStateFromString('');

  @editGroup.STYLE
  @editable(numberEdit()
      .label('custom-list.edit.limit')
      .min(1)
      .placeholder('custom-list.edit.enter-limit')
      .visibleWhen((data) => Boolean(data.selectedMention))
      .actionsOnChange([setPageSizeForBlockLinkedData()])
  )
  @observable customLimit: number = 10;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('custom-list.edit.header-background-color'))
  @observable headerBackgroundColor: BlockColorType = 'Transparent';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('custom-list.edit.header-spacing')
    .addOption('custom-list.edit.small-spacing', 5)
    .addOption('custom-list.edit.medium-spacing', 15)
    .addOption('custom-list.edit.large-spacing', 23)
  )
  @observable headerSpacing: number = 5;

  @editGroup.STYLE
  @editable(numberEdit()
    .label('custom-list.edit.max-header-rows')
    .min(1)
    .max(3)
  )
  @observable maxHeaderRows: number = 1;

  @editGroup.CONTENT
  @editable(fontStylePickerEdit()
    .label('custom-list.edit.descriptionTextFontStyle')
  )
  @observable fontStyleId: string;

  @editGroup.CONTENT
  @editable(fontAlignmentPickerEdit()
    .label('custom-list.edit.textAlignment')
  )
  @observable textAlignment: 'left' | 'center' | 'right' = 'left';

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('custom-list.edit.descriptionText')
    .isMultiLine()
    .addDataMentionsReducer(mentionsFN)
  )
  @observable text: any = getDraftStateFromString('');

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('custom-list.edit.text-background-color'))
  @observable textBackgroundColor: BlockColorType = 'Transparent';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('custom-list.edit.text-spacing')
    .addOption('custom-list.edit.small-spacing', 5)
    .addOption('custom-list.edit.medium-spacing', 15)
    .addOption('custom-list.edit.large-spacing', 23)
  )
  @observable textSpacing: number = 5;

  @editGroup.STYLE
  @editable(numberEdit()
    .label('custom-list.edit.max-text-rows')
    .min(2)
    .max(6)
  )
  @observable maxTextRows: number = 3;

  @editGroup.CONTENT
  @editable(fontStylePickerEdit()
    .label('custom-list.edit.footerTextFontStyle')
  )
  @observable footerFontStyleId: string;

  @editGroup.CONTENT
  @editable(fontAlignmentPickerEdit()
    .label('custom-list.edit.footerTextAlignment')
  )
  @observable footerTextAlignment: 'left' | 'center' | 'right' = 'left';

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('custom-list.edit.footerText')
    .addDataMentionsReducer(mentionsFN)
  )
  @observable footerText: any = getDraftStateFromString('');

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('custom-list.edit.footer-background-color'))
  @observable footerBackgroundColor: BlockColorType = 'Transparent';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('custom-list.edit.footer-spacing')
    .addOption('custom-list.edit.small-spacing', 5)
    .addOption('custom-list.edit.medium-spacing', 15)
    .addOption('custom-list.edit.large-spacing', 23)
  )
  @observable footerSpacing: number = 5;

  @editGroup.STYLE
  @editable(numberEdit()
    .label('custom-list.edit.max-footer-rows')
    .min(1)
    .max(3)
  )
  @observable maxFooterRows: number = 1;

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('custom-list.edit.buttonText')
    .addDataMentionsReducer(mentionsFN)
  )
  @observable buttonText: any = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(
      plainTextEdit()
          .label('custom-list.edit.moreButton_text')
          .onParameter('text'),
      colorPickerEdit()
          .label('custom-list.edit.moreButton_textColor')
          .onParameter('textColor'),
      colorPickerEdit()
          .label('custom-list.edit.moreButton_backgroundColor')
          .onParameter('backgroundColor')
  )
  @observable moreButton: IJobAdListMoreButtonData = {
    text: getDraftStateFromString('Show More'),
    link: undefined,
    textColor: 'SecondaryTextColor',
    backgroundColor: 'PrimaryButtonColor'
  };

  @editGroup.CONTENT
  @editable(linkEdit()
    .label('custom-list.edit.detailsLink')
    .isTemplate(true)
    .availableOrigin([ LinkOriginEnum.Internal, LinkOriginEnum.Mention ])
    .availableTypes([ DesignTypeEnum.CustomList ])
    .visibleWhen((data) => Boolean(data.selectedMention))
    .addDataMentionsReducer(mentionsFN)
    .setPageFilterFunction((page: IBasePageData, data: CustomListModel, state: AppStateModel, findLinkedData: (mention: IUsedDesignObjectMentionMetadata) => LinkedDataModel) => {
      if (page.type !== DesignTypeEnum.CustomList) {
        return false;
      }
      const customListData = state.editor.customListDefinitions.find((def) => def.id === (page as ICustomListData).customListDefinitionId);
      if (!customListData) {
        return false;
      }
      const linkedData = findLinkedData(data.selectedMention);
      return linkedData.mentionId.indexOf(customListData.id) !== -1;
    })
  )
  @observable detailsLinkTemplate: ILink;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('custom-list.edit.button-color'))
  @observable buttonColor: BlockColorType = 'Transparent';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('custom-list.edit.button-background-color'))
  @observable buttonBackgroundColor: BlockColorType = 'Transparent';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('custom-list.edit.button-spacing')
    .addOption('custom-list.edit.small-spacing', 5)
    .addOption('custom-list.edit.medium-spacing', 15)
    .addOption('custom-list.edit.large-spacing', 23)
  )
  @observable buttonSpacing: number = 5;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('custom-list.edit.button-alignment')
    .addOption('custom-list.edit.button-left', 'flex-start')
    .addOption('custom-list.edit.button-center', 'center')
    .addOption('custom-list.edit.button-right', 'flex-end')
  )
  @observable buttonAlignment: 'flex-start' | 'center' | 'flex-end' = 'center';

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
      .label('jobad-list.loading-message'))
  @observable loadingMessage: any = getDraftStateFromString('Loading...');
}
