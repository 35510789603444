import { API } from './api';
import { PageService } from './page-service';
import { MediaLibraryService } from './media-library-service';
import { SettingsService } from './settings-service';
import { IProvisioning } from '../interface/provisioning.interface';
import { JobAdsService } from './jobads-service';
import { AjaxError } from 'rxjs/Rx';
import { FormSubmitService } from './form-submit-service';
import { PickerService } from './picker-service';
import { SystemService } from './system-service';
import { LinkedDataResolverService } from './linked-data-resolver-service';
import { SiteService } from './site-service';
import { AppStateModel } from '../model/app-state.model';
import { TemplateService } from './template-service';
import { CustomListDefinitionService } from './custom-list-definition-service';
import { CustomListEntryService } from './custom-list-entry-service';
import {UploadService} from './upload-service';
import {MaintainanceService} from './maintainance-service';
import {LoggerType} from '../util/debug/logger.util';

export class Service {
  private _page: PageService;
  private _mediaLibrary: MediaLibraryService;
  private _settings: SettingsService;
  private _jobads: JobAdsService;
  private _formSubmitService: FormSubmitService;
  private _pickerService: PickerService;
  private _linkedDataResolverService: LinkedDataResolverService;
  private _systemService: SystemService;
  private _maintainanceService: MaintainanceService;
  private _siteService: SiteService;
  private _templateService: TemplateService;
  private _customListDefinition: CustomListDefinitionService;
  private _customListEntryService: CustomListEntryService;
  private _uploadService: UploadService;

  private api: API;

  constructor(logger: LoggerType, oidcClient, appState: AppStateModel, initialized?: boolean, hostname?: string, extraHeaders?: any) {
    this.api = new API(logger, oidcClient, appState, initialized, hostname, extraHeaders);
    this._page = new PageService(this.api);
    this._jobads = new JobAdsService(this.api);
    this._mediaLibrary = new MediaLibraryService(this.api);
    this._settings = new SettingsService(this.api);
    this._formSubmitService = new FormSubmitService(this.api);
    this._pickerService = new PickerService(this.api);
    this._linkedDataResolverService = new LinkedDataResolverService(this.api);
    this._systemService = new SystemService(this.api);
    this._maintainanceService = new MaintainanceService(this.api);
    this._siteService = new SiteService(this.api);
    this._templateService = new TemplateService(this.api);
    this._customListDefinition = new CustomListDefinitionService(this.api);
    this._customListEntryService = new CustomListEntryService(this.api);
    this._uploadService = new UploadService(this.api);
  }

  onInitialize = (callback: (initData: IProvisioning) => void) => {
    return this.api.onInitialize(callback);
  }

  onError = (callback: (error: AjaxError) => void) => {
    return this.api.onError(callback);
  }

  get settings(): SettingsService {
    return this._settings;
  }

  get mediaLibrary(): MediaLibraryService {
    return this._mediaLibrary;
  }

  get page(): PageService {
    return this._page;
  }

  get jobads(): JobAdsService {
    return this._jobads;
  }

  get formSubmitService(): FormSubmitService {
    return this._formSubmitService;
  }

  get pickerService(): PickerService {
    return this._pickerService;
  }

  get linkedDataResolverService(): LinkedDataResolverService {
    return this._linkedDataResolverService;
  }

  get systemService(): SystemService {
    return this._systemService;
  }

  get maintainanceService(): MaintainanceService {
    return this._maintainanceService;
  }

  get siteService(): SiteService {
    return this._siteService;
  }

  get templateService(): TemplateService {
    return this._templateService;
  }

  get customListDefinition(): CustomListDefinitionService {
    return this._customListDefinition;
  }

  get customListEntryService(): CustomListEntryService {
    return this._customListEntryService;
  }

  get uploadService(): UploadService {
    return this._uploadService;
  }
}
