export const formatNumber = (inputNumber: string | number, decimals: number = 0, decimalIndicator = '', thousandsIndicator: string = ' ') => {
    let n = inputNumber;
    const c = decimals;
    const d = decimalIndicator;
    const t = thousandsIndicator;
    const s = n < 0 ? '-' : '';
    n = Math.abs(Number(n) || 0).toFixed(c);
    const i = String(parseInt(n, 10));
    let j = i.length;
    j = j > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(Number(n) - Number(i)).toFixed(c).slice(2) : '');
};
