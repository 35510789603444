import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { ISelectedImage } from '../../interface/component/selected-image.interface';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { imageEdit } from '../../util/block/edit-types/image-edit.util';
import { htmlEdit } from '../../util/block/edit-types/html-edit.util';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { ILink } from '../../interface/page/link.interface';
import { linkEdit } from '../../util/block/edit-types/link-edit.util';
import { ColorType } from '../../enum/color.enum';
import { toggleEdit } from '../../util/block/edit-types/toggle-edit.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { blockLimit } from './decorators/block-limit.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';

export interface IRichTextBackground {
  text?: any;
  backgroundColor: BlockColorType;
}

export interface ILargeHeaderButton {
  backgroundColor: BlockColorType;
  text?: any;
  link?: ILink;
  textColor: BlockColorType;
}

@pageBlock
@customListBlock
@jobadBlock
@webDocumentBlock
@authenticatedBlock
@blockLimit(1)
@blockType(BlockTypeEnum.LargeHeader)
@blockBackgroundColor('PrimaryBackgroundColor')
export class LargeHeaderModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(imageEdit()
    .label('large-header.edit.leftImage'))
  @observable leftImage: ISelectedImage = {} as any;

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('large-header.edit.showButton'))
  @observable showButton: boolean;

  @editGroup.CONTENT
  @editable(
    plainTextEdit()
      .label(`large-header.edit.applyButton_text`)
      .onParameter('text')
      .visibleWhen((data) => data.showButton),
    linkEdit()
      .label(`large-header.edit.applyButton_link`)
      .onParameter('link')
      .visibleWhen((data) => data.showButton),
    colorPickerEdit()
      .excludeTransparent()
      .label(`large-header.edit.applyButton_backgroundColor`)
      .onParameter('backgroundColor')
      .visibleWhen((data) => data.showButton),
    colorPickerEdit()
      .textColors()
      .label(`large-header.edit.applyButton_color`)
      .onParameter('textColor')
      .visibleWhen((data) => data.showButton)
  )
  @observable button?: ILargeHeaderButton = {
    text: undefined,
    link: {},
    backgroundColor: 'PrimaryButtonColor',
    textColor: 'SecondaryTextColor'
  };

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('large-header.edit.bottomBackground'))
  @observable bottomBackground: ColorType = 'ContentBackground';

  @editGroup.CONTENT
  @editable(
    htmlEdit()
      .label('large-header.edit.largeText')
      .onParameter('text')
  )
  @observable largeText: IRichTextBackground = {
    backgroundColor: 'PrimaryButtonColor'
  };

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('large-header.edit.smallText'))
  @observable smallText: any = getDraftStateFromString();

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('large-header.edit.background'))
  @observable background: BlockColorType = 'PrimaryBackgroundColor';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label(`image.edit.largeHeaderStyle`)
    .addOption('image.edit.largeHeaderStyleWide', 'wide')
    .addOption('image.edit.largeHeaderStyleNormal', 'normal')
  )
  @observable style: 'wide' | 'normal' = 'wide';
}
