import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../interface/component/injected-props.interface';
import { BaseSocialShareIcon } from './base-social-share-icon';
import { FacebookIcon } from '../icons';

export const FacebookShareIconWrapper = inject('state')(observer((props: IInjectedProps) => {
  return (
    <BaseSocialShareIcon backgroundColor="#365899">
      <FacebookIcon
        width={24}
        height={24}
        fill={props.state.colors.White}
      />
    </BaseSocialShareIcon>
  );
}));
