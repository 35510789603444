import { action, observable } from 'mobx';
import { ImageModel } from './image.model';
import { persist } from 'mobx-persist';

export class FolderModel {
  @persist @observable id: string;
  @persist @observable name: string;
  @observable deleted: boolean;
  @persist('list', ImageModel) @observable images: ImageModel[] = [];

  @action
  deleteImage = (imageIndex: number) => {
    this.images.splice(imageIndex, 1);
  }
}
