import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { HeaderModel } from '../../../model/block';

const HeaderView = asyncComponent(() => import('./header.view').then(c => c.HeaderView));
const HeaderIcon = asyncComponent(() => import('../../icons/block/header.icon').then(c => c.HeaderIcon), 'svg');

export const Header = getBlock(HeaderModel, BlockGroupEnum.MenuAndStartPage, 'block.header-title',
  <HeaderIcon/>, HeaderView);
