import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';

export interface ITagsEditComponentProps {
  placeholderTranslateKey?: string;
  tagPrefix?: string;
}

interface IReturnType {
  placeholderTranslateKey: (placeholderTranslateKey: string) => IEditModeReturnValue<ITagsEditComponentProps, string[], IReturnType>;
  tagPrefix: (tagPrefix: string) => IEditModeReturnValue<ITagsEditComponentProps, string[], IReturnType>;
}

export const tagsEdit = (componentProps: ITagsEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<ITagsEditComponentProps, string[], IReturnType> = returnEditMode<ITagsEditComponentProps, string[], IReturnType>('tagsEdit', componentProps) as any;
  toReturn.placeholderTranslateKey = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholderTranslateKey = placeholder;
    return toReturn;
  };
  toReturn.tagPrefix = (tagPrefix: string) => {
    toReturn._decoratorProperties.componentProps.tagPrefix = tagPrefix;
    return toReturn;
  };
  return toReturn;
};
