import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';

export interface INumberEditComponentProps {
  min?: number;
  max?: number;
  maxLength?: number;
  placeholder?: string;
}

interface IReturnType {
  min: (min: number) => IEditModeReturnValue<INumberEditComponentProps, number, IReturnType>;
  max: (max: number) => IEditModeReturnValue<INumberEditComponentProps, number, IReturnType>;
  maxLength: (maxLength: number) => IEditModeReturnValue<INumberEditComponentProps, number, IReturnType>;
  placeholder: (placeholder: string) => IEditModeReturnValue<INumberEditComponentProps, number, IReturnType>;
}

export const numberEdit = (componentProps: INumberEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<INumberEditComponentProps, number, IReturnType> = returnEditMode<INumberEditComponentProps, string, IReturnType>('number', componentProps) as any;
  toReturn.min = (min: number) => {
    toReturn._decoratorProperties.componentProps.min = min;
    return toReturn;
  };
  toReturn.max = (max: number) => {
    toReturn._decoratorProperties.componentProps.max = max;
    return toReturn;
  };
  toReturn.maxLength = (maxLength: number) => {
    toReturn._decoratorProperties.componentProps.maxLength = maxLength;
    return toReturn;
  };
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  return toReturn;
};
