import { Observable } from 'rxjs/Observable';
import { getWindowSize } from './get-window-size.util';
import { isServerSide } from '../server-side-rendering/is-server-side';

let isScrolling = false;
let scrollTimeout;

if (!isServerSide()) {
  window.addEventListener('scroll', () => {
    isScrolling = true;
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    scrollTimeout = setTimeout(() => {
      isScrolling = false;
      scrollTimeout = undefined;
    }, 200);
  });
}

export function windowResize$() {
  if (isServerSide()) {
    return Observable.of({
      width: 1000, height: 1000
    });
  }
  return Observable.fromEvent(window, 'resize')
    .filter(() => !isScrolling)
    .map(() => getWindowSize());
}
