import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { DocumentListModel } from '../../../model/block';

const DocumentListView = asyncComponent(() => import('./document-list.view').then(c => c.DocumentListView));
const DocumentListIcon = asyncComponent(() => import('../../icons/block/document-list.icon').then(c => c.DocumentListIcon), 'svg');

export const DocumentList = getBlock(DocumentListModel, BlockGroupEnum.Lists, 'block.document-list-title',
  <DocumentListIcon/>, DocumentListView);
