import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { TwoColumnTextModel } from '../../../model/block';

const TwoColumnTextView = asyncComponent(() => import('./two-column-text.view').then(c => c.TwoColumnTextView));
const TwoColumnTextIcon = asyncComponent(() => import('../../icons/block/two-column-text.icon').then(c => c.TwoColumnTextIcon), 'svg');

export const TwoColumnText = getBlock(TwoColumnTextModel, BlockGroupEnum.Text, 'block.two-column-text-title',
  <TwoColumnTextIcon/>, TwoColumnTextView);
