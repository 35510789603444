import { ISettingsColor } from '../../interface/settings.interface';
import { IHsl } from '../../interface/component/react-color-interface';

export const getColorHsl: (
  color: ISettingsColor,
  allColors: ISettingsColor[]) => IHsl =
  (color) => {
    if (!color) {
      return null;
    }

    return {
      h: color.hue, s: color.saturation, l: color.light
    };
  };
