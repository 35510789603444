import * as React from 'react';
import * as History from 'history';
import { Link, LinkProps } from 'react-router-dom';
import { isString } from 'lodash';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../interface/component/injected-props.interface';
import { joinUrl } from '../../util/url/join-url.util';

@inject('state')
@observer
export class SmartLink extends React.Component<Pick<LinkProps, Exclude<keyof LinkProps, 'to'>> & { to?: History.LocationDescriptor } & IInjectedProps> {
  render() {
    const {to, ...other} = this.props;
    let isReactRouter = true;
    let url;
    if (to) {
      if (isString(to)) {
        url = to;
      } else {
        url = to.pathname;
        if (to.hash) {
          url += '#' + to.hash;
        }
        if (to.search) {
          url += '?' + to.search;
        }
      }
      if (url.indexOf('http') === 0) {
        if (url.indexOf(this.props.state.configuration.CMSUrl) === 0) {
          url = url.replace(this.props.state.configuration.CMSUrl, '');
        } else {
          isReactRouter = false;
        }
      }
    } else {
      isReactRouter = false;
    }
    if (isReactRouter) {
      url = joinUrl('/', url);
    }
    return isReactRouter ? <Link {...this.props} to={url}/> : <a href={url} {...other}/>;
  }
}
