import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { IMessage } from '../../../interface/message.interface';
import { getUniqueId } from '../../string/get-unique-id.util';

export const addGlobalError = (title: string, message: string) => returnAction<IMessage>(ActionEnum.ADD_GLOBAL_ERROR, {
  id: getUniqueId(),
  title: title,
  message: message
});
