import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { IUsedDesignObjectMentionMetadata } from '../../../interface/page/design-object-mention-metadata.interface';

export interface IDataSetEditComponentProps {
  placeholder?: string;
}

interface IReturnType {
  placeholder: (placeholder: string) => IEditModeReturnValue<IDataSetEditComponentProps, IUsedDesignObjectMentionMetadata, IReturnType>;
}

export const dataSetEdit = (componentProps: IDataSetEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IDataSetEditComponentProps, IUsedDesignObjectMentionMetadata, IReturnType> = returnEditMode<IDataSetEditComponentProps, IUsedDesignObjectMentionMetadata, IReturnType>('dataSetPicker', componentProps) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.setUsedMentionsReducer((data: IUsedDesignObjectMentionMetadata) => {
    return [ data ];
  });
  return toReturn;
};
