import { IEnumFieldOptions } from '../../../../interface/custom-lists/enum-field-options.interface';
import { persist } from 'mobx-persist';
import { observable } from 'mobx';
import { editGroup } from '../../../block/decorators/edit-group.decorator';
import { editable } from '../../../block/decorators/editable.decorator';
import { toggleEdit } from '../../../../util/block/edit-types/toggle-edit.util';
import { tagsEdit } from '../../../../util/block/edit-types/tags-edit.util';
import { BaseRequiredFieldOptionsModel } from '../base-required-field-options.model';

export class EnumFieldOptionsModel extends BaseRequiredFieldOptionsModel implements IEnumFieldOptions {
  @editGroup.CONTENT
  @editable(
    toggleEdit()
      .label('custom-list.field-options.enum.can-multi-select-label')
  )
  @persist @observable canMultiSelect: boolean;
  @editGroup.CONTENT
  @editable(
    tagsEdit()
      .withValidator(value => [ {
        isValid: value && value.length > 0,
        messageKey: 'custom-list.field-options.enum.one-option-required'
      } ])
      .label('custom-list.field-options.enum.possible-options')
  )
  @persist @observable possibleOptions: string[];
}