import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { DesignTypeEnum } from '../../../enum/design-type.enum';
import { ILoadDynamicPagePayload } from '../../../action/page.action';

export const loadDynamicPage = (type?: DesignTypeEnum.Page | DesignTypeEnum.JobAdPage | DesignTypeEnum.CustomList, ignoreSlug: boolean = false, useCache: boolean = true) => returnAction<ILoadDynamicPagePayload>(ActionEnum.LOAD_DYNAMIC_PAGE, {
  type,
  ignoreSlug,
  useCache
});
