import { action, observable } from 'mobx';
import { FolderModel } from './folder.model';
import { persist } from 'mobx-persist';
import { Signal } from 'signals';
import { ImageModel } from './image.model';
import { findIndex } from 'lodash';

export class MediaLibraryModel {
  @persist('list', FolderModel) @observable folders: FolderModel[] = [];
  @persist @observable isOpen: boolean = false;
  @observable isUploading: boolean = false;
  private insertSignal: Signal = new Signal();

  onInsert = (callback: (image: ImageModel) => void) => {
    this.insertSignal.add(callback);
  }

  @action
  appendFolder = (folder: FolderModel) => {
    this.folders.push(folder);
  }

  @action
  deleteFolder = (folderId: string) => {
    const folderIndex = findIndex(this.folders, { id: folderId });
    if (folderIndex !== -1) {
      const newFolders = this.folders.slice();
      newFolders.splice(folderIndex, 1);
      this.folders = newFolders;
    }
  }
}
