export function flatten<T>(arr: T[][]): T[] {
  return arr.reduce((previous, current) => {
    if (current && current.length !== undefined && typeof current.push === 'function') {
      return previous.concat(...current);
    } else {
      previous.push(current as any);
      return previous;
    }
  }, []);
}
