import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { ReactType } from 'react';

interface IOrderEditorOption {
  key: string;
  component: ReactType;
  props?: any;
  showOption?: (block: any) => boolean;
}

export interface IOrderEditorComponentProps {
  options: IOrderEditorOption[];
  isVertical: boolean;
}

interface IReturnType<D> {
  addOption: (option: IOrderEditorOption) => IEditModeReturnValue<IOrderEditorComponentProps, D[], IReturnType<D>>;
  addOptions: (options: IOrderEditorOption[]) => IEditModeReturnValue<IOrderEditorComponentProps, D[], IReturnType<D>>;
}

export const orderEditorEdit = <D = string>(componentProps: IOrderEditorComponentProps = {options: []} as any) => {
  const toReturn: IEditModeReturnValue<IOrderEditorComponentProps, D[], IReturnType<D>> = returnEditMode<IOrderEditorComponentProps, D[], IReturnType<D>>('orderEditor', componentProps) as any;
  toReturn.addOption = ({key, component, props, showOption = () => true}: IOrderEditorOption) => {
    toReturn._decoratorProperties.componentProps.options.push({
      key,
      component,
      props,
      showOption
    });
    return toReturn;
  };
  toReturn.addOptions = (options: IOrderEditorOption[]) => {
    options.forEach(option => toReturn.addOption(option));
    return toReturn;
  };
  return toReturn;
};
