import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { listMentionPickerEdit } from '../../util/block/edit-types/list-mention-picker-edit.util';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { PossibleListTypesEnum } from '../../enum/possible-list-types.enum';
import { ILink } from '../../interface/page/link.interface';
import { linkEdit } from '../../util/block/edit-types/link-edit.util';
import { DesignTypeEnum } from '../../enum/design-type.enum';
import { LinkOriginEnum } from '../../enum/link-origin.enum';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { toggleEdit } from '../../util/block/edit-types/toggle-edit.util';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import {JobadViewStyleEnum} from '../../enum/block/jobad-view-style.enum';
import { findMention } from '../../util/page/find-mention.util';
import { EditorModel } from '../editor.model';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';
import { setPageSizeForBlockLinkedData } from '../../util/action/type/set-page-size-for-block-linked-data.util';
import {JobadViewSortEnum} from '../../enum/block/jobad-view-sort.enum';
import {setSortForBlockLinkedData} from '../../util/action/type/set-sort-for-block-linked-data.util';

export interface IJobAdListMoreButtonData {
  text: any;
  link: ILink;
  textColor: BlockColorType;
  backgroundColor: BlockColorType;
}

const blockMentionEdit = (placeholder: string) => plainTextEdit()
  .placeholder(placeholder)
  .addDataMentionsReducer((value, data: JobadListModel, editor: EditorModel, possibleBlockMentions, findLinkedData) => {
    const linkedData = findLinkedData(data.selectedMention);
    const mention = findMention(linkedData ? linkedData.mentionId : null, editor.possibleMentions);
    if (!mention) {
      return [];
    }
    return mention.dataType;
  })
  .visibleWhen((data) => Boolean(data.selectedMention));

@pageBlock
@customListBlock
@jobadBlock
@authenticatedBlock
@blockType(BlockTypeEnum.JobAdList)
@blockBackgroundColor('PrimaryBackgroundColor')
export class JobadListModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(listMentionPickerEdit()
    .placeholder('jobad-list.edit.select-listData')
    .label('jobad-list.edit.listData')
    .listType(PossibleListTypesEnum.JobAds)
    .showFilters(true)
  )
  @observable selectedMention: IUsedDesignObjectMentionMetadata;

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.edit.general-search-text')
    .visibleWhen((data) => Boolean(data.selectedMention) && data.showFilter))
  @observable generalSearchText: any = getDraftStateFromString('Search...');

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label('jobad-list.edit.jobads-view-style')
    .placeholder(`jobad-list.edit.jobads-view-style`)
    .addOption('jobad-list.edit.jobads-view-style-list', 'LIST')
    .addOption('jobad-list.edit.jobads-view-style-card', 'CARD')
  )
  @observable jobadViewStyle: JobadViewStyleEnum = JobadViewStyleEnum.LIST;

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('jobad-list.edit.showFilterOptions')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable showFilter: boolean;

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.edit.location-search-text')
    .visibleWhen((data) => Boolean(data.selectedMention) && data.showFilter))
  @observable locationSearchText: any = getDraftStateFromString('Location...');

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('jobad-list.edit.tagBackgroundColor')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable tagBackgroundColor: BlockColorType = 'TertiaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('jobad-list.edit.selectedTagBackgroundColor')
    .visibleWhen((data) => Boolean(data.selectedMention) && data.showFilter))
  @observable selectedTagBackgroundColor: BlockColorType = 'TertiaryBackgroundColor';

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.edit.header-text')
    .placeholder('jobad-list.edit.header-text-placeholder')
    .visibleWhen((data) => Boolean(data.selectedMention))
  )
  @observable headerText: any = getDraftStateFromString('Current job openings');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.edit.fetch-error-text')
    .placeholder('jobad-list.edit.fetch-error-text-placeholder')
    .visibleWhen((data) => Boolean(data.selectedMention))
  )
  @observable fetchErrorMessage: any = getDraftStateFromString('There was an error while loading jobads.');

  @editGroup.TRANSLATION
  @editable(blockMentionEdit('jobad-list.edit.line-1-text-placeholder')
    .label('jobad-list.edit.line-1-text'))
  @observable line1Text: any = getDraftStateFromString('company', [ {
    position: {startIndex: 0, endIndex: 7},
    usedMention: {id: 'JobAdModel.Company', displayName: 'company', isBlockData: true}
  } ]);

  @editGroup.TRANSLATION
  @editable(blockMentionEdit('jobad-list.edit.line-2-text-placeholder')
    .label('jobad-list.edit.line-2-text'))
  @observable line2Text: any = getDraftStateFromString('JobAd.Title', [ {
    position: {startIndex: 0, endIndex: 11},
    usedMention: {id: 'JobAdModel.Title', displayName: 'JobAd.Title', isBlockData: true}
  } ]);

  @editGroup.TRANSLATION
  @editable(blockMentionEdit('jobad-list.edit.line-3-text-placeholder')
    .label('jobad-list.edit.line-3-text'))
  @observable line3Text: any = getDraftStateFromString('municipality', [ {
    position: {startIndex: 0, endIndex: 12},
    usedMention: {id: 'JobAdModel.Municipality', displayName: 'municipality', isBlockData: true}
  } ]);

  @editGroup.TRANSLATION
  @editable(blockMentionEdit('jobad-list.edit.tag-text-placeholder')
    .label('jobad-list.edit.tag-text'))
  @observable tagText: any = getDraftStateFromString('category', [ {
    position: {startIndex: 0, endIndex: 8},
    usedMention: {id: 'JobAdModel.Category', displayName: 'category', isBlockData: true}
  } ]);

  @editGroup.TRANSLATION
  @editable(blockMentionEdit('jobad-list.edit.tag-apply-placeholder')
    .label('jobad-list.edit.tag-apply'))
  @observable applyBeforeText: any = getDraftStateFromString('Apply Before: pubDateTo', [ {
    position: {startIndex: 14, endIndex: 23},
    usedMention: {id: 'JobAdModel.PubDateTo', displayName: 'pubDateTo', isBlockData: true}
  } ]);

  @editGroup.CONTENT
  @editable(linkEdit()
    .label('jobad-list.edit.detailsLink')
    .isTemplate(true)
    .availableOrigin([ LinkOriginEnum.Internal ])
    .availableTypes([ DesignTypeEnum.JobAdPage ])
    .visibleWhen((data) => Boolean(data.selectedMention))
  )
  @observable detailsLinkTemplate: ILink;

  @editGroup.STYLE
  @editable(numberEdit()
    .label('jobad-list.edit.limit')
    .min(1)
    .placeholder('jobad-list.edit.enter-limit')
    .visibleWhen((data) => Boolean(data.selectedMention))
    .actionsOnChange([setPageSizeForBlockLinkedData()])
  )
  @observable jobadLimit: number = 10;

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('jobad-list.edit.useShadows')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable useShadows: boolean;

  @editGroup.CONTENT
  @editable(
    plainTextEdit()
      .label('jobad-list.edit.moreButton_text')
      .onParameter('text'),
    colorPickerEdit()
      .label('jobad-list.edit.moreButton_textColor')
      .onParameter('textColor'),
    colorPickerEdit()
      .label('jobad-list.edit.moreButton_backgroundColor')
      .onParameter('backgroundColor')
  )
  @observable moreButton: IJobAdListMoreButtonData = {
    text: getDraftStateFromString('Show More'),
    link: undefined,
    textColor: 'SecondaryTextColor',
    backgroundColor: 'PrimaryButtonColor'
  };

  @editGroup.CONTENT
  @editable(enumPickerEdit()
      .label('jobad-list.edit.jobads-view-sort')
      .placeholder(`jobad-list.edit.jobads-view-sort`)
      .addOption('jobad-list.edit.jobads-view-sort-pub-date-asc', 'PUB_DATE_ASC')
      .addOption('jobad-list.edit.jobads-view-sort-pub-date-desc', 'PUB_DATE_DESC')
      .addOption('jobad-list.edit.jobads-view-sort-last-app-date-asc', 'LAST_APP_DATE_ASC')
      .addOption('jobad-list.edit.jobads-view-sort-last-app-date-desc', 'LAST_APP_DATE_DESC')
      .addOption('jobad-list.edit.jobads-view-sort-region-asc', 'REGION_ASC')
      .addOption('jobad-list.edit.jobads-view-sort-region-desc', 'REGION_DESC')
      .actionsOnChange([setSortForBlockLinkedData()])
  )
  @observable jobadViewSort: JobadViewSortEnum;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('jobad-list.edit.jobadBackgroundColor')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable jobadBackgroundColor: BlockColorType = 'TertiaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('jobad-list.edit.jobadTagTextColor')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable jobadTagTextColor: BlockColorType = 'Transparent';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('jobad-list.edit.jobadTagBackgroundColor')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable jobadTagBackgroundColor: BlockColorType = 'TertiaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('jobad-list.edit.moreLessTagBackgroundColor')
    .visibleWhen((data) => Boolean(data.selectedMention)))
  @observable moreLessTagBackgroundColor: BlockColorType = 'TertiaryBackgroundColor';

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.edit.more-filters-message'))
  @observable moreFiltersMessage: any = getDraftStateFromString('More filters');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.less-filters-message'))
  @observable lessFiltersMessage: any = getDraftStateFromString('Less filters');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.no-results-message'))
  @observable noResultsMessage: any = getDraftStateFromString('The search did not return any results');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('jobad-list.loading-message'))
  @observable loadingMessage: any = getDraftStateFromString('Loading...');
}
