import { ConfigurationModel } from '../model/configuration.model';
import { IActionsWithContext } from '../interface/action/actions-with-context.interface';

const initializeActionPromise = () => import('./initialize.action').then((m) => m.default);
const authActionPromise = () => import('./auth.action').then((m) => m.default);
const debugActionPromise = () => import('./debug.action').then((m) => m.default);
const httpActionPromise = () => import('./http.action').then((m) => m.default);
const editorActionPromise = () => import('./editor.action').then((m) => m.default);
const designObjectActionPromise = () => import('./design-object.action').then((m) => m.default);
const blockActionPromise = () => import('./block.action').then((m) => m.default);
const pageActionPromise = () => import('./page.action').then((m) => m.default);
const browserActionPromise = () => import('./browser.action').then((m) => m.default);
const mediaLibraryActionPromise = () => import('./media-library.action').then((m) => m.default);
const settingsActionPromise = () => import('./settings.action').then((m) => m.default);
const templateActionPromise = () => import('./template.action').then((m) => m.default);
const webDocActionPromise = () => import('./web-document.action').then((m) => m.default);
const customListsActionPromise = () => import('./custom-lists.action').then((m) => m.default);

export const getActions: (config: ConfigurationModel) => Promise<IActionsWithContext[]> = (config: ConfigurationModel) => Promise.all([
  debugActionPromise(),
  designObjectActionPromise(),
  editorActionPromise(),
  initializeActionPromise(),
  authActionPromise(),
  httpActionPromise(),
  pageActionPromise(),
  blockActionPromise(),
  browserActionPromise(),
  mediaLibraryActionPromise(),
  settingsActionPromise(),
  templateActionPromise(),
  webDocActionPromise(),
  customListsActionPromise()
]);
