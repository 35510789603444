import { BaseFieldOptionsModel } from '../base-field-options.model';
import { IImageFieldOptions } from '../../../../interface/custom-lists/image-field-options.interface';
import { ImageOriginEnum } from '../../../../enum/image-origin.enum';
import { persist } from 'mobx-persist';
import { observable } from 'mobx';
import { editGroup } from '../../../block/decorators/edit-group.decorator';
import { editable } from '../../../block/decorators/editable.decorator';
import { enumPickerEdit } from '../../../../util/block/edit-types/enum-picker-edit.util';

export class ImageFieldOptionsModel extends BaseFieldOptionsModel implements IImageFieldOptions {
  @editGroup.CONTENT
  @editable(
    enumPickerEdit()
      .label('custom-list.field-options.image.possible-origins')
      .addOption('custom-list.field-options.image.media-library-origin', ImageOriginEnum.MediaLibrary)
      .addOption('custom-list.field-options.image.external-origin', ImageOriginEnum.Url)
      .isMulti()
  )
  @persist @observable possibleOrigin: ImageOriginEnum[] = [ImageOriginEnum.Url, ImageOriginEnum.MediaLibrary];
}