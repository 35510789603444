import { observable } from 'mobx';
import { AuthenticationEnum } from '../enum/authentication.enum';
import { persist } from 'mobx-persist';
import { assignDesignObjectValue, DesignObjectModel } from './design-object.model';
import { IAuthenticatedDesignObject } from '../interface/autheticated-design-object.interface';
import { editable } from './block/decorators/editable.decorator';
import { enumPickerEdit } from '../util/block/edit-types/enum-picker-edit.util';
import { editGroup } from './block/decorators/edit-group.decorator';

export class AuthenticatedDesignObjectModel extends DesignObjectModel implements IAuthenticatedDesignObject {
  @editGroup.GENERAL
  @editable(
    enumPickerEdit()
      .label('page-editor-settings.page_authentication')
      .placeholder('page-editor-settings.page_authentication')
      .addOption('page-editor-settings.authenticationPublic', AuthenticationEnum.Public)
      .addOption('page-editor-settings.authenticationPrivate', AuthenticationEnum.Private)
  )
  @persist @observable authentication: AuthenticationEnum = AuthenticationEnum.Public;
  @persist('list') @observable roles: string[] = [];

  constructor(data?: IAuthenticatedDesignObject, inAdmin?: boolean, isDraft: boolean = false) {
    super(data, inAdmin, isDraft);
    if (data) {
      assignDesignObjectValue(this, data, 'authentication');
      assignDesignObjectValue(this, data, 'roles');
    }
  }
}
