import * as Draft from 'draft-js';
import { convertFromRaw, EditorState } from 'draft-js';
import { toJS } from 'mobx';
import { isNumber, isString } from 'lodash';
import { findEntitiesInState } from './find-entities-in-editor-state.util';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';
import { findLinkedData } from '../page/find-linked-data.util';
import { DraftMentionFormatterType } from '../../interface/draft/draft-mention-formatter.type';
import { AppStateModel } from '../../model/app-state.model';
import * as moment from 'moment';
import {formatMoney} from '../number/format-money.util';

export const defaultFormatter: DraftMentionFormatterType = {
  date: (date: string) => {
    return moment.utc(date).format('YYYY-MM-DD');
  }
};

export const draftToString = (draftJSValue: Draft.RawDraftContentState, state?: AppStateModel, blockMentionData: { [ key: string ]: any } = {}, missingLinkedDataText: (usedMentionData: IUsedDesignObjectMentionMetadata) => string = () => '', newFormatter?: DraftMentionFormatterType): string => {
  if (!draftJSValue) {
    return '';
  }
  const formatter = Object.assign({}, defaultFormatter, newFormatter);
  const editorState = convertFromRaw(toJS(draftJSValue));
  const mentions = findEntitiesInState(EditorState.createWithContent(editorState), 'MENTION');

  let returnString = editorState.getPlainText();
  if ((state && state.activeDesign.data) || (blockMentionData && Object.keys(blockMentionData).length > 0)) {
    mentions.forEach((mention) => {
      const usedMention: IUsedDesignObjectMentionMetadata = mention.entity.getData();
      const entity = mention.entity as any;
      const currentDataFormatter: DraftMentionFormatterType = Object.assign({}, formatter);
      if (entity.data.format) {
        if (entity.data.format[DesignObjectMentionMetadataType.Date]) {
          currentDataFormatter[DesignObjectMentionMetadataType.Date] = (value: string) => {
            return moment(value).format(entity.data.format[DesignObjectMentionMetadataType.Date]);
          };
        }
        if (entity.data.format[DesignObjectMentionMetadataType.Text]) {
          const oldFormatter = currentDataFormatter[DesignObjectMentionMetadataType.Text];
          currentDataFormatter[DesignObjectMentionMetadataType.Text] = (value: string, appState) => {
            if (!isNaN(Number(value))) {
              switch (entity.data.format[DesignObjectMentionMetadataType.Text]) {
                case 'integer':
                  return String(parseInt(value, 10));
                case 'decimal':
                  return Number(value).toFixed(2);
                case 'currency':
                  return formatMoney(value);
              }
            }
            return oldFormatter ? oldFormatter(value, appState) : value;
          };
        }
      }
      const linkedData = findLinkedData(usedMention, (state && state.activeDesign.data), blockMentionData);
      if (linkedData) {
        if ((linkedData.type === DesignObjectMentionMetadataType.Html || linkedData.type === DesignObjectMentionMetadataType.Text || linkedData.type === DesignObjectMentionMetadataType.Unknown || (state && currentDataFormatter.hasOwnProperty(linkedData.type))) && linkedData.data) {
          let data = linkedData.data;
          if (state && currentDataFormatter.hasOwnProperty(linkedData.type)) {
            data = currentDataFormatter[ linkedData.type ](data, state);
          }
          if (isString(data) || isNumber(data)) {
            returnString = returnString.replace((usedMention as any).displayName, String(data));
          } else if (data) {
            returnString = returnString.replace((usedMention as any).displayName, draftToString(data, state, blockMentionData, missingLinkedDataText, newFormatter));
          }
        } else if (missingLinkedDataText !== null) {
          returnString = returnString.replace((usedMention as any).displayName, missingLinkedDataText(usedMention));
        }
      }
    });
  }
  return returnString;
};
