import {
    CountryFlagAFIcon,
    CountryFlagALIcon,
    CountryFlagATIcon,
    CountryFlagAUIcon,
    CountryFlagBAIcon,
    CountryFlagBBIcon,
    CountryFlagBEIcon,
    CountryFlagBGIcon,
    CountryFlagBRIcon,
    CountryFlagBYIcon,
    CountryFlagCAIcon, CountryFlagCHIcon,
    CountryFlagCNIcon,
    CountryFlagCRIcon,
    CountryFlagCZIcon,
    CountryFlagDEIcon,
    CountryFlagDKIcon,
    CountryFlagEEIcon,
    CountryFlagEGIcon,
    CountryFlagESIcon,
    CountryFlagFIIcon,
    CountryFlagFRIcon, CountryFlagGBIcon,
    CountryFlagGRIcon,
    CountryFlagHRIcon,
    CountryFlagHUIcon,
    CountryFlagIEIcon,
    CountryFlagINIcon,
    CountryFlagISIcon,
    CountryFlagITIcon,
    CountryFlagLTIcon,
    CountryFlagLVIcon,
    CountryFlagMKIcon,
    CountryFlagNLIcon,
    CountryFlagNOIcon, CountryFlagPKIcon,
    CountryFlagPLIcon,
    CountryFlagRUIcon,
    CountryFlagSEIcon, CountryFlagTRIcon, CountryFlagUSIcon
} from '../../component/icons/country-flags';
import { GlobeIcon } from '../../component/icons';

export interface ICountry {
    countryCode: string;
    callingCode: string;
    areaCodes?: string[];
    name: string;
    // TODO2024 - remove any
    icon: any;
}

export const countryNone: ICountry = {
    icon: GlobeIcon,
    callingCode: '',
    countryCode: '',
    name: ''
};

const countries: ICountry[] = [
    {
        countryCode: 'au',
        callingCode: '+61',
        name: 'Australia',
        icon: CountryFlagAUIcon
    }, {
        countryCode: 'at',
        callingCode: '+43',
        name: 'Austria',
        icon: CountryFlagATIcon
    }, {
        countryCode: 'af',
        callingCode: '+93',
        name: 'Afghanistan',
        icon: CountryFlagAFIcon
    }, {
        countryCode: 'al',
        callingCode: '+355',
        name: 'Albania',
        icon: CountryFlagALIcon
    }, {
        countryCode: 'bb',
        callingCode: '+1',
        areaCodes: ['246'],
        name: 'Barbados',
        icon: CountryFlagBBIcon
    }, {
        countryCode: 'be',
        callingCode: '+32',
        name: 'Belgium',
        icon: CountryFlagBEIcon
    }, {
        countryCode: 'by',
        callingCode: '+375',
        name: 'Belarus',
        icon: CountryFlagBYIcon
    }, {
        countryCode: 'ba',
        callingCode: '+387',
        name: 'Bosnia and Herzegovina',
        icon: CountryFlagBAIcon
    }, {
        countryCode: 'br',
        callingCode: '+55',
        name: 'Brazil',
        icon: CountryFlagBRIcon
    }, {
        countryCode: 'bg',
        callingCode: '+359',
        name: 'Bulgaria',
        icon: CountryFlagBGIcon
    }, {
        countryCode: 'cr',
        callingCode: '+506',
        name: 'Costa Rica',
        icon: CountryFlagCRIcon
    }, {
        countryCode: 'ca',
        callingCode: '+1',
        areaCodes: ['204', '226', '236', '249', '250', '289', '306', '343', '365', '367', '403', '416', '418', '431', '437',
            '438', '450', '506', '514', '519', '548', '579', '581', '587', '604', '613', '639', '647', '705', '709', '778',
            '780', '782', '807', '819', '825', '867', '873', '902', '905'],
        name: 'Canada',
        icon: CountryFlagCAIcon
    }, {
        countryCode: 'cn',
        callingCode: '+86',
        name: 'China',
        icon: CountryFlagCNIcon
    }, {
        countryCode: 'hr',
        callingCode: '+385',
        name: 'Croatia',
        icon: CountryFlagHRIcon
    }, {
        countryCode: 'cz',
        callingCode: '+420',
        name: 'Czech Republic',
        icon: CountryFlagCZIcon
    }, {
        countryCode: 'dk',
        callingCode: '+45',
        name: 'Denmark',
        icon: CountryFlagDKIcon
    }, {
        countryCode: 'eg',
        callingCode: '+20',
        name: 'Egypt',
        icon: CountryFlagEGIcon
    }, {
        countryCode: 'ee',
        callingCode: '+372',
        name: 'Estonia',
        icon: CountryFlagEEIcon
    }, {
        countryCode: 'fi',
        callingCode: '+358',
        name: 'Finland',
        icon: CountryFlagFIIcon
    }, {
        countryCode: 'fr',
        callingCode: '+33',
        name: 'France',
        icon: CountryFlagFRIcon
    }, {
        countryCode: 'de',
        callingCode: '+49',
        name: 'Germany',
        icon: CountryFlagDEIcon
    }, {
        countryCode: 'gr',
        callingCode: '+30',
        name: 'Greece',
        icon: CountryFlagGRIcon
    }, {
        countryCode: 'hu',
        callingCode: '+36',
        name: 'Hungary',
        icon: CountryFlagHUIcon
    }, {
        countryCode: 'is',
        callingCode: '+354',
        name: 'Iceland',
        icon: CountryFlagISIcon
    }, {
        countryCode: 'in',
        callingCode: '+91',
        name: 'India',
        icon: CountryFlagINIcon
    }, {
        countryCode: 'ie',
        callingCode: '+353',
        name: 'Ireland',
        icon: CountryFlagIEIcon
    }, {
        countryCode: 'it',
        callingCode: '+39',
        name: 'Italy',
        icon: CountryFlagITIcon
    }, {
        countryCode: 'lv',
        callingCode: '+371',
        name: 'Latvia',
        icon: CountryFlagLVIcon
    }, {
        countryCode: 'lt',
        callingCode: '+370',
        name: 'Lithuania',
        icon: CountryFlagLTIcon
    }, {
        countryCode: 'mk',
        callingCode: '+389',
        name: 'Macedonia',
        icon: CountryFlagMKIcon
    }, {
        countryCode: 'nl',
        callingCode: '+31',
        name: 'Netherlands',
        icon: CountryFlagNLIcon
    }, {
        countryCode: 'no',
        callingCode: '+47',
        name: 'Norway',
        icon: CountryFlagNOIcon
    }, {
        countryCode: 'pl',
        callingCode: '+48',
        name: 'Poland',
        icon: CountryFlagPLIcon
    }, {
        countryCode: 'ru',
        callingCode: '+7',
        name: 'Russia',
        icon: CountryFlagRUIcon
    }, {
        countryCode: 'es',
        callingCode: '+34',
        name: 'Spain',
        icon: CountryFlagESIcon
    }, {
        countryCode: 'se',
        callingCode: '+46',
        name: 'Sweden',
        icon: CountryFlagSEIcon
    }, {
        countryCode: 'pk',
        callingCode: '+92',
        name: 'Pakistan',
        icon: CountryFlagPKIcon
    }, {
        countryCode: 'ch',
        callingCode: '+41',
        name: 'Switzerland',
        icon: CountryFlagCHIcon
    }, {
        countryCode: 'tr',
        callingCode: '+90',
        name: 'Turkey',
        icon: CountryFlagTRIcon
    }, {
        countryCode: 'us',
        callingCode: '+1',
        name: 'United States',
        icon: CountryFlagUSIcon
    }, {
        countryCode: 'gb',
        callingCode: '+44',
        name: 'United Kingdom',
        icon: CountryFlagGBIcon
    }
];
export default countries;
