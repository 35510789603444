import { BlockTypeEnum } from './block-type.enum';
import * as AllBlocks from '../../model/block';
import { invariant } from '../../util/debug/invariant.util';
import { BaseBlockDataModel } from '../../model/block/base-block-data.model';
import { IBlockDataModelConstructor } from '../../interface/block/block-data-contructor.interface';

// Maps block constructors to type
const BlockByType: {
  [key in BlockTypeEnum]: IBlockDataModelConstructor
} = {} as any;

const allBlockKeys = Object.keys(AllBlocks);

for (const key of allBlockKeys) {
  invariant(!BlockByType[ AllBlocks[ key ].blockType ], 'There must be only one block model per type!', AllBlocks[ key ].blockType);
  BlockByType[ AllBlocks[ key ].blockType ] = AllBlocks[ key ];
}

export function getBlockByType(type: BlockTypeEnum): IBlockDataModelConstructor {
  let toReturn: IBlockDataModelConstructor = BlockByType[ type ];
  if (!toReturn) {
    console.warn(`Block of type: ${type} not found!`);
    toReturn = BaseBlockDataModel as IBlockDataModelConstructor;
  }
  return toReturn;
}
