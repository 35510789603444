import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { isFunction } from 'lodash';
import { invariant } from '../../debug/invariant.util';
import { AppStateModel } from '../../../model/app-state.model';
import { stringRequiredValidator } from '../../validators/string-required-validator';

export interface ILabelValue {
  value: any;
  label: string;
}

export interface IEnumPickerEditComponentProps {
  placeholder?: string;
  options: ILabelValue[] | ((value: any, data: any, state: AppStateModel) => (ILabelValue[] | Promise<ILabelValue[]>));
  isMulti: boolean;
}

interface IReturnType {
  placeholder: (placeholder: string) => IEditModeReturnValue<IEnumPickerEditComponentProps, any, IReturnType>;
  addOption: (label: string, value: any) => IEditModeReturnValue<IEnumPickerEditComponentProps, any, IReturnType>;
  setOptions: (options: ILabelValue[]) => IEditModeReturnValue<IEnumPickerEditComponentProps, any, IReturnType>;
  setOptionsGetter: (getOptions: (value: any, data: any, state: AppStateModel) => (ILabelValue[] | Promise<ILabelValue[]>)) => IEditModeReturnValue<IEnumPickerEditComponentProps, any, IReturnType>;
  isMulti: () => IEditModeReturnValue<IEnumPickerEditComponentProps, any, IReturnType>;
  required: (message: string) => IEditModeReturnValue<IEnumPickerEditComponentProps, number, IReturnType>;
}

export const enumPickerEdit = (componentProps: IEnumPickerEditComponentProps = {} as any) => {
  if (!componentProps.options) {
    componentProps.options = [];
  }
  const toReturn: IEditModeReturnValue<IEnumPickerEditComponentProps, any, IReturnType> = returnEditMode<IEnumPickerEditComponentProps, any, IReturnType>('enum', componentProps) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.addOption = (label: string, value: any) => {
    if (isFunction(toReturn._decoratorProperties.componentProps.options)) {
      invariant(false, 'Options must be a array to add options!');
    } else {
      toReturn._decoratorProperties.componentProps.options.push({label, value});
    }
    return toReturn;
  };
  toReturn.required = (errorMessage) => {
    toReturn.withValidator(stringRequiredValidator(errorMessage));
    return toReturn;
  };
  toReturn.setOptions = (options) => {
    toReturn._decoratorProperties.componentProps.options = options || toReturn._decoratorProperties.componentProps.options;
    return toReturn;
  };
  toReturn.setOptionsGetter = (optionsGetter) => {
    toReturn._decoratorProperties.componentProps.options = optionsGetter || toReturn._decoratorProperties.componentProps.options;
    return toReturn;
  };
  toReturn.isMulti = () => {
    toReturn._decoratorProperties.componentProps.isMulti = true;
    return toReturn;
  };
  return toReturn;
};
