import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { ISelectedImage } from '../../../interface/component/selected-image.interface';
import { ImageOriginEnum } from '../../../enum/image-origin.enum';

export interface ImageEditComponentProps {
  availableOrigin?: ImageOriginEnum[];
}

interface IReturnType {
  availableOrigin: (availableOrigin: ImageOriginEnum[]) => IEditModeReturnValue<ImageEditComponentProps, ISelectedImage, IReturnType>;
}

export const videoEdit = (componentProps: ImageEditComponentProps = {}) => {
  const toReturn: IEditModeReturnValue<ImageEditComponentProps, ISelectedImage, IReturnType> = returnEditMode<ImageEditComponentProps, ISelectedImage, IReturnType>('video', componentProps) as any;
  toReturn.availableOrigin = (availableOrigin) => {
    toReturn._decoratorProperties.componentProps.availableOrigin = availableOrigin;
    return toReturn;
  };
  toReturn.setUsedMentionsReducer((image: ISelectedImage) => {
    if (image && image.origin === ImageOriginEnum.Mention && image.mention) {
      return [ image.mention ];
    }
    return [];
  });
  return toReturn;
};
