import * as React from 'react';
import { BaseBlockView } from '../../component/blocks/base-block.view';
import { Logger } from '../debug/logger.util';
import styled from 'styled-components';
import { ColorEnum } from '../../enum/color.enum';
import { BlockColorType } from '../../enum/block-color.enum';
import { ErrorBoundary } from '../../component/error-boundary';
import { isStickyBlock } from './is-sticky-block.util';
import { getBlockViewByType } from '../../enum/block/block-view-by-type';
import { BlockModel } from '../../model/block/block.model';
import { DesignObjectModel } from '../../model/design-object.model';
import { BlockViewTypes } from '../../enum/block/block-view-types.type';
import { DesignTypeEnum } from '../../enum/design-type.enum';
import { inject } from 'mobx-react';
import { AppStateModel } from '../../model/app-state.model';
import { HorizontalMenuType } from '../../component/horizontal-icon-menu/horizontal-menu-item';

const StyledWrapper = styled.div`
  background-color: ${(props: { backgroundColor: BlockColorType }) => ColorEnum.getColor(props.backgroundColor)(props as any)};
  width: 100%;
`;

const BlockViewWrapper = inject('state')((props: { backgroundColor: BlockColorType, viewType: BlockViewTypes, state?: AppStateModel, children: any }) => {
  if (props.viewType === DesignTypeEnum.Email) {
    return <tr
      style={{
        backgroundColor: props.state.colors.getColor(props.backgroundColor)
      }}
    >
      <td />
      {props.children}
      <td />
    </tr>;
  }
  return <StyledWrapper {...props} />;
});

export interface ICreateBlockViewParams<T extends BlockModel> {
  blockModel: T;
  design: DesignObjectModel;
  viewType: DesignTypeEnum;
  inEditor?: boolean;
  onInnerBlockAction?: (blockClicked: BlockModel, actionClicked: HorizontalMenuType) => void;
  draggingId?: string;
  isSelected?: boolean;
  parentId?: string;
  dataItem?: any;
}

export function createBlockView<T extends BlockModel>(params: ICreateBlockViewParams<T>): BaseBlockView<T['data']> {
  let BlockView = getBlockViewByType(params.blockModel.blockType, params.viewType);
  if (!BlockView) {
    Logger.warn(`There is no block view for type ${params.blockModel.blockType}`);
    BlockView = (BaseBlockView as any);
  }
  let backgroundColor = params.blockModel.backgroundColor;
  if (!backgroundColor || (backgroundColor as any) === 'Transparent') {
    backgroundColor = params.design.backgroundColor as any;
  }
  const content = <ErrorBoundary>
    <BlockView
      id={params.blockModel.id}
      blockType={params.blockModel.blockType}
      content={params.blockModel.data}
      backgroundColor={backgroundColor}
      inEditor={params.inEditor}
      isSticky={isStickyBlock(params.blockModel, params.design.blocks)}
      onInnerBlockAction={params.onInnerBlockAction}
      draggingId={params.draggingId}
      isSelected={params.isSelected}
      parentId={params.parentId}
      dataItem={params.dataItem}
    />
  </ErrorBoundary>;
  // BlockView props are from IBlockProps
  return (
    <BlockViewWrapper
      key={`${params.design.type}.${params.design.id}.${params.blockModel.id}`}
      viewType={params.viewType}
      backgroundColor={params.blockModel.backgroundColor}
    >
      {content}
    </BlockViewWrapper> as any
  );
}
