import { ISettingsColor } from '../../interface/settings.interface';
import { getColorHsl } from './get-color-hsl.util';
import { ColorEnum } from '../../enum/color.enum';

const hue2rgb = (p: number, q: number, t: number) => {
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
};

const padWithZero = (text: string) => {
  if (text.length < 2) {
    return `0${text}`;
  }
  return text;
};

export const getColorHexValue = (color: ISettingsColor, allColors: ISettingsColor[]) => {
  let r;
  let g;
  let b;
  const hsl = getColorHsl(color, allColors);
  if (!hsl) {
    return ColorEnum.Transparent();
  }

  const h = hsl.h / 360;
  const s = hsl.s / 100;
  const l = hsl.l / 100;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return `#${padWithZero(Math.round(r * 255).toString(16))}${padWithZero(Math.round(g * 255).toString(16))}${padWithZero(Math.round(b * 255).toString(16))}`;
};
