import { observable } from 'mobx';
import { persist } from 'mobx-persist';
import { ISettingsRootFile } from '../interface/settings.interface';
import { LinkedinLoginTypeEnum } from '../enum/linkedin-login-type.enum';

export class AdminSettingsModel {
  @persist @observable linkedInLoginType: LinkedinLoginTypeEnum;
  @persist @observable linkedInClientId: string;
  @persist @observable linkedInClientSecret: string;
  @persist @observable tagManagerId: string;
  @persist @observable tagManagerTrackCustomEvents: boolean;
  @persist @observable tagManagerLanguage: string;
  @persist @observable tagManagerCountryCode: string;
  @persist @observable tagManagerBookingEngine: string;
  @persist @observable tagManagerBusinessArea: string;
  @persist @observable faviconLocation: string;
  @persist('list') @observable rootFiles: ISettingsRootFile[];
  @persist @observable errorMessageTitleText: string;
  @persist @observable errorMessageShowMoreText: string;
  @persist @observable noInternetConnectionTitleText: string;
  @persist @observable errorMessageJobAdExpiredText: string;
  @persist @observable errorMessageJobAdNotPublishedText: string;
  @persist @observable errorMessageIeWarning: string;
  @persist @observable enableIntelliplanCookiePolicy: boolean;
  @persist @observable enableOneTrustCookiePolicy: boolean;
  @persist @observable oneTrustAccountId: string;
  @persist @observable enableCookieInformationPolicy: boolean;
  @persist @observable cookieInformationLanguage: string;
}
