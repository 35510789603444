import { css } from 'styled-components';
import { largeMobileMedia } from './large-mobile-media';
import { tabletMedia } from './tablet-media';
import { LARGE_SCREEN_SIZE, SMALL_SCREEN_SIZE, XLARGE_SCREEN_SIZE, XSMALL_SCREEN_SIZE } from '../../constants';

interface ICenterOfPageStyleProps {
  mobileStyle?: string;
  tabletStyle?: string;
}

interface ICenterOfScreenSizes {
  maxWidth: number;
  label: 'Responsive' | 'LargeTablet' | 'Tablet' | 'LargeMobile' | 'Mobile';
  width: number;
  marginLeft: number;
}

export const centerOfScreenSizes: ICenterOfScreenSizes[] = [ {
  maxWidth: undefined,
  width: 0.5486244,
  label: 'Responsive',
  marginLeft: 0.2256878
}, {
  maxWidth: XLARGE_SCREEN_SIZE,
  width: 0.7542656,
  label: 'LargeTablet',
  marginLeft: 0.1228672
}, {
  maxWidth: LARGE_SCREEN_SIZE,
  width: 0.8542656,
  label: 'Tablet',
  marginLeft: 0.0728672
}, {
  maxWidth: SMALL_SCREEN_SIZE,
  width: 0.8971574,
  label: 'LargeMobile',
  marginLeft: 0.0514213
}, {
  maxWidth: XSMALL_SCREEN_SIZE,
  width: 0.8705267,
  label: 'Mobile',
  marginLeft: 0.0647367
} ];

const baseSize = centerOfScreenSizes.filter(size => !size.maxWidth)[ 0 ];

export const getCenterOfPageStyle = (options: ICenterOfPageStyleProps = {} as any) => (props: any) => {
  if (props && props.theme && props.theme._inEditor) {
    return '';
  }
  return css`
        width: ${baseSize.width * 100}%;
        margin-left: ${baseSize.marginLeft * 100}%;
        ${centerOfScreenSizes.filter(size => Boolean(size.maxWidth)).map(size => css`@media(max-width: ${size.maxWidth}px) {width: ${size.width * 100}%; margin-left: ${size.marginLeft * 100}%;}`)}
        ${tabletMedia(`
          ${options.tabletStyle || ''}
        `)}
        ${largeMobileMedia(`
          ${options.mobileStyle || ''}
        `)}
    `;
};
