import { IDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';

export function findMention(mentionId: string, mentions: IDesignObjectMentionMetadata[] = []): IDesignObjectMentionMetadata {
  if (!mentions || mentions.length === 0 || !mentionId) {
    return undefined;
  }
  let foundMention = mentions.find((mention) => mention.mentionId === mentionId);
  if (foundMention) {
    return foundMention;
  }
  const folders = mentions.filter((mention: IDesignObjectMentionMetadata) => mention.type === DesignObjectMentionMetadataType.Folder);
  for (const folder of folders) {
    foundMention = findMention(mentionId, folder.children);
    if (foundMention) {
      return foundMention;
    }
  }
  return undefined;
}
