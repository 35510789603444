import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { EmailTextBlockModel, TextBlockModel } from '../../../model/block';
import { draftToString } from '../../../util/draft/draft-to-string.util';

const TextBlockView = asyncComponent(() => import('./text-block.view').then(c => c.TextBlockView));
const EmailTextBlockView = asyncComponent(() => import('./email-text-block.view').then(c => c.EmailTextBlockView));
const TextBlockIcon = asyncComponent(() => import('../../icons/block/text-block.icon').then(c => c.TextBlockIcon), 'svg');

export const TextBlock = getBlock(TextBlockModel, BlockGroupEnum.Text, 'block.text-block-title',
  <TextBlockIcon/>, TextBlockView);
export const EmailTextBlock = getBlock(EmailTextBlockModel, BlockGroupEnum.Text, 'block.text-block-title',
  <TextBlockIcon/>, EmailTextBlockView, {
    text: (model, state) => draftToString(model.text, state)
  });
