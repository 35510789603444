import { draftToString } from './draft-to-string.util';
import { AppStateModel } from '../../model/app-state.model';
import { DraftMentionFormatterType } from '../../interface/draft/draft-mention-formatter.type';
import { isStringEmpty } from '../string/is-string-empty.util';

export const isDraftEmpty = (draft: any, state?: AppStateModel, blockMentionData?: { [ key: string ]: any }, formatter?: DraftMentionFormatterType) => {
  if (!draft) {
    return true;
  }
  const draftString = draftToString(draft, state, blockMentionData, () => '', formatter);
  return isStringEmpty(draftString);
};
