// Maps block view constructors to type
import { BlockTypeEnum } from './block-type.enum';
import * as AllBlockViews from '../../component/blocks';
import { BaseBlockView } from '../../component/blocks/base-block.view';
import { BaseBlockDataModel } from '../../model/block/base-block-data.model';
import { DesignTypeEnum } from '../design-type.enum';
import { BlockViewTypes } from './block-view-types.type';
import { invariant } from '../../util/debug/invariant.util';

type IBlockViewConstructor = new() => BaseBlockView<BaseBlockDataModel>;

export const BlockViewByType: {
  [key in BlockTypeEnum]: {
  [designKey in DesignTypeEnum]?: IBlockViewConstructor
} & { defaultView: IBlockViewConstructor }
} = {} as any;

const allBlockKeys = Object.keys(AllBlockViews);

for (const key of allBlockKeys) {
  invariant(!BlockViewByType[ AllBlockViews[ key ].model.blockType ], `You already have a block view type of: ${AllBlockViews[ key ].model.blockType}, all block views must have a unique type!`);
  BlockViewByType[ AllBlockViews[ key ].model.blockType ] = {defaultView: AllBlockViews[ key ].view, ...AllBlockViews[ key ].specificViews};
}

export function getBlockViewByType(blockType: BlockTypeEnum, viewType: BlockViewTypes) {
  if (BlockViewByType[ blockType ][ viewType ]) {
    return BlockViewByType[ blockType ][ viewType ];
  }
  return BlockViewByType[ blockType ].defaultView;
}
