import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { ISubmitFormPayload } from '../../../action/http.action';
import { IFileData } from '../../../component/blocks/form/fields/file-field';

export interface ISubmitFormErrorMessages {
  alreadyAppliedErrorTitle: string;
  alreadyAppliedErrorBody: string;
  invalidFileFormatTitle: string;
  invalidFileFormatBody: string;
  emptyFileUploadedErrorMessageTitle: string;
  emptyFileUploadedErrorMessageBody: string;
  serverErrorBody: string;
  serverErrorTitle: string;
}

export const submitForm = (
  url: string,
  form: { fields: { [key: string]: any }; files: { [key: string]: IFileData } },
  errorMessages: ISubmitFormErrorMessages,
  queryData: any,
  urlParams: { [key: string]: string },
) =>
  returnAction<ISubmitFormPayload>(ActionEnum.SUBMIT_FORM, {
    url,
    queryData,
    form,
    errorMessages,
    urlParams,
  });
