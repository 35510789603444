import { matchRoutes } from 'react-router-config';
import { getBlockViewByType } from '../../enum/block/block-view-by-type';
import { DesignObjectModel } from '../../model/design-object.model';

export function ensurePathIsReady(routeConfig, design: DesignObjectModel, providedLocation = window.location.pathname) {
  const matches = matchRoutes(routeConfig, providedLocation);
  return Promise.all(matches.map((match) => {
    const {component} = match.route;
    if (component && component.load) {
      return component.load();
    }
    return undefined;
  }).concat(design ? design.blocks.map((b) => {
    const view: any = getBlockViewByType(b.blockType, design.type);
    if (view && view.load) {
      return view.load();
    }
    return undefined;
  }) : []));
}
