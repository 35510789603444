import { observable } from 'mobx';
import { persist } from 'mobx-persist';
import { IBuildingBlock } from '../interface/block/building-block.interface';
import { IDesignObjectMentionMetadata } from '../interface/page/design-object-mention-metadata.interface';
import { getAllBuildingBlocks } from '../util/block/get-all-building-blocks.util';
import { CustomListDefinitionModel } from './custom-lists/custom-list-definition.model';
import { ICustomListDefinition } from '../interface/custom-lists/custom-list-definition.interface';
import { ICustomListEntry } from '../interface/custom-lists/custom-list-entry.interface';
import { CustomListEntryModel } from './custom-lists/custom-list-entry.model';

export class EditorModel {
  @persist('list') @observable designBuildingBlocks: IBuildingBlock[] = getAllBuildingBlocks();
  @persist('list') @observable possibleMentions: IDesignObjectMentionMetadata[] = [];
  @persist('list') @observable customListDefinitions: ICustomListDefinition[] = [];
  @persist('object', CustomListDefinitionModel) @observable editingCustomListDefinition: CustomListDefinitionModel;
  @persist('list') @observable customListDefinitionEntries: ICustomListEntry[] = [];
  @persist('object', CustomListEntryModel) @observable editingCustomListEntry: CustomListEntryModel;
}
