import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/dom/ajax';
import {AjaxRequestMethodEnum} from '../../enum/ajax-request-method.enum';

/**
 * Isomorphic Observable.ajax request.
 *
 * Code was taken from here: https://github.com/ReactiveX/rxjs/issues/2099#issuecomment-288278741
 * Basically on SSR, ajax calls will not work because it does not know how to make the XHR request
 * This, in theory, is supposed to also work with ssl.
 */
export function isomorphicAjax(options: {
  url: string,
  method: AjaxRequestMethodEnum,
  body?: any,
  headers?: {[key: string]: string},
  crossDomain?: boolean}) {
  if (typeof XMLHttpRequest !== 'undefined') {
    return Observable.ajax(options);
  } else {
    return Observable.ajax({
      createXHR: () => {
        const https = require('https');
        const XHR2 = require('xhr2');
        const xhr = new XHR2();
        // DO NOT DO rejectUnauthorized: false IN PRODUCTION !
        const agent = new https.Agent(process.env.NODE_ENV === 'production' ? {keepAlive: true, keepAliveMsecs: 300, maxFreeSockets: 10000} : {keepAlive: true, keepAliveMsecs: 300, maxFreeSockets: 10000, rejectUnauthorized: false});
        agent.maxSockets = 100000000;
        xhr.nodejsSet({httpsAgent: agent});
        return xhr;
      },
      ...options
    });
  }
}
