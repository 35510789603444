export function joinUrl(...parts: string[]): string {
  return parts.reduce((totalPath, currentPath) => {
    if (!currentPath) {
      return totalPath;
    }
    if (totalPath) {
      if (totalPath[ totalPath.length - 1 ] !== '/' && currentPath[ 0 ] !== '/') {
        totalPath += '/';
      }
      if (totalPath[ totalPath.length - 1 ] === '/' && currentPath[ 0 ] === '/') {
        currentPath = currentPath.slice(1);
      }
      totalPath += currentPath;
      return totalPath;
    } else {
      return currentPath;
    }
  }, '');
}
