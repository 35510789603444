import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { TextImageModel } from '../../../model/block';

const TextImageView = asyncComponent(() => import('./text-image.view').then(c => c.TextImageView));
const TextImageIcon = asyncComponent(() => import('../../icons/block/text-image.icon').then(c => c.TextImageIcon), 'svg');

export const TextImage = getBlock(TextImageModel, BlockGroupEnum.ImageAndVideo, 'block.text-image-title',
  <TextImageIcon/>, TextImageView);
