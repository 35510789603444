import { IEditModeReturnValue } from './return-edit-mode.util';
import { enumPickerEdit } from './enum-picker-edit.util';

export interface IFontEditComponentProps {
}

interface IReturnType {
}

export const fontStylePickerEdit = (componentProps: IFontEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IFontEditComponentProps, string, IReturnType> = enumPickerEdit(componentProps as any)
    .setOptionsGetter((value, data, state) => {
      return (state.configuration.settings.fonts || []).map(font => ({
        label: font.label,
        value: font.id
      }));
    });
  return toReturn;
};
