import { observable } from 'mobx';
import { persist } from 'mobx-persist';

export class ImageModel {
  @persist @observable id: string;
  @persist @observable name: string;
  @persist @observable dimensions: string;
  @persist @observable size: number;
  @persist @observable location: string;
  @persist @observable largeLocation: string;
  @persist @observable smallLocation: string;
  @persist @observable mediumLocation: string;
  @persist @observable thumbnailLocation: string;
  @persist @observable iconLocation: string;
  @persist @observable created: string;
  @persist @observable imageUrl?: string;
}
