import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { ISelectedImage } from '../../interface/component/selected-image.interface';
import { editable } from './decorators/editable.decorator';
import { imagesEdit } from '../../util/block/edit-types/images-edit.util';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { editGroup } from './decorators/edit-group.decorator';
import { htmlEdit } from '../../util/block/edit-types/html-edit.util';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';
import { imageSizeStylePickerEdit } from '../../util/block/edit-types/image-size-style-picker-edit.util';
import { ImageSizeStyleEnum } from '../../enum/image-size-style.enum';

@pageBlock
@customListBlock
@jobadBlock
@webDocumentBlock
@authenticatedBlock
@blockType(BlockTypeEnum.TextImage)
@blockBackgroundColor('PrimaryBackgroundColor')
export class TextImageModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(imagesEdit()
    .label('textImage.edit.images'))
  @observable images: ISelectedImage[] = [];

  @editGroup.STYLE
  @editable(imageSizeStylePickerEdit()
    .label('textImage.edit.images-size-style'))
  @observable imagesSizeStyle: ImageSizeStyleEnum = ImageSizeStyleEnum.Stretch;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label(`textImage.edit.carouselStyle`)
    .addOption('textImage.edit.carouselStyleWide', 'wide')
    .addOption('textImage.edit.carouselStyleNormal', 'normal')
  )
  @observable style: 'wide' | 'normal' = 'normal';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label(`textImage.edit.textBlockAlignment`)
    .addOption('textImage.edit.textBlockAlignmentLeft', 'left')
    .addOption('textImage.edit.textBlockAlignmentRight', 'right')
  )
  @observable textBlockAlignment: 'left' | 'right' = 'left';

  @editGroup.STYLE
  @editable(numberEdit()
    .label(`textImage.edit.presentationTimePlaceholder`)
    .min(1.5)
    .max(30)
  )
  @observable transitionTime: number = 3;

  @editGroup.STYLE
  @editable(numberEdit()
    .label(`textImage.edit.transitionDurationPlaceholder`)
    .min(0.1)
    .max(1.2)
  )
  @observable transitionDuration: number = 1;

  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('textImage.textTitle'))
  @observable text: any = getDraftStateFromString('');
}
