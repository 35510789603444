import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { formMentionPickerEdit } from '../../util/block/edit-types/form-mention-picker-edit.util';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { toggleEdit } from '../../util/block/edit-types/toggle-edit.util';
import { hashMapEdit } from '../../util/block/edit-types/hash-map-edit.util';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { blockType } from './decorators/block-type.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { findMention } from '../../util/page/find-mention.util';
import { BaseBlockDataModel } from './base-block-data.model';
import {enumPickerEdit} from '../../util/block/edit-types/enum-picker-edit.util';
import { AvailableFileTypes } from '../../enum/block/available-file-types.enum';
import { LinkedinLoginTypeEnum } from '../../enum/linkedin-login-type.enum';
import { FormFileUploadModeEnum } from '../../enum/block/form-file-upload-mode.enum';
import { FormLinkedDataModel } from '../linked-data';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { FormCommentModeEnum } from '../../enum/block/form-comment-mode.enum';
import { FormLinkedInModeEnum } from '../../enum/block/form-linkedin-mode.enum';
import {blockLimit} from './decorators/block-limit.decorator';
import countries from '../../util/phone-number/countries';
import {getTranslation} from '../../util/translation/get-translation';

@jobadBlock
@authenticatedBlock
@blockLimit(1)
@blockType(BlockTypeEnum.Form)
export class FormModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(formMentionPickerEdit()
    .label('form.edit.data')
    .placeholder('form.edit.select-data')
    .showRemainingControlsWhen((data) => Boolean(data.selectedMention))
  )
  @observable selectedMention: IUsedDesignObjectMentionMetadata;

  @editGroup.CONTENT
  @editable(plainTextEdit()
      .label('form.edit.show-countries-label'))
  @observable showAllCountriesLabel: any = getDraftStateFromString('Show Countries');

  @editGroup.CONTENT
  @editable(plainTextEdit()
      .label('form.edit.hide-countries-label'))
  @observable hideAllCountriesLabel: any = getDraftStateFromString('Hide Countries');

  @editGroup.CONTENT
  @editable(plainTextEdit()
      .label('form.edit.close-all-countries-label'))
  @observable closeCountryPickerLabel: any = getDraftStateFromString('Close Countries');

  @editGroup.CONTENT
  @editable(enumPickerEdit()
      .label('form.edit.preferred-countries')
      .placeholder('form.edit.select-preferred-countries')
      .setOptionsGetter(() => {
        return countries.map((c) => {
          return {
            label: c.name,
            value: c.countryCode
          };
        });
      }).isMulti()
      .withValidator(value => value.length <= 5 ? [{isValid: true, messageKey: ''}] : [{isValid: false, messageKey: 'form.edit.max-preferred-country-size'}]))
  @observable preferredCountries: string[] = [];

  @editGroup.CONTENT
  @editable(enumPickerEdit()
      .label('form.edit.default-country')
      .placeholder('form.edit.select-default-country')
      .setOptionsGetter((value, data, state) => {
        const returnCountries = countries.map((c) => {
          return {
            label: c.name,
            value: c.countryCode
          };
        });
        returnCountries.unshift({
          label: getTranslation(state.configuration, 'form.edit.unknown-default-country'),
          value: ''
        });
        return returnCountries;
      }))
  @observable defaultCountry: string = '';

  @editGroup.TRANSLATION
  @editable(hashMapEdit()
    .label('form.edit.translations')
    .setGetHashMapKeys((data, state, findLinkedData) => {
      const linkedData = findLinkedData(data.selectedMention);
      return findMention(linkedData.mentionId, state.editor.possibleMentions).dataType.reduce((accumulator, currentField) => {
        const [label, placeholder] = currentField.displayName.split(':');
        accumulator.push(label);
        accumulator.push(placeholder);
        return accumulator;
      }, []);
    })
    .setLabel('form.edit.fieldsTranslation')
  )
  @observable fieldTranslations: any = {};

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.submit-button-text')
    .placeholder('form.submit-button-text-placeholder'))
  @observable submitButtonText: any = getDraftStateFromString('Apply');

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('form.linked-in-enabled-label')
    .visibleWhen((data, state) => state.adminSettings.linkedInLoginType !== LinkedinLoginTypeEnum.None))
  @observable linkedInEnabled: boolean = true;

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.success-message-header'))
  @observable successMessageHeader: any = getDraftStateFromString('Thank you for applying!');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.success-message-body'))
  @observable successMessageBody: any = getDraftStateFromString('You have successfully applied for the job role and we will review your application shortly.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.file-uploading')
    .placeholder('form.file-uploading-placeholder'))
  @observable fileUploadingMessage: any = getDraftStateFromString('Uploading please wait...');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.no-file-chosen'))
  @observable noFileChosenMessage: any = getDraftStateFromString('No file chosen');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.upload-more-files-message'))
  @observable uploadMoreDocumentsMessage: any = getDraftStateFromString('Upload more files');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.upload-file-error-message'))
  @observable uploadFileErrorMessage: any = getDraftStateFromString('Failed to upload file');

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('form.apply-button-background-color'))
  @observable submitButtonBackgroundColor: BlockColorType = 'PrimaryButtonColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('form.submit-button-disabled-background-color'))
  @observable submitButtonBackgroundColorDisabled: BlockColorType = 'PrimaryButtonColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('form.search-icon-color'))
  @observable searchIconColor: BlockColorType = 'PrimaryButtonColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('form.apply-form-input-group-label-color'))
  @observable groupLabelColor: BlockColorType = 'PrimaryButtonColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('form.apply-form-input-label-color'))
  @observable inputLabelColor: BlockColorType = 'PrimaryTextColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('form.success-applied-block-color'))
  @observable successAppliedBlockColor: BlockColorType = 'PrimaryBackgroundColor';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('form.success-applied-box-color'))
  @observable successAppliedBoxColor: BlockColorType = 'ConfirmColor';

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.linked-in-fill-other-info-text')
    .visibleWhen((data, state) => state.adminSettings.linkedInLoginType !== LinkedinLoginTypeEnum.None))
  @observable fillOtherInfoText: any = getDraftStateFromString('Fill in the rest of the application to apply for the job.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.error-fetching-data-message'))
  @observable errorFetchingDataMessage: any = getDraftStateFromString('Error fetching data');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.replace-file-message'))
  @observable replaceFileMessage: any = getDraftStateFromString('Replace file');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.empty-string-message'))
  @observable emptyStringMessage: any = getDraftStateFromString('Provide a non empty string');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.invalid-phone-number-message'))
  @observable invalidPhoneNumberMessage: any = getDraftStateFromString('Provide a valid phone number');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.invalid-email-message'))
  @observable invalidEmailMessage: any = getDraftStateFromString('Provide a valid email address');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.exceeded-max-chars-message'))
  @observable exceededMaxCharsMessage: any = getDraftStateFromString('Exceeded maximum length for a field');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.required-field-message'))
  @observable requiredFieldMessage: any = getDraftStateFromString('Field is required');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.consent-missing-message'))
  @observable consentMissingMessage: any = getDraftStateFromString('Consent is missing from form');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.view.missing-clientedit-message'))
  @observable missingLinkedInClientId: any = getDraftStateFromString('Configure linkedIn properly');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.view.linkedin-login-text-message'))
  @observable linkedInLoginTextMessage: any = getDraftStateFromString('Login with LinkedIN message');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.view.or-message'))
  @observable orMessage: any = getDraftStateFromString('Or');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.view.drop-file-message'))
  @observable dropFileMessage: any = getDraftStateFromString('Drop files here');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.linkedin-current-last-position-text'))
  @observable currentLastPositionText: any = getDraftStateFromString('Current/last position:');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.server-error-title'))
  @observable serverErrorTitle: any = getDraftStateFromString('Oops! something went wrong.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.server-error-body'))
  @observable serverErrorBody: any = getDraftStateFromString('There seems to be something wrong with the server, please try again later.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.already-applied-error-title'))
  @observable alreadyAppliedErrorTitle: any = getDraftStateFromString('Your application did not send.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.already-applied-error-body'))
  @observable alreadyAppliedErrorBody: any = getDraftStateFromString('Sorry, It seems like you have already applied for this job role.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.invalid-file-format-error-title'))
  @observable invalidFileFormatTitle: any = getDraftStateFromString('Your application did not send.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.invalid-file-format-error-body'))
  @observable invalidFileFormatBody: any = getDraftStateFromString('Sorry, It seems like you have attempted to use a format that is not supported for your CV, please use PDF or Microsoft Word for your CV.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.unknown-file-error-title'))
  @observable unknownFileErrorTitle: any = getDraftStateFromString('An upload error occurred');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.unknown-file-error-body'))
  @observable unknownFileErrorBody: any = getDraftStateFromString('An unknown upload error occurred please try again.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.empty-file-upload-title'))
  @observable emptyFileUploadedErrorTitle: any = getDraftStateFromString('You uploaded a empty file.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.empty-file-upload-body'))
  @observable emptyFileUploadedErrorBody: any = getDraftStateFromString('Sorry, It seems like you have attempted to use upload a empty file. Please check the file');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.file-size-exceeded-title'))
  @observable fileSizeExceededErrorTitle: any = getDraftStateFromString('File size exceeded.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.file-size-exceeded-body'))
  @observable fileSizeExceededErrorBody: any = getDraftStateFromString('Sorry, It seems like you have attempted to use upload file with size above accepted limit. Please check the file');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.file-name-invalid-title'))
  @observable fileNameInvalidTitle: any = getDraftStateFromString('File name invalid.');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.file-name-invalid-body'))
  @observable fileNameInvalidBody: any = getDraftStateFromString('Not allowed characters: <>:"/\\|?*');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.yes'))
  @observable yesTranslation: any = getDraftStateFromString('Yes');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.no'))
  @observable noTranslation: any = getDraftStateFromString('No');

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .placeholder('form.edit.consent-header')
    .label('form.edit.consent'))
  @observable consentHeaderText?: any = getDraftStateFromString();

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .placeholder('form.edit.consent-full-short-text-placeholder')
    .label('form.edit.consent-full-short-text'))
  @observable consentFullShortText?: any = getDraftStateFromString();

  @editGroup.CONTENT
  @editable(plainTextEdit()
    .placeholder('form.edit.consent-limited-short-text-placeholder')
    .label('form.edit.consent-limited-short-text'))
  @observable consentLimitedShortText?: any = getDraftStateFromString();

  @editGroup.CONTENT
  @editable(enumPickerEdit()
    .placeholder('form.edit.select-available-file-types')
    .label('form.edit.available-file-types')
    .addOption('form.edit.pdf-type', AvailableFileTypes.PDF)
    .addOption('form.edit.doc-type', AvailableFileTypes.DOC)
    .addOption('form.edit.docx-type', AvailableFileTypes.DOCX)
    .isMulti())
  @observable availableFileTypes: AvailableFileTypes[] = [AvailableFileTypes.PDF, AvailableFileTypes.DOC, AvailableFileTypes.DOCX];

  @editGroup.CONTENT
  @editable(enumPickerEdit()
    .placeholder('form.edit.file-upload-mode-placeholder')
    .label('form.edit.file-upload-mode-label')
    .addOption('form.edit.file-upload-mode.none', FormFileUploadModeEnum.NONE)
    .addOption('form.edit.file-upload-mode.optional', FormFileUploadModeEnum.OPTIONAL)
    .addOption('form.edit.file-upload-mode.mandatory', FormFileUploadModeEnum.MANDATORY)
    .onChange(((value, oldValue, data: FormModel, state, findLinkedData) => {
      const linkedData = findLinkedData(data.selectedMention);
      (linkedData as FormLinkedDataModel).fileUploadMode = value;
      return value;
    }))
  )
  @observable fileUploadMode: FormFileUploadModeEnum = FormFileUploadModeEnum.MANDATORY;

  @editGroup.CONTENT
  @editable(toggleEdit()
    .label('form.edit.force-open-consent-label')
    .onChange(((value, oldValue, data: FormModel, state, findLinkedData) => {
      const linkedData = findLinkedData(data.selectedMention);
      (linkedData as FormLinkedDataModel).forceOpenConsent = value;
      return value;
    }))
  )
  @observable forceOpenConsent: boolean = false;

  @editGroup.CONTENT
  @editable(enumPickerEdit()
    .placeholder('form.edit.comment-mode-placeholder')
    .label('form.edit.comment-mode-label')
    .addOption('form.edit.comment-mode.none', FormCommentModeEnum.NONE)
    .addOption('form.edit.comment-mode.optional', FormCommentModeEnum.OPTIONAL)
    .addOption('form.edit.comment-mode.mandatory', FormCommentModeEnum.MANDATORY)
    .onChange(((value, oldValue, data: FormModel, state, findLinkedData) => {
      const linkedData = findLinkedData(data.selectedMention);
      (linkedData as FormLinkedDataModel).commentMode = value;
      return value;
    }))
  )
  @observable commentMode: FormCommentModeEnum = FormCommentModeEnum.OPTIONAL;

  @editGroup.CONTENT
  @editable(enumPickerEdit()
    .placeholder('form.edit.linkedin-mode-placeholder')
    .label('form.edit.linkedin-mode-label')
    .addOption('form.edit.linkedin-mode.none', FormLinkedInModeEnum.NONE)
    .addOption('form.edit.linkedin-mode.optional', FormLinkedInModeEnum.OPTIONAL)
    .addOption('form.edit.linkedin-mode.mandatory', FormLinkedInModeEnum.MANDATORY)
    .onChange(((value, oldValue, data: FormModel, state, findLinkedData) => {
      const linkedData = findLinkedData(data.selectedMention);
      (linkedData as FormLinkedDataModel).linkedInMode = value;
      return value;
    }))
  )
  @observable linkedInMode: FormLinkedInModeEnum = FormLinkedInModeEnum.OPTIONAL;

  @editGroup.CONTENT
  @editable(numberEdit()
    .placeholder('form.edit.other-files-count-limit-placeholder')
    .label('form.edit.other-files-count-limit-label')
    .min(0)
    .onChange(((value, oldValue, data: FormModel, state, findLinkedData) => {
      const linkedData = findLinkedData(data.selectedMention);
      (linkedData as FormLinkedDataModel).otherFilesCountLimit = value;
      return value;
    }))
  )
  @observable otherFilesCountLimit: number = 10;

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .placeholder('form.edit.consent-confirm-placeholder')
    .label('form.edit.consent-confirm'))
  @observable consentConfirmText?: any = getDraftStateFromString('Confirm');

  @editGroup.TRANSLATION
  @editable(plainTextEdit()
    .label('form.edit.scroll-to-bottom-label'))
  @observable scorllToBottomToEnable?: any = getDraftStateFromString('Read the text and approve at the bottom of the page.');

  @editGroup.CONTENT
  @editable(numberEdit()
    .label('form.edit.file-size-limit')
    .min(0)
    .max(50)
  )
  @observable fileSizeLimit: number = 5;
}
