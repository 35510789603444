import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { LargeHeaderModel } from '../../../model/block';

const LargeHeaderView = asyncComponent(() => import('./large-header.view').then(c => c.LargeHeaderView));
const LargeHeaderIcon = asyncComponent(() => import('../../icons/block/large-header.icon').then(c => c.LargeHeaderIcon), 'svg');

export const LargeHeader = getBlock(LargeHeaderModel, BlockGroupEnum.MenuAndStartPage, 'block.large-header-title',
  <LargeHeaderIcon/>, LargeHeaderView);
