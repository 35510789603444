export function readSessionCookie(getCookieValue: (cookieKey: string) => string, cookie: string) {
  let cookieValue = getCookieValue(cookie);
  if (!cookieValue) {
    cookieValue = '';
  }

  const cookieArray = cookieValue.split(',');
  const redResult = {};
  for (const cookieValueItem of cookieArray) {
    const r = cookieValueItem.split(':');
    if (!r[ 0 ] || r[ 0 ] === '') {
      continue;
    }

    redResult[ r[ 0 ] ] = r[ 1 ];
  }

  return redResult;

}