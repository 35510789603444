import { MentionFilterTypeEnum } from '../../enum/mention-filter-type.enum';

export enum FilterValueTypes {
  NORMAL = 0,
  MENTION = 1
}

export interface IFilterValue {
  filterValueType: FilterValueTypes;
  operator: MentionFilterTypeEnum;
  value: string;
}

export interface IFilter {
  propertyName: string;
  value: IFilterValue[];
}
