import { invariant } from '../debug/invariant.util';

type LoopCallbackType = (currentCycle: number, totalCycles: number, startingCycles: number) => any;

function getCallbackArgs(index, startingValue, endingValue) {
  return [ startingValue + (startingValue > endingValue ? -index : index), Math.max(endingValue, startingValue), Math.min(startingValue, endingValue) ];
}

class Looper {
  private startingValue: number = 0;
  private endingValue: number = 0;
  private step: number = 1;
  private loopArray: number[];

  constructor(startingValue: number, endingValue: number, step: number) {
    this.startingValue = startingValue;
    this.endingValue = endingValue;
    this.step = step;
    const normalizedStart = Math.ceil(this.startingValue / this.step);
    const normalizedEnd = Math.ceil(this.endingValue / this.step);
    this.loopArray = new Array(Math.abs(Math.abs(Math.max(normalizedEnd, normalizedStart) - Math.min(normalizedEnd, normalizedStart))) + 1).fill(0);
    if (this.loopArray.length === 0) {
      this.loopArray.push(0);
    }
    this.loopArray = this.loopArray.map((v, i) => i * this.step);
  }

  get length() {
    return this.loopArray.length;
  }

  filter = (callback: LoopCallbackType) => {
    const returnLooper = new Looper(this.startingValue, this.endingValue, this.step);
    returnLooper.loopArray = returnLooper.loopArray.filter((v) => {
      return callback.apply(callback, getCallbackArgs(v, this.startingValue, this.endingValue));
    });
    return returnLooper;
  }

  slice = (start?: number, end?: number) => {
    const returnLooper = new Looper(this.startingValue, this.endingValue, this.step);
    returnLooper.loopArray = returnLooper.loopArray.slice(start, end);
    return returnLooper;
  }

  pop = () => {
    const index = this.loopArray.pop();
    return this.startingValue + (this.startingValue > this.endingValue ? -index : index);
  }

  shift = () => {
    const index = this.loopArray.shift();
    return this.startingValue + (this.startingValue > this.endingValue ? -index : index);
  }

  map = (callback: LoopCallbackType) => {
    return this.loopArray.map((v) => {
      return callback.apply(callback, getCallbackArgs(v, this.startingValue, this.endingValue));
    });
  }

  forEach = (callback: LoopCallbackType) => {
    return this.loopArray.forEach((v) => {
      return callback.apply(callback, getCallbackArgs(v, this.startingValue, this.endingValue));
    });
  }

  reduce: <T>(callback: (previousValue: T, currentCycle: number, totalCycles: number, startingCycles: number) => any, returnObject: T) => T = (callback, returnObject) => {
    return this.loopArray.reduce((previousValue, v) => {
      return callback.apply(callback, [ previousValue, ...getCallbackArgs(v, this.startingValue, this.endingValue) ]);
    }, returnObject as any);
  }

  reverse = () => {
    return new Looper(this.endingValue, this.startingValue, this.step);
  }
}

export const loop = (fromValue: number, toValue?: number, step: number = 1) => {
  invariant(step > 0, 'Step must be a value greater then 0');
  if (fromValue === undefined) {
    fromValue = 0;
  }
  if (toValue === undefined) {
    toValue = fromValue;
    fromValue = 0;
  }
  return new Looper(fromValue, toValue, step);
};
