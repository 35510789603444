import { asyncComponent } from '../component/async-component/async-component';
import { loadDynamicPage } from '../util/action/type/load-dynamic-page-action.util';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { CUSTOM_LIST_SLUG, JOBAD_DETAILS_SLUG, JOBAD_SLUG } from '../constants';

const HomePage = asyncComponent(() => import('../component/page/frontend/home.page').then((_) => _.HomePage));
const AboutPage = asyncComponent(() => import('../component/page/frontend/about.page').then((_) => _.AboutPage));
const SilentRefresh = asyncComponent(() => import('../component/page/frontend/silent-refresh').then((_) => _.SilentRefresh));
const LogoutPage = asyncComponent(() => import('../component/page/frontend/logout.page').then((_) => _.LogoutPage));

export const FrontendRoutes = (silentLoginCallbackUrl: string) => ([
  {
    path: `/${silentLoginCallbackUrl}`,
    exact: true,
    component: SilentRefresh
  },
  {
    path: `/about`,
    exact: true,
    component: AboutPage
  },
  {
    path: `/logout`,
    exact: true,
    component: LogoutPage
  },
  {
    path: `/${JOBAD_DETAILS_SLUG}/:linkerId`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage, true),
    component: HomePage
  },
  {
    path: `/${JOBAD_DETAILS_SLUG}/:linkerId/preview`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage, true, false),
    component: HomePage
  },
  {
    path: `/${JOBAD_DETAILS_SLUG}/:linkerId/*/preview`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage, true, false),
    component: HomePage
  },
  {
    path: `/${JOBAD_DETAILS_SLUG}/:linkerId/*`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage, true),
    component: HomePage
  },
  {
    path: `/${JOBAD_SLUG}/:linkerId`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage),
    component: HomePage
  },
  {
    path: `/${JOBAD_SLUG}/:linkerId/preview`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage, false, false),
    component: HomePage
  },
  {
    path: `/${JOBAD_SLUG}/:linkerId/*/preview`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage, false, false),
    component: HomePage
  },
  {
    path: `/${JOBAD_SLUG}/:linkerId/*`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.JobAdPage),
    component: HomePage
  },
  {
    path: `/${CUSTOM_LIST_SLUG}/:linkerId/*`,
    exact: true,
    action: loadDynamicPage(DesignTypeEnum.CustomList),
    component: HomePage
  },
  {
    path: `/*`,
    exact: true,
    action: loadDynamicPage(),
    component: HomePage
  }
]);
