import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { stringRequiredValidator } from '../../validators/string-required-validator';

export interface IDateEditComponentProps {
  allowDateEdit?: boolean;
  allowTimeEdit?: boolean;
}

interface IReturnType {
  allowDateEdit: (allowDateEdit: boolean) => IEditModeReturnValue<IDateEditComponentProps, any, IReturnType>;
  allowTimeEdit: (allowTimeEdit: boolean) => IEditModeReturnValue<IDateEditComponentProps, any, IReturnType>;
  required: (errorMessage?: string) => IEditModeReturnValue<IDateEditComponentProps, any, IReturnType>;
}

export const dateEdit = (componentProps: IDateEditComponentProps = {allowDateEdit: true, allowTimeEdit: true} as any) => {
  const toReturn: IEditModeReturnValue<IDateEditComponentProps, any, IReturnType> = returnEditMode<IDateEditComponentProps, any, IReturnType>('date', componentProps) as any;
  toReturn.allowDateEdit = (allowDateEdit: boolean) => {
    toReturn._decoratorProperties.componentProps.allowDateEdit = allowDateEdit;
    return toReturn;
  };
  toReturn.allowTimeEdit = (allowTimeEdit: boolean) => {
    toReturn._decoratorProperties.componentProps.allowTimeEdit = allowTimeEdit;
    return toReturn;
  };
  toReturn.required = (errorMessage) => {
    toReturn.withValidator(stringRequiredValidator(errorMessage));
    return toReturn;
  };
  return toReturn;
};
