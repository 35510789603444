import { matchRoutes } from 'react-router-config';
import { Dispatcher } from '../../interface/action/dispatcher.type';
import { IAction } from '../../interface/action/action.interface';
import { ILocation } from '../../interface/history.interface';

function executeActionWithPath(dispatch: Dispatcher, action: IAction, providedLocation: ILocation, match) {
  const actionPayload = Object.assign({}, action.payload, {location: providedLocation, ...match});
  return dispatch(Object.assign({}, action, {payload: actionPayload}));
}

export function executePathActions(routeConfig, dispatch: Dispatcher, providedLocation: ILocation) {
  const matches = matchRoutes(routeConfig, providedLocation.pathname);
  return Promise.all([ ...matches.map((match) => {
    const {actions, action} = match.route;
    if (action) {
      return executeActionWithPath(dispatch, action, providedLocation, match);
    } else if (actions) {
      return Promise.all(actions.map((currentAction) => {
        return executeActionWithPath(dispatch, currentAction, providedLocation, match);
      }));
    }
    return undefined;
  }), Promise.resolve() ]);
}
