import { BaseService } from './base-service';
import { CustomListDefinitionModel } from '../model/custom-lists/custom-list-definition.model';
import { toJS } from 'mobx';
import { joinUrl } from '../util/url/join-url.util';

export class CustomListDefinitionService extends BaseService {
  baseURL = 'customListDefinitions';

  saveCustomListDefinition = (listDefinition: CustomListDefinitionModel) => {
    const listDefData = toJS(listDefinition);
    delete listDefData.isSaved;
    const toSave = JSON.stringify(listDefData);
    if (listDefinition.isSaved) {
      return this.put('/', undefined, toSave);
    }
    return this.post('/', undefined, toSave);
  }

  getAllLists = () => {
    return this.get('/');
  }

  deleteList = (id: string) => {
    return this.delete('/', {id});
  }

  getListById = (id: string) => {
    return this.get(id);
  }

  getMetadata = (definitionId: string) => {
    return this.get(joinUrl('metadata', definitionId));
  }
}
