import { observable } from 'mobx';
import { persist } from 'mobx-persist';
import { BlockColorType } from '../../enum/block-color.enum';
import { ICookiePolicySettings } from '../../interface/cookie-policy-settings.interface';
import { ILink } from '../../interface/page/link.interface';

export class CookiePolicyModel implements ICookiePolicySettings {
  @persist @observable cookieWarningBackgroundColor: BlockColorType = 'PrimaryBackgroundColor';
  @persist @observable cookieWarningFontColor: BlockColorType = 'PrimaryTextColor';
  @persist @observable cookieWarningButtonColor: BlockColorType = 'PrimaryButtonColor';
  @persist @observable cookieWarningButtonText: string = 'I understand';
  @persist @observable cookieWarningText: string = 'To get the best experienceTo get the best experienceTo get the best experienceTo get the best experience on this website we use cookies';
  @persist('object') @observable cookieWarningLink: ILink = {} as any;
  @persist @observable cookieWarningOpacity: number = 0.9;
}
