import { LinkedDataModel } from '../../model/linked-data/linked-data.model';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { invariant } from '../debug/invariant.util';

export const findLinkedDataAsyncDataToResolve = (linkedData: LinkedDataModel[]) => {
  invariant(linkedData !== undefined, 'You must supply linked data');
  const mentionsToResolve: IUsedDesignObjectMentionMetadata[] = [];
  linkedData.forEach((data) => {
    if (data.initialAsyncData && data.isEmpty) {
      mentionsToResolve.push(Object.assign({}, data, {_data: undefined, data: undefined, isBlockData: false}));
    }
  });
  return mentionsToResolve;
};
