import { DesignTypeEnum } from '../enum/design-type.enum';
import { filterObjectKeys } from '../util/object/filter-object-keys.util';
import { persist } from 'mobx-persist';
import { computed, observable } from 'mobx';
import {isNumberString} from '../util/number/is-number-string.util';

export interface IParams {
  [ key: string ]: number | string;
}

export class PageLoadParametersModel {
  @persist @observable type: DesignTypeEnum;
  @persist @observable slug: string;
  @persist @observable slugSEO: string;
  @persist('object') @observable params: IParams = {};

  // filter parameters are only named load parameters
  @computed
  get filterParameters(): IParams {
    return filterObjectKeys(this.params, (key) => !isNumberString(key));
  }
}
