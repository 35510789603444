import { css } from 'styled-components';
import { SMALL_SCREEN_SIZE } from '../../constants';

export const largeMobileMedia = (style: string) => {
  return css`
      @media (max-width: ${SMALL_SCREEN_SIZE}px) {
        ${style}
      }
    `;
};
