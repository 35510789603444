import { PropertyEditTypesType } from '../../../enum/property-edit-types.enum';
import { ChangeFunction, IActionsOnChangePayload, IEditConfig } from '../../../interface/block/edit-config.interface';
import { DesignObjectModel } from '../../../model/design-object.model';
import { flatten } from '../../array/flatten.util';
import { ValidatorFunction } from '../../../interface/validator-function.type';
import { IAction } from '../../../interface/action/action.interface';
import { AppStateModel } from '../../../model/app-state.model';
import { ROOTED_LINKED_DATA_ID_PREFIX } from '../../../constants';

interface IEditModeReturnValueInternal<T, C = any, R = {}> {
  _decoratorProperties: IEditConfig<T, C>;
  _fetchDecoratorProperties: (key: string) => IEditConfig<T, C>;
  fetchProperties: (key: string) => IEditConfig<T, C>;
  withValidator: (validator: ValidatorFunction<C>) => IEditModeReturnValue<T, C, R> & R;
  onChange: (changeFunction: ChangeFunction<C>) => IEditModeReturnValue<T, C, R> & R;
  onParameter: (parameter: string) => IEditModeReturnValue<T, C, R> & R;
  setUsedMentionsReducer: (usedMentionsReducer: IEditConfig<T, C>['usedMentionsReducer']) => IEditModeReturnValue<T, C, R> & R;
  addDataMentionsReducer: (dataMentionsReducer: IEditConfig<T, C>['dataMentionsReducer']) => IEditModeReturnValue<T, C, R> & R;
  visibleWhen: (predicate: IEditConfig<T, C>['isVisible']) => IEditModeReturnValue<T, C, R> & R;
  showRemainingControlsWhen: (predicate: IEditConfig<T, C>['showRemainingControls']) => IEditModeReturnValue<T, C, R> & R;
  disabledWhen: (predicate: IEditConfig<T, C>['isDisabled']) => IEditModeReturnValue<T, C, R> & R;
  actionsOnChange: <APayload = any>(actionsOnChange?: IAction<IActionsOnChangePayload<C> & APayload>[]) => IEditModeReturnValue<T, C, R> & R;
  label: (labelKey: string) => IEditModeReturnValue<T, C, R> & R;
  placeAfter: (field: string) => IEditModeReturnValue<T, C, R> & R;
  labelDirection: (labelDirection: 'vertical' | 'horizontal') => IEditModeReturnValue<T, C, R> & R;
}

export type IEditModeReturnValue<T, C = any, R = {}> = IEditModeReturnValueInternal<T, C, R> & R;

export const returnEditMode = <T = any, C = any, R = {}>(type: PropertyEditTypesType, componentProps: T = {} as any) => {
  const dataMentionReducer: IEditConfig<T, C>['usedMentionsReducer'] = () => [];
  const returnValue: IEditModeReturnValue<T, C, R> = {
    _decoratorProperties: {
      type,
      componentProps,
      labelDirection: 'vertical',
      label: '',
      dataMentionsReducer: () => [],
      _dataMentionsReducer: dataMentionReducer
    }
  } as any;
  returnValue.label = (labelKey) => {
    returnValue._decoratorProperties.label = labelKey;
    return returnValue;
  };
  returnValue.placeAfter = field => {
    returnValue._decoratorProperties.placeAfter = field;
    return returnValue;
  };
  returnValue.labelDirection = (labelDirection) => {
    returnValue._decoratorProperties.labelDirection = labelDirection;
    return returnValue;
  };
  returnValue.onParameter = (parameter: string) => {
    returnValue._decoratorProperties.parameter = parameter;
    return returnValue;
  };
  returnValue.onChange = (changeFunction: ChangeFunction<C>) => {
    returnValue._decoratorProperties.onChange = changeFunction;
    return returnValue;
  };
  returnValue.visibleWhen = (predicate) => {
    returnValue._decoratorProperties.isVisible = predicate;
    return returnValue;
  };
  returnValue.showRemainingControlsWhen = (predicate) => {
    returnValue._decoratorProperties.showRemainingControls = predicate;
    return returnValue;
  };
  returnValue.disabledWhen = (predicate) => {
    returnValue._decoratorProperties.isDisabled = predicate;
    return returnValue;
  };
  returnValue.withValidator = (validator) => {
    if (!returnValue._decoratorProperties._validators) {
      returnValue._decoratorProperties._validators = [];
    }
    returnValue._decoratorProperties._validators.push(validator);
    return returnValue;
  };
  returnValue._decoratorProperties.validate = (value: any, oldValue: any, data: C, config: IEditConfig, state: AppStateModel) => {
    if (!returnValue._decoratorProperties._validators || returnValue._decoratorProperties._validators.length === 0) {
      return [];
    }
    return flatten(returnValue._decoratorProperties._validators.map((validator) => validator(value, oldValue, data, config, state)));
  };
  returnValue._decoratorProperties.usedMentionsReducer = (data: any, design: DesignObjectModel, findLinkedData) => {
    let finalData = data;
    if (finalData && returnValue._decoratorProperties.parameter) {
      finalData = finalData[ returnValue._decoratorProperties.parameter ];
    }
    if (!finalData) {
      return [];
    }
    return (returnValue._decoratorProperties as any)._dataMentionsReducer(finalData, design, findLinkedData).filter((m) => !m.isBlockData || m.id.indexOf(ROOTED_LINKED_DATA_ID_PREFIX) === 0);
  };
  returnValue.setUsedMentionsReducer = (dataMentionsReducer) => {
    (returnValue._decoratorProperties as any)._dataMentionsReducer = dataMentionsReducer;
    return returnValue;
  };
  returnValue.addDataMentionsReducer = (dataMentionsReducer) => {
    returnValue._decoratorProperties.dataMentionsReducer = (value, data, editor, possibleBlockMentions, findLinkedData) => {
      const mentions = dataMentionsReducer(value, data, editor, possibleBlockMentions, findLinkedData);
      if (!mentions) {
        return [];
      }
      return mentions.map(m => {
        return Object.assign({}, m, {isBlockData: true});
      });
    };
    return returnValue;
  };
  returnValue.actionsOnChange = (actionsOnChange: IAction<IActionsOnChangePayload<C>>[] = []) => {
    returnValue._decoratorProperties._actionsOnChange = actionsOnChange;
    return returnValue;
  };
  returnValue.addDataMentionsReducer((value, data, editor, possibleBlockMentions) => possibleBlockMentions);
  returnValue.fetchProperties = () => returnValue._decoratorProperties;
  returnValue._fetchDecoratorProperties = (key) => returnValue.fetchProperties(key);
  // Default values
  returnValue._decoratorProperties.isDisabled = () => false;
  returnValue._decoratorProperties._actionsOnChange = [];
  return returnValue;
};
