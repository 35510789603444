import { Logger } from '../debug/logger.util';
import { processSignInResponseCallback } from './process-sign-in-response-callback.util';
import { ConfigurationModel } from '../../model/configuration.model';

let silentRefreshPromise;

export function refreshTokenSilent(configuration: ConfigurationModel, client) {
  if (!silentRefreshPromise) {
    silentRefreshPromise = client.signinSilent().then((response) => {
      silentRefreshPromise = undefined;
      processSignInResponseCallback(configuration, () => undefined)(response);
    }).catch((err) => {
      silentRefreshPromise = undefined;
      Logger.log(err);
    });
  }

  return silentRefreshPromise;
}
