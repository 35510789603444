import { IDesignObjectData } from '../../interface/design-object-data.interface';
import { DesignObjectModel } from '../../model/design-object.model';
import { DesignTypeEnum } from '../../enum/design-type.enum';
import { PageModel } from '../../model/page.model';
import { IPageData } from '../../interface/page/page-data.interface';
import { EmailModel } from '../../model/email.model';
import { IEmailData } from '../../interface/page/email-data.interface';
import { WebDocumentModel } from '../../model/web-document.model';
import { IWebDocumentData } from '../../interface/page/web-document-data.interface';
import { invariant } from '../debug/invariant.util';
import { CustomListModel } from '../../model/custom-list.model';
import { ICustomListData } from '../../interface/page/custom-list-data.interface';

export function createDesignObject(designObjectData: IDesignObjectData, inAdmin: boolean, isDraft?: boolean): DesignObjectModel {
  if (!designObjectData) {
    return undefined;
  }
  if (designObjectData.type === DesignTypeEnum.JobAdPage || designObjectData.type === DesignTypeEnum.Page) {
    return new PageModel(designObjectData as IPageData, inAdmin, isDraft);
  } else if (designObjectData.type === DesignTypeEnum.CustomList) {
    return new CustomListModel(designObjectData as ICustomListData, inAdmin, isDraft);
  } else if (designObjectData.type === DesignTypeEnum.Email) {
    return new EmailModel(designObjectData as IEmailData, inAdmin, isDraft);
  } else if (designObjectData.type === DesignTypeEnum.WebDocument) {
    return new WebDocumentModel(designObjectData as IWebDocumentData, inAdmin, isDraft);
  }
  invariant(false, 'Invalid design for type', designObjectData.type);
}
