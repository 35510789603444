import { uniq } from 'lodash';
import { observable, toJS } from 'mobx';
import { persist } from 'mobx-persist';
import * as Draft from 'draft-js';
import { convertFromRaw, EditorState } from 'draft-js';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { IEmailData } from '../interface/page/email-data.interface';
import { assignDesignObjectValue, DesignObjectModel } from './design-object.model';
import { editGroup } from './block/decorators/edit-group.decorator';
import { editable } from './block/decorators/editable.decorator';
import { plainTextEdit } from '../util/block/edit-types/plain-text-edit.util';
import { findEntitiesInState } from '../util/draft/find-entities-in-editor-state.util';
import { flatten } from '../util/array/flatten.util';
import { getDraftStateFromString } from '../util/block/get-draft-state-from-string.util';

export class EmailModel extends DesignObjectModel implements IEmailData {
  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('email-editor-settings.subject')
    .placeholder('email-editor-settings.subject_placeholder')
  )
  @persist('object') @(observable.shallow) subject: Draft.RawDraftContentState = getDraftStateFromString('');

  get usedMentions(): string[] {
    let subjectMentions = [];
    if (this.subject) {
      const state = EditorState.createWithContent(convertFromRaw(toJS(this.subject)));
      const entities = findEntitiesInState(state, 'MENTION');
      subjectMentions = entities.map((foundEntity) => foundEntity.entity.getData().id);
    }
    return uniq(flatten(this.blocks.map((block) => block.getUsedMentions(this).slice())).concat(subjectMentions));
  }

  constructor(emailData?: IEmailData, inAdmin?: boolean, isDraft: boolean = false) {
    super(emailData, inAdmin, isDraft);
    if (emailData) {
      assignDesignObjectValue(this, emailData, 'subject');
    }
    this.type = DesignTypeEnum.Email;
  }
}
