import {Logger} from '../debug/logger.util';
import {getBlockByType} from '../../enum/block/block-by-type.enum';
import {BaseBlockDataModel} from '../../model/block/base-block-data.model';
import {AuthenticatedBlockModel} from '../../model/block/authenticated-block.model';
import {IBlock} from '../../interface/block/block.interface';
import {BlockModel} from '../../model/block/block.model';
import {isContainerBlock} from './is-container-block.util';

export function createBlock(blockData: IBlock): BlockModel {
  let CurrentBlockModel = getBlockByType(blockData.blockType as any);
  if (!CurrentBlockModel) {
    Logger.warn(`Block type ${blockData.blockType} does not exist, creating base block.`);
    CurrentBlockModel = BaseBlockDataModel as any;
  }

  const newModel = Object.assign((CurrentBlockModel.authenticatedBlock ? new AuthenticatedBlockModel() : new BlockModel()), blockData);
  newModel.data = Object.assign(new CurrentBlockModel(), blockData.data);
  if (CurrentBlockModel.backgroundColor && !newModel.backgroundColor) {
    newModel.backgroundColor = CurrentBlockModel.backgroundColor;
  }

  if (isContainerBlock(newModel)) {
    newModel.data.blocks = newModel.data.blocks.map(block => createBlock(block));
  }
  return newModel;
}
