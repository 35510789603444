import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { SocialShareButtonsModel } from '../../../model/block';

const SocialShareButtonsView = asyncComponent(() => import('./social-share-buttons.view').then(c => c.SocialShareButtonsView));
const SocialButtonsIcon = asyncComponent(() => import('../../icons/block/social-buttons.icon').then(c => c.SocialButtonsIcon), 'svg');

export const SocialShareButtons = getBlock(SocialShareButtonsModel, BlockGroupEnum.MenuAndStartPage, 'block.social-share-buttons-title',
  <SocialButtonsIcon/>, SocialShareButtonsView);
