import { BaseService } from './base-service';

export class MaintainanceService extends BaseService {
  baseURL = 'maintainance';

  warmupCache = () => {
    return this.get('warmup-cache', { refreshContent: true }, {
      ['X-IsActiveSite']: 'true'
    });
  }

  ping = () => {
    return this.get('ping', undefined, {
      ['X-IsActiveSite']: 'true'
    });
  }
}
