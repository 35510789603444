import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export class ErrorBoundary extends React.Component {
  @observable currentError;

  @action
  componentDidCatch(error, info) {
    this.currentError = {
      error, info
    };
  }

  @action
  componentWillReceiveProps() {
    this.currentError = undefined;
  }

  render() {
    if (this.currentError) {
      return <div
        style={{
          textAlign: 'center',
          background: '#CB0044',
          color: '#FFF',
          padding: 10
        }}
      >
        {this.currentError.error.message}
        <div style={{display: 'none'}}>
          {this.currentError.info.componentStack}
        </div>
      </div>;
    }
    return this.props.children;
  }
}
