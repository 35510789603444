import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { IGetPageBySlugPayload } from '../../../action/http.action';
import { DesignTypeEnum } from '../../../enum/design-type.enum';

export const getPageBySlug = (slug: string, useCache: boolean, type: DesignTypeEnum, filterParameters?: any) => returnAction<IGetPageBySlugPayload>(ActionEnum.GET_PAGE_BY_SLUG, {
  slug,
  type,
  filterParameters,
  useCache
});
