import * as React from 'react';
import styled from 'styled-components';

interface IBaseSocialShareIconProps {
  backgroundColor: string;
}

export const IconWrapper = styled.div`
  padding:10px;
  display: inline-block;
  background-color: ${(props: IBaseSocialShareIconProps) => props.backgroundColor};
`;

export const BaseSocialShareIcon = (props) => (
  <IconWrapper backgroundColor={props.backgroundColor}>
    {props.children}
  </IconWrapper>
);
