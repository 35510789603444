import { persist } from 'mobx-persist';
import {action, observable, reaction} from 'mobx';
import { PageLoadParametersModel } from './page-parameters.model';
import { IDesignObjectData } from '../interface/design-object-data.interface';
import { DesignObjectModel } from './design-object.model';
import { custom, serializable } from 'serializr';
import { PageModel } from './page.model';
import { isPageModel } from '../util/design-object/is-page-model.util';
import { isEmailModel } from '../util/design-object/is-email-model.util';
import { EmailModel } from './email.model';
import { isWebDocumentModel } from '../util/design-object/is-web-document-model.util';
import { WebDocumentModel } from './web-document.model';
import { createDesignObject } from '../util/design-object/create-design-object.util';
import { convertDesignObjectToServerData } from '../util/page/convert-page-to-server-data.util';
import { BlockModel } from './block/block.model';
import {findParentBlock} from '../util/block/find-parent-block.util';
import {findLinkedDataById} from '../util/page/find-linked-data-by-id.util';
import {DataSetLinkedDataModel} from './linked-data';
import {recursiveAddParentId} from '../util/block/recursive-add-parent-id.util';
import StoreProvider from './store-provider';
import {BaseContainerBlockModel} from './block/base-container-block.model';

const DesignObjectSerializationFunction = (design: DesignObjectModel) => {
  return convertDesignObjectToServerData(design);
};

const DesignObjectDeseralizationFunction = (designObjectData: any) => {
  return createDesignObject(designObjectData, false);
};

export class DesignObjectGroupModel {
  @persist('object') @observable lastDataSyncState: any;
  @persist('object') previousData?: IDesignObjectData;
  @persist templateId: string;
  @observable selectedBlock: BlockModel;
  @persist('object', PageLoadParametersModel) @observable loadParams: PageLoadParametersModel = new PageLoadParametersModel();
  @observable @serializable(custom(DesignObjectSerializationFunction, DesignObjectDeseralizationFunction)) private _data: DesignObjectModel;

  constructor() {
    reaction(() => {
        return this.selectedBlock;
      }, () => {
        if (!this.selectedBlock) {
          return;
        }
        const parentBlock = findParentBlock(this.data, this.selectedBlock);
        if (parentBlock) {
          const parentData = parentBlock.data as BaseContainerBlockModel;
          const parentLinkedData = parentData.getUsedMentionsNotRecursivly(this.data).map(usedMentionId => findLinkedDataById(usedMentionId, this.data));
          const selectedDatasetInParent: DataSetLinkedDataModel = parentLinkedData.find(ld => ld instanceof DataSetLinkedDataModel) as any;
          StoreProvider.stores.services().templateService.getTemplateMetadataForGroup(selectedDatasetInParent.mentionId, selectedDatasetInParent.dataSetConfig.groupId)
            .toPromise()
            .then(action((r: any) => {
              if (this.selectedBlock) {
                this.selectedBlock.possibleMentions = recursiveAddParentId(r.response, selectedDatasetInParent.id);
              }
            }));
        }
    });
  }

  get data(): DesignObjectModel {
    return this._data;
  }

  get page(): PageModel {
    if (isPageModel(this._data)) {
      return this._data;
    }
    return null;
  }

  get email(): EmailModel {
    if (isEmailModel(this._data)) {
      return this._data;
    }
    return null;
  }

  get webDocument(): WebDocumentModel {
    if (isWebDocumentModel(this._data)) {
      return this._data;
    }
    return null;
  }

  setDesignData(data: IDesignObjectData, inAdmin: boolean, isDraft = false) {
    this._data = createDesignObject(data, inAdmin, isDraft);
  }
}
