import * as React from 'react';
import { BaseBlockView } from '../base-block.view';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../../interface/component/injected-props.interface';
import { draftToString } from '../../../util/draft/draft-to-string.util';
import { getLinkFinalURL } from '../../../util/url/get-link-final-url';
import styled from 'styled-components';
import { CmsButton } from '../../cms-button/cms-button';
import { ButtonBlockModel } from '../../../model/block';
import { getCenterOfPageStyle } from '../../../util/style/get-center-of-page-style';
import { DesignTypeEnum } from '../../../enum/design-type.enum';

interface IButtonWrapperProps {
  alignment: 'left' | 'right' | 'center';
  background: string;
}

const alignmentMap: { [key in IButtonWrapperProps['alignment']]: string } = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

// @ts-ignore
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${(props: IButtonWrapperProps) => alignmentMap[props.alignment]};
  background: ${(props: IButtonWrapperProps) => props.background};
  padding: 0 13px;
  ${getCenterOfPageStyle()}
`;

@inject('state', 'history')
@observer
export class ButtonView extends BaseBlockView<ButtonBlockModel, IInjectedProps> {
  render() {
    const { content, state, history } = this.props;

    let url = getLinkFinalURL(state, content.link, undefined, true);
    // Append query string if it is a job ad page. Read more: https://intelliplanab.visualstudio.com/Sweden/_sprints/taskboard/TeamGrowth/Sweden/Sprint%202024-05?workitem=3094
    if (state.activeDesign.page.type === DesignTypeEnum.JobAdPage) {
      url += `${history.location.search}`;
    }

    return (
      <ButtonWrapper
        background={state.colors.getColor(content.buttonBackgroundColor)}
        alignment={content.alignment}
      >
        <CmsButton
          text={draftToString(content.label, state)}
          color={content.buttonColor}
          to={url}
        />
      </ButtonWrapper>
    );
  }
}
