import {IDesignObjectMentionMetadata} from '../../interface/page/design-object-mention-metadata.interface';

export const recursiveAddParentId = (mentions: IDesignObjectMentionMetadata[], parentId: string): IDesignObjectMentionMetadata[] => {
  return mentions.map((mention) => {
    const newMention = Object.assign({}, mention);
    newMention.parentId = parentId;
    if (newMention.children && newMention.children.length > 0) {
      newMention.children = recursiveAddParentId(newMention.children, parentId);
    }
    return newMention;
  });
};