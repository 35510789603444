/* tslint:disable:max-classes-per-file */
import * as React from 'react';
import { SnippetContext } from './snippet-context';
import { getUniqueId } from '../../util/string/get-unique-id.util';
import { SnippetsCollectorContext } from './snippets-collector';
import { invariant } from '../../util/debug/invariant.util';
import { isValidUrl } from '../../util/url/is-valid-url.util';
import { SchemaORG } from '../../interface/schema.org';

interface ISnippetProps {
  children: any;
}

type ReplaceID<T extends SchemaORG.Thing, C = SchemaORG.ExcludeType<T>> =
  Pick<C, Exclude<keyof C, '@id'>>
  & { id: SchemaORG.SchemaURL };

class SnippetC extends React.Component<ISnippetProps> {
  private _id = getUniqueId();

  render() {
    return <SnippetContext.Consumer>{(snippetCollector: SnippetsCollectorContext) => {
      snippetCollector.addSnippet(this._id, this.props.children);
      return null;
    }}</SnippetContext.Consumer>;
  }
}

const getClassWithProps = <T extends SchemaORG.Thing>(snippetType: SchemaORG.AllSnippets) => class ReturnClass extends React.Component<ReplaceID<T>> {
  render() {
    const childrenData: any = Object.assign({}, this.props);
    const id = childrenData.id;
    delete childrenData.id;
    invariant(isValidUrl(id), 'Ids of snippets must be a valid URL (this is the page that uniquely identifies this resource) if no unique page exists, add a #typeOfSnippet to the end of the page where the snippet is.', id);
    childrenData[ '@id' ] = id;
    return <SnippetC>
      {Object.assign({[ '@type' ]: snippetType}, childrenData)}
    </SnippetC>;
  }
};

export const Snippet = {
  MedicalEntity: getClassWithProps<SchemaORG.MedicalEntity>('MedicalEntity'),
  LocationFeatureSpecification: getClassWithProps<SchemaORG.LocationFeatureSpecification>('LocationFeatureSpecification'),
  PropertyValue: getClassWithProps<SchemaORG.PropertyValue>('PropertyValue'),
  MonetaryAmountDistribution: getClassWithProps<SchemaORG.MonetaryAmountDistribution>('MonetaryAmountDistribution'),
  QuantitativeValue: getClassWithProps<SchemaORG.QuantitativeValue>('QuantitativeValue'),
  PostalAddress: getClassWithProps<SchemaORG.PostalAddress>('PostalAddress'),
  ContactPoint: getClassWithProps<SchemaORG.ContactPoint>('ContactPoint'),
  OpeningHoursSpecification: getClassWithProps<SchemaORG.OpeningHoursSpecification>('OpeningHoursSpecification'),
  GeoShape: getClassWithProps<SchemaORG.GeoShape>('GeoShape'),
  MonetaryAmount: getClassWithProps<SchemaORG.MonetaryAmount>('MonetaryAmount'),
  RepaymentSpecification: getClassWithProps<SchemaORG.RepaymentSpecification>('RepaymentSpecification'),
  TypeAndQuantityNode: getClassWithProps<SchemaORG.TypeAndQuantityNode>('TypeAndQuantityNode'),
  WarrantyPromise: getClassWithProps<SchemaORG.WarrantyPromise>('WarrantyPromise'),
  OwnershipInfo: getClassWithProps<SchemaORG.OwnershipInfo>('OwnershipInfo'),
  StructuredValue: getClassWithProps<SchemaORG.StructuredValue>('StructuredValue'),
  Property: getClassWithProps<SchemaORG.Property>('Property'),
  Class: getClassWithProps<SchemaORG.Class>('Class'),
  QualitativeValue: getClassWithProps<SchemaORG.QualitativeValue>('QualitativeValue'),
  DayOfWeek: getClassWithProps<SchemaORG.DayOfWeek>('DayOfWeek'),
  EventStatusType: getClassWithProps<SchemaORG.EventStatusType>('EventStatusType'),
  PhysicalActivityCategory: getClassWithProps<SchemaORG.PhysicalActivityCategory>('PhysicalActivityCategory'),
  ItemListOrderType: getClassWithProps<SchemaORG.ItemListOrderType>('ItemListOrderType'),
  OfferItemCondition: getClassWithProps<SchemaORG.OfferItemCondition>('OfferItemCondition'),
  PaymentMethod: getClassWithProps<SchemaORG.PaymentMethod>('PaymentMethod'),
  ItemAvailability: getClassWithProps<SchemaORG.ItemAvailability>('ItemAvailability'),
  DeliveryMethod: getClassWithProps<SchemaORG.DeliveryMethod>('DeliveryMethod'),
  BusinessFunction: getClassWithProps<SchemaORG.BusinessFunction>('BusinessFunction'),
  BusinessEntityType: getClassWithProps<SchemaORG.BusinessEntityType>('BusinessEntityType'),
  WarrantyScope: getClassWithProps<SchemaORG.WarrantyScope>('WarrantyScope'),
  MapCategoryType: getClassWithProps<SchemaORG.MapCategoryType>('MapCategoryType'),
  ContactPointOption: getClassWithProps<SchemaORG.ContactPointOption>('ContactPointOption'),
  Specialty: getClassWithProps<SchemaORG.Specialty>('Specialty'),
  GenderType: getClassWithProps<SchemaORG.GenderType>('GenderType'),
  ActionStatusType: getClassWithProps<SchemaORG.ActionStatusType>('ActionStatusType'),
  MusicAlbumProductionType: getClassWithProps<SchemaORG.MusicAlbumProductionType>('MusicAlbumProductionType'),
  MusicAlbumReleaseType: getClassWithProps<SchemaORG.MusicAlbumReleaseType>('MusicAlbumReleaseType'),
  MusicReleaseFormatType: getClassWithProps<SchemaORG.MusicReleaseFormatType>('MusicReleaseFormatType'),
  Enumeration: getClassWithProps<SchemaORG.Enumeration>('Enumeration'),
  AggregateRating: getClassWithProps<SchemaORG.AggregateRating>('AggregateRating'),
  Rating: getClassWithProps<SchemaORG.Rating>('Rating'),
  Audience: getClassWithProps<SchemaORG.Audience>('Audience'),
  Duration: getClassWithProps<SchemaORG.Duration>('Duration'),
  Distance: getClassWithProps<SchemaORG.Distance>('Distance'),
  Quantity: getClassWithProps<SchemaORG.Quantity>('Quantity'),
  Offer: getClassWithProps<SchemaORG.Offer>('Offer'),
  LoanOrCredit: getClassWithProps<SchemaORG.LoanOrCredit>('LoanOrCredit'),
  FinancialProduct: getClassWithProps<SchemaORG.FinancialProduct>('FinancialProduct'),
  BroadcastService: getClassWithProps<SchemaORG.BroadcastService>('BroadcastService'),
  CableOrSatelliteService: getClassWithProps<SchemaORG.CableOrSatelliteService>('CableOrSatelliteService'),
  Service: getClassWithProps<SchemaORG.Service>('Service'),
  ServiceChannel: getClassWithProps<SchemaORG.ServiceChannel>('ServiceChannel'),
  Brand: getClassWithProps<SchemaORG.Brand>('Brand'),
  SpeakableSpecification: getClassWithProps<SchemaORG.SpeakableSpecification>('SpeakableSpecification'),
  MediaSubscription: getClassWithProps<SchemaORG.MediaSubscription>('MediaSubscription'),
  OfferCatalog: getClassWithProps<SchemaORG.OfferCatalog>('OfferCatalog'),
  BreadcrumbList: getClassWithProps<SchemaORG.BreadcrumbList>('BreadcrumbList'),
  ItemList: getClassWithProps<SchemaORG.ItemList>('ItemList'),
  ProgramMembership: getClassWithProps<SchemaORG.ProgramMembership>('ProgramMembership'),
  Demand: getClassWithProps<SchemaORG.Demand>('Demand'),
  Occupation: getClassWithProps<SchemaORG.Occupation>('Occupation'),
  AlignmentObject: getClassWithProps<SchemaORG.AlignmentObject>('AlignmentObject'),
  Trip: getClassWithProps<SchemaORG.Trip>('Trip'),
  DataFeedItem: getClassWithProps<SchemaORG.DataFeedItem>('DataFeedItem'),
  EntryPoint: getClassWithProps<SchemaORG.EntryPoint>('EntryPoint'),
  BroadcastFrequencySpecification: getClassWithProps<SchemaORG.BroadcastFrequencySpecification>('BroadcastFrequencySpecification'),
  BroadcastChannel: getClassWithProps<SchemaORG.BroadcastChannel>('BroadcastChannel'),
  JobPosting: getClassWithProps<SchemaORG.JobPosting>('JobPosting'),
  Intangible: getClassWithProps<SchemaORG.Intangible>('Intangible'),
  ImageObject: getClassWithProps<SchemaORG.ImageObject>('ImageObject'),
  AudioObject: getClassWithProps<SchemaORG.AudioObject>('AudioObject'),
  DataDownload: getClassWithProps<SchemaORG.DataDownload>('DataDownload'),
  VideoObject: getClassWithProps<SchemaORG.VideoObject>('VideoObject'),
  MediaObject: getClassWithProps<SchemaORG.MediaObject>('MediaObject'),
  NewsArticle: getClassWithProps<SchemaORG.NewsArticle>('NewsArticle'),
  Article: getClassWithProps<SchemaORG.Article>('Article'),
  Review: getClassWithProps<SchemaORG.Review>('Review'),
  SchemaMap: getClassWithProps<SchemaORG.SchemaMap>('SchemaMap'),
  Photograph: getClassWithProps<SchemaORG.Photograph>('Photograph'),
  AboutPage: getClassWithProps<SchemaORG.AboutPage>('AboutPage'),
  WebPage: getClassWithProps<SchemaORG.WebPage>('WebPage'),
  WebPageElement: getClassWithProps<SchemaORG.WebPageElement>('WebPageElement'),
  Answer: getClassWithProps<SchemaORG.Answer>('Answer'),
  CorrectionComment: getClassWithProps<SchemaORG.CorrectionComment>('CorrectionComment'),
  SchemaComment: getClassWithProps<SchemaORG.SchemaComment>('SchemaComment'),
  Question: getClassWithProps<SchemaORG.Question>('Question'),
  SoftwareApplication: getClassWithProps<SchemaORG.SoftwareApplication>('SoftwareApplication'),
  DataFeed: getClassWithProps<SchemaORG.DataFeed>('DataFeed'),
  Dataset: getClassWithProps<SchemaORG.Dataset>('Dataset'),
  DataCatalog: getClassWithProps<SchemaORG.DataCatalog>('DataCatalog'),
  WebSite: getClassWithProps<SchemaORG.WebSite>('WebSite'),
  MusicComposition: getClassWithProps<SchemaORG.MusicComposition>('MusicComposition'),
  MusicRecording: getClassWithProps<SchemaORG.MusicRecording>('MusicRecording'),
  MusicAlbum: getClassWithProps<SchemaORG.MusicAlbum>('MusicAlbum'),
  MusicRelease: getClassWithProps<SchemaORG.MusicRelease>('MusicRelease'),
  MusicPlaylist: getClassWithProps<SchemaORG.MusicPlaylist>('MusicPlaylist'),
  CreativeWork: getClassWithProps<SchemaORG.CreativeWork>('CreativeWork'),
  Person: getClassWithProps<SchemaORG.Person>('Person'),
  EducationalOrganization: getClassWithProps<SchemaORG.EducationalOrganization>('EducationalOrganization'),
  MusicGroup: getClassWithProps<SchemaORG.MusicGroup>('MusicGroup'),
  PerformingGroup: getClassWithProps<SchemaORG.PerformingGroup>('PerformingGroup'),
  LocalBusiness: getClassWithProps<SchemaORG.LocalBusiness>('LocalBusiness'),
  Organization: getClassWithProps<SchemaORG.Organization>('Organization'),
  Country: getClassWithProps<SchemaORG.Country>('Country'),
  AdministrativeArea: getClassWithProps<SchemaORG.AdministrativeArea>('AdministrativeArea'),
  Place: getClassWithProps<SchemaORG.Place>('Place'),
  PublicationEvent: getClassWithProps<SchemaORG.PublicationEvent>('PublicationEvent'),
  Event: getClassWithProps<SchemaORG.Event>('Event'),
  Language: getClassWithProps<SchemaORG.Language>('Language'),
  ProductModel: getClassWithProps<SchemaORG.ProductModel>('ProductModel'),
  Product: getClassWithProps<SchemaORG.Product>('Product'),
  PriceSpecification: getClassWithProps<SchemaORG.PriceSpecification>('PriceSpecification'),
  GeoCoordinates: getClassWithProps<SchemaORG.GeoCoordinates>('GeoCoordinates'),
  GeospatialGeometry: getClassWithProps<SchemaORG.GeospatialGeometry>('GeospatialGeometry'),
  InteractionCounter: getClassWithProps<SchemaORG.InteractionCounter>('InteractionCounter'),
  Action: getClassWithProps<SchemaORG.Action>('Action'),
  Thing: getClassWithProps<SchemaORG.Thing>('Thing')
};
