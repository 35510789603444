import { Observable } from 'rxjs/Observable';
import { noop } from '../util/action/type/noop-action.util';
import { IAction } from '../interface/action/action.interface';

export function endMethod<T, R extends IAction>(this: Observable<T>, predicate: (value: T) => any): Observable<any> {
  return this.map((value) => {
    const returnValue = predicate(value);
    return Object.assign(noop(), {payload: returnValue}) as any;
  });
}

Observable.prototype.end = endMethod;
