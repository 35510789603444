import { API } from './api';
import { invariant } from '../util/debug/invariant.util';
import { encodeQuery } from '../util/url/encode-query.util';
import { joinUrl } from '../util/url/join-url.util';

export class BaseService {
  protected baseURL: string;

  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  protected get = (path: string, query?: any, extraHeaders?: any) => {
    this.checkBaseURL();
    return this.api.get(this.getUrlForPath(path, query), extraHeaders);
  }

  protected post = (path: string, query?: any, body?: any, contentType?: string, extraHeaders?: any) => {
    this.checkBaseURL();
    return this.api.post(this.getUrlForPath(path, query), body, contentType, extraHeaders);
  }

  protected put = (path: string, query?: any, body?: any, contentType?: string, extraHeaders?: any) => {
    this.checkBaseURL();
    return this.api.put(this.getUrlForPath(path, query), body, contentType, extraHeaders);
  }

  protected delete = (path: string, query?: any, body?: any, extraHeaders?: any) => {
    this.checkBaseURL();
    return this.api.delete(this.getUrlForPath(path, query), body, extraHeaders);
  }

  private checkBaseURL = () => {
    invariant(this.baseURL !== undefined, 'baseURL must be set', this.constructor.name);
  }

  private getUrlForPath = (path: string, query: any) => {
    let toReturnURL = joinUrl(this.baseURL, path);
    if (query && Object.keys(query).filter((key) => query[ key ]).length > 0) {
      toReturnURL += `${path.indexOf('?') === -1 ? '?' : '&'}${encodeQuery(query)}`;
    }
    return toReturnURL;
  }
}
