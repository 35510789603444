import { Logger } from './logger.util';

export function invariant(check: boolean, message: string, ...things): check is true {
  if (!check) {
    const e = new Error(`[CMS] Invariant failed: ${message} ${(things && things.length > 0 ? ` in '${things.map((thing) => JSON.stringify(thing)).join(', ')}'` : '')}`);
    Logger.error(e);
    throw e;
  }
  return true;
}
