import {action, computed, observable} from 'mobx';
import {findIndex} from 'lodash';
import {findBlockById} from '../util/block/find-block-by-id.util';
import {
  IDesignObjectMentionMetadata,
  IIDLabel,
  IUsedDesignObjectMentionMetadata
} from '../interface/page/design-object-mention-metadata.interface';
import {findLinkedDataById} from '../util/page/find-linked-data-by-id.util';
import {DesignObjectMentionMetadataType} from '../enum/page-mention-metadata-type.enum';
import {DataSetLinkedDataModel} from './linked-data';
import StoreProvider from './store-provider';
import {BlockTypeEnum} from '../enum/block/block-type.enum';
import {isDataSetData} from '../util/linked-data/is-dataset-data.util';

export class RepeaterBlocksStore {

  @observable
  private repeaterModels: RepeaterBlockViewModel[];

  constructor() {
    this.repeaterModels = [];
  }

  @action
  public attachViewModel(repeaterId: string, parentRepeaterId: string, usedMention: IUsedDesignObjectMentionMetadata): RepeaterBlockViewModel {
    const model = new RepeaterBlockViewModel(repeaterId, usedMention);

    const repeater = this._recursiveFindRepeater(parentRepeaterId, this.repeaterModels);
    if (repeater) {
      repeater.addChildRepeater(model);
    } else {
      this.repeaterModels.push(model);
    }

    return model;
  }

  @action
  public detachViewModel(repeaterId: string) {
    this._recursiveDeleteRepeater(repeaterId, this.repeaterModels);
  }

  public getRepeaterViewModel(repeaterId: string) {
    return this._recursiveFindRepeater(repeaterId, this.repeaterModels);
  }

  get selectedBlockViewModel(): RepeaterBlockViewModel {
    return StoreProvider.stores.appState.repeaterBlocksStore.getRepeaterViewModel(
      StoreProvider.stores.appState.activeDesign.selectedBlock ? StoreProvider.stores.appState.activeDesign.selectedBlock.id : undefined
    );
  }

  public getBlockViewModelById(blockId: string) {
    return StoreProvider.stores.appState.repeaterBlocksStore.getRepeaterViewModel(blockId);
  }

  private _recursiveFindRepeater(repeaterId: string, inList: RepeaterBlockViewModel[]): RepeaterBlockViewModel {
    if (!repeaterId) {
      return undefined;
    }
    for (const repeater of inList) {
      if (repeater.repeaterId === repeaterId) {
        return repeater;
      } else {
        const found = this._recursiveFindRepeater(repeaterId, repeater.nestedRepeaterModels);
        if (found) {
          return found;
        }
      }
    }

    return undefined;
  }

  private _recursiveDeleteRepeater(repeaterId: string, inList: RepeaterBlockViewModel[]) {
    if (!repeaterId) {
      return;
    }
    let indexToRemove = -1;

    for (const repeater of inList) {
      if (repeater.repeaterId === repeaterId) {
        indexToRemove = findIndex(inList, (r) => r.repeaterId === repeaterId);

      } else {
        this._recursiveDeleteRepeater(repeaterId, repeater.nestedRepeaterModels);
      }
    }

    if (indexToRemove !== -1) {
      inList.splice(indexToRemove, 1);
    }
  }
}

export class RepeaterBlockViewModel {
  repeaterId: string;

  @observable
  usedMention: IUsedDesignObjectMentionMetadata;

  @observable
  nestedRepeaterModels: RepeaterBlockViewModel[];

  @observable
  parentRepeater: RepeaterBlockViewModel;

  constructor(repeaterId: string, usedMention: IUsedDesignObjectMentionMetadata) {
    this.repeaterId = repeaterId;
    this.nestedRepeaterModels = [];
    this.usedMention = usedMention;
  }

  @action
  addChildRepeater(model: RepeaterBlockViewModel) {
    model.parentRepeater = this;
    this.nestedRepeaterModels.push(model);
  }

  @action
  updateUsedMention(usedMention: IUsedDesignObjectMentionMetadata) {
    this.usedMention = usedMention;
  }

  @computed
  get usedMentions(): string[] {
    if (StoreProvider.stores.appState.activeDesign.data === undefined) {
      return [];
    }
    const currentBlock = findBlockById(StoreProvider.stores.appState.activeDesign.data, this.repeaterId);
    const usedMentions = currentBlock.getUsedMentionsNotRecursivly();
    return usedMentions;
  }

  @computed
  get mineAndSuccessorsUsedMentions(): string[] {
    if (StoreProvider.stores.appState.activeDesign.data === undefined) {
      return [];
    }
    const currentBlock = findBlockById(StoreProvider.stores.appState.activeDesign.data, this.repeaterId);
    const usedMentions = currentBlock.getUsedMentions(StoreProvider.stores.appState.activeDesign.data);
    return usedMentions;
  }

  @computed
  get possibleMentions(): IDesignObjectMentionMetadata[] {
    const possibleMentions = StoreProvider.stores.appState.editor.possibleMentions;
    return possibleMentions;
  }

  @computed
  get currentBlockPossibleMentions(): IDesignObjectMentionMetadata[] {
    const currentBlock = findBlockById(StoreProvider.stores.appState.activeDesign.data, this.repeaterId);
    return currentBlock.possibleMentions;
  }

  @computed
  get currentBlockPossibleDatasetMentions(): IDesignObjectMentionMetadata[] {
    const currentBlock = findBlockById(StoreProvider.stores.appState.activeDesign.data, this.repeaterId);
    if (!currentBlock.possibleMentions) {
      return [];
    }
    return currentBlock.possibleMentions.filter(x => x.type === DesignObjectMentionMetadataType.DataSet);
  }

  @computed
  get possibleGroupsForNestedRepeater(): IIDLabel[] {
    const usedDatasetMentionsFromParents = [];
    this._getUsedDatasetMentionsFromParents(usedDatasetMentionsFromParents);

    if (this.usedDatasetMentions && this.usedDatasetMentions.length > 0
      && this.parentRepeater && this.parentRepeater.usedDatasetMentions
      && this.usedDatasetMentions[0].mentionId !== this.parentRepeater.usedDatasetMentions[0].mentionId) {
      return (this.usedDatasetMentions[0] as any).dataSetGroups;
    }

    const usedDatasetsAndGroups = usedDatasetMentionsFromParents.map(x => {
      const ret = {
        mentionId: x.mentionId,
        groupId: x.dataSetConfig.groupId
      };
      return ret;
    });
    const usedGroups = usedDatasetsAndGroups.map(x => (x as any).groupId);

    const selectedMentions = this.possibleMentions
      .filter(x => x.mentionId === (usedDatasetsAndGroups[0] as any).mentionId && x.type === DesignObjectMentionMetadataType.DataSet);

    if (selectedMentions.length === 0) {
      return [];
    }

    const datasetGroups = selectedMentions[0].dataSetGroups.filter(x => usedGroups.indexOf(x.id) === -1);

    return datasetGroups;
  }

  @computed
  get usedDatasetMentionsFromParents(): DataSetLinkedDataModel[] {
    const acc: DataSetLinkedDataModel[] = [];
    this._getUsedDatasetMentionsFromParents(acc);
    return acc;
  }

  private _getUsedDatasetMentionsFromParents = (usedDatasetsAccumulator: DataSetLinkedDataModel[], repaterBlockModel: RepeaterBlockViewModel = this) => {
    if (repaterBlockModel.parentRepeater) {
      this._getUsedDatasetMentions(usedDatasetsAccumulator, repaterBlockModel.parentRepeater);

      // recursion
      this._getUsedDatasetMentionsFromParents(usedDatasetsAccumulator, repaterBlockModel.parentRepeater);
    }
  }

  @computed
  get usedDatasetMentions(): DataSetLinkedDataModel[] {
    const acc: DataSetLinkedDataModel[] = [];
    this._getUsedDatasetMentions(acc);
    return acc;
  }

  private _getUsedDatasetMentions(usedDatasetsAccumulator: DataSetLinkedDataModel[], repaterBlockModel: RepeaterBlockViewModel = this) {
    const usedMentionIds = repaterBlockModel.usedMentions.slice();
    for (const mentionId of usedMentionIds) {
      const linkedData = findLinkedDataById(mentionId, StoreProvider.stores.appState.activeDesign.data);
      if (linkedData.type === DesignObjectMentionMetadataType.DataSet) {
        const asDataSet = linkedData as DataSetLinkedDataModel;
        usedDatasetsAccumulator.push(asDataSet);
      }
    }
  }

  @computed
  get usedMentionsFromParents(): string[] {
    const acc: string[] = [];
    this._getUsedMentionsFromParents(acc);
    return acc;
  }

  private _getUsedMentionsFromParents = (usedMentionsAccumulator: string[] = [], repaterBlockModel: RepeaterBlockViewModel = this) => {
    if (repaterBlockModel.parentRepeater) {
      repaterBlockModel.parentRepeater.usedMentions.slice().forEach(m => usedMentionsAccumulator.push(m));
      // recursion
      this._getUsedMentionsFromParents(usedMentionsAccumulator, repaterBlockModel.parentRepeater);
    }
  }

  @computed
  get nestedLevel(): number {
    return this._getNestedLevel(this);
  }

  private _getNestedLevel = (repaterBlockModel: RepeaterBlockViewModel = this): number => {
    if (repaterBlockModel.parentRepeater) {
      return this._getNestedLevel(repaterBlockModel.parentRepeater) + 1;
    } else {
      return 0;
    }
  }

  @computed
  get canDropCurrentlyDragging(): boolean {
    const hasSetDataSet = this.usedMentions.some((x) => {
      const linkedData = findLinkedDataById(x, StoreProvider.stores.appState.activeDesign.data);
      return isDataSetData(linkedData);
    });
    if (!hasSetDataSet) {
      return false;
    }

    const currentDraggedBlockType = StoreProvider.stores.appState.currentDraggingBlockType;
    const simpleBlockTypes: BlockTypeEnum[] = [BlockTypeEnum.Hero, BlockTypeEnum.LargeHeader, BlockTypeEnum.TextBlock, BlockTypeEnum.TwoColumnText, BlockTypeEnum.Image,
      BlockTypeEnum.VideoBlock, BlockTypeEnum.TextImage, BlockTypeEnum.Space, BlockTypeEnum.TableRowBlock, BlockTypeEnum.Button];

    const isSimpleBlock = simpleBlockTypes.indexOf(currentDraggedBlockType) > -1;
    if (isSimpleBlock) {
      const hasGroupButNotSelected = this.usedMentions.some((x) => {
        const linkedData = findLinkedDataById(x, StoreProvider.stores.appState.activeDesign.data);
        console.log('DataSet linkedData:', linkedData.mentionId);
        const groups = (linkedData as any).dataSetGroups;
        const hasGroupsAndHasSelected = isDataSetData(linkedData) && (linkedData.dataSetConfig.groupId !== undefined && groups && groups.length > 0);
        const hasNotGroupsAndNoneSelected = isDataSetData(linkedData) && (!groups || groups.length === 0);
        return hasGroupsAndHasSelected || hasNotGroupsAndNoneSelected;
      });
      return hasGroupButNotSelected;
    }

    if (currentDraggedBlockType === BlockTypeEnum.RepeaterBlock) {
      const hasGroupInDataSet = this.usedMentions.some((x) => {
        const linkedData = findLinkedDataById(x, StoreProvider.stores.appState.activeDesign.data);
        return isDataSetData(linkedData) && linkedData.dataSetConfig.groupId !== undefined;
      });
      return hasSetDataSet && hasGroupInDataSet;
    }
  }
}