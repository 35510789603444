// Maps field definition constructors to type
import * as AllFieldDefinitions from '../../model/custom-lists/fields';
import { CustomListFieldDefinitionTypeEnum } from '../../enum/custom-lists/custom-list-field-definition-type.enum';
import { IFieldDefinitionConstructor } from '../../interface/custom-lists/field-data-contructor.interface';
import { invariant } from '../debug/invariant.util';
import { FieldDefinitionModel } from '../../model/custom-lists/field-definition.model';

export const FieldDefinitionsByType: {
  [key in CustomListFieldDefinitionTypeEnum]: IFieldDefinitionConstructor
} = {} as any;

const allFieldDefinitionKeys = Object.keys(AllFieldDefinitions);

for (const key of allFieldDefinitionKeys) {
  invariant(!FieldDefinitionsByType[ AllFieldDefinitions[ key ].customListFieldType ], 'There must be only one field definition model per type!', AllFieldDefinitions[ key ].customListFieldType);
  FieldDefinitionsByType[ AllFieldDefinitions[ key ].customListFieldType ] = AllFieldDefinitions[ key ];
}

export function getCustomListFieldDefinitionByType(type: CustomListFieldDefinitionTypeEnum): IFieldDefinitionConstructor {
  let toReturn: IFieldDefinitionConstructor = FieldDefinitionsByType[ type ];
  if (!toReturn) {
    console.warn(`Field definition of type: ${type} not found!`);
    toReturn = FieldDefinitionModel as IFieldDefinitionConstructor;
  }
  return toReturn;
}
