import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { EmailSpaceModel, SpaceModel } from '../../../model/block';
import { loop } from '../../../util/number/loop';

const SpaceView = asyncComponent(() => import('./space.view').then(c => c.SpaceView));
const EmailSpaceView = asyncComponent(() => import('./email-space.view').then(c => c.EmailSpaceView));
const SpaceIcon = asyncComponent(() => import('../../icons/block/space.icon').then(c => c.SpaceIcon), 'svg');

export const Space = getBlock(SpaceModel, BlockGroupEnum.Spacing, 'block.space-title', <SpaceIcon/>, SpaceView, {
  text: (block) => loop(Math.ceil(block.height / 20)).map(() => '\n\r').join()
});

export const EmailSpace = getBlock(EmailSpaceModel, BlockGroupEnum.Spacing, 'block.space-title', <SpaceIcon/>, EmailSpaceView, {
  text: (block) => loop(Math.ceil(block.height / 20)).map(() => '\n\r').join()
});
