import { invariant } from '../debug/invariant.util';

export const forEachPrototype = (value, iteratee: (baseConstructor: any) => void, continueClass = Object) => {
  invariant(Boolean(iteratee), 'Missing iteratee');
  let baseConstructor = Object.getPrototypeOf(value);
  while (baseConstructor && baseConstructor instanceof continueClass) {
    iteratee(baseConstructor);
    // get next baseConstructor
    baseConstructor = Object.getPrototypeOf(baseConstructor);
  }
};
