import { LinkedDataModel } from './linked-data.model';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';

export interface ISingleJobadItemData {
  id: number;
  pubDateFrom: string; // 'Wed, 04 Apr 2018 22:00:00 GMT',
  pubDateTo: string; // 'Thu, 30 Aug 2018 22:00:00 GMT',
  title: string;
  category: string;
  companyName: string;
  company: string;
  companyLogoUrl: string;
  country: string;
  countryId: string;
  state: string;
  stateId: string;
  municipality: string;
  municipalityId: string;
  position: string;
  jobPositionTitle: string;
  positionId: string;
  positionCategory: string;
  positionCategoryId: string;
  contacts: any[];

  [ key: string ]: any;
}

export class JobadListLinkedDataModel extends LinkedDataModel<ISingleJobadItemData[]> {
  static types = [ DesignObjectMentionMetadataType.JobAdList ];

  defaultData = [];
}
