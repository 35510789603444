import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IInjectedProps } from '../../interface/component/injected-props.interface';
import { BaseSocialShareIcon } from './base-social-share-icon';
import { LinkedInIcon } from '../icons';

export const LinkedShareInIconWrapper = inject('state')(observer((props: IInjectedProps) => {
  return (
    <BaseSocialShareIcon backgroundColor="#0077b5">
      <LinkedInIcon
        width={24}
        height={24}
        fill={props.state.colors.White}
      />
    </BaseSocialShareIcon>
  );
}));
