import { ISettingsColor, ISettingsFont } from '../interface/settings.interface';
import { isArray } from 'lodash';
import { AppStateModel } from '../model/app-state.model';
import { invariant } from '../util/debug/invariant.util';
import { FontStringReturnType, getFontStyle } from '../util/style/get-font-style.util';

interface IStyledPropsWithIPTheme {
  theme: {
    _ip_fonts: ISettingsFont[],
    _ip_colors: ISettingsColor[]
  };
}

interface IStateProps {
  state: AppStateModel;
}

function isStyledProps(props: any): props is IStyledPropsWithIPTheme {
  return props.hasOwnProperty('theme') && props.theme && props.theme.hasOwnProperty('_ip_fonts') && props.theme._ip_fonts && props.theme.hasOwnProperty('_ip_colors') && props.theme._ip_colors;
}

function isFonts(props: any): props is ISettingsFont[] {
  return isArray(props) && (props.some((p) => p.hasOwnProperty('fontFamily')) || props.length === 0);
}

function isStateProps(props: any): props is IStateProps {
  return props.hasOwnProperty('state') && props.state && props.state instanceof AppStateModel;
}

const DefaultFonts = {
  Normal: {
    id: 'Page',
    label: 'Page',
    fontSize: 14,
    predefined: true,
    fontFamily: '\'Helvetica\', \'Arial\', sans-serif',
    textColor: 'ContentColor'
  },
  Header1: {
    id: 'Header1',
    label: 'Header 1',
    fontSize: 36,
    predefined: true,
    fontFamily: '\'Helvetica\', \'Arial\', sans-serif',
    textColor: 'ContentColor',
    bold: true,
    italic: true
  },
  Header2: {
    id: 'Header2',
    label: 'Header 2',
    predefined: true,
    fontSize: 18,
    fontFamily: '\'Helvetica\', \'Arial\', sans-serif',
    textColor: 'LightGray'
  },
  Header3: {
    id: 'Header3',
    label: 'Header 3',
    predefined: true,
    fontSize: 64,
    fontFamily: '\'Helvetica\', \'Arial\', sans-serif',
    textColor: 'AppChrome'
  },
  Header4: {
    id: 'Header4',
    label: 'Header 4',
    predefined: true,
    fontSize: 14,
    fontFamily: '\'Helvetica\', \'Arial\', sans-serif',
    textColor: 'AppChrome'
  }
};

export type FontType = keyof typeof DefaultFonts;

export type FontEnum = {
  [key in FontType]: FontStringReturnType;
};

type HelperFontEnum = {
  [key in FontType]: (props: any) => FontStringReturnType;
};

interface IFontEnumGetFont {
  getFont: (fontId: string) => FontStringReturnType;
  getFontStyle: (font: ISettingsFont) => FontStringReturnType;
  getRawFont: (colorId: string) => ISettingsFont;
  getAllRawFonts: () => ISettingsFont[];
}

export type FontEnumReturn = FontEnum & IFontEnumGetFont;

export const FontEnum: ((props: IStyledPropsWithIPTheme | ISettingsFont[], colors?: ISettingsColor[]) => FontEnumReturn) & HelperFontEnum = ((props, colors) => {
  let fonts: ISettingsFont[];
  let clrs: ISettingsColor[];
  if (isStyledProps(props)) {
    fonts = props.theme._ip_fonts;
    clrs = props.theme._ip_colors;
  } else if (isFonts(props)) {
    fonts = props;
    invariant(Boolean(colors), `You must provide colors if you are provide a array of fonts for the first parameter in FontEnum!`);
    clrs = colors;
  } else if (isStateProps(props)) {
    invariant(false, 'Use props.state.fonts.[WantedFont] when using props with state');
  } else {
    invariant(false, 'As a input to this function, you must supply props from a styled component, or a list of colors', Object.keys(props));
  }
  if (fonts) {
    const toReturn: FontEnumReturn = {} as any;
    const currentKnownKeys = Object.keys(DefaultFonts);
    for (const key of currentKnownKeys) {
      Object.defineProperty(toReturn, key, {get: () => getFontStyle(fonts.filter((f) => f.id === key && f.predefined)[ 0 ] || DefaultFonts[ key ], clrs)});
    }
    toReturn.getAllRawFonts = () => fonts.slice();
    toReturn.getFont = (fontId) => {
      return getFontStyle(fonts.filter((f) => f.id === fontId)[ 0 ], clrs);
    };
    toReturn.getFontStyle = (font) => {
      return getFontStyle(font, clrs);
    };
    toReturn.getRawFont = (fontId) => {
      return fonts.filter((f) => f.id === fontId)[ 0 ];
    };
    return toReturn;
  }
  return {} as any;
}) as any;

export const allFonts = Object.keys(DefaultFonts);

allFonts.forEach((fontKey) => {
  FontEnum[ fontKey ] = (props) => {
    return FontEnum(props)[ fontKey ].css;
  };
});
