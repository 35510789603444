import { ConfigurationModel } from '../../model/configuration.model';
import { isServerSide } from '../server-side-rendering/is-server-side';

export const getOIDCClient = (config: ConfigurationModel) => {
  if (isServerSide()) {
    return {
      signinSilentCallback: () => undefined,
      signinSilent: () => Promise.resolve()
    };
  }
  const UserManager = require('../../oidc-client/UserManager').default;
  const WebStorageStateStore = require('../../oidc-client/WebStorageStateStore').default;
  const {STORAGE} = require('../storage/storage');
  const settings = {
    authority: config.authorityUrl,
    client_id: config.clientId,
    redirect_uri: `${config.CMSUrl}/${config.loginCallbackUrl}`,
    post_logout_redirect_uri: `${config.CMSUrl}/${config.logoutCallbackUrl}`,
    silent_redirect_uri: `${config.CMSUrl}/${config.silentCallbackUrl}`,
    response_type: 'id_token token',
    scope: 'openid email profile cms shuffler ip_sid roles',
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: false,
    userStore: new WebStorageStateStore({store: STORAGE} as any),
    stateStore: new WebStorageStateStore({store: STORAGE} as any),
    acr_values: config.deviceId ? ('device_id:' + config.deviceId) : ''
  };

  return new UserManager(settings);
};
