import { EDITABLE_METADATA_KEY } from './editable.decorator';
import { EditorGroupEnum } from '../../../enum/editor-group.enum';

export const EDIT_LABEL_KEY = '_editLabel';

export const editGroup: ((name: EditorGroupEnum) => (target: any, key: string, descriptor?) => any) & {
  [key in keyof typeof EditorGroupEnum]: (target: any, key: string, descriptor?) => any
} = ((name) => {
  return (target: any, key: string, descriptor?) => {
    if (Object.getOwnPropertyNames(target).indexOf(EDITABLE_METADATA_KEY) === -1) {
      const editableMetadata = {};
      Object.defineProperty(target, EDITABLE_METADATA_KEY, {
        get: () => (editableMetadata),
        set: (v) => undefined
      });
    }
    if (target[ EDITABLE_METADATA_KEY ][ key ] === undefined) {
      target[ EDITABLE_METADATA_KEY ][ key ] = {
        [ EDIT_LABEL_KEY ]: name
      };
    } else {
      target[ EDITABLE_METADATA_KEY ][ key ][ EDIT_LABEL_KEY ] = name;
    }

    return descriptor;
  };
}) as any;

(editGroup as any).CONTENT = editGroup(EditorGroupEnum.CONTENT);
(editGroup as any).TRANSLATION = editGroup(EditorGroupEnum.TRANSLATION);
(editGroup as any).COLOR = editGroup(EditorGroupEnum.COLOR);
(editGroup as any).STYLE = editGroup(EditorGroupEnum.STYLE);
(editGroup as any).GENERAL = editGroup(EditorGroupEnum.GENERAL);
