const storageDetectionDummyKey = 'storageDetectionDummyKey';

export default class StorageDetector {
  static isEnabled(storageType) {
    try {
      if (!((window[ storageType ] as any).setItem &&
        (window[ storageType ] as any).getItem &&
        (window[ storageType ] as any).removeItem)) {
        return false;
      }
      (window[ storageType ] as any).setItem(storageDetectionDummyKey, 'dummy value');
      (window[ storageType ] as any).getItem(storageDetectionDummyKey);
      (window[ storageType ] as any).removeItem(storageDetectionDummyKey);
      return true;
    } catch (e) {
      return false;
    }
  }
}
