import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { ICustomListEntry } from '../../interface/custom-lists/custom-list-entry.interface';
import { ICustomListFieldData } from '../../interface/custom-lists/custom-list-field-data.interface';
import { editGroup } from '../block/decorators/edit-group.decorator';
import { editable } from '../block/decorators/editable.decorator';
import { plainStringEdit } from '../../util/block/edit-types/plain-string-edit.util';
import { STATIC_PAGE_SLUGS } from '../../constants';
import { getUrlSafeString } from '../../util/string/get-url-safe-string.util';
import { dateEdit } from '../../util/block/edit-types/date-edit.util';

export class CustomListEntryModel implements ICustomListEntry {
  @observable @persist id: string;
  @observable @persist creationDate: string;
  @observable @persist customListDefinitionId: string;
  @observable @persist lastUpdatedDate: string;
  @observable @persist('list') listFieldData: ICustomListFieldData[] = [];

  @editGroup.CONTENT
  @editable(plainStringEdit()
    .label('custom-list.entry.title')
    .required('custom-list.entry.title-required')
    .placeholder('custom-list.entry.title-placeholder')
    .onChange(action((newValue, oldValue, data) => {
      const name = newValue;
      const oldNameValue = oldValue;
      const isStaticPage = STATIC_PAGE_SLUGS.indexOf(data.slug) !== -1;
      if (!isStaticPage && getUrlSafeString(oldNameValue) === data.slug) {
        data.slug = getUrlSafeString(name);
      }
      return newValue;
    }))
  )
  @observable @persist title: string = '';

  @editGroup.CONTENT
  @editable(plainStringEdit()
    .label('custom-list.entry.slug')
    .withValidator((value, oldValue, data, configuration, state) => {
      return [
        {
          isValid: state.editor.customListDefinitionEntries.filter((d) => d.id !== data.id).map((d) => d.slug).indexOf(value) === -1,
          messageKey: 'custom-list.entry.slug.must-be-unique'
        }
      ];
    })
    .required('custom-list.entry.slug-required')
  )
  @observable @persist slug: string = '';

  @editGroup.CONTENT
  @editable(dateEdit()
    .label('custom-list.entry.publish-date')
  )
  @observable @persist publishDate: string;

  @editGroup.CONTENT
  @editable(dateEdit()
    .label('custom-list.entry.publish-end-date')
  )
  @observable @persist publishEndDate: string;

  @observable @persist isSaved: boolean = false;

  setCustomListEntryData = (data: ICustomListEntry) => {
    Object.assign(this, data);
    this.listFieldData = data.listFieldData.map(fd => Object.assign({}, fd));
    this.isSaved = true;
  }
}
