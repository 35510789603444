export function writeSessionCookie(cookieSetFunction: (cookieKey: string, value: string) => void, cookie: string, value: any = {}) {
  const keys = Object.keys(value);
  let cookieValue = '';
  for (let i = 0; i < keys.length; i++) {
    if (i > 0) {
      cookieValue += ',';
    }
    cookieValue += `${keys[ i ]}:${value[ keys[ i ] ]}`;
  }
  cookieSetFunction(cookie, cookieValue);
}
