import { IEditConfig } from '../../../interface/block/edit-config.interface';
import { IEditModeReturnValue } from '../../../util/block/edit-types/return-edit-mode.util';

export const EDITABLE_METADATA_KEY = '_editableMetadata';
export const EDIT_CONFIGS_KEY = '_editConfigs';

function isEditModeReturnValue(value: any): value is IEditModeReturnValue<any, any> {
  return value.hasOwnProperty('_decoratorProperties');
}

export const editable = (...editConfigs: (IEditConfig | IEditModeReturnValue<any, any>)[]) => (target: any, key: string, descriptor?) => {
  if (Object.getOwnPropertyNames(target).indexOf(EDITABLE_METADATA_KEY) === -1) {
    const editableMetadata = {};
    Object.defineProperty(target, EDITABLE_METADATA_KEY, {
      get: () => (editableMetadata),
      set: (v) => undefined
    });
  }
  const finalConfigs: IEditConfig<any, any>[] = editConfigs.map((editConfig) => {
    if (isEditModeReturnValue(editConfig)) {
      return editConfig._fetchDecoratorProperties(key);
    }
    return editConfig;
  });
  if (target[ EDITABLE_METADATA_KEY ][ key ] === undefined) {
    target[ EDITABLE_METADATA_KEY ][ key ] = {
      [ EDIT_CONFIGS_KEY ]: finalConfigs
    };
  } else {
    target[ EDITABLE_METADATA_KEY ][ key ][ EDIT_CONFIGS_KEY ] = finalConfigs;
  }

  return descriptor;
};
