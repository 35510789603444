import { ILinkedData } from '../../interface/linked-data.interface';
import * as AllLinkedDataModels from '../../model/linked-data';
import { Logger } from '../debug/logger.util';
import { isArray } from 'lodash';
import { invariant } from '../debug/invariant.util';
import { LinkedDataModel } from '../../model/linked-data/linked-data.model';

const AllLinkedDataModelsByType = {};

const keys = Object.keys(AllLinkedDataModels);

for (const key of keys) {
  const CurrentLinkedDataModel = AllLinkedDataModels[ key ];
  const types = (CurrentLinkedDataModel as any).types;
  if (!types) {
    Logger.warn(`There is no types on LinkedDataModel ${CurrentLinkedDataModel}`);
    continue;
  }
  invariant(isArray(types), `Types must be a array!`, CurrentLinkedDataModel);
  types.forEach((type) => {
    AllLinkedDataModelsByType[ type ] = CurrentLinkedDataModel;
  });
}

export function createLinkedDataFromData(data: Partial<ILinkedData>, generateDummyData: boolean = false): LinkedDataModel {
  let Constructor = AllLinkedDataModelsByType[ data.type ];
  if (!Constructor) {
    Constructor = LinkedDataModel;
    Logger.warn(`Unknown LinkedDataModel for type: ${data.type}!`);
  }
  const newData = new Constructor();
  newData.setServerData(data);
  if (generateDummyData) {
    newData.generateDummyData();
  }
  return newData;
}
