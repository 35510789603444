import { BaseService } from './base-service';
import { IFileData } from '../component/blocks/form/fields/file-field';

export class FormSubmitService extends BaseService {
  baseURL = '';

  submit = (
    url,
    formData: { fields: { [key: string]: any }; files: { [key: string]: IFileData } },
    queryData: any,
    urlParams: { [key: string]: string },
  ) => {
    return this.post(url, queryData, { ...formData, urlParams: urlParams });
  };
}
