import { ConfigurationModel } from '../../model/configuration.model';

const translationRegEx = /{([^}]*)}/gi;

export function getTranslation(configuration: ConfigurationModel, key: string, parameters: string[] | { [ key: string ]: string; } = []): string {
  let translation = configuration.translations[ key ];
  if (!translation) {
    translation = key;
  }

  const originalTranslation = translation;
  let match = translationRegEx.exec(originalTranslation);
  while (match !== null) {
    let propValue = parameters[ match[ 1 ] ];
    if (propValue === undefined) {
      propValue = `UNKNOWN PROP: (${match[ 1 ]})`;
    }
    translation = translation.replace(match[ 0 ], propValue);
    match = translationRegEx.exec(originalTranslation);
  }

  translationRegEx.lastIndex = 0;
  return translation;
}
