import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { ILink } from '../../interface/page/link.interface';
import { linkEdit } from '../../util/block/edit-types/link-edit.util';
import { colorPickerEdit } from '../../util/block/edit-types/color-picker-edit.util';
import { BlockColorType } from '../../enum/block-color.enum';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { customListBlock } from './decorators/custom-list-block.decorator';

@customListBlock
@jobadBlock
@pageBlock
@webDocumentBlock
@blockType(BlockTypeEnum.Button)
@blockBackgroundColor('PrimaryBackgroundColor')
export class ButtonBlockModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(plainTextEdit()
    .label('email-button-block.label'))
  @observable label: any = getDraftStateFromString('');

  @editGroup.CONTENT
  @editable(linkEdit()
    .label('email-button-block.link'))
  @observable link: ILink;

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('email-button-block.buttonColor'))
  @observable buttonColor: BlockColorType = 'Transparent';

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .addOption('email-button-block.left', 'left')
    .addOption('email-button-block.center', 'center')
    .addOption('email-button-block.right', 'right')
    .label('email-button-block.alignment'))
  @observable alignment: 'left' | 'right' | 'center' = 'center';

  @editGroup.COLOR
  @editable(colorPickerEdit()
    .label('button-block.background-color'))
  @observable buttonBackgroundColor: BlockColorType = 'Transparent';
}
