import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { RepeatingTextBlockModel } from '../../../model/block';

const RepeatingTextBlockView = asyncComponent(() => import('./repeating-text-block.view').then(c => c.RepeatingTextBlockView));
const RepeatingTextBlockIcon = asyncComponent(() => import('../../icons/block/repeating-text-block.icon').then(c => c.RepeatingTextBlockIcon), 'svg');

export const RepeatingTextBlock = getBlock(RepeatingTextBlockModel, BlockGroupEnum.Lists, 'block.repeating-text-block-title',
  <RepeatingTextBlockIcon/>, RepeatingTextBlockView);
