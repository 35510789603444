import { BaseService } from './base-service';
import { IHttpDeletePagePayload } from '../action/http.action';
import { DesignTypeEnum } from '../enum/design-type.enum';

export class PageService extends BaseService {
  baseURL = 'Page';

  getAllPages = (includeAllInfo: boolean = false) => {
    if (includeAllInfo) {
      return this.get('/?includeAllInfo=true');
    }
    return this.get('/');
  }

  getMetadata = () => {
    return this.get('/metadata');
  }

  getPageBySlug = (slug: string, type: DesignTypeEnum, filterParameters: any, useCache: boolean) => {
    return this.get(`by-slug`, {slug, pageType: type || DesignTypeEnum.Page, ...filterParameters, useCache});
  }

  getHomePage = (type: DesignTypeEnum, filterParameters: any, useCache: boolean) => {
    return this.get(`homepage`, {pageType: type || DesignTypeEnum.Page, ...filterParameters, useCache});
  }

  createPage = (content: string) => {
    return this.post(`/`, undefined, {content});
  }

  updatePage = (pageId: string, pageContent: any) => {
    return this.put(`/`, undefined, {
      id: pageId,
      content: pageContent
    });
  }

  deletePage = (requestModel: IHttpDeletePagePayload) => {
    return this.delete(`/`, undefined, {id: requestModel.pageId});
  }
}
