import { findIndex, isObject } from 'lodash';
import { AppStateModel } from '../../model/app-state.model';
import { ILink } from '../../interface/page/link.interface';
import { joinUrl } from './join-url.util';
import { getPageURI } from './get-page-uri.util';
import { LinkOriginEnum } from '../../enum/link-origin.enum';
import { draftToString } from '../draft/draft-to-string.util';
import { findLinkedData } from '../page/find-linked-data.util';
import { DesignTypeEnum } from '../../enum/design-type.enum';
import { CUSTOM_LIST_SLUG, JOBAD_DETAILS_SLUG } from '../../constants';
import { IBasePageData } from '../../interface/page/base-page-data.interface';
import { PageLoadParametersModel } from '../../model/page-parameters.model';
import { toJS } from 'mobx';
import {isCustomListInterface} from '../design-object/is-custom-list-interface.util';

function produceFinalURL(state: AppStateModel, noRelativeURL: boolean, uri: string) {
  if (noRelativeURL) {
    return joinUrl(state.configuration.CMSUrl, uri);
  } else {
    return joinUrl('/', uri);
  }
}

function getURI(page: IBasePageData, blockMentionData: any, linkerId: string, loadParams: PageLoadParametersModel) {
  let title = '';
  if (blockMentionData) {
    title = draftToString(page.ogTitle || page.title, undefined, blockMentionData);
  }
  if (linkerId) {
    return getPageURI(Object.assign({}, page, {title}), linkerId);
  } else {
    if (Object.keys(loadParams.filterParameters).length > 0) {
      return getPageURI(Object.assign({}, page, {title}), loadParams.filterParameters.linkerId as string);
    } else {
      return getPageURI(Object.assign({}, page, {title}));
    }
  }
}

export function getLinkFinalURL(state: AppStateModel, link: ILink, blockMentionData?: any, noRelativeURL: boolean = false): string {
  if (!link) {
    return '';
  }
  if (link.origin === LinkOriginEnum.Internal) {
    const allPages = toJS(state.allPages);
    const index = findIndex(allPages, {id: link.pageId});
    const page = allPages[ index ];
    if (page) {
      if (isCustomListInterface(page) && !page.customListDefinitionId) {
        return '';
      }
      return produceFinalURL(state, noRelativeURL, getURI(page, blockMentionData, link.linkerId, state.activeDesign.loadParams));
    } else {
      if (link.pageType === DesignTypeEnum.JobAdPage) {
        return joinUrl('/', JOBAD_DETAILS_SLUG, link.linkerId);
      }
      if (link.pageType === DesignTypeEnum.CustomList) {
        return joinUrl('/', CUSTOM_LIST_SLUG, link.linkerId);
      }
      const dLink = joinUrl('/', link.linkerId);
      return dLink;
    }
  } else if (link.origin === LinkOriginEnum.External) {
    return link.url || '';
  } else if (link.origin === LinkOriginEnum.Mention && link.mention) {
    const linkedData = findLinkedData(link.mention, state.activeDesign.data, blockMentionData);
    if (linkedData) {
      if (isObject(linkedData.data)) {
        return getLinkFinalURL(state, linkedData.data, blockMentionData, noRelativeURL);
      } else {
        return linkedData.data || '';
      }
    }
  }
  return '';
}
