import * as Cookies from 'js-cookie';
import {shouldSetSamesiteCookie} from '../dom/should-set-same-site.util';

export default class CookieStorage {
  setItem(key, value) {
    const cookieOpts = shouldSetSamesiteCookie() ? {sameSite: 'none', secure: true} : {sameSite: false};
    Cookies.set(key, value, cookieOpts);
  }

  getItem(key) {
    return Cookies.get(key);
  }

  removeItem(key) {
    Cookies.remove(key);
  }

  key(index) {
    const allKeys = Object.keys(Cookies.getJSON());

    return (index > -1 && index <= allKeys.length) ? allKeys[ index ] : '';
  }

  get length() {
    return Object.keys(Cookies.getJSON()).length;
  }
}
