import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { IButtonTheme } from '../../interface/button-theme.interface';
import { ColorEnum } from '../../enum/color.enum';
import { BlockColorType } from '../../enum/block-color.enum';
import { lightenColor } from '../../util/style/lighten-color.util';
import { determineTextColor } from '../../util/style/determine-text-color';
import { ellipsisText } from '../../util/style/ellipsis-text.util';
import { SmartLink } from '../smart-link/smart-link';

interface IBaseButtonProps {
  color: BlockColorType;
  textColor?: BlockColorType;
}

interface IButtonThemeProps extends IBaseButtonProps {
  theme?: { _button: IButtonTheme };
  isDisabled?: boolean;
}

const getButtonColor = (props: IButtonThemeProps, brightness: number) => {
  return lightenColor(ColorEnum.getColor(props.color)(props as any), brightness);
};

const getButtonBackgroundColor = (props: IButtonThemeProps) => props.theme._button.hasBackgroundColor ? getButtonColor(props, props.theme._button.colorBrightness) : `transparent`;
const getButtonHoverColor = (props: IButtonThemeProps) => props.theme._button.hasHoverBackgroundColor ? getButtonColor(props, props.theme._button.hoverColorBrightness) : `transparent`;

const calculateTextColor = (props: IButtonThemeProps, backgroundColor: string) => {
  if (backgroundColor === 'transparent') {
    return ColorEnum.getColor(props.color)(props);
  } else {
    return ColorEnum.getColor(determineTextColor(backgroundColor, 'PrimaryTextColor', 'SecondaryTextColor'))(props);
  }
};

const ButtonCSS = css`
  outline: none;
  display: block;
  text-align: center;
  font-size: 14px;
  height: 42px;
  width: auto;
  padding: 11px 30px;
  border-radius: ${(props: IButtonThemeProps) => props.theme._button.borderRadius}px;
  border: 2px solid ${(props: IButtonThemeProps) => getButtonColor(props, props.theme._button.colorBrightness)};
  color: ${(props: IButtonThemeProps) => props.textColor ? ColorEnum.getColor(props.textColor)(props) : calculateTextColor(props, getButtonBackgroundColor(props))} !important;
  background-color: ${getButtonBackgroundColor};
  cursor: ${(props: IButtonThemeProps) => props.isDisabled ? 'not-allowed' : 'pointer'};
  box-sizing: border-box;
  margin: 0 10px;
  &:focus{
    outline: none;
  }
  &:hover {
    border-color: ${getButtonHoverColor};
    background-color: ${getButtonHoverColor};
    color: ${(props: IButtonThemeProps) =>  props.textColor ? (props.theme._button.hasHoverBackgroundColor ? lightenColor(ColorEnum.getColor(props.textColor)(props), props.theme._button.hoverColorBrightness) : ColorEnum.getColor(props.textColor)(props)) : calculateTextColor(props, getButtonHoverColor(props))} !important;
  }
  ${ellipsisText}
`;

const StyledCMSButton = styled.button`
  ${ButtonCSS}
`;

const StyledLinkCMSButton = styled(SmartLink)`
  ${ButtonCSS}
`;

interface ICmsButton extends IBaseButtonProps {
  leftIcon?: React.ReactNode;
  text: string;
  onClick?: (ev) => void;
  onDisabledClick?: () => void;
  to?: string;
  disabled?: boolean;
}

@observer
export class CmsButton extends React.Component<ICmsButton> {
  onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.disabled) {
      if (this.props.onDisabledClick) {
        this.props.onDisabledClick();
      }
    } else {
      if (this.props.onClick) {
        this.props.onClick(e);
      }
    }
  }

  render() {
    const StyledButton: any = this.props.to ? StyledLinkCMSButton : StyledCMSButton;
    const to = this.props.to && this.props.to.startsWith('www.') ? `https://${this.props.to}` : this.props.to;
    return <StyledButton
      isDisabled={this.props.disabled}
      onClick={this.props.onClick && this.onClick}
      color={this.props.color}
      textColor={this.props.textColor}
      to={to}
    >{this.props.leftIcon}
      {this.props.text}
    </StyledButton>;
  }
}
