import { AjaxRequestMethodEnum } from '../enum/ajax-request-method.enum';
import { createAjaxRequest } from '../util/service/create-ajax-request';
import { Observable } from 'rxjs/Observable';
import { Signal } from 'signals';
import { IProvisioning } from '../interface/provisioning.interface';
import { AjaxError, AjaxResponse } from 'rxjs/Rx';
import { isValidIpAddress } from '../util/string/is-valid-ip-address.util';
import { AppStateModel } from '../model/app-state.model';
import { computed } from 'mobx';
import {LoggerType} from '../util/debug/logger.util';

interface IRequestQue {
  args: { method: AjaxRequestMethodEnum, path: string, body?: any };
  resolve: (resolveValue: any) => void;
  reject: (rejectError: any) => void;
}

export class API {
  private inited: boolean = false;
  private appState: AppStateModel;
  private oidcClient: any;
  private _initialized: Signal = new Signal();
  private _error: Signal = new Signal();
  private _hostname: string;
  private _logger: LoggerType;

  private requestQue: IRequestQue[] = [];

  constructor(logger: LoggerType, oidcClient, state: AppStateModel, initialized: boolean = false, hostname?: string, extraHeaders?: any) {
    this.oidcClient = oidcClient;
    this.appState = state;
    this.inited = initialized;
    this._hostname = hostname;
    this._logger = logger;

    if (!this.isInitialized) {
      this.initialize(extraHeaders);
    }
  }

  get = (path: string, extraHeaders?: any) => {
    const args = this.getArgumentsForMethodAndPath(AjaxRequestMethodEnum.GET, path);
    if (!this.isInitialized) {
      return this.addToRequestQue(args);
    }
    return this.doAjaxRequest(args.method, args.path, args.body, undefined, extraHeaders);
  }

  post = (path: string, body?: any, contentType?: string, extraHeaders?: any) => {
    const args = this.getArgumentsForMethodAndPath(AjaxRequestMethodEnum.POST, path, body);
    if (!this.isInitialized) {
      return this.addToRequestQue(args);
    }
    return this.doAjaxRequest(args.method, args.path, args.body, contentType, extraHeaders);
  }

  put = (path: string, body?: any, contentType?: string, extraHeaders?: any) => {
    const args = this.getArgumentsForMethodAndPath(AjaxRequestMethodEnum.PUT, path, body);
    if (!this.isInitialized) {
      return this.addToRequestQue(args);
    }
    return this.doAjaxRequest(args.method, args.path, args.body, contentType, extraHeaders);
  }

  delete = (path: string, body?: any, extraHeaders?: any) => {
    const args = this.getArgumentsForMethodAndPath(AjaxRequestMethodEnum.DELETE, path, body);
    if (!this.isInitialized) {
      return this.addToRequestQue(args);
    }
    return this.doAjaxRequest(args.method, args.path, args.body, extraHeaders);
  }

  onInitialize = (callback: (initData: IProvisioning) => void) => {
    this._initialized.add(callback);
    return {
      remove: () => {
        this._initialized.remove(callback);
      }
    };
  }

  onError = (callback: (error: AjaxError) => void) => {
    this._error.add(callback);
    return {
      remove: () => {
        this._error.remove(callback);
      }
    };
  }

  private get isInitialized() {
    return this.inited;
  }

  private getArgumentsForMethodAndPath = (method: AjaxRequestMethodEnum, path: string, body?: any) => {
    return {method, path, body};
  }

  private addToRequestQue = (args: { method: AjaxRequestMethodEnum, path: string, body?: any }) => {
    let resolve;
    let reject;
    const promise = new Promise((rs, rj) => {
      resolve = rs;
      reject = rj;
    });
    this.requestQue.push({args, resolve, reject});
    return Observable.fromPromise(promise);
  }

  private get CMSUrl() {
    if (this.appState.configuration.debug) {
      const splited = this.appState.configuration.CMSUrl.split(':');
      if (isValidIpAddress(splited[ 1 ].substr(2))) {
        splited[ 1 ] = '//localhost';
        return splited.join(':');
      }
    }
    return this.appState.configuration.CMSUrl;
  }

  private get hostname() {
    return this._hostname || (this.appState.configuration.debug && this.appState.configuration.debugCMSUrl ? this.appState.configuration.debugCMSUrl : this.CMSUrl);
  }

  private get isActiveSite() {
    return (this.appState.configuration.inAdmin || this.appState.configuration.isPreview) ? 'false' : 'true';
  }

  private get hasCache() {
    return this.appState.configuration.hasCache;
  }

  @computed
  private get headers() {
    const headers = {
      [ 'X-Hostname' ]: this.hostname,
      [ 'X-IsActiveSite' ]: this.isActiveSite,
      [ 'X-CMS-Cache' ]: this.hasCache ? 'only-if-cached' : 'no-cache',
    };
    if (this.appState.configuration.debugTenantId) {
      headers[ 'X-Tenant-Id' ] = this.appState.configuration.debugTenantId;
    }
    return headers;
  }

  private initialize = (extraHeaders: any = {}) => {
    const provisioningPath = 'Provisioning';
    const ajaxMethod = AjaxRequestMethodEnum.POST;
    const body = {
      includeAuthSettings: this.appState.configuration.inAdmin,
      hostname: this.hostname
    };
    this._logger.debug(body, 'Provisioning on path', provisioningPath);
    createAjaxRequest(this._logger, this.appState, this.oidcClient, ajaxMethod, provisioningPath, body, undefined, {
      ...this.headers,
      ...extraHeaders
    })
      .toPromise()
      .then((response: AjaxResponse) => {
        const responseData = response.response;
        while (this.requestQue.length > 0) {
          const requestQueItem = this.requestQue.shift();
          requestQueItem.resolve(this.doAjaxRequest(requestQueItem.args.method, requestQueItem.args.path, requestQueItem.args.body).toPromise());
        }
        this._initialized.dispatch(responseData);
        this.clearListeners();
        this.inited = true;
      }).catch(e => {
      this._error.dispatch(e);
      this.clearListeners();
    });
  }

  private clearListeners = () => {
    this._error.removeAll();
    this._initialized.removeAll();
  }

  private doAjaxRequest(method: AjaxRequestMethodEnum, path: string, body?: any, contentType?: any, extraHeaders: any = {}) {
    return createAjaxRequest(
      this._logger,
      this.appState,
      this.oidcClient,
      method,
      path,
      body,
      contentType,
      {
        ...this.headers,
        ...extraHeaders
      });
  }
}
