import * as React from 'react';
import { getBlock } from '../../../util/block/get-block.util';
import { BlockGroupEnum } from '../../../enum/block/block-group.enum';
import { asyncComponent } from '../../async-component/async-component';
import { VideoBlockModel } from '../../../model/block';

const VideoView = asyncComponent(() => import('./video.view').then(c => c.VideoView));
const VideoIcon = asyncComponent(() => import('../../icons/block/video.icon').then(c => c.VideoIcon), 'svg');

export const Video = getBlock(VideoBlockModel, BlockGroupEnum.ImageAndVideo, 'block.video-title', <VideoIcon/>, VideoView);
