import { mentionPickerEdit, MentionPickerReturnType } from './mention-picker-edit.util';
import { DesignObjectMentionMetadataType } from '../../../enum/page-mention-metadata-type.enum';

interface IListMentionPickerProps {
  placeholder?: string;
}

interface IReturnType {
  placeholder: (placeholder: string) => MentionPickerReturnType<IReturnType>;
}

export const customListMentionPickerEdit = (componentProps: IListMentionPickerProps = {} as any) => {
  const toReturn: MentionPickerReturnType<IReturnType> = mentionPickerEdit(componentProps)
    .filterMentions((mention) => mention.type === DesignObjectMentionMetadataType.CustomList) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.showFilters(true);
  return toReturn;
};
