import * as cloudinary from 'cloudinary-core';
import { ISelectedImage } from '../../interface/component/selected-image.interface';
import { AppStateModel } from '../../model/app-state.model';
import { ImageOriginEnum } from '../../enum/image-origin.enum';
import { isObject } from 'lodash';
import { isStringEmpty } from '../string/is-string-empty.util';
import { findLinkedData } from '../page/find-linked-data.util';
import {joinUrl} from './join-url.util';
import {getImageCloudinaryURL} from './get-image-cloudinary-url.util';

const cl = new cloudinary.Cloudinary({cloud_name: 'intelliplan-test', secure: true});

interface ImageLocationsInteface {
  location: string;
}

const baseImage: ImageLocationsInteface = {
  location: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg'
};

const placeholderImage: ImageLocationsInteface = {
  location: 'https://getuikit.com/v2/docs/images/placeholder_200x100.svg'
};

const validURLRegEx = /^(?:http(s)?:\/\/)([\w.-]+(?:\.[\w\.-]+)+)[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;

export const IMAGE_SIZE_INTERVALS = 500;

export function getImageURL(state: AppStateModel, image: ISelectedImage, size: (number | {width?: number, height?: number, useSizeInterval?: boolean}), blockMentionData?: any, useOriginalImage: boolean = false, crop: string = 'lfill'): ImageLocationsInteface {
  if (!image) {
    return null;
  }
  if (image.origin === ImageOriginEnum.Url) {
    return {
      location: getImageCloudinaryURL(image.imageUrl, isObject(size) ? size.width : size, isObject(size) ? size.height : size)
    };
  } else if (image.origin === ImageOriginEnum.MediaLibrary) {
    let location: string = image.location || '';
    if (isStringEmpty(location)) {
      return placeholderImage;
    }
    if (location.indexOf('.svg') === -1) {
      let options;
      if (size) {
        let pixelWidth: number;
        let pixelHeight: number;
        let useSizeInterval: boolean = true;
        if (isObject(size)) {
          pixelWidth = size.width;
          pixelHeight = size.height;
          useSizeInterval = size.useSizeInterval;
        } else {
          pixelWidth = size as number;
        }
        if (useSizeInterval) {
          const multiplicationFactor = Math.ceil(pixelWidth / IMAGE_SIZE_INTERVALS);
          pixelWidth = multiplicationFactor * IMAGE_SIZE_INTERVALS;
        }
        options = {
          width: pixelWidth,
          height: pixelHeight,
          crop: crop,
          gravity: 'faces:center',
          quality: 'auto:best'
        };
      }
      if (useOriginalImage) {
        location = cl.url(joinUrl('storage', location), options);
      } else {
        location = cl.url(joinUrl('storage', image.largeLocation || location), options);
      }
      const newLoc = location.split(',');
      newLoc.splice(1, 0, 'f_auto');
      location = newLoc.join(',');
    }
    return {
      location
    };
  } else if (image.origin === ImageOriginEnum.Mention) {
    if (!state.activeDesign.data) {
      return baseImage;
    }
    if (!image.mention) {
      return placeholderImage;
    }
    const linkedData = findLinkedData(image.mention, state.activeDesign.data, blockMentionData);
    if (linkedData) {
      const location = linkedData.data;
      if (!location) {
        return null;
      }
      if (isObject(location)) {
        return getImageURL(state, location as any, size, blockMentionData, useOriginalImage);
      }
      if (validURLRegEx.test(location)) {
        return {
          location: getImageCloudinaryURL(location, isObject(size) ? size.width : size, isObject(size) ? size.height : size)
        };
      } else {
        return null;
      }
    }
  }
  return null;
}
