import { ISettingsColor, ISettingsFont } from '../../interface/settings.interface';
import { ColorEnum } from '../../enum/color.enum';

export type FontStringReturnType = { [key in keyof ISettingsFont]: string } & { css: string };

export function getFontStyle(font: ISettingsFont, colors: ISettingsColor[]): FontStringReturnType {
  if (!font) {
    return;
  }
  const fontFamilyString = `font-family: ${font.fontFamily};`;
  const fontSizeString = `font-size: ${font.fontSize}px;`;
  let fontString: string = `${fontFamilyString} ${fontSizeString}`;
  const fontStringObject: FontStringReturnType = {fontFamily: fontFamilyString, fontSize: fontSizeString} as any;
  if (font.bold) {
    const boldStyle = 'font-weight: 600;';
    fontString = fontString + ` ${boldStyle}`;
    fontStringObject.bold = boldStyle;
  } else {
    fontString = fontString + ` font-weight: 400;`;
    fontStringObject.bold = '';
  }
  if (font.italic) {
    const italicStyle = 'font-style: italic;';
    fontString = fontString + ` ${italicStyle}`;
    fontStringObject.italic = italicStyle;
  } else {
    const normalStyle = 'font-style: normal;';
    fontString = fontString + ` ${normalStyle}`;
    fontStringObject.italic = '';
  }
  if (font.underline) {
    const undeflineStyle = 'text-decoration: underline;';
    fontString = fontString + ` ${undeflineStyle}`;
    fontStringObject.underline = undeflineStyle;
  } else {
    fontStringObject.underline = '';
  }
  if (font.textColor) {
    const textColor = `color: ${ColorEnum(colors).getColor(font.textColor)};`;
    fontString = fontString + ` ${textColor}`;
    fontStringObject.textColor = textColor;
  } else {
    fontStringObject.textColor = '';
  }
  if (font.highlightColor && font.highlightColor !== 'Transparent') {
    const highlightColor = `background-color: ${ColorEnum(colors).getColor(font.highlightColor)};`;
    fontString = fontString + ` ${highlightColor}`;
    fontStringObject.highlightColor = highlightColor;
  } else {
    fontStringObject.highlightColor = '';
  }
  fontStringObject.css = fontString;
  return fontStringObject;
}
