export enum ActionEnum {
  PING,
  HTTP_WARM_UP_CACHE,
  SET_PAGE_SIZE_FOR_BLOCK_LINKED_DATA,
  DELETE_CUSTOM_LIST_ENTRY,
  SAVE_CUSTOM_LIST_DEFINITION,
  NEW_CUSTOM_LIST_DEFINITION,
  REPLACE_PAGE,
  REDIRECT_PAGE,
  GET_WEB_DOC_BY_HASH,
  LOAD_WEB_DOC_ROUTE,
  GET_HOME_PAGE,
  HTTP_UPDATE_GLOBAL_ERROR_SETTINGS,
  HTTP_UPDATE_TEMPLATE,
  UPDATE_TEMPLATE,
  GET_TEMPLATE_METADATA,
  GET_TEMPLATE_BY_ID,
  LOAD_DYNAMIC_TEMPLATE,
  HTTP_UPDATE_BUTTON_THEME_SETTINGS,
  LOAD_TEMPLATE_ROUTE,
  LOAD_EDIT_TEMPLATE_ROUTE,
  SETTINGS_UPDATE_BUTTON_SETTINGS,
  SETTINGS_UPDATE_MAIN_MENU_SETTINGS,
  SETTINGS_UPDATE_SPINNER_SETTINGS,
  HTTP_UPDATE_SPINNER_SETTINGS,
  HTTP_UPDATE_MAIN_MENU_SETTINGS,
  HTTP_DELETE_FILE,
  HTTP_UPLOAD_FILE,
  COOKIE_POLICY_ACCEPTED,
  HTTP_UPDATE_COOKIE_POLICY_SETTINGS,
  HTTP_UPDATE_COOKIE_POLICIES_SETTINGS,
  SETTINGS_UPDATE_COOKIE_POLICY_SETTINGS,
  HTTP_UPLOAD_FAV_ICON,
  HTTP_UPDATE_ADMIN_SETTINGS,
  HTTP_UPDATE_TAG_MANAGER_SETTINGS,
  HTTP_GET_LINKED_IN_SETTINGS,
  SETTINGS_UPDATE_LOGOS,
  HTTP_UPDATE_LOGOS,
  SETTINGS_UPDATE_FONTS,
  SETTINGS_UPDATE_COLORS,
  HTTP_UPDATE_FONTS,
  HTTP_UPDATE_COLORS,
  LOAD_ROLES,
  REMOVE_ACTIVE_DESIGN_OBJECT,
  SUBMIT_FORM,
  RESOLVE_LINKED_DATA,
  HTTP_GET_DESIGN_OBJECT_METADATA,
  GET_DESIGN_OBJECT_METADATA_ON_CHANGE,
  COPY_PAGE,
  MANAGE_EDITOR,
  HTTP_RENAME_IMAGE,
  HTTP_DELETE_IMAGE,
  HTTP_MOVE_IMAGE,
  HTTP_DELETE_FOLDER,
  HTTP_GET_MEDIALIBRARY_FOLDERS,
  HTTP_MEDIALIBRARY_RENAME,
  UPDATE_ACTIVE_DESIGN_OBJECT_SETTINGS,
  DELETE_BLOCK,
  RESET_ACTIVE_DESIGN_OBJECT,
  LOAD_NEW_DESIGN_OBJECT_DRAFT,
  LOAD_DESIGN_OBJECT_DRAFT,
  DELETE_DESIGN_DRAFT,
  SAVE_DESIGN_OBJECT_DRAFT,
  CLOSE_PREVIEW_DESIGN_OBJECT,
  CALL_URL,
  SEND_EMAIL,
  OPEN_NEW_TAB,
  OPEN_POPUP_WINDOW,
  PREVIEW_DESIGN_OBJECT,
  UPDATE_PAGE,
  GET_ALL_PAGES,
  LOAD_DYNAMIC_PAGE,
  NEW_DYNAMIC_PAGE,
  REORDER_BLOCKS,
  INSERT_BLOCK_AT_INDEX,
  SAVE_PAGE,
  GET_PAGE_BY_SLUG,
  HTTP_SAVE_PAGE,
  HTTP_UPDATE_PAGE,
  HTTP_DELETE_PAGE,
  HTTP_UPLOAD_IMAGE,
  HTTP_CREATE_FOLDER,
  HTTP_GET_MEDIALIBRARY_IMAGES,
  LOAD_ADMIN_ROUTE,
  LOAD_PUBLIC_ROUTE,
  ADD_GLOBAL_ERROR,
  INITIALIZE,
  NOOP,
  UNKNOWN,
  // BE ONLY ACTIONS
  SET_AUTHENTICATION_HEADERS,
  INITIALIZE_ENVIRONMENT,
  MEDIA_LIBRARY_OPEN,
  MEDIA_LIBRARY_CLOSE,
  // TEMPLATES
  LOAD_DOCUMENT,
  LOAD_CUSTOM_LIST_DEFINITIONS,
  DELETE_CUSTOM_LIST_DEFINITION,
  LOAD_CUSTOM_LIST_DEFINITION,
  LOAD_DYNAMIC_CUSTOM_LIST_DEFINITION,
  LOAD_CUSTOM_LIST_DEFINITION_ENTRIES,
  SAVE_CUSTOM_LIST_ENTRY,
  NEW_CUSTOM_LIST_ENTRY,
  LOAD_DYNAMIC_CUSTOM_LIST_ENTRY,
  LOAD_CUSTOM_LIST_ENTRY,
  HTTP_UPDATE_CUSTOM_FONTS,
  HTTP_UPDATE_GOOGLE_FONTS,
  SET_GOOGLE_FONTS_STATE,
  SET_CUSTOM_FONTS_STATE,
  INSERT_BLOCK_IN_CONTAINER,
  SET_SORT_FOR_BLOCK_LINKED_DATA
}
