import { ISettingsColor } from '../interface/settings.interface';
import { getColorHexValue } from '../util/style/get-color-hex-value.util';
import { isArray } from 'lodash';
import { AppStateModel } from '../model/app-state.model';
import { invariant } from '../util/debug/invariant.util';

interface IStyledPropsWithIPTheme {
  theme: {
    _ip_colors: ISettingsColor[]
  };
}

interface IStateProps {
  state: AppStateModel;
}

function isStyledProps(props: any): props is IStyledPropsWithIPTheme {
  return props.hasOwnProperty('theme') && props.theme && props.theme.hasOwnProperty('_ip_colors') && props.theme._ip_colors;
}

function isColors(props: any): props is ISettingsColor[] {
  return isArray(props) && (props.some((p) => p.hasOwnProperty('saturation')) || props.length === 0);
}

function isStateProps(props: any): props is IStateProps {
  return props.hasOwnProperty('state') && props.state && props.state instanceof AppStateModel;
}

const DefaultColorEnum = {
  DarkBlack: '#2C2C2C',
  ContentBackground: '#F5F5F5',
  RightSettingsBackground: '#FBFBFB',
  ContentColor: '#505050',
  White: '#FFFFFF',
  Blue: '#0098C3',
  Green: '#69BE28',
  Orange: '#FF6E00',
  CoolGray: '#ACBAC1',
  RegentGray: '#7A8F9B',
  DarkGray: '#4C6A7C',
  Red: '#CB0044',
  Red30Opacity: '#e12a66',
  AppChrome: '#4C6A7C',
  MediumBlue: '#52B9D7',
  MediumGreen: '#8DCD5D',
  MediumOrange: '#FF994C',
  MediumGray: '#88929F',
  MediumRed: '#D53369',
  MediumDisabled: '#C6D0D5',
  LightBlue: '#DCEBF0',
  LightGreen: '#DEEBD4',
  LightOrange: '#F5E3D6',
  LightColdGray: '#C0C6CD',
  LightGray: '#F0F0F0',
  HoverGray: '#E8E8E8',
  Selected: '#DDF1F7',
  Gray: '#909090',
  Tundora: '#4A4A4A',
  AshGray: '#D5D5D5',
  StoneBlue: '#7E8B98',
  SkyBlue: '#98B2CA',
  BerryBlue: '#4D7399',
  PearlGray: '#C7C7C7',
  GrayGreen: '#A0B094',
  LimeGreen: '#ABDC84',
  DarkBlue: '#1E2E3D',
  AltoGray: '#E0E0E0',
  Yellow: '#FFEC7B'
};

export type ColorEnum = {
  [key in keyof typeof DefaultColorEnum]: string;
};

interface IColorEnumGetColor {
  Transparent: string;
  getColor: (colorId: string) => string;
  getAllColors: () => string[];
  getAllBlockColors: () => string[];
  getRawColor: (colorId: string) => ISettingsColor;
  getAllRawColors: () => ISettingsColor[];
  getAllRawBlockColors: () => ISettingsColor[];
}

export type ColorEnumReturn = ColorEnum & IColorEnumGetColor;

export type ColorEnumHelper = {
  [key in keyof ColorEnum]: (props) => string;
} & { Transparent: (props?) => string, getColor: (colorId: string) => (props) => string };

export const ColorEnum: ((props: IStyledPropsWithIPTheme | ISettingsColor[]) => ColorEnumReturn) & ColorEnumHelper = ((props: any) => {
  let colors: ISettingsColor[];
  if (isStyledProps(props)) {
    colors = props.theme._ip_colors;
  } else if (isColors(props)) {
    colors = props;
  } else if (isStateProps(props)) {
    invariant(false, 'Use props.state.colors.[WantedColor] when using props with state');
  } else {
    invariant(false, 'As a input to this function, you must supply props from a styled component, or a list of colors', Object.keys(props));
  }
  if (colors) {
    const toReturn: ColorEnumReturn = {} as any;
    const currentKnownKeys = Object.keys(DefaultColorEnum);
    for (const key of currentKnownKeys) {
      Object.defineProperty(toReturn, key, { get: () => getColorHexValue(colors.filter((c) => c.id === key && c.predefined)[0], colors) || DefaultColorEnum[key] });
    }
    toReturn.getRawColor = (colorId) => {
      return colors.filter((c) => c.id === colorId)[0];
    };
    toReturn.getColor = (colorId) => {
      if (!colorId || colorId === 'Transparent') {
        return ColorEnum.Transparent();
      }
      const color = colors.filter((c) => c.id === colorId)[0];
      if (!color) {
        return null;
      }
      return getColorHexValue(color, colors);
    };
    toReturn.getAllColors = () => {
      return colors.map((c) => getColorHexValue(c, colors));
    };
    toReturn.getAllRawColors = () => {
      return colors.slice();
    };
    toReturn.getAllBlockColors = () => {
      return colors.filter(color => !color.isAdmin).map((c) => getColorHexValue(c, colors)).concat(['Transparent']);
    };
    toReturn.getAllRawBlockColors = () => {
      return colors.filter(color => !color.isAdmin);
    };
    toReturn.Transparent = ColorEnum.Transparent();
    return toReturn;
  }
  return {} as any;
}) as any;

const knownColorKeys = Object.keys(DefaultColorEnum);
for (const key of knownColorKeys) {
  ColorEnum[key] = (props) => ColorEnum(props)[key];
}
ColorEnum.Transparent = () => 'rgba(0, 0, 0, 0)';
ColorEnum.getColor = (colorId) => (props) => ColorEnum(props).getColor(colorId);

let configuredColors: {
  [key in ColorType]: string;
} = {} as any;

export function configureColors(colorConfig: {
  [key in ColorType]: string;
}) {
  configuredColors = colorConfig || {} as any;
}

const keys = Object.keys(ColorEnum);
for (const key of keys) {
  const defaultValue = ColorEnum[key];
  Object.defineProperty(ColorEnum, key, {
    get: () => {
      return configuredColors[key] || defaultValue;
    },
    configurable: true
  });
}

export type ColorType = keyof typeof DefaultColorEnum | 'Transparent';

export const allColors = Object.keys(DefaultColorEnum);
