// AUTH
export const ACCESS_TOKEN_KEY = 'jwt_accesstoken';
export const ID_TOKEN_KEY = 'jwt_idtoken';
export const LOCALE_KEY = 'locale';
export const REDIRECT_AFTER_LOGIN_COOKIE = 'redirectUrlAfterLogin';
export const COOKIE_POLICY_ACCEPTED = 'cookiePolicyAccepted';
export const ADMIN_HEADER = 'X-IsInAdmin';

export const BLOCKS_PICKER_DROPPABLE_ID = 'BLOCKS_PICKER_DROPPABLE_ID';
export const EDIT_PAGE_DROPPABLE_ID = 'EDIT_PAGE_DROPPABLE_ID';
export const REPEATER_BLOCK_DRAGGABLE_ID = 'REPEATER_BLOCK_DRAGGABLE_ID';
export const AJAX_REQUEST_DEBOUNCE_MS = 200;
export const TAB_LABEL_HEIGHT = 33;
export const IMAGE_HEIGHT = 455;
export const FAVICON_LINK = 'faviconLink';

export const XLARGE_SCREEN_SIZE = 991;
export const LARGE_SCREEN_SIZE = 767;
export const SMALL_SCREEN_SIZE = 600;
export const XSMALL_SCREEN_SIZE = 380;

// z-indexes
export const HORIZONTAL_ICON_MENU_ZINDEX = 100;
export const COOKIE_POLICY_Z_INDEX = 100000;
export const REACT_SELECT_ZINDEX = 50;
export const HEADER_VIEW_IN_VIEW_MODE = 10000; // make sure this is above all
export const HEADER_MENU_CONTENT_ZINDEX = 501;
export const LAYERS_DIV_ID = 'LAYERS_DIV_ID';
export const POPUP_CONTAINER_ID = 'POPUP_CONTAINER_ID';

export const STATIC_PAGE_SLUGS = [ '404', 'error' ];
// social buttons
export const SOCIAL_BUTTON_LARGE_ICON_SIZE = 24;
export const SOCIAL_BUTTON_SMALL_ICON_SIZE = 24;

export const SOCIAL_BUTTON_LARGE_FONT_SIZE = 16;
export const SOCIAL_BUTTON_SMALL_FONT_SIZE = 16;

export const IMAGE_NOT_FOUND_PLACEHOLDER = 'https://www.datacenters.com/image-not-found.svg';
export const ADMIN_NAVIGATION_WIDTH = 64;
// values used by twitter, facebook window popups
export const POPUP_WINDOW_WIDTH = 550;
export const POPUP_WINDOW_HEIGHT = 471;
export const POPUP_LARGE_WINDOW_WIDTH = 640;
export const POPUP_LARGE_WINDOW_HEIGHT = 720;

export const LINKED_IN_BACKGROUND_COLOR = '#0077b5';

export const LINKED_IN_LOGIN_ROUTE = 'linkedInLogin';
export const LINKED_IN_LOGIN_STORAGE_KEY = 'linkedInProfile';

export const DEFAULT_GLOBAL_ERROR_TITLE = 'Oops! Something went wrong';
export const DEFAULT_NO_INTERNET_CONNECTION_TITLE = 'It seems like you are not connected to the internet';
export const DEFAULT_GLOBAL_ERROR_SHOW_MORE = 'Show error code';
export const DEFAULT_ERROR_JOBAD_EXPIRED = 'Job Ad has expired';
export const DEFAULT_ERROR_JOBAD_NOT_PUBLISHED = 'Job Ad is inactive';
export const DEFAULT_IE_WARNING = `The web browser you are using is not supported for this website.
If you want the full experience of this website, please use any of the other browsers listed down bellow:
* Chrome
* Safari
* Firefox
* Etc`;

// markers
export const ROOTED_LINKED_DATA_ID_PREFIX = '__rooted.';

// Nested items constants
export const ITEMS_WRAPPER_MAX_HEIGHT = 444;
export const ITEMS_WRAPPER_DEFAULT_WIDTH = 356;
export const ITEM_HEIGHT = 52;
export const MARGIN = 13;
export const WIDTH_TO_DURATION_CONVERSION_FACTOR = 1.4;

export const SUBMENU_WIDTH = 250;

// TODO: Really bad piece of code. Depends on class names from Backend
export const CATEGORY_CLASS_NAMES =
  [ 'JobAdModel.Title', 'JobAdModel.Company', 'JobAdModel.Country', 'JobAdModel.State', 'JobAdModel.JobPositionTitle', 'JobAdModel.Category' ];
export const LOCATION_CLASS_NAMES = [ 'JobAdModel.Municipality' ];
export const SLIDERS_ANIMATION_DURATION = 0.3;

// SLUG keywords
export const CUSTOM_LIST_SLUG = 'content';
export const JOBAD_SLUG = 'jobb';
export const JOBAD_DETAILS_SLUG = 'lediga-jobb';
export const ADMIN_PREVIEW_KEYWORD = 'preview';

// LOADER
export const LOADING_SHOW_TIMEOUT = 450;
