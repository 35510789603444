import { observable } from 'mobx';
import { persist } from 'mobx-persist';
import { editGroup } from '../../block/decorators/edit-group.decorator';
import { editable } from '../../block/decorators/editable.decorator';
import { toggleEdit } from '../../../util/block/edit-types/toggle-edit.util';
import { IBaseRequiredFieldOptions } from '../../../interface/custom-lists/base-required-field-options.interface';
import { BaseFieldOptionsModel } from './base-field-options.model';

export class BaseRequiredFieldOptionsModel extends BaseFieldOptionsModel implements IBaseRequiredFieldOptions {
  @editGroup.CONTENT
  @editable(
    toggleEdit()
      .label('custom-list.field-options.required-label')
  )
  @persist @observable required: boolean;
}