import { isRouteAction } from './is-route-action';
import { Dispatcher } from '../../interface/action/dispatcher.type';
import { IAction } from '../../interface/action/action.interface';
import { isFunction } from 'lodash';
import * as Actions from './type/all-actions';
import { invariant } from '../debug/invariant.util';
import { ConfigurationModel } from '../../model/configuration.model';

export function createComponentDispatcher(dispatcher: Dispatcher, config: ConfigurationModel): Dispatcher {
  const componentDispatcher: Dispatcher = ((action: IAction) => {
    return new Promise((resolve) => {
      invariant(!isRouteAction(action, config), 'You can\'t dispatch route actions from a component!');
      resolve(dispatcher(action));
    });
  }) as any;
  Object.keys(Actions)
    .forEach(key => {
      const value = Actions[ key ];
      if (isFunction(value)) {
        componentDispatcher[ key ] = (...args) => componentDispatcher(value(...args));
      } else {
        componentDispatcher[ key ] = value;
      }
    });
  return componentDispatcher;
}
