import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import {
  IDesignObjectMentionMetadata,
  IFilterGroup,
  IUsedDesignObjectMentionMetadata
} from '../../../interface/page/design-object-mention-metadata.interface';
import { toJS } from 'mobx';
import { FilterValueTypes, IFilter } from '../../../interface/page/mention-filter.interface';
import { DesignObjectModel } from '../../../model/design-object.model';

type MentionFilterType = (mention: IDesignObjectMentionMetadata) => boolean;

export interface IMentionPickerComponentProps {
  placeholder?: string;
  filterPlaceholder?: string;
  filterFieldPlaceholder?: string;
  mentionFilter?: MentionFilterType;
  showFilters?: boolean;
}

interface IReturnType<E> {
  placeholder: (placeholder: string) => MentionPickerReturnType<E>;
  filterPlaceholder: (filterPlaceholder: string) => MentionPickerReturnType<E>;
  filterFieldPlaceholder: (filterFieldPlaceholder: string) => MentionPickerReturnType<E>;
  filterMentions: (filterFunction: MentionFilterType) => MentionPickerReturnType<E>;
  showFilters: (shouldShow: boolean) => MentionPickerReturnType<E>;
}

export type MentionPickerReturnType<E> =
  IEditModeReturnValue<IMentionPickerComponentProps, IUsedDesignObjectMentionMetadata, IReturnType<E> & E>
  & IReturnType<E>
  & E;

export const mentionPickerEdit = <E = {}>(componentProps: IMentionPickerComponentProps = {} as any) => {
  const toReturn: MentionPickerReturnType<E> & E = returnEditMode<IMentionPickerComponentProps, IUsedDesignObjectMentionMetadata, IReturnType<E> & E>('mentionPicker', componentProps) as any;
  toReturn.placeholder = (placeholder: string) => {
    toReturn._decoratorProperties.componentProps.placeholder = placeholder;
    return toReturn;
  };
  toReturn.filterPlaceholder = (filterPlaceholder: string) => {
    toReturn._decoratorProperties.componentProps.filterPlaceholder = filterPlaceholder;
    return toReturn;
  };
  toReturn.filterFieldPlaceholder = (filterFieldPlaceholder: string) => {
    toReturn._decoratorProperties.componentProps.filterFieldPlaceholder = filterFieldPlaceholder;
    return toReturn;
  };
  toReturn.filterMentions = (filterFunction: MentionFilterType) => {
    toReturn._decoratorProperties.componentProps.mentionFilter = filterFunction;
    return toReturn;
  };
  toReturn.showFilters = (shouldShow) => {
    toReturn._decoratorProperties.componentProps.showFilters = shouldShow;
    return toReturn;
  };
  toReturn.setUsedMentionsReducer((data: IUsedDesignObjectMentionMetadata, design: DesignObjectModel, findLinkedData) => {
    return [ data, ...(findLinkedData(data).filters || {filterGroups: []})
      .filterGroups
      .reduce((acc, filterGroup: IFilterGroup) => acc.concat(toJS(filterGroup.filters)), [])
      .map((f: IFilter) => f.value.filter((v) => v && v.value && v.filterValueType === FilterValueTypes.MENTION))
      .reduce((accumulator, currentValues) => {
        accumulator = accumulator.concat(currentValues);
        return accumulator;
      }, []).map(v => ({
        id: v.value,
        isBlockData: false
      })) ];
  });
  return toReturn;
};
