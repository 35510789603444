import * as React from 'react';
import { BlockGroupEnum } from '../../enum/block/block-group.enum';
import { IBlockComponent } from '../../interface/block/block-component.interface';
import { IBlockDataModelConstructor } from '../../interface/block/block-data-contructor.interface';
import { DesignTypeEnum } from '../../enum/design-type.enum';
import { BaseBlockDataModel } from '../../model/block/base-block-data.model';
import { AppStateModel } from '../../model/app-state.model';

export const getBlock: <T extends BaseBlockDataModel>(model: IBlockDataModelConstructor<T>, group: BlockGroupEnum, title: string, icon: React.ReactNode, view: React.ComponentClass, specificViews?: { [key in DesignTypeEnum]?: React.ComponentClass } & { text?: (model: T, state: AppStateModel) => string }) => IBlockComponent = (model, group, title, icon, view, specificViews = {}) => {
  return Object.freeze({
    model, view, group, title, icon, specificViews
  });
};
