import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { IGetHomePagePayload } from '../../../action/http.action';
import { DesignTypeEnum } from '../../../enum/design-type.enum';

export const getHomePage = (type: DesignTypeEnum, filterParameters?: any, useCache: boolean = true) => returnAction<IGetHomePagePayload>(ActionEnum.GET_HOME_PAGE, {
  type,
  filterParameters,
  useCache
});
