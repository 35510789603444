import * as React from 'react';
import { observer } from 'mobx-react';
import { SnippetsCollectorContext } from './snippets-collector';
import { SnippetContext } from './snippet-context';

interface ISnippetsContextComponentProps {
  snippetsCollector: SnippetsCollectorContext;
}

@observer
export class SnippetsProvider extends React.Component<ISnippetsContextComponentProps> {
  render() {
    return <SnippetContext.Provider value={this.props.snippetsCollector}>
      {React.Children.only(this.props.children)}
    </SnippetContext.Provider>;
  }
}
