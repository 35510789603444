import { IFieldDefinition } from '../../interface/custom-lists/field-definition.interface';
import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { CustomListFieldDefinitionTypeEnum } from '../../enum/custom-lists/custom-list-field-definition-type.enum';
import { BaseFieldOptionsModel } from './fields/base-field-options.model';
import { IBaseFieldOptions } from '../../interface/custom-lists/base-field-options.interface';
import { editable } from '../block/decorators/editable.decorator';
import { editGroup } from '../block/decorators/edit-group.decorator';
import { decoratedPropertiesEdit } from '../../util/block/edit-types/decorated-properties-edit.util';
import { plainStringEdit } from '../../util/block/edit-types/plain-string-edit.util';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { getCustomListFieldTypeLabel } from '../../component/page/admin/custom-list-definition-editor.page/get-custom-list-field-type-label.util';
import { getUniqueId } from '../../util/string/get-unique-id.util';

const visiblePredicate = (data) => data.type !== undefined && data.type !== null;

export class FieldDefinitionModel<T extends BaseFieldOptionsModel = BaseFieldOptionsModel> implements IFieldDefinition {
  @observable @persist id: string;
  @editGroup.CONTENT
  @editable(
    enumPickerEdit()
      .label('custom-list.type')
      .setOptionsGetter(() => import('../../util/custom-lists/field-definition-by-type.enum').then(m => Object.keys(m.FieldDefinitionsByType).map((type) => ({
        label: getCustomListFieldTypeLabel(type as any),
        value: type
      }))))
  )
  @observable @persist type: CustomListFieldDefinitionTypeEnum;

  @editGroup.CONTENT
  @editable(
    plainStringEdit()
      .label('custom-list.label')
      .required('custom-list.label.required')
      .visibleWhen(visiblePredicate)
  )
  @observable @persist label: string;

  @editGroup.CONTENT
  @editable(
    decoratedPropertiesEdit()
      .label('custom-list.field.options')
      .setUniqueIdGetter((data) => data.id)
      .visibleWhen(visiblePredicate)
  )
  @observable @persist('object') fieldOptions: T = {} as any;

  constructor() {
    this.type = (this.constructor as any).customListFieldType;
    this.id = getUniqueId();
    this.setData(undefined);
  }

  @action
  setFieldOptions = (data: IBaseFieldOptions) => {
    this.fieldOptions = new BaseFieldOptionsModel() as any;
    Object.assign(this.fieldOptions, data);
  }

  @action
  setData = (data: IFieldDefinition) => {
    Object.assign(this, data);
    this.setFieldOptions((data ? data.fieldOptions : undefined) as any);
  }
}
