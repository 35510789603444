import { AuthenticatedBlockModel } from './block/authenticated-block.model';
import { computed, observable, toJS } from 'mobx';
import { createBlock } from '../util/block/create-block.util';
import { persist } from 'mobx-persist';
import { custom, list, serializable } from 'serializr';
import { createLinkedDataFromData } from '../util/linked-data/create-linked-data-from-data';
import { LinkedDataModel } from './linked-data/linked-data.model';
import { flatten } from '../util/array/flatten.util';
import { IDesignObjectData } from '../interface/design-object-data.interface';
import { BlockModel } from './block/block.model';
import { DesignTypeEnum } from '../enum/design-type.enum';
import { editGroup } from './block/decorators/edit-group.decorator';
import { editable } from './block/decorators/editable.decorator';
import { colorPickerEdit } from '../util/block/edit-types/color-picker-edit.util';
import { BlockColorType } from '../enum/block-color.enum';
import { isArrayLike, isBoolean, isDate, isNumber, isString, uniqBy } from 'lodash';

const LinkDataSerializationFunction = (linkData: LinkedDataModel) => {
  return toJS(linkData);
};

const LinkDataDeseralizationFunction = (linkData: any) => {
  return createLinkedDataFromData(linkData);
};

const BlockSerializationFunction = (block: AuthenticatedBlockModel) => {
  return toJS(block);
};

const BlockDeseralizationFunction = (blockData: any) => {
  return createBlock(blockData);
};

function isPlainObject(value) {
  if (value === null || typeof value !== 'object') {
    return false;
  }
  const proto = Object.getPrototypeOf(value);
  return proto === Object.prototype || proto === null;
}

export function assignDesignObjectValue<T extends DesignObjectModel, D extends IDesignObjectData>(designObject: T, designData: D, key: keyof T): void {
  if (designData[ key as any ] === undefined) {
    designObject[ key ] = designObject[ key ];
  } else {
    const data = designData[ key as any ];
    if (data === undefined || data === null || isString(data) || isNumber(data) || isBoolean(data) || isDate(data) || isArrayLike(data)) {
      designObject[ key ] = data as any;
    } else {
      if (isPlainObject(data)) {
        designObject[ key ] = data;
      } else {
        // handle proxy objects because mobx doesn't recognize proxies
        const newObject: any = {};
        Object.keys(data).forEach((objectKey) => {
          newObject[ objectKey ] = data[ objectKey ];
        });
        designObject[ key ] = newObject;
      }
    }
  }
}

export class DesignObjectModel implements IDesignObjectData {
  @computed
  get linkedData(): LinkedDataModel[] {
    return this._linkedData;
  }

  set linkedData(value: LinkedDataModel[]) {
    this._linkedData = uniqBy(value, 'id');
    this.filterOutUnusedLinkedData();
  }

  @computed
  get usedMentions(): string[] {
    return flatten(this.blocks.map((block) => block.getUsedMentions(this).slice()));
  }

  @persist @observable id: string;
  @persist @observable dateUpdated: string = '';

  @editGroup.CONTENT
  @editable(colorPickerEdit()
    .label('design-object-editor-settings.background-color'))
  @persist @observable backgroundColor: BlockColorType = 'Transparent';

  @persist @observable type: DesignTypeEnum = DesignTypeEnum.Page;
  @serializable(list(custom(BlockSerializationFunction, BlockDeseralizationFunction))) @observable blocks: BlockModel[] = [];
  // Local only property to indicate that the design object is in draft mode
  @observable isDraft: boolean = false;

  @observable @serializable(list(custom(LinkDataSerializationFunction, LinkDataDeseralizationFunction))) private _linkedData: LinkedDataModel[] = [];

  get uniqueId() {
    return `design-${this.type}-${this.id}`;
  }

  constructor(data?: IDesignObjectData, inAdmin?: boolean, isDraft: boolean = false) {
    if (data) {
      assignDesignObjectValue(this, data, 'id');
      assignDesignObjectValue(this, data, 'dateUpdated');
      assignDesignObjectValue(this, data, 'backgroundColor');
      assignDesignObjectValue(this, data, 'type');
      this.blocks = (data.blocks || []).map((block) => createBlock(block));
      this._linkedData = (data.linkedData || (data as any)._linkedData || []).map((currentLinkedData) => createLinkedDataFromData(currentLinkedData, inAdmin));
    }
    this.isDraft = isDraft;
  }

  filterOutUnusedLinkedData = () => {
    const usedMentionIds = this.usedMentions;
    this._linkedData = this._linkedData.filter((data) => usedMentionIds.indexOf(data.id) !== -1);
  }
}
