import { returnAction } from '../return-action.util';
import { ActionEnum } from '../../../enum/action.enum';
import { IHttpUpdateTemplatePayload } from '../../../action/http.action';
import { EmailModel } from '../../../model/email.model';
import { WebDocumentModel } from '../../../model/web-document.model';

export const httpUpdateTemplate = (templateId: string, content: EmailModel | WebDocumentModel) => returnAction<IHttpUpdateTemplatePayload>(ActionEnum.HTTP_UPDATE_TEMPLATE, {
  content,
  templateId
});
