import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { observable } from 'mobx';
import { editGroup } from './decorators/edit-group.decorator';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { toggleEdit } from '../../util/block/edit-types/toggle-edit.util';
import { plainTextEdit } from '../../util/block/edit-types/plain-text-edit.util';
import { orderEditorEdit } from '../../util/block/edit-types/order-editor.util';
import { SocialShareButtonsEnum } from '../../enum/block/social-share-buttons.enum';
import { FacebookShareIconWrapper } from '../../component/social-share-icons/facebook-share-icon-wrapper';
import { TwitterShareIconWrapper } from '../../component/social-share-icons/twitter-share-icon-wrapper';
import { LinkedShareInIconWrapper } from '../../component/social-share-icons/linked-share-in-icon-wrapper';
import { MailShareIconWrapper } from '../../component/social-share-icons/mail-share-icon-wrapper';
import { tagsEdit } from '../../util/block/edit-types/tags-edit.util';
import { blockLimit } from './decorators/block-limit.decorator';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';

export interface IButtonVisibility {
  showButton: boolean;
}

export interface ISocialShareButtons {
  size: 'small' | 'large';
}

export interface IFacebookButton extends ISocialShareButtons, IButtonVisibility {
}

export interface ILinkedInButton extends ISocialShareButtons, IButtonVisibility {
  description?: any;
  title?: any;
}

export interface ITwitterButton extends ISocialShareButtons, IButtonVisibility {
  inviteText?: any;
  hashTags?: string[];
  via?: any;
}

export interface IMailButton extends ISocialShareButtons, IButtonVisibility {
  subject?: any;
  body?: any;
}

const enumSizePicker = (label: string) => enumPickerEdit()
  .label(label)
  .placeholder(`social-share.edit.buttonStylePlaceholder_size`)
  .addOption('social-share.edit.buttonStyleSmall', 'small')
  .addOption('social-share.edit.buttonStyleLarge', 'large')
  .onParameter('size');

const showButtonToggle = (label: string) => toggleEdit()
  .label(label)
  .labelDirection('vertical')
  .onParameter('showButton');

const orderSocialButtonsEdit = (label: string) => orderEditorEdit<SocialShareButtonsEnum>()
  .label(label)
  .addOptions([
    {
      key: SocialShareButtonsEnum.Facebook,
      component: FacebookShareIconWrapper,
      showOption: (block: SocialShareButtonsModel) => block.facebookButton.showButton
    },
    {
      key: SocialShareButtonsEnum.Twitter,
      component: TwitterShareIconWrapper,
      showOption: (block: SocialShareButtonsModel) => block.twitterButton.showButton
    },
    {
      key: SocialShareButtonsEnum.LinkedIn,
      component: LinkedShareInIconWrapper,
      showOption: (block: SocialShareButtonsModel) => block.linkedInButton.showButton
    },
    {
      key: SocialShareButtonsEnum.Mail,
      component: MailShareIconWrapper,
      showOption: (block: SocialShareButtonsModel) => block.mailButton.showButton
    } ]
  );

@pageBlock
@customListBlock
@jobadBlock
@authenticatedBlock
@blockLimit(1)
@blockType(BlockTypeEnum.SocialShareButtons)
@blockBackgroundColor('PrimaryBackgroundColor')
export class SocialShareButtonsModel extends BaseBlockDataModel {
  @editGroup.STYLE
  @editable(orderSocialButtonsEdit('social-share.edit.order'))
  @observable socialButtonsOrder: SocialShareButtonsEnum[] = Object.keys(SocialShareButtonsEnum).map(key => SocialShareButtonsEnum[ key ]);

  @editGroup.STYLE
  @editable(
    enumSizePicker('social-share.edit.facebookButton-size'),
    showButtonToggle('social-share.edit.facebookButton-show')
  )
  @observable facebookButton: IFacebookButton = {
    size: 'small',
    showButton: true
  };

  @editGroup.STYLE
  @editable(
    enumSizePicker('social-share.edit.twitterButton-size'),
    plainTextEdit()
      .label(`social-share.edit.twitterButton_inviteText`)
      .onParameter('inviteText'),
    plainTextEdit()
      .label(`social-share.edit.twitterButton_via`)
      .onParameter('via'),
    tagsEdit()
      .label(`social-share.edit.twitterButton_hashTags_placeholder`)
      .onParameter('hashTags'),
    showButtonToggle('social-share.edit.twitterButton-show')
  )
  @observable twitterButton: ITwitterButton = {
    size: 'small',
    inviteText: getDraftStateFromString(''),
    via: getDraftStateFromString(''),
    hashTags: [],
    showButton: true
  };

  @editGroup.STYLE
  @editable(
    enumSizePicker('social-share.edit.mailButton-size'),
    plainTextEdit()
      .label(`social-share.edit.mailButton_subject`)
      .onParameter('subject'),
    plainTextEdit()
      .label(`social-share.edit.mailButton_body`)
      .onParameter('body'),
    showButtonToggle('social-share.edit.mailButton-show')
  )
  @observable mailButton: IMailButton = {
    subject: getDraftStateFromString(''),
    body: getDraftStateFromString(''),
    size: 'small',
    showButton: true
  };

  @editGroup.STYLE
  @editable(
    enumSizePicker('social-share.edit.linkedInButton-size'),
    plainTextEdit()
      .label(`social-share.edit.linkedInButton_title`)
      .onParameter('title'),
    plainTextEdit()
      .label(`social-share.edit.linkedInButton_description`)
      .onParameter('description'),
    showButtonToggle('social-share.edit.linkedInButton-show')
  )
  @observable linkedInButton: ILinkedInButton = {
    showButton: true,
    title: getDraftStateFromString(''),
    description: getDraftStateFromString(''),
    size: 'small'
  };
}
