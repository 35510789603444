import { DesignTypeEnum } from '../../enum/design-type.enum';
import { joinUrl } from './join-url.util';
import { getUrlSafeString } from '../string/get-url-safe-string.util';
import { isStringEmpty } from '../string/is-string-empty.util';
import { CUSTOM_LIST_SLUG, JOBAD_DETAILS_SLUG, JOBAD_SLUG } from '../../constants';

interface IPageURIData {
  slug: string;
  title: string;
  isHomePage: boolean;
  type: DesignTypeEnum;
}

export const MAXIMUM_SEO_CHARACTERS_FOR_PAGE_URI = 200;

export function getPageURI(page: IPageURIData, id?: string, forceSlug: boolean = false): string {
  const type = page.type || DesignTypeEnum.Page;
  if (type === DesignTypeEnum.Page && page.isHomePage && !forceSlug) {
    return '/';
  }
  switch (type) {
    case DesignTypeEnum.JobAdPage: {
      const SEOSlug = getUrlSafeString(page.title).slice(0, MAXIMUM_SEO_CHARACTERS_FOR_PAGE_URI);
      if (page.isHomePage && !forceSlug) {
        return joinUrl(JOBAD_DETAILS_SLUG, id, SEOSlug);
      }
      if (!isStringEmpty(SEOSlug) && SEOSlug !== page.slug) {
        return joinUrl(JOBAD_SLUG, id, page.slug || '', SEOSlug);
      } else {
        return joinUrl(JOBAD_SLUG, id, page.slug || '');
      }
    }
    case DesignTypeEnum.CustomList: {
      return joinUrl(CUSTOM_LIST_SLUG, id, page.slug);
    }
    default: {
      return page.slug || '';
    }
  }
}
