import { ILinkedData } from '../../interface/linked-data.interface';
import { DesignObjectMentionMetadataType } from '../../enum/page-mention-metadata-type.enum';
import { isEmpty } from 'lodash';
import {
  IDesignObjectMentionMetadata,
  IFilterGroupsHolder
} from '../../interface/page/design-object-mention-metadata.interface';
import { computed, observable } from 'mobx';
import { ILinkedDataPagedProperties } from '../../interface/linked-data-paged-properties.interface';
import {ILinkedDataError} from '../../interface/linked-data-error.interface';

export class LinkedDataModel<T = any> implements ILinkedData {
  dataType: IDesignObjectMentionMetadata[];
  id: string;
  parentId: string;
  mentionId: string;
  displayName: string;
  resolverType: string;
  pickerResolver: string;
  initialAsyncData: boolean = false;
  @observable pagedProperties: ILinkedDataPagedProperties;
  @observable  sortBy: string;
  type: DesignObjectMentionMetadataType;
  error: ILinkedDataError;
  @observable filters: IFilterGroupsHolder;

  @observable private _data: T;

  @computed
  get data(): T {
    return this._data || (this as any).defaultData;
  }

  set data(data: T) {
    this._data = data;
  }

  @computed
  get isEmpty() {
    return (this as any).defaultData === this._data || isEmpty(this._data);
  }

  setServerData = (data: Partial<ILinkedData>) => {
    Object.assign(this, data);
  }

  generateDummyData = () => undefined;
}
