import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';
import { ColorType } from '../../../enum/color.enum';
import { invariant } from '../../debug/invariant.util';

export interface IColorEditComponentProps {
  excludedColors?: ColorType[];
}

interface IReturnType {
  excludedColor: (excludedColor: ColorType) => IEditModeReturnValue<IColorEditComponentProps, string, IReturnType>;
  textColors: () => IEditModeReturnValue<IColorEditComponentProps, string, IReturnType>;
  excludeTransparent: () => IEditModeReturnValue<IColorEditComponentProps, string, IReturnType>;
}

export const colorPickerEdit = (componentProps: IColorEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IColorEditComponentProps, string, IReturnType> = returnEditMode<IColorEditComponentProps, string, IReturnType>('colorPicker', componentProps) as any;
  toReturn.excludedColor = (excludedColor: ColorType) => {
    if (!toReturn._decoratorProperties.componentProps.excludedColors) {
      toReturn._decoratorProperties.componentProps.excludedColors = [];
    }
    invariant(toReturn._decoratorProperties.componentProps.excludedColors.indexOf(excludedColor) === -1, `The color: ${excludedColor} is already added to the excluded colors.`);
    toReturn._decoratorProperties.componentProps.excludedColors.push(excludedColor);
    return toReturn;
  };
  toReturn.labelDirection('vertical');
  toReturn.textColors = () => {
    return toReturn.excludeTransparent();
  };
  toReturn.excludeTransparent = () => {
    return toReturn.excludedColor('Transparent');
  };
  return toReturn;
};
