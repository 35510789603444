import { observable } from 'mobx';
import { BaseBlockDataModel } from './base-block-data.model';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { BlockColorType } from '../../enum/block-color.enum';
import { IBlock } from '../../interface/block/block.interface';
import { DesignObjectModel } from '../design-object.model';
import {IDesignObjectMentionMetadata} from '../../interface/page/design-object-mention-metadata.interface';

/**
 * Properties are persisted using custom serializer: BlockSerializationFunction as defined
 * in design-object.model.ts
 */
export class BlockModel<T extends BaseBlockDataModel = BaseBlockDataModel> implements IBlock {
  @observable id: string;
  @observable blockType: BlockTypeEnum;
  @observable backgroundColor: BlockColorType;

  @observable data: T;
  @observable parentBlockId: string;
  @observable possibleMentions: IDesignObjectMentionMetadata[];
  getUsedMentions: (design: DesignObjectModel) => string[] = (design: DesignObjectModel) => {
    if (!this.data) {
      return [];
    }
    return this.data.getUsedMentions(design);
  }
}
