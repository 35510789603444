import { IPageData } from '../../interface/page/page-data.interface';
import { toJS } from 'mobx';
import { DesignObjectModel } from '../../model/design-object.model';

export function convertDesignObjectToServerData(page: DesignObjectModel): IPageData {
  if (!page) {
    return undefined;
  }
  const designData: any = toJS(page);
  delete designData.isNew;
  delete designData.isDraft;
  delete designData.isCopy;
  delete (designData as any)._editableMetadata;
  designData.linkedData = (designData as any)._linkedData;
  delete (designData as any)._linkedData;
  designData.blocks = designData.blocks.map(block => {
    delete (block as any)._editableMetadata;
    return block;
  });
  return designData;
}
