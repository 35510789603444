import * as generateRandomKey from 'draft-js/lib/generateRandomKey';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import * as Draft from 'draft-js';

export const getDraftStateFromString = (message = '', mentions: { usedMention: IUsedDesignObjectMentionMetadata & { displayName: string }, position: { startIndex: number, endIndex: number } }[] = []) => {
  const draftState = {
    blocks: [ {
      key: generateRandomKey(),
      text: message,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    } ], entityMap: {}
  };
  for (let i = 0; i < mentions.length; i++) {
    const mention = mentions[ i ];
    draftState.entityMap[ i ] = {type: 'MENTION', mutability: 'IMMUTABLE', data: mention.usedMention};
    draftState.blocks[ 0 ].entityRanges.push({
      offset: mention.position.startIndex,
      length: mention.position.endIndex - mention.position.startIndex,
      key: i
    });
  }
  return draftState as Draft.RawDraftContentState;
};
