import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { ISelectedImage } from '../../interface/component/selected-image.interface';
import { editable } from './decorators/editable.decorator';
import { imagesEdit } from '../../util/block/edit-types/images-edit.util';
import { enumPickerEdit } from '../../util/block/edit-types/enum-picker-edit.util';
import { editGroup } from './decorators/edit-group.decorator';
import { numberEdit } from '../../util/block/edit-types/number-edit.util';
import { toggleEdit } from '../../util/block/edit-types/toggle-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { pageBlock } from './decorators/page-block.decorator';
import { jobadBlock } from './decorators/jobad-block.decorator';
import { BaseBlockDataModel } from './base-block-data.model';
import { customListBlock } from './decorators/custom-list-block.decorator';
import { imageSizeStylePickerEdit } from '../../util/block/edit-types/image-size-style-picker-edit.util';
import { ImageSizeStyleEnum } from '../../enum/image-size-style.enum';

@pageBlock
@customListBlock
@jobadBlock
@webDocumentBlock
@authenticatedBlock
@blockType(BlockTypeEnum.Image)
export class ImageModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(imagesEdit()
    .label('image.edit.images'))
  @observable images: ISelectedImage[] = [];

  @editGroup.STYLE
  @editable(imageSizeStylePickerEdit()
    .label('image.edit.images-size-style'))
  @observable imagesSizeStyle: ImageSizeStyleEnum = ImageSizeStyleEnum.Stretch;

  @editGroup.STYLE
  @editable(enumPickerEdit()
    .label(`image.edit.carouselStyle`)
    .addOption('image.edit.carouselStyleWide', 'wide')
    .addOption('image.edit.carouselStyleNormal', 'normal')
  )
  @observable style: 'wide' | 'normal' = 'normal';

  @editGroup.STYLE
  @editable(toggleEdit()
    .label('image.edit.shouldLoop')
    .visibleWhen(data => data.images && data.images.length > 1)
  )
  @observable loop: boolean = true;

  @editGroup.STYLE
  @editable(numberEdit()
    .label(`image.edit.presentationTimePlaceholder`)
    .min(1.5)
    .max(30)
    .visibleWhen((data: ImageModel) => data.loop && data.images && data.images.length > 1)
  )
  @observable transitionTime: number = 3;

  @editGroup.STYLE
  @editable(numberEdit()
    .label('image.edit.transitionDuration')
    .placeholder(`image.edit.transitionDurationPlaceholder`)
    .min(0.1)
    .max(1.2)
    .visibleWhen((data: ImageModel) => data.loop && data.images && data.images.length > 1)
  )
  @observable transitionDuration: number = 1;
}
