import { observable } from 'mobx';
import { BlockTypeEnum } from '../../enum/block/block-type.enum';
import { editable } from './decorators/editable.decorator';
import { editGroup } from './decorators/edit-group.decorator';
import { htmlEdit } from '../../util/block/edit-types/html-edit.util';
import { blockType } from './decorators/block-type.decorator';
import { blockBackgroundColor } from './decorators/block-background-color.decorator';
import { authenticatedBlock } from './decorators/authenticated-block.decorator';
import { webDocumentBlock } from './decorators/web-document-block.decorator';
import { getDraftStateFromString } from '../../util/block/get-draft-state-from-string.util';
import { BaseBlockDataModel } from './base-block-data.model';
import { dataSetEdit } from '../../util/block/edit-types/dataset-edit.util';
import { IUsedDesignObjectMentionMetadata } from '../../interface/page/design-object-mention-metadata.interface';
import { linkEdit } from '../../util/block/edit-types/link-edit.util';
import { LinkOriginEnum } from '../../enum/link-origin.enum';
import { ILink } from '../../interface/page/link.interface';

@authenticatedBlock
@webDocumentBlock
@blockType(BlockTypeEnum.RepeatingTextBlock)
@blockBackgroundColor('PrimaryBackgroundColor')
export class RepeatingTextBlockModel extends BaseBlockDataModel {
  @editGroup.CONTENT
  @editable(dataSetEdit()
    .label('repeating-text-block.edit.data')
    .placeholder('repeating-text-block.edit.select-data')
  )
  @observable selectedMention: IUsedDesignObjectMentionMetadata;

  @editGroup.CONTENT
  @editable(linkEdit()
    .label('repeating-text-block.edit.detailsLink')
    .isTemplate(true)
    .availableOrigin([ LinkOriginEnum.Mention ])
    .visibleWhen((data) => Boolean(data.selectedMention))
  )
  @observable detailsLinkTemplate: ILink;

  @editGroup.CONTENT
  @editable(htmlEdit()
    .label('repeating-text-block.textTitle'))
  @observable text: any = getDraftStateFromString('');
}
