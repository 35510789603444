import { FieldDefinitionModel } from '../field-definition.model';
import { customListFieldType } from '../decorators/custom-list-field-type';
import { CustomListFieldDefinitionTypeEnum } from '../../../enum/custom-lists/custom-list-field-definition-type.enum';
import { LinkFieldOptionsModel } from './options/link-field-options.model';
import { ILinkFieldOptions } from '../../../interface/custom-lists/link-field-options.interface';

@customListFieldType(CustomListFieldDefinitionTypeEnum.Link)
export class LinkFieldDefinitionModel extends FieldDefinitionModel<LinkFieldOptionsModel> {
  setFieldOptions = (data: ILinkFieldOptions) => {
    this.fieldOptions = new LinkFieldOptionsModel();
    Object.assign(this.fieldOptions, data);
  }
}
