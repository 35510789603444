import { IEditModeReturnValue, returnEditMode } from './return-edit-mode.util';

export interface IDecoratedPropertiesEditComponentProps {
  hiddenLabel?: boolean;
  getUniqueId?: (data: any) => string;
}

interface IReturnType {
  isLabelHidden: (hiddenLabel: boolean) => IEditModeReturnValue<IDecoratedPropertiesEditComponentProps, string, IReturnType>;
  setUniqueIdGetter: (getUniqueId: (data: any) => string) => IEditModeReturnValue<IDecoratedPropertiesEditComponentProps, string, IReturnType>;
}

export const decoratedPropertiesEdit = (componentProps: IDecoratedPropertiesEditComponentProps = {} as any) => {
  const toReturn: IEditModeReturnValue<IDecoratedPropertiesEditComponentProps, string, IReturnType> = returnEditMode<IDecoratedPropertiesEditComponentProps, string, IReturnType>('decoratedProperties', componentProps) as any;
  toReturn.isLabelHidden = (hiddenLabel => {
    toReturn._decoratorProperties.componentProps.hiddenLabel = hiddenLabel;
    return toReturn;
  });
  toReturn.setUniqueIdGetter = (getUniqueId) => {
    toReturn._decoratorProperties.componentProps.getUniqueId = getUniqueId;
    return toReturn;
  };
  return toReturn;
};
