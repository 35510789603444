import {action, observable} from 'mobx';
import { BaseBlockDataModel } from './base-block-data.model';
import {BlockModel} from './block.model';
import {DesignObjectModel} from '../design-object.model';
import {flatten} from '../../util/array/flatten.util';

export class BaseContainerBlockModel extends BaseBlockDataModel {

  @observable blocks: BlockModel[] = [];

  getUsedMentions(design: DesignObjectModel): string[] {
    const superMentions = super.getUsedMentions.apply(this, [design]);
    const innerMentions = flatten(this.blocks.map(block =>  block.getUsedMentions(design)));
    return superMentions.concat(innerMentions);
  }

  getUsedMentionsNotRecursivly(design: DesignObjectModel): string[] {
    const superMentions = super.getUsedMentions.apply(this, [design]);
    return superMentions;
  }

  @action
  addChildBlock(block: BlockModel) {
    this.blocks.push(block);
  }
}
